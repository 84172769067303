export default [
 {
    title: 'Human Behavior and Emerging Markets: The Case of Cricket World Cup and Indians Not Buying Televisions',
    summary: 'The thing about demographic dividend is that everyone will expect that more people with higher purchasing power will continue to drive consumption up. And it comes as a surprise when the companies have started to talk about slowdown. But that is the new reality in India and investor sentiment is seriously affected. The poor sales of televisions in the midst of a Cricket World Cup is an interesting example. The way retail sales of physical stores got impacted because of online purchases, is Television staring at the same fate and what it means?',
    category: 'News flow and Market Movement',
    date: '7/10/2019',
 },
 {
    title: 'Emerging Markets and Importance of Managing the Corporate Governance Risk',
   summary: 'To avoid unpleasant surprise, we need to apply a Corporate Governance filter first and then only trade. Another somewhat similar way is to look at the stocks through ESG (Environmental, Social and Governance) framework and then decide what you can invest in and what you should avoid. In EMs especially, that is as important as tracking news flow, understanding investor sentiment or analysing financial statements. In the markets to succeed, the first learning needs to be…how to minimize mistakes and is why ‘Corporate Governance’ helps.',
   category: 'News flow and Market Movement',
   date: '8/22/2019',
 },
 {
    title: "'Fundamentals' and 'Quant' - The Virtues of Discipline and Why Systematic Trading?",
    summary: 'The debate on which method of stock trading is superior between ‘fundamental approach’ and ‘quantitative approach’ is age old and never ending. Then, what is the solution? The best possible approach we believe is an approach which is systematic. The idea is simple: unless you follow an approach diligently and don’t let your emotions affect your investment decisions, it is difficult to evaluate it. You have rules and you don’t tweak them unless you think that some of the premises are no longer valid. Otherwise, you let the system do the ‘decision making’ for you.',
    category: 'News flow and Market Movement',
    date: '8/28/2019',
 },
{
    title: 'Human Emotions and How Systematic Investing Can Mitigate the Impact on Your Portfolio?',
    summary: 'Often it is difficult to control emotions in the markets and extraordinary events become even more testing. It takes great discipline to not follow emotions in investment and this is what the insight talks about. Even if one has a quantitative, machine learning based model, it is easy to yield to emotions and start tweaking with model parameters. Many quant managers yield to this temptation and tweak the parameters at the worst possible moment. Discipline does not come easy and it takes experience and practice, but it pays off.',
    category: ' News flow and Market Movement',
    date: '10/14/2019',
 },{
    title: 'The Listing of Saudi Aramco: Messages Beyond Emerging Markets',
    summary: 'As EMAlpha has noticed in several Emerging Markets, the Sentiment Analysis of News flow is a bigger game changer in countries where the information flow is irregular or the ones which are not understood that well by global investors. In this context, there are important takeaways from what the Initial Public Offering of Saudi Aramco means for the region and for the local and Emerging Markets in general. As we see in this insight, Market inefficiencies and investment opportunities will remain till the time financial markets exist and hence, an efficient and timely analysis of news and prevailing market sentiment could be the difference between gains and losses.',
    category: 'News flow and Market Movement',
    date: '2/9/2020',
 },{
    title: 'Coronavirus and Markets: Sentiment Bigger Driver than Fundamentals in Near-Term',
    summary: 'As the news sentiment has evolved leading to the market gyrations because of Coronavirus pandemic, EMAlpha is tracking the overall market sentiment on a daily basis. While the way forward does not look good, sentiment will clearly be a bigger driver for markets than fundamentals in the near term. In this insight, we also take a look at some of the important questions such as how are hedge fund strategies doing against this backdrop and how has the EMAlpha long/short equity strategy done?',
    category: 'News flow and Market Movement',
    date: '2/27/2020',
 },{
    title: 'Coronavirus Impact on Markets: Is Local News Sentiment Getting More Important?',
    summary: 'In the initial response to Coronavirus pandemic, most financial markets globally reacted in a predictable negative fashion. But this is slowly changing now and the role of local news flow and underlying sentiment is getting more prominent. For example, after India reported two fresh cases of Coronavirus in the afternoon on 2nd March, Indian markets reacted sharply and closed lower. It is not looking very good on EMAlpha country-by-country sentiment analysis and there could be more downside ahead.',
    date: '3/2/2020',
 },{
    title: 'News Sentiment Watch and How Portfolio Managers Can Use It for Systematic Trading?',
    summary: 'EMAlpha Coronavirus Sentiment reflects that the markets have reacted strongly to pandemic related news and country-by-country scores are even more interesting. We have used EMAlpha proprietary sentiment measure to first find the raw sentiment for each country for news related to Coronavirus and the raw scores were then Z-scored for standardization. As the daily sentiment changes for countries, it can be a useful input into systematic trading model for portfolio managers and it can also be valuable in risk management.  ',
    date: '3/4/2020',
 },{
    title: 'Human Irrationality, Threat Perception and Coronavirus Pandemic',
    summary: "As reflected in the EMAlpha News Sentiment and reaction of Global Markets to Coronavirus threat, it is easy to see why sentiment is often much more powerful than facts alone. Over the years, many philosophers, psychologists, authors and scientists have spoken about this phenomenon where the impact of news flow of certain events immediately triggers a 'survival reaction' or 'fight or flight' response. In many of these areas, one of the most elegant explanations and lucid examples backed by years of research and experiments with large sample size have come from Daniel Kahneman. This insight explores some of these snippets.",
    date: '3/4/2020',
 },{
    title: 'News Sentiment, Market Imapct and Unchecked Spread of Coronavirus Pandemic',
    summary: "On March 9th 2020, VIX Index, the widely monitored gauge of market volatility, shot up to levels not seen since the Great Financial Crisis more than ten years ago. At EMAlpha, we monitor daily news sentiments in a range of emerging and developed markets and from this, it has become clear that Coronavirus related news sentiment could continue to be a driver of market sentiment for a while. We collect Coronavirus related targeted news flow in a number of languages as for many emerging market countries, most of the news flow is in local language. There are some interesting observations on the market impact of the news and underlying sentiment country by country.",
    date: '3/10/2020',
 },{
    title: 'For Global Economy and Emerging Markets, How Much Better News Sentiment on US Helps',
    summary: "There have been interesting takeaways from the EMAlpha sentiment analysis for news flow around some very important and immensely popular key words. These news topics which matter for US Markets and hence, are important for other global markets can also capture the market direction effectively. EMAlpha machines have picked up some improvement in sentiment for US economy and that gels well with the improvement in sentiment for Dow Jones Index and NASDAQ composite both.",
    date: '3/25/2020',
 },{
    title: 'Dichotomy Between News and Reaction: Coronavirus Pandemic Getting worse, But Markets Getting Better',
    summary: "To assess the market impact of Coronavirus pandemic, EMAlpha tracks several important indicators including, a) Coronavirus Country-by-Country Sentiment, b) Global Coronavirus Sentiment, c) News Topic Sentiment on terms such as “Donald Trump”, “Dow Jones Index”, “Fed Interest Rate”, “Nasdaq Composite”, “Chinese Economy” and “US Economy”, d) Crude Oil related News Sentiment, and, e) Coronavirus Sentiment Heat Map. These EMAlpha Sentiment trackers offer interesting insights on the current state of this pandemic and how News coverage is reacting to it.",
    date: '3/31/2020',
 },{
    title: 'Why Local News based Sentiment Analysis Matters and Do Machines Can See the Difference?',
    summary: "When machines pick news on same subject in multiple languages for the same country, the result can be significantly different. EMAlpha has noted such divergence for many countries including China, Japan and Turkey and when news flow is different, sentiment analysis will not be the same. For Emerging Markets, EMAlpha experience with news collection and Sentiment Analysis for different countries in different languages confirms that English news and translated (from local language to English) are qualitatively and quantitatively different.",
    date: '4/8/2020',
 },{
    title: 'Fed Making Fundamentals Irrelevant For Markets - Saving From Disaster or Creating a Bigger One?',
    summary: "The Coronavirus pandemic has made the US Fed extremely active and this has led to a dichotomy. The market is telling you that it is focusing less on fundamentals and more on Fed. A look at the S&P 500 chart makes it clear the link between Fed Action, Impact of Coronavirus on Fundamentals of US Economy and the direction of Markets. There will always be questions but at least for the time being, the markets are telling you that what Fed is doing, is working.",
    date: '4/9/2020',
 },{
    title: "Is Donald Trump Losing 'Perception Battle' on Coronavirus Pandemic and Why Should Markets care?",
    summary: "In the past, US presidents have mostly benefitted from crisis, politically and in the eyes of media. However, EMAlpha News flow Sentiment on US President, Donald Trump had quickly worsened over last couple of weeks. And it has worsened significantly. Trump’s inconsistent approach and him being in rush to lift the lockdown could have been the key factors in making Trump an easy media target but it is a fact that the sentiment in news on Trump is negative. Why this is important for the markets in short to medium terms? Effective handling of crisis matters for Economic recovery and if the negative news sentiment on Trump sustains, this may play a key role in US presidential elections.",
    date: '4/15/2020',
 },{
    title: "News Sentiment on Trump Does Not Matter for Markets, Oh...Really?",
    summary: "The mishandling of Coronavirus pandemic negatively impacted the EMAlpha News sentiment on US President. When we look at Markets and EMAlpha News sentiment on Donald Trump, there is no sign that market is really bothered about Trump. However, it can be looked at this way: Once the emergency response has been provided by Fed, the economy will still require a careful steering and proactive, consistent response from the administration. So, Fed has saved the markets in near term but the divergence between news sentiment on Trump and market direction is not sustainable.",
    date: '4/17/2020',
 },{
    title: "Market and Coronavirus Pandemic News: Shifting Pendulum Between Optimism and Pessimism",
    summary: "On EMAlpha Global Coronavirus news sentiment and country-by-country Coronavirus sentiment, things continue to deteriorate. Most countries are struggling to contain infections and the global Coronavirus sentiment has deteriorated sharply. There are interesting takeaways from EMAlpha News Topic Sentiment for Key Words such as Donald Trump and Chinese Economy, Crude Oil News Sentiment and Aggregate India Equity Markets Sentiment. ",
    date: '4/20/2020',
 },{
    title: "Crude Oil and Coronavirus Pandemic: Oil Futures in Negative and How Machines are Reading it?",
    summary: "Monday, 20th April 2020 was a historic day for Crude Oil Industry as the May US oil futures contract in went into negative territory for the first time in history. The US reserves are full and there is simply no place to put the crude and everyone is avoiding taking delivery of physical crude. But it is not just a storage problem. There is limited visibility on when the demand will recover. This is also visible on EMAlpha Oil News Sentiment tracker as well. When seen in the context of SAUDI Aramco’s stock price movement and recovery in S&P 500, we can conclude that investors are less worried about markets in general and the concern is limited to Oil only. ",
    date: '4/21/2020',
 },{
    title: "Oil’s Historic Fall: Precipitated by Quickly Worsened Sentiment amid Demand Concerns",
    summary: "As May WTI Futures traded in Negative, the historic fall in crude oil prices prompted us to investigate this issue further on the lines of how EMAlpha Oil news sentiment fared around that time. After Coronavirus pandemic has become a global issue, the market performance in several countries is strongly linked with local news flow and underlying sentiment on Coronavirus related developments. This applies to Oil too as the EMAlpha Oil News sentiment plot shows how the oil sentiment has fallen to a level lower than what it was in mid-March when the Saudi-Russia talks broke down and oil price and stock market fell.",
    date: '4/22/2020',
 },{
    title: "Oil Sentiment has Already Hit Rock Bottom, But Will it Plunge even More?",
    summary: "While the drop in Oil prices is putting huge pressure on producers, they can’t easily agree on who needs to cut how much of production. The drop in demand and low crude prices actually made some of them more desperate in their attempts to gain market share. This is also visible on EMAlpha Oil News Sentiment tracker. While the market movements are related to specific reasons, the general sentiment on the demand side is extremely negative. EMAlpha Oil News Sentiment is at the lowest levels after it has hit an all-time low on April 20th. Because of this commodity’s strong linkage with global economy, will this have implications for financial markets worldwide? ",
    date: '4/27/2020',
 },{
    title: "‘News Sentiment Impact’ and Its Influence on Market Movement",
    summary: "For US economy and market indices like S&P500, the signals from fundamentals of economy and what Fed is doing are pulling the financial markets in US in opposite directions. From the market reaction to datapoints like jobless claims, can we deduce that the Fed action is in the price already while fundamentals could get worse and that will matter more for the markets. EMAlpha News flow sentiment on specific topics and from multiple countries is reflecting interesting linkages between Sentiment Analysis and Market movement. ",
    date: '4/30/2020',
 },{
    title: "Are Central Banks Encouraging Risk Taking Behavior in Speculators…Oops…Investors",
    summary: "EMAlpha Sentiment Analysis based on News flow indicates that the signalling mechanism by Central Banks is a very powerful tool to change the risk perception by market participants. When a Central Banker is telling investors that it is OK to indulge in high risk investments, investors become more adventurous. This has been a driving force for markets as picked in sentiment on commodities and country by country sentiment analysis. How much risk an asset class carries is more dependent on perception EMAlpha analysis confirms. ",
    date: '5/1/2020',
 },{
    title: "Facebook-Jio Deal and Stock Price Movement in Reliance Industries Limited Immediately Before that",
    summary: "Before Reliance Industries Ltd. (the largest market cap company in India) announced a deal with Facebook that the Social Media giant will Invest USD 5.6bn in Reliance-Jio Platforms for a 9.99% Stake, the Reliance stock had shown interesting movement. This movement which was distinctly different in the morning and afternoon session a day prior, provides further evidence about impact of sentiment and news flow on price. ",
    date: '5/4/2020',
 },{
    title: "Coronavirus Threat and Lockdowns: Which Countries can Afford Them and for How Long?",
    summary: "An analysis of the country-by-country EMAlpha New Sentiment shows that the Govt’s stance to combat Coronavirus pandemic is dependent on threat sentiment. When we analyse country sentiment scores for our news collection, we find that there are significant differences in the tone and frequency of Coronavirus related news flow. Some of the countries which are still nowhere close to the disastrous impact some others have seen, are still more scared. In reality, a country’s ability to afford a lock down depend on its economy, both size as well as wealth distribution over the population. However, Govt policy on lock downs will be determined more by threat perception than the ability to afford it. ",
    date: '5/10/2020',
 },{
    title: "Is Oil Sentiment Telling Us that Demand Recovery has Begun and How Long it Will Take?",
    summary: "One of the most closely tracked sentiments at EMAlpha is on Oil News as the short-term price movement in crude depends on near term outlook, which is influenced heavily by the news flow sentiment. If we go by the prevailing sentiment, the demand is unlikely to pick up anytime soon. However, the EMAlpha Oil News Sentiment is having its ‘break out’ moment and the Oil News sentiment has scaled its new peak. This is an entirely different picture from what we had seen in the month of April. Is this change in oil news sentiment telling us that we are past the worst of Coronavirus crisis? ",
    date: '5/14/2020',
 },{
    title: "Did World Media Underestimate Coronavirus Crisis in Latin America?",
    summary: "EMAlpha analysis of news and underlying sentiment indicates that news coverage on Coronavirus is partial. This directly impacts sentiment on threat perception and how serious the crisis will appear in different parts of the world. The contrast in EMAlpha News Sentiment between Europe and the United States versus South America speaks a lot about supremacy of underlying news sentiment over actual facts. This shapes several factors including the local government’s response, foreign aids to deal with the crisis and global sympathy for affected countries. ",
    date: '5/14/2020',
 },{
    title: "Oil Sentiment has Conflicting Signals from IEA and Saudi Aramco Stock Price",
    summary: "EMAlpha Oil News Sentiment is having its ‘break out’ moment and this change is telling us that we are past the worst of Coronavirus crisis and things will get better from now on for Oil. We looked at other possible signals on what may happen next and the most interesting were two data points which are giving us conflicting view. The first is Saudi Aramco Stock price and the second data point is International Energy Agency (IEA) forecast. What do they tell us and how to read it?",
    date: '5/16/2020',
 },{
    title: "Oil News Sentiment Captures the Firmness in Crude Prices, Is the worst over for Oil?",
    summary: "The EMAlpha Oil News Sentiment is volatile but it is clearly showing a significant improvement in last fifteen days. As such the average EMAlpha Oil News Sentiment in May is much more positive than either March or April and in last two weeks, the volatility also has been on the lower side. This implies that the news Sentiment is doing a good job in making the price forecast and on the basis of EMAlpha Oil News sentiment, it is unlikely that Oil is going below 30 dollars.",
    date: '5/29/2020',
 },{
    title: "Does Geography Change Interpretation and Relevance of Local News Sentiment ",
    summary: "On news flow sentiment analysis, often the question is being asked: In this day and age of global interconnection, does local news matter anymore? But on the basis of automated and unbiased news flow collection of EMAlpha for multiple countries, it is easy to see that English news and Local language news can be quite different. So, when news flow is different, sentiment analysis will not be the same either. We discuss the intricacies and implications here as the Sentiment Analysis outcomes are often different for English and Local News. ",
    date: '6/10/2020',
 },{
    title: "Can the Sentiment in Local News Determine the Course of a Geo-Political Conflict?",
    summary: "It is possible to argue that media only reflects the public opinion. But when it comes to sensitive situations, the role of media becomes even more important as it also has a role in shaping the popular discourse. In conflict between countries, media has to act responsibly and should not do anything which can lead to unnecessary and avoidable conflict. But unfortunately, what EMAlpha machines have picked up is not really unpleasant. ",
    date: '6/18/2020',
 },{
    title: "Does International News Flow Capture Seriousness of Coronavirus Pandemic in Brazil?",
    summary: "From the lack of news coverage in other parts of the world, it is difficult to figure out that Brazil is one of the worst-hit from the Coronavirus pandemic. However when we analyse what EMAlpha has picked up on Brazil in media coverage, most of the news flow is still capturing less relevant though more interesting developments like traditional cures and football matches.",
    date: '6/20/2020',
 },
];  
