import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index'; 
import { DateRangePicker } from "materialui-daterange-picker";
import loader from '../../../assets/images/loader.gif';
import axios from 'axios';
import moment from 'moment';
import News from '../Signin/News';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'; 
import FormControl from '@material-ui/core/FormControl'; 
import Select from '@material-ui/core/Select'; 
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {HOST_URL} from '../../../store/Constant';
import {SASDdata} from './SASDdata';
import {UNSDGdata} from './UNSDGdata';
import {GRIdata} from './GRIdata';
import unsdgImg from '../../../assets/images/SDG-Wheel_WEB.png';
import griImg from '../../../assets/images/GRI_Master_Logo-solo.svg';
import sasbImg from '../../../assets/images/sasbLogo.png';

let userMail = '';
let finalArr = []
 
class EsgReportingInner extends Component {

	constructor(props){
	    super(props);

	    this.state = {
	    	dictionayName: "Firstcsv",
	    	tableArr : [],
	    	valueInput: null,
	    	tableShow: false,
	    	userEmail: "",
	    	disabled3Go: false,
	    	showResult: false,
	    	errorMessage: "",
	    	apiError:"",
	    	displayMessage: false,
	    	dictionayArr : [],
	    	showLoader: false,
	    	saveMessage: "",
	    	displayMsg: false,
	    	//succesSave: false,
	    	afterSave: false,
	    	afterSaveMsg: "",
	    	downbutton: false,
	    	saveDisabled: false,
	    	show_buttons: false,
	    	resetitem: false,
	    	uploadFile: "",
	    	uploaderror: "",
	    	showUploaderror: false,
	    	imgName: "",
	    }

	    this.handleDictionaryChange = this.handleDictionaryChange.bind(this);
	    this.handleChangeInput = this.handleChangeInput.bind(this);
	    this.handleGoClick = this.handleGoClick.bind(this);
	    this.handleSaveColumn = this.handleSaveColumn.bind(this);
	    this.resetHandle = this.resetHandle.bind(this);
	    this.uploadFile = this.uploadFile.bind(this);
   
	}


	componentDidMount(){
		  if( sessionStorage.getItem('signInEmail') === "" || sessionStorage.getItem('signInEmail') === null){
	      this.setState({
	        userEmail: sessionStorage.getItem('signUpEmail')
	      });
	      userMail = sessionStorage.getItem('signUpEmail')
	    }else{
	      this.setState({
	        userEmail: sessionStorage.getItem('signInEmail')
	      });
	      userMail = sessionStorage.getItem('signInEmail')
	    }

			this.setState({
				SASDdata: SASDdata
			})
      this.props.getFileList();
	}
	
	
	componentDidUpdate(prevProps, prevState) {
		let $this = this;
	  if(this.props.reportingrand_hash !== prevProps.reportingrand_hash){
	  	if(this.props.reportingStatusCode === 1){
	  		/*console.log('SASDdata',SASDdata);
	  		console.log('this.props.ResponseReporting.all_scores',this.props.ResponseReporting.all_scores)*/;
	  		if(this.state.dictionayName === "SASB"){
	  			SASDdata.map((item, index) => {
	  				console.log('item',item)
	  				item.scores = this.props.ResponseReporting.all_scores[index].Score_value;
	  			})
	  			console.log('SASDdata',SASDdata)
	  			this.setState({
	  				SASDdata: SASDdata
	  			})
	  		}else if(this.state.dictionayName === "UN-SDG"){
	  			UNSDGdata.map((item, index) => {
	  				console.log('item',item)
	  				item.scores = this.props.ResponseReporting.all_scores[index].Score_value;
	  			})
	  			console.log('UNSDGdata',UNSDGdata)
	  			this.setState({
	  				UNSDGdata: UNSDGdata
	  			})
	  		}else if ( this.state.dictionayName === "GRI"){
	  			GRIdata.map((item, index) => {
	  				console.log('item',item)
	  				item.scores = this.props.ResponseReporting.all_scores[index].Score_value;
	  			})
	  			console.log('GRIdata',GRIdata)
	  			this.setState({
	  				GRIdata: GRIdata
	  			})
	  		}
		  	this.setState({
		  		tableShow: true,
		  		resultdata: this.props.ResponseReporting.all_scores,
		  		showResult: true,
		  		displayMessage: false,
		  		resetitem: true,

		  	})
		  	
	  	}else{
	  		this.setState({
	  			apiError: this.props.reportingStatus
	  		})
	  	}
	  }

	  // get list 
	  if(this.props.reportingrand_hashlist !== prevProps.reportingrand_hashlist){
	  	if(this.props.reportingStatusCodelist === 1){
	  		let fileName = this.props.ResponseReportinglist[0];
	  		this.setState({
	  			dictionayArr : this.props.ResponseReportinglist,
	  			dictionayName: fileName,
	  			imgName: griImg,
	  		})
	  		

	  		let getColoumnParam = JSON.stringify({
	        "file_name":fileName,
	        "mail_id": this.state.userEmail
	      });
	  		this.props.getColoumData(getColoumnParam)


	  	}else{
	  		this.setState({
	  			dictionayArr: [],
	  		})
	  	}
	  }

	  // get coloumn list
	  if(this.props.reportingrand_hashCell !== prevProps.reportingrand_hashCell){
	  	if(this.props.reportingStatusCodeCell === 1){
	  		this.setState({
	  			tableShow: true,
	  			tableArr: this.props.ResponseReportingCell,
	  			showLoader: false,
	  			show_buttons: true,
	  			showUploaderror: false,
	  			uploaderror: "",
	  		})
	  		if(this.props.uploaded_file === "true"){

		  		let allValue =  JSON.stringify(this.props.ResponseReportingCell);
		  		const esgReportingSave = new FormData();
			    esgReportingSave.append('valueInput', allValue);
			    esgReportingSave.append('mail_id', userMail);
			    esgReportingSave.append('dictionary_file', this.state.dictionayName);


			  	this.props.esgReportingSave(esgReportingSave)
	  		}

	  		if(this.props.column_valueData === "edited"){
	  			this.setState({
	  				complete_file_path: this.props.complete_file_pathData,
	  				downbutton: true,
	  				resetitem: true,
	  			})
	  		}else{
	  			this.setState({
	  				complete_file_path: "",
	  				downbutton: false,
	  				resetitem: false
	  			})
	  		}


	  	}else{
	  		this.setState({
	  			showUploaderror: true,
	  			uploaderror: this.props.reportingStatusCell,
	  			showLoader: false,
	  		})
	  	}
	  }

	  // save click
	  if(this.props.saveUpload_hash !== prevProps.saveUpload_hash){
	  	if(this.props.saveStatusCode === 1){
	  		this.setState({
	  			afterSave: true,
					afterSaveMsg: this.props.saveStatus,
					succesSave: true,
					displayMsg: false,
					complete_file_path: this.props.complete_file_path,
					downbutton: true
	  		})
	  	}else{
	  		this.setState({
	  			afterSave: true,
					afterSaveMsg: this.props.saveStatus,
					succesSave: false,
					displayMsg: false,
					complete_file_path: "",
					downbutton: false
	  		})
	  	}
		  setTimeout(function(){
		  	$this.setState({
		  		afterSave: false,
		  	})
		  },3000)
	  }



	}

	handleDictionaryChange(e){
		this.setState({
			dictionayName: e.target.value,
			valueInput:null,
			disabled3Go: false,
			showResult: false,
			apiError:"",
			errorMessage: "",
			displayMessage:false,
			showLoader: true
			//tableShow: false
		})

		if(e.target.value === "GRI"){
			this.setState({
				imgName : griImg
			})
		}else if (e.target.value === "SASB"){
			this.setState({
				imgName : sasbImg
			})
		}else if ( e.target.value === "UN-SDG"){
			this.setState({
				imgName: unsdgImg
			})
		}
		

		let fileName = e.target.value;

		let getColoumnParam = JSON.stringify({
      "file_name":fileName,
      "mail_id": this.state.userEmail
    });
		this.props.getColoumData(getColoumnParam)


	}

	handleChangeInput(i,e) {
   
    this.setState({
      //valueInput: { ...this.state.valueInput, [i]: e.target.value },
     //tableArr: { ...this.state.tableArr, {description[i]: e.target.value }},
      disabled3Go: false,
      errorMessage: "",
      apiError:"",
      displayMessage: false,
      saveDisabled: false,
      resetitem: true,
      showUploaderror: false,
    	uploaderror: ""
    });

    let items = [...this.state.tableArr];
    let item = {...items[i]};
    item.description = e.target.value;
    items[i] = item;
  
    this.setState({tableArr : items});


  };

  handleGoClick(){
  	let allValue =  JSON.stringify(this.state.tableArr)
  	
  	for(let i=0 ; i< this.state.tableArr.length; i++){
  		if(this.state.tableArr[i].description !== ""){
  			
  			this.setState({
		  		disabled3Go: true,
		  		showResult: false,
		  		errorMessage: "",
		  		//displayMessage: "We are calculating scores , please wait.",
		  		displayMessage: true,
		  		tableShow: true,
		  		downbutton: false,
		  		//succesSave: false,
		  		resetitem: false,
		  	})

		  	const esgReporting = new FormData();
		    esgReporting.append('valueInput', allValue);
		    esgReporting.append('mail_id', userMail);
		    esgReporting.append('dictionary_file', this.state.dictionayName);


		  	this.props.esgReportingScores(esgReporting)
  			break;
  		}else{
  			this.setState({
	  			errorMessage: "Please fill one entry in the above table to get the ESG scores."
	  		})
  		}
  	}

 

  }

  handleSaveColumn(){
  	let allValue =  JSON.stringify(this.state.tableArr);
  	let flagVal = 0 ;
  	
  	for(let i=0 ; i< this.state.tableArr.length; i++){
  		if(this.state.tableArr[i].description !== ""){
  			
  			flagVal = 1
  			this.setState({
		  		displayMsg: true,
		  		downbutton: false,
		  		saveDisabled: true,
		  		showResult: false,
		  		//saveMessage: "Your file is being saved, Please wait for a while."
		  	})

		  	const esgReportingSave = new FormData();
		    esgReportingSave.append('valueInput', allValue);
		    esgReportingSave.append('mail_id', userMail);
		    esgReportingSave.append('dictionary_file', this.state.dictionayName);


		  	this.props.esgReportingSave(esgReportingSave)
  			break;
  		}else{
  			
  		}
  	}

  	if(flagVal === 0) {
  		this.setState({
  			errorMessage: "Please fill one entry in the above table to get the ESG scores."
  		})
  	}else{
  		this.setState({
  			errorMessage: ""
  		})
  	}


  }

  resetHandle(){
  	let newMarkers = this.state.tableArr.map(el => (
		      {...el, description: ""}
		))
		
  	this.setState({
  		downbutton: false,
  		tableArr: newMarkers,
  		resetitem: false,
  		showResult: false,
  		showUploaderror: false,
    	uploaderror: ""
  	})

  	const esgreset = new FormData();
    esgreset.append('mail_id', userMail);
    esgreset.append('dictionary_file', this.state.dictionayName);

    this.props.resetHandleApi(esgreset);
  }

  uploadFile(e){
  

  	let file = e.target.files[0];
    //console.log('e.target',e.target)
   	if(file === undefined || file === "" || file === null){
   			console.log('file nooooooooo')
   	}else{
   		//console.log('file',file)
		 	this.setState({
	    	uploadFile: file,
	    	showLoader: true,
	    	showResult: false,
	    	showUploaderror: false,
	    	uploaderror: ""
	    });

	  	const data = new FormData();
	    data.append('file', file);
	    data.append('mail_id', userMail);
	    data.append('dictionary_file', this.state.dictionayName);

	    this.props.uploadNewFramework(data)
		}
   
  }

  
	
	render() {
		
		return (
			<div className="fixScroesText">
				<div className="container_wrapper">
					<div className="right_info">
						<div className="">
							<FormControl>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Select Framework:
                </InputLabel>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={this.state.dictionayName}
                  defaultValue={this.state.dictionayName}
                  onChange={this.handleDictionaryChange}
                >
              		{/*<MenuItem value="Firstcsv" index="0" >Firstcsv</MenuItem>
              		<MenuItem value="Secondcsv" index="1" >Secondcsv</MenuItem>
              		<MenuItem value="Thirthcsv" index="2" >Thirthcsv</MenuItem>*/}

              		{this.state.dictionayArr ? this.state.dictionayArr.map((item,index) => 
              			<MenuItem value={item} index={index} >{item}</MenuItem>
              		) : ""}

                </Select>
                
              </FormControl>


						</div>
					</div>

					<div className="framework_img">
						<img src={this.state.imgName} />
						{/*<img src= />
						<img src= />*/}
					</div>

					{this.state.tableShow ? <div className="table_editable">
						<div>
							<table>
								  <thead>
								    <tr>
								      <th className="onepart_head">Metric</th>
								      <th className="twopart_head">Description</th>
								    </tr>
								  </thead>
								  <tbody>
								    {this.state.tableArr.map((item, i) => 
								    		<tr  className={`${item.key === 'Header' ? "header_table" : ""}`} >
										      <td className="onepart">{item.value}</td>
										      <td className="twopart">
										      	<input 
										          className={`inputRow`}
										          /*value={this.state.valueInput ? this.state.valueInput[i] : ""}
										          defaultValue={this.state.valueInput ? this.state.valueInput[i] : ""}*/
										          value={item.description}
										          defaultValue = {item.description}
										          onChange={this.handleChangeInput.bind(this,i)}
											        />
										      </td>
									    	</tr> 
								    )}
								    	
								    
								  </tbody>
								</table>
								{this.state.showLoader ?<div className="loader_gif">
                  <img src={loader} /> 
                </div> : ""}

						</div>
					</div>  : "" }

					{this.state.displayMsg ?<div className="save_msg">
						<p>We are saving your data, Please wait for a while <img src={loader} /> </p>
					</div> : ""}

					{this.state.afterSave ? <div className={`afterSave ${this.state.succesSave === true ? 'success_msg' : 'failed_msg'} `}>
						<p>{this.state.afterSaveMsg}</p>
					</div> : ""}

					{this.state.showUploaderror ? <div className="upload_error">
						<p>{this.state.uploaderror}</p>
					</div> : ""}

					{this.state.show_buttons ? <div className={`reset_button ${this.state.resetitem === false ? "disabled_reset" : ""}`}>
						<a onClick={this.resetHandle}>Reset</a>
					</div> : ""}


					{this.state.show_buttons ? <div className="button_groups">
						<a onClick={this.handleSaveColumn} className={`save_button ${this.state.saveDisabled === true ? 'save_disabled' : ""}`}>Save</a>
						<a href={`${HOST_URL}${this.state.complete_file_path}`} target="_blank" className={`download_item ${this.state.downbutton === false ? "disabled_download" : ""}`}>Download</a>
						<div className="upload_csv uploadESG">
    					<input onChange={e => this.uploadFile(e)} type="file" className="custom-file-input" id="csv_upload" accept=".csv" />
    				</div>
					</div> : ""}

					<div className="go_button">
						<p className="esg_errorMsg">{this.state.errorMessage}</p>
						{/*<p className="esg_errorMsg">{this.state.apiError}</p>*/}
						{this.state.displayMessage ? <p style={{fontSize: "18px", marginBottom: "20px" }} >We are calculating scores , please wait <img src={loader} /></p> : ""}
						<a onClick={this.handleGoClick} className={(this.state.disabled3Go ? 'disabledGo' : '')} >Go</a>
					</div>

			{/*		{this.state.showResult ? 
						<div className="showResultsScroes">
							<div>
								ESG Scores:
								<div className="result_row">
									{this.state.resultdata ?
										this.state.resultdata.map((item,index) =>
											<p><b>{item.Category_name}</b> : <span className={`${item.Score_value > 0 ? "greenText" : item.Score_value < 0 ? "redText" : "" } `}>{item.Score_value}</span></p>
										)
									: "" }
								</div>
							</div>
					</div> : ""}*/}


					{this.state.showResult ? 
						<div className="showResultsScroes">
							<div>
								ESG Scores:
								{this.state.dictionayName === 'SASB' ? 
									<table className="scores_table">
										  <thead>
										    <tr>
										      <th>SASB Dimensions</th>
										      <th>SASB general issues Catergory</th>
										      <th>Scores</th>
										    </tr>
										  </thead>
										  <tbody>
										    {this.state.SASDdata.map((item,index) => 
										    	<tr >
											      {item.flag === "true" ? <td style={{backgroundColor: item.bgcolor , color: item.color}} rowspan={item.category_no}>{item.dimension}</td> : ""}
											      <td style={{backgroundColor: item.bgcolor , color: item.color}} >{item.subcategory}</td>
											      <td style={{backgroundColor: item.bgcolor , color: item.color}} >{item.scores}</td>
											    </tr>
										    )}
										   
										  </tbody>
										</table>
								: "" }

								{this.state.dictionayName === 'UN-SDG' ? 
									<table className="scores_table unsdg_table" >
										  <thead>
										    <tr>
										      <th className="col_one">UN-SDG Goals</th>
										      <th className="col_two">UN-SDG Description</th>
										      <th className="col_three">Scores</th>
										    </tr>
										  </thead>
										  <tbody>
										    {this.state.UNSDGdata.map((item,index) => 
										    	<tr >
											      <td className="col_one"> <img src={`/un-sdg/${item.image}`} /></td>
											      <td className="col_two">{item.description}</td>
											      <td className="col_three">{item.scores}</td>
											    </tr>
										    )}
										   
										  </tbody>
										</table>
								: "" }
								{this.state.dictionayName === 'GRI' ? 
									<table className="scores_table gri_table">
										  <thead>
										    <tr>
										      <th>GRI Dimensions</th>
										      <th>GRI general issues Catergory</th>
										      <th>Scores</th>
										    </tr>
										  </thead>
										  <tbody>
										    {this.state.GRIdata.map((item,index) => 
										    	<tr >
											      {item.flag === "true" ? <td style={{backgroundColor: item.bgcolor , color: item.color}} rowspan={item.category_no}>{item.dimension}</td> : ""}
											      <td style={{backgroundColor: item.bgcolor , color: item.color}} >{item.subcategory}</td>
											      <td style={{backgroundColor: item.bgcolor , color: item.color}} >{item.scores}</td>
											    </tr>
										    )}
										   
										  </tbody>
										</table>
								: "" }


							</div>
					</div> :  "" }

						{/*<div className="result_row">
									{this.state.resultdata ?
										this.state.resultdata.map((item,index) =>
											<p><b>{item.Category_name}</b> : <span className={`${item.Score_value > 0 ? "greenText" : item.Score_value < 0 ? "redText" : "" } `}>{item.Score_value}</span></p>
										)
									: "" }
								</div>*/}
				

				</div>
			</div>
			
		)
	}
}


const mapStateToProps = state => {
  return{
    ResponseReporting: state.esgReducers.ResponseReporting,
    reportingStatus: state.esgReducers.reportingStatus,
    reportingStatusCode: state.esgReducers.reportingStatusCode,
    reportingrand_hash: state.esgReducers.reportingrand_hash,

    ResponseReportinglist: state.esgReducers.ResponseReportinglist,
    reportingStatuslist: state.esgReducers.reportingStatuslist,
    reportingStatusCodelist: state.esgReducers.reportingStatusCodelist,
    reportingrand_hashlist: state.esgReducers.reportingrand_hashlist,

    ResponseReportingCell: state.esgReducers.ResponseReportingCell,
    reportingStatusCell: state.esgReducers.reportingStatusCell,
    reportingStatusCodeCell: state.esgReducers.reportingStatusCodeCell,
    reportingrand_hashCell: state.esgReducers.reportingrand_hashCell,
    column_valueData: state.esgReducers.column_valueData,
    complete_file_pathData: state.esgReducers.complete_file_pathData,
    uploaded_file: state.esgReducers.uploaded_file,

    complete_file_path: state.esgReducers.complete_file_path,
    saveStatus: state.esgReducers.saveStatus,
    saveStatusCode: state.esgReducers.saveStatusCode,
    saveUpload_hash: state.esgReducers.saveUpload_hash,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    esgReportingScores : (esgReporting) => dispatch(dataActions.esgReportingScores(esgReporting)),
    getFileList : () => dispatch(dataActions.getFileList()),
    getColoumData : (getColoumnParam) => dispatch(dataActions.getColoumData(getColoumnParam)),
    esgReportingSave : (esgReportingSave) => dispatch(dataActions.esgReportingSave(esgReportingSave)),
    resetHandleApi : (esgreset) => dispatch(dataActions.resetHandleApi(esgreset)),
    uploadNewFramework : (data) => dispatch(dataActions.uploadNewFramework(data)),
    
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(EsgReportingInner);