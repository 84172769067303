import React, { Component } from 'react';
import {connect} from 'react-redux';
import './InsightsSignin.css';
/*import allCategory from './allCatory';*/

import * as dataActions from '../../../store/actions/index';
import Pagination from '@material-ui/lab/Pagination';
let startno = 0 ;
let endno = 5 ;

class InsightsSignin extends Component {

	constructor(props){
    super(props);

    this.state = {
    	allCategory: [],
    	startno: 0,
    	endno: 5,
    	targetValue: 1,
    	pageCount: 0,
    	innerHTMLVAlue: false,
    	categoryListName: [],
    	activeCategory: "",
    	noInsights: true,
    	image_folder_name:"",
    }
    // this.handleChange = this.handleChange.bind(this);
    this.paginationChange = this.paginationChange.bind(this);
    this.getInsightHtml = this.getInsightHtml.bind(this);
    this.backInsights = this.backInsights.bind(this);
    this.getFilterInsights = this.getFilterInsights.bind(this);
	   
	}

	componentDidMount(){
		/*this.setState({
      allCategory : allCategory
    });
*/
    this.props.getInsightsList();
    this.props.getCategoryList();
	}
	
  

  componentDidUpdate(prevProps, prevState) {


  	if(this.props.insightDataList.StatusCode === 1){
  		if(this.props.insightDataList.Map_hash !== prevProps.insightDataList.Map_hash){
  			var allData = this.props.insightDataList.ResponseData;
  		
  			this.setState({
  				allCategory: this.props.insightDataList.ResponseData.slice(this.state.startno,this.state.endno),
  				pageCount: Math.ceil(allData.length/5),
  				fullDataValues: this.props.insightDataList.ResponseData,
  				noInsights: false,
  				image_folder_name: this.props.insightDataList.image_folder_name

  			})
	  	}
  	}
  	else{

  		if(this.props.insightDataList.Map_hash !== prevProps.insightDataList.Map_hash){
  		
  			this.setState({
  				noInsights: true,
  				allCategory: [],
					fullDataValues:[],
  			})
  		}
  	}


  	if(this.props.insightDataHTML.StatusCode === 1){
  		if(this.props.insightDataHTML.Map_hash !== prevProps.insightDataHTML.Map_hash){
  			this.setState({
  				innerHTMLVAlue: true,
  				
  			})
  		}
  	}

  	if(this.props.categoryListName.StatusCode === 1){
  		if(this.props.categoryListName.Map_hash !== prevProps.categoryListName.Map_hash){
  			this.setState({
  				categoryListName: this.props.categoryListName.ResponseData
  			})
  		}
  	}

	  	
  }


	paginationChange (event) {
		// let $this = this;
	
		
		if(event.target.getAttribute('aria-label') === 'Go to next page'){
			
			startno = 0 + (5 * (this.state.targetValue));
			endno = 5 + (5 * (this.state.targetValue));
			
			this.setState({
	      targetValue : this.state.targetValue + 1,
	      startno:startno,
				endno:endno,

	    });
			
			this.setState({
	      allCategory : this.state.fullDataValues.slice(startno,endno)
	    });
		}else if(event.target.getAttribute('aria-label') === 'Go to previous page'){
			
			startno = 0 + (5 * (this.state.targetValue - 2));
			endno = 5 + (5 * (this.state.targetValue - 2));
			this.setState({
		      targetValue : this.state.targetValue - 1,
		      startno:startno,
					endno:endno,
		    });
			
			this.setState({
	      allCategory : this.state.fullDataValues.slice(startno,endno)
	    });
		}else{
			
			var page_no = event.target.getAttribute('aria-label').split(' ').pop();
			
			this.setState({
	      targetValue : page_no
	    });
			startno = 0 + (5 * (page_no - 1));
			endno = 5 + (5 * (page_no - 1));
			
			this.setState({
				startno:startno,
				endno:endno,
			})
			
			this.setState({
	      allCategory : this.state.fullDataValues.slice(startno,endno)
	    });
		} 
	}
  
  getInsightHtml(filename,categoryName){
  	
  	let categoryNameValue = categoryName.split(',')[0]
  	
  	let insightsParams = JSON.stringify({
			file_name: filename ,
			category_name: categoryNameValue
		});
		this.props.getInsightFileData(insightsParams);
  }

  backInsights(){
  	this.setState({
  		innerHTMLVAlue: false,
  	})
  }

  getFilterInsights(categoryName) {
  	
  	let insightsParams = JSON.stringify({
			category_name: categoryName
		});
		this.setState({
			activeCategory: categoryName,
			innerHTMLVAlue: false,
		 	startno: 0,
  		endno: 5,
  		pageCount: 0,
  		targetValue: 1,
		})
	  this.props.getFilterInsightsList(insightsParams);
  }

  
	
	render() {
		
		return (
			<div>
				<div className="leftTabs_main insight_tab">
					{/*<span className="sub_heading">Lorum ipsum</span>*/}
					<h1>Insights</h1>
					<p>Research and analysis focusing on Emerging Markets.</p>
					<div className="insightsSection">
						<div>
							<div className="category_main">
								<div className="container">
									<div className="category_blogs">
										{/*<div className="insight_search">
											<input type="text" placeholder="Search insight"/>
											<i className="fa fa-search"></i>
										</div>*/}
										{!this.state.innerHTMLVAlue ? <div className="blogs_section">
											<div>
											{this.state.allCategory ? this.state.allCategory.map((data,index) => {
													return <div className="single_blog" data-attr={data.File_Name} key={index} >
														<a href="javascript:void(0)"  onClick={() => this.getInsightHtml(data.File_Name,data.Category)}>
															<div className="blog_img">
																<img alt="" src={`${this.state.image_folder_name}/${data.Image_Name}`} />
																
															</div>
															<div className="blog_details">
																<div>
																	<h5>{data.Category}</h5>
																	<h2>{data.Title}</h2>
																	<p>{ ((data.Description).length > 200) ? 
																	    (((data.Description).substring(0,200)) + '...') : 
																	    data.Description }</p>
																	<p className="dateon">Published on: <span>{data.Date}</span></p>
																</div>
															</div>
														</a>
													</div>
												}) : ""
											}
											{!this.state.noInsights ? <div className="insight_pagination">
												<div className="">
								      		<Pagination onChange={this.paginationChange} count={this.state.pageCount} variant="outlined" shape="rounded" />
								      	</div>
									    </div> : ""}

									    {this.state.noInsights ? <div className="no_insights">
									    	<div>
									    		<p>No Insights are present for this Category.</p>
									    	</div>
									    </div>: ""}

											</div>
										</div>
										:
										<div className="singleblog_display">
											<div>
												<span className="back_insights" onClick={this.backInsights}>Back to All insights</span>
												{this.props.insightDataHTML ?  <div dangerouslySetInnerHTML={{ __html: this.props.insightDataHTML.ResponseData }} /> : ""}
											</div>
										</div> }
										
									</div>
									
								</div>
							</div> 
						</div> 
					</div>
				</div>
				<div className="insights_sidebar">
					<div className="category_listing">
						{/*<div className="subscription_section">
							<div>
								<p>Sign up to receive the latest Insights posts in your inbox.</p>
								<a href="#">Subscribe</a>
							</div>
						</div>*/}
						<div className="name_list">
							<div>
								<h4>Topics</h4>
								<ul className="">
								{this.state.categoryListName ? this.state.categoryListName.map((list,index) =>{
									return <li key={index}>
											<span className={this.state.activeCategory === list ? "active_category" : ""} data-value={list} key={index} onClick={() => this.getFilterInsights(list)}> {list} </span>
										</li>
								}) : ""}
									
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div> 
		)
	}
}

const mapStateToProps = state => {
  return{
    insightDataList: state.insightReducer.insightDataList,
    insightDataHTML: state.insightReducer.insightDataHTML,
    categoryListName: state.insightReducer.categoryListName,
   
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getInsightsList : () => dispatch(dataActions.getInsightsList()),
    getCategoryList : () => dispatch(dataActions.getCategoryList()),
    getInsightFileData : (insightsParams) => dispatch(dataActions.getInsightFileData(insightsParams)),
    getFilterInsightsList : (insightsParams) => dispatch(dataActions.getFilterInsightsList(insightsParams)),
   
    
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InsightsSignin);