import React  from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import UploadDictionary from './UploadDictionary';
import ScoreText from './ScoreText';
import ScoresFiles from './ScoresFiles';
import News from '../Signin/News';

let portfolioNames = [];
 
 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




export default function ESGWorkspaceTab() {
	
	const [value, setValue] = React.useState(0);
	

	const handleChange = (event, newValue) => {
		
	  setValue(newValue);
  };
  
	

	return (
		<div>
			<div className="leftTabs_main">
				{/*<span className="sub_heading">Lorum ipsum</span>*/}
				<h1>ESG Lens</h1>
				<p>User workspace to test standard and custom frameworks</p>
				<div className="innertab_main">
					<AppBar position="static">
		        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
		          <Tab label="Choose Framework" {...a11yProps(0)} />
		          <Tab label="Score Text" {...a11yProps(1)} />
		          <Tab label="Score Files" {...a11yProps(2)} />
		         
		        </Tabs>
	      	</AppBar>
			  </div>
			  <div className="innercontent_container">
		    	<TabPanel value={value} index={0}>
		      	<div id="country_tab" className="tab-content current">
		      		{/*<h2>Upload Dictionary</h2> */}
		      		<UploadDictionary />
						</div>
		    	</TabPanel>
			    <TabPanel value={value} index={1}>
			      <div id="scoretest_tab" className="tab-content">
							{/*<h2>Score Test</h2> */}
							<ScoreText />
				  </div>
			    </TabPanel>
			      
			    <TabPanel value={value} index={2}>
			      <div id="scorefiles_tab" className="tab-content">
							{/*<h2>Score Files</h2> */}
							<ScoresFiles />
						</div>
			    </TabPanel>
			      	
			  </div>
			</div>
			{/*<News />*/}
	  </div>
	)
	
}

