import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

let fullDatarow = [];
let investmentValue = [];
let titleValue = "";
let stockNames= [];


class TRiskAOne extends Component {

	componentDidUpdate(prevProps,prevState){
		if(this.props.sbtirand_hash !== prevProps.sbtirand_hash){
			
		}
	}

	render() {
		fullDatarow = [];
		investmentValue= [];
		stockNames= [];

		if(this.props.dataArray){
			
			titleValue = this.props.dataArray.risk_name ;
		
			let items_row = [];
			this.props.dataArray.data.map((row , index )=> {
				investmentValue.push({
				  label: row.key,
				  y: row.value,
				});
				
				stockNames.push({
					name: row.key,
				})
				

			})

			items_row =  investmentValue;

			fullDatarow.push({
				type: "column",
				legendText: "{label}",
				toolTipContent: "{label}: <strong>{y}</strong>",
				indexLabel: "{y}",
				dataPoints: items_row

			})
		}

		
		

		const options = {
			theme: "light",
			animationEnabled: true,
			axisX:{
			   labelAngle: 0,
			},
			title:{
				text: this.props.titleText,
				fontSize: 15,
			},
			data: fullDatarow
			
		}
		// console.log('options',options)
		return (
		<div>
			<CanvasJSChart options = {options} />
			{stockNames.length ? <div className="line_indicator">
				<ul>
					{stockNames.map((stockName,index) => {
						return <li key={index} >
							<span className="first_shape"></span>
							<span>{stockName.name}</span>
						</li>
					}) }
				</ul>
			</div> : ""}
		</div>
		);
	}
}
export default TRiskAOne;       