import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import insight from '../../../assets/images/insight.png';
import placeholder3 from '../../../assets/images/placeholder3.jpg';
import './Insights.css';
import allCategory from './allCatory';

import Pagination from '@material-ui/lab/Pagination';
let startno = 0;
let endno = 7;
let pageCount = 5;



 
class Insights extends Component {
	

	constructor(props){
			super(props);

	    this.state = {
	    	insightData: [],
	    	startno: 0,
	    	endno: 7,
	    	targetValue: 1,
	    }
	    this.paginationChange = this.paginationChange.bind(this);
	}

	componentDidMount() {
		
		pageCount = Math.ceil(allCategory.length/7);
		
		this.setState({
	      insightData : allCategory.slice(startno,endno)
	    });
	}

	paginationChange (event) {
		let $this = this;
		
		if(event.target.getAttribute('aria-label') === 'Go to next page'){
			
			startno = 0 + (7 * (this.state.targetValue));
			endno = 7 + (7 * (this.state.targetValue));
			
			this.setState({
		      targetValue : this.state.targetValue + 1
		    });
		}else if(event.target.getAttribute('aria-label') === 'Go to previous page'){
			
			startno = 0 + (7 * (this.state.targetValue - 2));
			endno = 7 + (7 * (this.state.targetValue - 2));
			this.setState({
		      targetValue : this.state.targetValue - 1
		    });
		}else{
			var page_no = event.target.getAttribute('aria-label').split(' ').pop();
			
			this.setState({
		      targetValue : page_no
		    });
			startno = 0 + (7 * (page_no - 1));
			endno = 7 + (7 * (page_no - 1));
		}
		
		$this.setState({
	      insightData : allCategory.slice(startno,endno)
	    });
		
	}


	render() {
		return (
			<div>
				<div className="main_container">
					<div className="main_tabs">
						<div className="insights_main">
							<div>
								<div className="insight_banner">
									<div className="container">
										<div className="banner_secription">
											<h5>INSIGHTS</h5>
											<h2>We love to solve hard problems. Sharing ideas is integral to how we do it.</h2>
											<p>Whether an idea directly applies to our work or is simply interesting, we voraciously consume cutting-edge research—and contribute thinking of our own.</p>
										</div>
									</div>
								</div>
								<div className="top_category">
									<div className="container">
										<div>
											<div className="left_insight">
												<div className="img_section">
													<img src={insight} alt=""/>
													<div className="img_text">
														<h5>COVID-19</h5>
														<h2>Does 'Economic Revival' from Coronavirus Pandemic Depend on 'Recklessness in Human Behavior'?</h2>
													</div>
												</div>
											</div>
											<div className="right_insight">
												<div className="img_section">
													<img src={placeholder3} alt="" />
													<div className="img_text">
														<h5>Sentiment Analysis</h5>
														<h2>Do the Global Financial Markets Care About COVID-19 Numbers Anymore?</h2>
													</div>
												</div>
												<div className="img_section">
													<img src={placeholder3} alt="" />
													<div className="img_text">
														<h5>Emerging Markets</h5>
														<h2>Does International News Flow Capture Seriousness of Coronavirus Pandemic in Brazil?</h2>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="category_main">
								<div className="container">
									<div className="category_blogs">
										{/*<div className="insight_search">
											<input type="text" placeholder="Search insight"/>
											<i className="fa fa-search"></i>
										</div>*/}
										<div className="blogs_section">
											<div>
											{this.state.insightData ? this.state.insightData.map(data=> {
													return <div className="single_blog">
														<div>
															<div className="blog_img">
																<img src={insight} />
															</div>
															<div className="blog_details">
																<div>
																	<h5>{data.category}</h5>
																	<h2>{data.title}</h2>
																	<p>{ ((data.summary).length > 200) ? 
																	    (((data.summary).substring(0,200)) + '...') : 
																	    data.summary }</p>
																	<p className="dateon">Published on: <span>{data.date}</span></p>
																</div>
															</div>
														</div>
													</div>
												}) : ""
											}
												
											</div>
										</div>
										<div className="insight_pagination">
											<div className="">
									      		<Pagination onChange={this.paginationChange} count={pageCount} variant="outlined" shape="rounded" />
									      	</div>
									    </div>
									</div>
									<div className="category_listing">
										<div className="subscription_section">
											<div>
												<p>Sign up to receive the latest Insights posts in your inbox.</p>
												<a href="#">Subscribe</a>
											</div>
										</div>
										<div className="name_list">
											<div>
												<h4>Topics</h4>
												<ul className="">
													<li><Link to="/insights/covid-19">Covid-19</Link></li>
													<li><Link to="/insights/emerging markets">Emerging Markets</Link></li>
													<li><Link to="/insights/developed markets">Developed Markets</Link></li>
													<li><Link to="/insights/Equities">Equities</Link></li>
													<li><Link to="/insights/fixed income">Fixed Income</Link></li>
													<li><Link to="/insights/commodities">Commodities</Link></li>
													<li><Link to="/insights/markets and micro">Markets and Macro</Link></li>
													<li><Link to="/insights/behavioral finance">Behavioral Finance</Link></li>
													<li><Link to="/insights/newsflow and impact">News flow and Impact</Link></li>
													<li><Link to="/insights/systematic investing">Systematic Investing</Link></li>
													<li><Link to="/insights/big-data">Big Data and AI in Finance</Link></li>
													<li><Link to="/insights/sentiment analysis">Sentiment Analysis</Link></li>
													<li><Link to="/insights/esg and sustainability">ESG and Sustainability</Link></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		)
	}
}

export default Insights