import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index'; 
import { DateRangePicker } from "materialui-daterange-picker";
import loader from '../../../assets/images/loader.gif';
import axios from 'axios';
import moment from 'moment';
import News from '../Signin/News';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'; 
import FormControl from '@material-ui/core/FormControl'; 
import Select from '@material-ui/core/Select'; 
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

let userMail = '';
/*let arr1 = ['csv1text1','csv1text2','csv1text3'];
let arr2 = ['csv2text1','csv2text2','csv2text3'];
let arr3 = ['csv3text1','csv3text2','csv3text3'];*/
 
class SecondPartyOpinion extends Component {

	constructor(props){
	    super(props);

	    this.state = {
	    	dictionayName: "Firstcsv",
	    	tableArr : [],
	    	valueInput: null,
	    	tableShow: false,
	    	userEmail: "",
	    	disabled3Go: false,
	    	showResult: false,
	    	errorMessage: "",
	    	apiError:"",
	    	displayMessage: false,
	    	dictionayArr : [],
	    	showLoader: false,
	    }

	   // this.handleDictionaryChange = this.handleDictionaryChange.bind(this);
	    this.handleChangeInput = this.handleChangeInput.bind(this);
	    this.handleGoClick = this.handleGoClick.bind(this);
   
	}


	componentDidMount(){
		  if( sessionStorage.getItem('signInEmail') === "" || sessionStorage.getItem('signInEmail') === null){
	      this.setState({
	        userEmail: sessionStorage.getItem('signUpEmail')
	      });
	      userMail = sessionStorage.getItem('signUpEmail')
	    }else{
	      this.setState({
	        userEmail: sessionStorage.getItem('signInEmail')
	      });
	      userMail = sessionStorage.getItem('signInEmail')
	    }

	 	this.props.getColoumDataSecond();
	}
	
	
	componentDidUpdate(prevProps, prevState) {
	  if(this.props.reportingrand_hash !== prevProps.reportingrand_hash){
	  	if(this.props.reportingStatusCode === 1){

		  	this.setState({
		  		tableShow: true,
		  		resultdata: this.props.ResponseReporting.all_scores,
		  		showResult: true,
		  		displayMessage: false,

		  	})
		  	
	  	}else{
	  		this.setState({
	  			apiError: this.props.reportingStatus
	  		})
	  	}
	  }

	  // get list 
	 /* if(this.props.reportingrand_hashlist !== prevProps.reportingrand_hashlist){
	  	if(this.props.reportingStatusCodelist === 1){
	  		let fileName = this.props.ResponseReportinglist[0];
	  		this.setState({
	  			dictionayArr : this.props.ResponseReportinglist,
	  			dictionayName: fileName
	  		})
	  		//console.log('fileName',fileName)

	  		let getColoumnParam = JSON.stringify({
	        "file_name":fileName
	      });
	  		this.props.getColoumData(getColoumnParam)


	  	}else{
	  		this.setState({
	  			dictionayArr: [],
	  		})
	  	}
	  }*/

	  // get coloumn list
	  if(this.props.reportingrand_hashCell !== prevProps.reportingrand_hashCell){
	  	if(this.props.reportingStatusCodeCell === 1){
	  		this.setState({
	  			tableShow: true,
	  			tableArr: this.props.ResponseReportingCell,
	  			showLoader: false,
	  		})
	  	}
	  }


	}

	

	handleChangeInput(i,e) {
   
    this.setState({
      valueInput: { ...this.state.valueInput, [i]: e.target.value },
      disabled3Go: false,
      errorMessage: "",
      apiError:"",
      displayMessage: false,
    });


  };

  handleGoClick(){
  	let valueInputvar = JSON.stringify(this.state.valueInput);
  	let flagValue = 0;
  	let qq = this.state.valueInput;
  	if(this.state.valueInput !== null ){
  		for (var key in qq){
  			//console.log('in lop')
  			if(qq[key] !== ""){
  				//console.log('yesssssssss')
  				flagValue = 0;
  				this.setState({
			  		disabled3Go: true,
			  		showResult: false,
			  		errorMessage: "",
			  		displayMessage: true,
			  		tableShow: true,
			  	})

			  	const esgReporting = new FormData();
			    esgReporting.append('valueInput', valueInputvar);
			    esgReporting.append('mail_id', userMail);
			    //esgReporting.append('dictionary_file', this.state.dictionayName);


			  	this.props.esgSecondReportingScores(esgReporting)



  				break;
  			}else{
  				//console.log('nooooo')
  				flagValue = 1;
  				/*this.setState({
		  			errorMessage: "Please fill one entry in the above table to get the ESG scores."
		  		})*/
  			}
  		}
	  	
  	}else{
  		flagValue = 1
  		this.setState({
  			errorMessage: "Please fill one entry in the above table to get the ESG scores."
  		})
  	}

  	if(flagValue === 1){
  		this.setState({
  			errorMessage: "Please fill one entry in the above table to get the ESG scores."
  		})
  	}else{
  		this.setState({
  			errorMessage: ""
  		})
  	}

  }

  
	
	render() {
		//console.log('valueInput',this.state.valueInput)
		//console.log('this.state.valueInput[i]',this.state.valueInput[i])
		return (
			<div className="fixScroesText">
				<div className="container_wrapper">
					<div className="right_info">
				
					</div>

					{this.state.tableShow ? <div className="table_editable">
						<div>
							<table>
								  <thead>
								    <tr>
								      <th className="onepart_head">Category</th>
								      <th className="onepart_head">Sub category</th>
								      <th className="onepart_head">Questions</th>
								      <th className="twopart_head">Answers</th>
								    </tr>
								  </thead>
								  <tbody>
								    {this.state.tableArr.map((item, i) => 
								    		<tr>
										      <td className="onepart">{item.Category}</td>
										      <td className="onepart">{item.Sub_category}</td>
										      <td className="onepart">{item.Question}</td>
										      <td className="twopart">
										      	<input
										          className="inputRow"
										          value={this.state.valueInput ? this.state.valueInput[i] : ""}
										          defaultValue={this.state.valueInput ? this.state.valueInput[i] : ""}
										          onChange={this.handleChangeInput.bind(this,i)}
											        />
										      </td>
									    	</tr> 
								    )}
								    	
								    
								  </tbody>
								</table>
								{this.state.showLoader ?<div className="loader_gif">
                  <img src={loader} /> 
                </div> : ""}

						</div>
					</div>  : "" }

					<div className="go_button">
						<p className="esg_errorMsg">{this.state.errorMessage}</p>
						{/*<p className="esg_errorMsg">{this.state.apiError}</p>*/}
						{this.state.displayMessage ? <p style={{fontSize: "18px", marginBottom: "20px" }} > We are calculating scores , please wait <img src={loader} /> </p> : ""}
						<a onClick={this.handleGoClick} className={(this.state.disabled3Go ? 'disabledGo' : '')} >Go</a>
					</div>

					{this.state.showResult ? <div className="showResultsScroes">
						<div>
							ESG Scores:
							<div className="result_row">
								{this.state.resultdata ?
									this.state.resultdata.map((item,index) =>
										<p><b>{item.Category_name}</b> : <span className={`${item.Score_value > 0 ? "greenText" : item.Score_value < 0 ? "redText" : "" } `}>{item.Score_value}</span></p>
									)
								: "" }
							</div>
						</div>
					</div> : ""}

				</div>
			</div>
			
		)
	}
}


const mapStateToProps = state => {
  return{
    ResponseReporting: state.esgReducers.ResponseReporting,
    reportingStatus: state.esgReducers.reportingStatus,
    reportingStatusCode: state.esgReducers.reportingStatusCode,
    reportingrand_hash: state.esgReducers.reportingrand_hash,

    ResponseReportinglist: state.esgReducers.ResponseReportinglist,
    reportingStatuslist: state.esgReducers.reportingStatuslist,
    reportingStatusCodelist: state.esgReducers.reportingStatusCodelist,
    reportingrand_hashlist: state.esgReducers.reportingrand_hashlist,

    ResponseReportingCell: state.esgReducers.ResponseReportingCell,
    reportingStatusCell: state.esgReducers.reportingStatusCell,
    reportingStatusCodeCell: state.esgReducers.reportingStatusCodeCell,
    reportingrand_hashCell: state.esgReducers.reportingrand_hashCell,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    esgSecondReportingScores : (esgReporting) => dispatch(dataActions.esgSecondReportingScores(esgReporting)),
    //getFileList : () => dispatch(dataActions.getFileList()),
    getColoumDataSecond : (getColoumnParam) => dispatch(dataActions.getColoumDataSecond(getColoumnParam)),
    
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(SecondPartyOpinion);