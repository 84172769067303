import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'; 
import SBTITab from '../ESGTab/SBTITab';
import PactaTab from '../ESGTab/PactaTab';
let portfolioNames = [];
 
 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




export default function ClimateData() {
	
	const [value, setValue] = React.useState(0);
	
 

	const handleChange = (event, newValue) => {
		
	  setValue(newValue);
  };
  
	
	return (
		<div>
			<div className="leftTabs_main">
				{/*<span className="sub_heading">Overall sentiments</span>*/}
				<h1>Climate Data</h1> 
				<p>Climate data for companies.</p>
				<div className="innertab_main">
					<AppBar position="static">
		        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
		          <Tab label="Net-Zero" {...a11yProps(0)} />
		          <Tab label="Climate Stress" {...a11yProps(1)} />
		         
		        </Tabs>
	      	</AppBar>
			  </div>
			  <div className="innercontent_container">
		    	<TabPanel value={value} index={0}>
			      <div id="sbti_tab" className="tab-content">
							<SBTITab />
						</div> 
					</TabPanel>
					<TabPanel value={value} index={1}>
			      <div id="Pacta_tab" className="tab-content">
							<PactaTab />
						</div>
			    </TabPanel> 
			      	
			      	
			  </div>
		  </div>
		  {/*<News />*/}
	  </div>
	)
	
}

