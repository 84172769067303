export default [
 {
    title: 'Human Emotions and How Systematic Investing Can Mitigate the Impact on Your Portfolio?',
    summary: 'Often it is difficult to control emotions in the markets and extraordinary events become even more testing. It takes great discipline to not follow emotions in investment and this is what the insight talks about. Even if one has a quantitative, machine learning based model, it is easy to yield to emotions and start tweaking with model parameters. Many quant managers yield to this temptation and tweak the parameters at the worst possible moment. Discipline does not come easy and it takes experience and practice, but it pays off.',
    category: 'Systematic Investing',
    date: '10/14/2019',
 },
 {
    title: "Fundamentals' and 'Quant' - The Virtues of Discipline and Why Systematic Trading?",
   summary: 'The debate on which method of stock trading is superior between ‘fundamental approach’ and ‘quantitative approach’ is age old and never ending. Then, what is the solution? The best possible approach we believe is an approach which is systematic. The idea is simple: unless you follow an approach diligently and don’t let your emotions affect your investment decisions, it is difficult to evaluate it. You have rules and you don’t tweak them unless you think that some of the premises are no longer valid. Otherwise, you let the system do the ‘decision making’ for you.',
   category: 'Systematic Investing',
   date: '8/28/2019',
 }
];  
