export default [

 {
    title: 'EURO Gaining Strength over USD: What it Means for Emerging Markets?',
    summary: 'After Germany and France proposed a huge Recovery Fund to provide the EU with more capacity to deal with Coronavirus pandemic shock, Euro has gained significantly against USD. More importantly, it gives enhanced visibility on the future of European Union. EMAlpha has explored how does this link with overall market sentiment?  The "topical sentiment" scores emphasizes the point that gains for EUR/USD and weak dollar have sent the overall sentiment soaring.',
    category: 'Equities',
    date: '8-Jun-20',
 },
 {
    title: 'Sentiment Analysis and Its Role In Investing: Now and In The Future',
    summary: 'When the time scale is short, the sentiment can matter more than the facts. In stock markets when the reaction time needs to be quick and the trade decision has to be made immediately after the event, the fund manager lacks the luxury to do it at leisure. The sentiment, i.e. the first impression, can matter more in this case and the price impact can be seen very quickly after a good or bad event. Since even a small change in sentiment can quickly become big, Sentiment analysis and discovery of news which can move markets is useful for market participants.',
    category: 'Equities',
    date: '12-Oct-19',
 },
 {
    title: 'Investment Management - The Final Frontier for Machines',
   summary: 'The adoption rate of new technology is dependent on several factors but the important thing is that the new age machines are not just taking over the work which used to be done by humans in the financial markets, they are also opening new avenues and approaches for investment management. EMAlpha’s proprietary model which generates sentiment scores using our dictionary and the trades executed on the basis of those sentiment scores are beating markets and this tells us that there is need for more sophisticated data analytics, more advanced algorithms and use of Artificial Intelligence for generating superior investment returns.',
   category: 'Equities',
   date: '6-Oct-19',
 },
 {
    title: 'Will Huge Cut in Corporate Tax Rate Revive Investor Interest in India?',
    summary: 'The massive corporate tax rate cut in India is a historic step and a massive comforting factor for the industry that the Government is listening to the signals from the ground. However, earnings growth is a challenge for Indian companies and this step in the form of a tax rate cut for companies is an artificial one-time measure. It doesnt do anything to address the core problem of slack in demand. The expectation that the investment cycle will pick up is unrealistic. Having more money is one thing but the corporate will not invest unless they are hopeful on demand.',
    category: 'Equities',
    date: '22-Sep-19',
 },
 {
    title: 'Fundamentals and Quant - The Virtues of Discipline and Why Systematic Trading?',
    summary: 'The debate on which method of stock trading is superior between ‘fundamental approach’ and ‘quantitative approach’ is age old and never ending. Then, what is the solution? The best possible approach we believe is an approach which is systematic. The idea is simple: unless you follow an approach diligently and don’t let your emotions affect your investment decisions, it is difficult to evaluate it. You have rules and you don’t tweak them unless you think that some of the premises are no longer valid. Otherwise, you let the system do the ‘decision making’ for you.',
    category: 'Equities',
    date: '28-Aug-19',
 },
 {
    title: 'Emerging Markets and Importance of Managing the Corporate Governance Risk',
    summary: 'To avoid unpleasant surprise, we need to apply a Corporate Governance filter first and then only trade. Another somewhat similar way is to look at the stocks through ESG (Environmental, Social and Governance) framework and then decide what you can invest in and what you should avoid. In EMs especially, that is as important as tracking news flow, understanding investor sentiment or analysing financial statements. In the markets to succeed, the first learning needs to be…how to minimize mistakes and is why ‘Corporate Governance’ helps.',
    category: 'Equities',
    date: '22-Aug-19',
 },
 {
    title: 'Emerging Markets: Increasing Domestic Retail Participation and Declinging Role for Foreign Investors',
    summary: 'Historically, Foreign Institutional Investors (FIIs) have been a major driving force in Emerging Markets (EMs). FIIs may not always outperform but they have two significant advantages vs. domestic investors, a) they can look at other comparable markets and this is not just in EM space and other markets globally but they can also look at other asset classes and then decide how attractive equities in a particular EM look like, b) the FIIs track news flow carefully and diligently but they don’t get too exuberant or excessively pessimistic unnecessarily and they will approach an EM with more equanimity.  But, do FIIs remain equally important at all times and this is what the insight takes a look at.',
    category: 'Equities',
    date: '22-Jul-19',
 },
 {
    title: 'Fed Rate Cut Will Help, But Policy Makers in Emerging Markets Need to Do More',
    summary: 'It may appear from the immediate market reaction that a rate cut by Fed will help the EMs. But, this is an illusion. Investor sentiment is impacted as a result of several factors and that is a key thing to focus on in the longer term because fundamental factors will continue to be more important for EMs and that is something the news analysis based sentiment gauge as of now is not really able to capture completely.',
    category: 'Equities',
    date: '12-Jul-19',
 },
  {
    title: 'India Automobiles Sales in Slow Lane and How Robust is the Emerging Market Consumption Story',
    summary: 'The news flow for Indian Auto Industry is turning from bad to worse over previous many months and over last one year and a little more, there have been multiple reports highlighting factors like poor sales numbers and liquidity crunch impacting sales of vehicles. The bigger question is related to consumer demand. One way to look at this is that there may not be any huge structural challenges for discretionary demand in India and challenges related with consumer demand may only be temporary. However, discretionary demand is relatively safer in EMs but we need to keep an eye on the data points.',
    category: 'Equities',
    date: '6-Jul-19',
 },
 {
    title: 'India’s Central Bank Suffers Another High Profile Exit and The Importance of Strong and Independent Institutions',
    summary: 'Reserve Bank of India (RBI) is one of the oldest monetary institutions in the World and it is much respected as well. Usually, RBI Deputy Governors tend to be away from media glare, but Dr Viral Acharya was different. He was vocal and his statements were often controversial which media could not always ignore. Dr. Acharya, who quit as Reserve Bank of India deputy governor on 24th June, is joining the long list of high profile exits caused by difference of opinion between institutions and ruling dispensation in last few years. These controversies are totally unnecessary because these events reflect very poorly on India’s governance standards and how much respect the government shows to a dissenting opinion.',
    category: 'Equities',
    date: '26-Jun-19',
 },
 {
    title: 'India and Economic Policy Making: Do Election Results Really Matter?',
    summary: 'In Emerging Markets, political stability and continuity of Government policy is very important for the direction of indices. In this respect, India has been relatively stable over years with democracy taking deep roots and despite changes in political parties which enjoyed power, the Government policy has remained largely consistent and broad contours of economic policies over last three decades have changed for better. Does this mean that elections are irrelevant and unnecessary distraction for markets? This is what we explore in this insight.',
    category: 'Equities',
    date: '7-Apr-19',
 },
];  
