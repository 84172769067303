import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

let fullDatarow = [];
let portfolioESGValues = [];
let fileNames= [];


class ControlledStats extends Component {
	constructor(props){
    super(props);

    this.state = {
    	noData: true,
    	goClicked: false,
    	sentimentsHashNo: '0a96bbd5feb2451e884e1c2e6d00d7c3',
    	nodataValue: 'No Data Found',
    	
    }
  	
 
	}

	componentDidUpdate(prevProps, prevState) {
	
		if(this.props.portfolioESGcode == 1){
			if(this.props.sectorControlledBuckets !== prevProps.sectorControlledBuckets){
				this.props.onGoClick(false);
				
				this.setState({
					noData:false,
					csvError:false,
					nodataValue: 'No portfolio selected',
				});
			}
			
		}
		else{
			if(this.props.portfolioESGhash !== prevProps.portfolioESGhash){
				this.props.onGoClick(false)
				this.setState({
					portfolioESGhash:this.props.sentimentsHashNo,
					noData:true,
					csvError:false,
					nodataValue: 'No data found',
				});
				if( this.props.portfolioESGerror === 'Wrong Header'){
					this.setState({
						csvError:true,
						nodataValue: 'No portfolio selected',
					});
				}
			}
		}

		
	}

	render() {
	

	fullDatarow = [];
	portfolioESGValues = [];
	fileNames= [];
	
	if(this.props.sectorControlledBuckets){
		
		this.props.sectorControlledBuckets.map((row , index )=> {
			
			
			let items_row = [];
			portfolioESGValues = [];
			row.data.map((item,index)=> {
				portfolioESGValues.push({
				  label: item.Sector_Bucket,
				  y: parseFloat(item.Stats),
				});

			 })
			items_row =  portfolioESGValues
			
			fullDatarow.push({
				type: "column",
				toolTipContent:"{y} Returns",
				dataPoints: items_row

			})
		});

	}
	

	const options = {
		theme: "light2", // "light1", "dark1", "dark2"
		title:{
	      text: ""  
	     },
		axisX:{
		   title:"Bucket",
		   // valueFormatString: "DD-MMM-YY",
		   labelAngle: 0
		},
		
		axisY:[{
			title: "Return %",
			labelAngle: 0,
			// interval: 2
		}],
		data: fullDatarow
		
		
	} 
	

	return (
		<div>
			{this.state.csvError ? <div class="header_error">Headers are not correct in above CSV file.</div> : "" }
			{!this.state.noData ? <div>
				
				<div className="chart_wrapper"><CanvasJSChart options = {options}
					/* onRef={ref => this.chart = ref} */
				/>
				<div className="whiteStrip"></div></div>
				
			</div> : <div className="noData">{this.state.nodataValue}</div> }
		</div>
		);
	}
}
export default ControlledStats;   