import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index'; 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'; 
import FormControl from '@material-ui/core/FormControl'; 
import Select from '@material-ui/core/Select'; 
import loader from '../../../assets/images/loader.gif';
import FileUpload from './FileUpload';

let userMail = '';
let dictionaryName = ['dummy.csv','dumy1.csv','dummy2.csv'];
let scoresFiles = [];
let fileNames = [];
let download_file_name = "";
 
class ScoresFiles extends Component {

	constructor(props){
    super(props);

    this.state = {
    	uploadFile:'',
    	dictionaryUpload:"",
    	modelOpen: false,
    	dictionaryName:[],
    	deleteName: '',
      radioValue: 'textarea',
      textareaActive: true,
      textareaText:'',
      resultDictionary: false,
      files: [],
      scoresFiles: [],
      file:'',
      fileNames: [],
      successMsg: false,
      showResult: false,
      user_id:0,
      error_Show:false,
      errorMessage: "",
      errorMessageDisplay:"",
      waitingMessage: false,
      download_file_name: "",
    

    }
    this.handleDictionaryUpload = this.handleDictionaryUpload.bind(this); 
    this.uploadFile = this.uploadFile.bind(this);
    this.submitFormData = this.submitFormData.bind(this);
    this.changeText = this.changeText.bind(this);
    this.addFile = this.addFile.bind(this);
    this.handleUploadedFiles = this.handleUploadedFiles.bind(this);
    this.handleMultiSubmit = this.handleMultiSubmit.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
    
	    
	}


	componentDidMount(){
		
    if( sessionStorage.getItem('signInEmail') === "" || sessionStorage.getItem('signInEmail') === null){
    	this.setState({
	      userEmail: sessionStorage.getItem('signUpEmail')
	    });
	    userMail = sessionStorage.getItem('signUpEmail')
    }else{
    	this.setState({
	      userEmail: sessionStorage.getItem('signInEmail')
	    });
	    userMail = sessionStorage.getItem('signInEmail')
    }


		let filenameParam = JSON.stringify({
      "mail_id":userMail
    });

    this.props.getDictionaryFilesName(filenameParam);

		scoresFiles = [];
   
    let deletefile = JSON.stringify({
      "mail_id":userMail,
      "file_name": download_file_name,
    })

    this.props.deleteDownloadFile(deletefile)
	}
	
 componentDidUpdate(prevProps, prevState) {

    let filenameParam = JSON.stringify({
      "mail_id":userMail
    });



    if(this.props.file_hash !== prevProps.file_hash){
      this.setState({
        file_hash: this.props.file_hash,
        dictionaryName : this.props.dictionaryNamesList
      })
      dictionaryName = this.props.dictionaryNamesList
    }
   
    if(this.props.dictionaryCode === 1){
      if(this.props.dictionaryHash !== prevProps.dictionaryHash){
        this.props.getDictionaryFilesName(filenameParam)
      }
    }
    // console.log('this.props.multiStatusCode11',this.props.multiStatusCode)
    if(this.props.multiStatusCode === 1){
      if(this.props.Score_files_hash !== prevProps.Score_files_hash){
        this.setState({
          successMsg : true,
          Score_files_hash : this.props.Score_files_hash ,
          showResult: true,
          user_id:this.props.user_id,
          waitingMessage: false,
          download_file_name: this.props.download_file_name
        })
        download_file_name = this.props.download_file_name
      }
    }else{
      if(this.props.Score_files_hash !== prevProps.Score_files_hash){
        // alert('11')
        scoresFiles = [];
        this.setState({
          error_Show: true,
          errorMessageDisplay: this.props.multiStatus,
          scoresFiles : scoresFiles,
          waitingMessage: false,
        })
      }
    }


  }

  componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  handleDictionaryUpload (event){
  	
    this.setState({
      dictionaryUpload: event.target.value,
    })
  };

 

  uploadFile(event) {
    let file = event.target.files[0];
   
    this.setState({
      uploadFile: file,
      
    });

    const data = new FormData();
    data.append('file', file);
    data.append('mail_id', userMail); 
  }

  changeText(event){
    this.setState({
      textareaText: event.target.value
    })
  }

  submitFormData(){
    this.setState({
      resultDictionary: true,
    })
  }

  addFile (file) {
    this.setState({
      files: file.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    });
  };

  handleUploadedFiles(files) {
   

    var totalLength = parseInt(scoresFiles.length) + parseInt(files.length)

    if(totalLength > 10){
      this.setState({
        uploadErrror: "You can only upload 10 files (pdf/txt) maximum.",
        errorMessage:"",
      })
    }else{
      files.map(file => {
        scoresFiles.push(file);
      });
      this.setState({
        scoresFiles:scoresFiles,
        file:files,
        errorMessage:"",
        uploadErrror: "",
        error_Show: false,
        errorMessageDisplay: "",
      })
    }

  
  }

  handleMultiSubmit(){
   
  

    if(this.state.dictionaryUpload === "" || this.state.dictionaryUpload === undefined ){ 
      this.setState({
        errorMessage: "Please choose a dictionary and then upload files below"
      })
    }else if(this.state.scoresFiles.length < 1){ 
      this.setState({
        errorMessage: "Please upload files"
      })
    }else{ 
      fileNames = [];

      this.state.scoresFiles.map((file,index) => 
        fileNames.push(file.name)
      )

      this.setState({
        filelength : this.state.scoresFiles.length,
        fileNames: fileNames,
        errorMessage: '',
        waitingMessage: true,
      })


      const formData = this.state.scoresFiles;
      const fd = new FormData(); 
      for(const k of formData){
        // console.log(k)
        fd.append('file',k , k.name);
      };
      fd.append('mail_id', userMail);
      fd.append('dictionary_file', this.state.dictionaryUpload);

      this.props.multiFileSubmit(fd);
    }

  }

  downloadFiles(){
    let downloadFile = JSON.stringify({
      "mail_id": userMail
    });
    let filename = this.props.download_file_name+'.csv';
    //console.log('filename1111',filename)
    // console.log('downloadFile',downloadFile)

    window.open('https://analytics.emalpha.com:5000/download/file/'+userMail+'/'+filename+'/downloadableFile', '_blank').focus();

    //window.location.href = 'https://analytics.emalpha.com:5000/downloadableFile/'+this.state.user_id+'/'+this.props.download_file_name+'.csv'

  
    scoresFiles = [];

    this.setState({
      filelength : this.state.scoresFiles.length,
      fileNames: fileNames,
      showResult: false,
      scoresFiles: scoresFiles,
      successMsg: false ,
      error_Show: false ,
      uploadErrror:"",
      errorMessage:"",
    })
    
    let deletefile = JSON.stringify({
      "mail_id":userMail,
      "file_name": download_file_name,
    })

    this.props.deleteDownloadFile(deletefile)
   
  }

	
	render() {
		return (
			<div className="esgWorkspace upload_dictionary">
				
      {!this.state.showResult ? <div className="container_wrapper">
      		
					<div className="right_info">
						<div>
							<FormControl>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Select Framework:
                </InputLabel>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={this.state.dictionaryUpload}
                  defaultValue={this.state.dictionaryUpload}
                  onChange={this.handleDictionaryUpload}
                >
                  {this.state.dictionaryName ? this.state.dictionaryName.map((item,index) => 
                		<MenuItem value={item.file_name} key={index} > 
	                    {item.file_name}
	                  	{/*<i className="fa fa-times" value={name} onClick={(e) => this.handleRemoveOption(e)} ></i>*/}
	                  </MenuItem>

                  ): ""}

                </Select>
                
              </FormControl>


						</div>
					</div>

	        <div className="upload_MultipleFiles" >
            <div>
              <FileUpload handleUploadedFiles={this.handleUploadedFiles } />
              <span className="imp_note">Note:- Maximum number of files that can be uploaded at the same time is 10 with cumulative upload size 300Mb</span>
              <p className="uploaderror">{this.state.uploadErrror}</p>
              <div className="uploaded_files">
                <div>
                  
                  {this.state.scoresFiles ? this.state.scoresFiles.map((file,index) => 
                    <div className="upload_file" key={index} >
                      <i className="fa fa-file-text-o"></i>
                      <p title={file.name}>{file.name}</p>
                    </div>
                  ): ""} 
                    
                </div>
              </div>
            

             <p className="error_upload">{this.state.errorMessage}</p>
             <button className="upload_bttn " onClick={this.handleMultiSubmit}>Upload and Submit</button>

             {this.state.successMsg ? <p className="Success_msg">File is uploded Successfully</p> : "" }
             {/*this.state.error_Show ? <p className="Error_msg">Some problem occured please hit "UPLOAD AND SUBMIT" button again to get the procced data.</p> : ""*/ }
             {this.state.error_Show ? <p className="Error_msg">{this.state.errorMessageDisplay}</p> : "" }

             {this.state.waitingMessage ? <div className="showing_message">
                <div>
                  <p>Please wait. The files are being processed.
                   <img src={loader} />
                  </p>
                </div>
              </div> : ""}


            </div>
          </div>

          <div className="note_area multiplefile_notes note_section">
            <div>
              <h3>Notes:</h3>
              <ul>
                <li>The uploaded files will be processed and assigned category scores using the same logic as in the "Score Text" tab.</li>
                <li>The upload window expects PDF files. Other format files will be ignored.</li>
                <li>Maximum number of files that can be uploaded at a time is 10 with cumulative upload size 300Mb.</li>
                <li>After upload and submission, each file will receive category scores. Results will be stored in the downloadable CSV file.</li>
              </ul>
            </div>
          </div>
					
				</div> 

       : <div className="download_File">
          <div>
            {this.state.filelength > 1 ?
              <h3>{this.state.filelength} files is uploaded for File extension</h3>
              : <h3>1 file is uploaded for File extension</h3> }
            <div className="details_info">
              <h2>Selected Framework</h2>
              <p>{this.state.dictionaryUpload}</p>
            </div>
            <div className="details_info">
              <h2>Uploaded Files</h2>
              {this.state.fileNames.map((name,index) =>
                <p key={index}>{name}</p>
              )}
            </div>
            <a href="javascript:void(0)" className="download_bttn" onClick={this.downloadFiles}> Process files and download results</a>
          </div>
        </div> }
       
				
			</div>

			
		)
	}
}


const mapStateToProps = state => {
  return{
    dictionaryNamesList: state.esgReducers.dictionaryNames,
    dictionaryCodeStatus: state.esgReducers.dictionaryCodeStatus,
    file_hash: state.esgReducers.file_hash,
    multiStatus: state.esgReducers.multiStatus,
    Score_files_hash: state.esgReducers.Score_files_hash,
    multiStatusCode: state.esgReducers.multiStatusCode,
    returnMain: state.esgReducers.returnMain,
    user_id: state.esgReducers.user_id,
    download_file_name: state.esgReducers.download_file_name,

    
    
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getDictionaryFilesName : (data) => dispatch(dataActions.getDictionaryFilesName(data)),
    multiFileSubmit : (data) => dispatch(dataActions.multiFileSubmit(data)),
    deleteDownloadFile : (data) => dispatch(dataActions.deleteDownloadFile(data)),
    //downloadCSVFile : (downloadFile) => dispatch(dataActions.downloadCSVFile(downloadFile)),
   
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ScoresFiles);
