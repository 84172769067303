import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(id, name, contribution, percentage,portfolio ,sector ,temperature) {
  return { id, name, contribution, percentage ,portfolio ,sector ,temperature };
}


let rows = [
  
];

export default function CompanyDataTables(props) {

  
  if(props.sbtiTResponse ){
    rows = [];
    props.sbtiTResponse.map((item,index) => {
      let newrow = createData(item.company_id,item.company_name,item.contribution,item.ownership_percentage,item.portfolio_percentage,item.sector,item.temperature_score);
      
      rows.push(newrow)
    }) 
  }
  
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Company id</TableCell>
            <TableCell align="center">Company Name</TableCell>
            <TableCell align="center">Contribution</TableCell>
            <TableCell align="center">Ownership Percentage</TableCell>
            <TableCell align="center">Portfolio Percentage</TableCell>
            <TableCell align="center">Sector</TableCell>
            <TableCell align="center">Temperature Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell align="center">{row.id}</TableCell>
              <TableCell align="center">{row.name}</TableCell>
              <TableCell align="center">{row.contribution}</TableCell>
              <TableCell align="center">{row.percentage}</TableCell>
              <TableCell align="center">{row.portfolio}</TableCell>
              <TableCell align="center">{row.sector}</TableCell>
              <TableCell align="center">{row.temperature}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}