import React, { Component } from 'react';

import SignUp from './SignUp/SignUp';
import  './SignUp/SignUp.css';


class Signin extends Component {
	render() {
		return (
			<div>
				<div className="main_container">
					<div className="main_tabs">
						<div className="signup_main">
							<SignUp />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Signin;