import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import './Header.css'

import logoNew from '../../../assets/images/logo-new.png';



class Header extends Component {

	constructor(props){
	    super(props);

	    this.state = {
	    	activeLink: '',
	    	headerFixed: false,
	    }
	}

	componentDidMount() {
	    window.addEventListener('scroll', this.handleScroll , true);

	}

	activeMenu(e,menuItem) { 
		// console.log('e',menuItem)
		if(menuItem === 'Logout' ){
			//sessionStorage.setItem("logout", "logout");
		
			sessionStorage.setItem('signupStatus', '0');
			sessionStorage.setItem('signinStatus', '0');
			window.location.href = "https://www.emalpha.com/";
			
		}else if (menuItem === "header_img"){

			if(parseInt(sessionStorage.getItem('signinStatus')) === 1){
				sessionStorage.setItem('signupStatus', '1');
				sessionStorage.setItem('signinStatus', '1');
				window.location.href = "https://www.emalpha.com/";

			}else{
				sessionStorage.setItem('signupStatus', '0');
				sessionStorage.setItem('signinStatus', '0');
				window.location.href = "https://www.emalpha.com/";
			}
		}
	    this.setState({ activeLink: menuItem });
	}

	handleScroll = (e) => {
		
		if(document.documentElement.scrollTop > 10){
			this.setState({ headerFixed: true });
		}else{
			this.setState({ headerFixed: false });
		}
	}


	

	render() {
		/*console.log('sadsad')
		console.log(parseInt(sessionStorage.getItem('signupStatus')))
		console.log(parseInt(sessionStorage.getItem('signinStatus')))*/
		const windLocation = window.location.pathname
		return (
			<div className={`main_header ${this.state.headerFixed ? 'headerFixed' : ''} ${windLocation === '/' ? 'homePage' : ''}`} >
				<div>
					<div className="logo_main">
						<Link onClick={(e,menuItem) => this.activeMenu(e,'header_img')}>
							<img src={logoNew} alt=""  />
						</Link>
					</div>
					<div className="rightmenubar">
						<ul>
							{/*<li className={this.state.activeLink === 'Products' ? 'active_link' : ""} 
             					onClick={(e,menuItem) => this.activeMenu(e,'Products')}>
								
								<Link to="/products">Products</Link>
							</li>
							<li className={this.state.activeLink === 'Applications' ? 'active_link' : ""} 
             					onClick={(e,menuItem) => this.activeMenu(e,'Applications')} >
								<Link to="#">Applications</Link>
							</li>
							<li className={this.state.activeLink === 'Insights' ? 'active_link' : ""} 
             					onClick={(e,menuItem) => this.activeMenu(e,'Insights')}>
								<Link to="/insights">Insights</Link>
								<div className="inner_menu">
									<ul className="menu_dropdown">
										<li><Link to="/insights/covid-19">Covid-19</Link></li>
										<li><Link to="/insights/emerging markets">Emerging Markets</Link></li>
										<li><Link to="/insights/developed markets">Developed Markets</Link></li>
										<li><Link to="/insights/Equities">Equities</Link></li>
										<li><Link to="/insights/fixed income">Fixed Income</Link></li>
										<li><Link to="/insights/commodities">Commodities</Link></li>
										<li><Link to="/insights/markets and micro">Markets and Macro</Link></li>
										<li><Link to="/insights/behavioral finance">Behavioral Finance</Link></li>
										<li><Link to="/insights/newsflow and impact">News flow and Impact</Link></li>
										<li><Link to="/insights/systematic investing">Systematic Investing</Link></li>
										<li><Link to="/insights/big-data">Big Data and AI in Finance</Link></li>
										<li><Link to="/insights/sentiment analysis">Sentiment Analysis</Link></li>
										<li><Link to="/insights/esg and sustainability">ESG and Sustainability</Link></li>
									</ul>
								</div>
							</li>
							<li className={this.state.activeLink === 'News' ? 'active_link' : ""} 
             					onClick={(e,menuItem) => this.activeMenu(e,'News')}>
								<Link to="#">News</Link>
							</li>*/}
							{(parseInt(sessionStorage.getItem('signupStatus')) !== 1 &&  parseInt(sessionStorage.getItem('signinStatus')) !== 1) ? <li className={this.state.activeLink === 'Signin' ? 'active_link' : ""} 
             					onClick={(e,menuItem) => this.activeMenu(e,'Signin')}>
								<Link to="/signin"></Link>
							</li> : 
							<li onClick={(e,menuItem) => this.activeMenu(e,'Logout')} className="logout_bttn" >
								<Link to="/signin">Log Out</Link>
							</li> }
							
							{/*<li className="request_bttn">
								<a href="#">Request a Trial</a>
							</li>*/}
							
						</ul>
					</div>
				</div>
			</div>
		)
	}
}

export default Header;