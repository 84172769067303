export const GRIdata = [
  {
    dimension: "GRI 200 Series",
    flag: "true",
    subcategory: "GRI 200: Economic Standards",
    category_no: '8',
    scores: "",
    bgcolor: "rgb(74 134 232 / 62%)",
    color: '#000',
  },
  {
    dimension: "GRI 200 Series",
    flag: "false",
    subcategory: "GRI 201: Economic Performance 2016",
    category_no: '8',
    scores: "",
    bgcolor: "rgb(74 134 232 / 62%)",
    color: '#000',
  },
  {
    dimension: "GRI 200 Series",
    flag: "false",
    subcategory: "GRI 202: Market Presence 2016",
    category_no: '8',
    scores: "",
    bgcolor: "rgb(74 134 232 / 62%)",
    color: '#000',
  },
  {
    dimension: "GRI 200 Series",
    flag: "false",
    subcategory: "GRI 203: Indirect Economic Impacts 2016",
    category_no: '8',
    scores: "",
    bgcolor: "rgb(74 134 232 / 62%)",
    color: '#000',
  },
  {
    dimension: "GRI 200 Series",
    flag: "false",
    subcategory: "GRI 204: Procurement Practices 2016",
    category_no: '8',
    scores: "",
    bgcolor: "rgb(74 134 232 / 62%)",
    color: '#000',
  },
  {
    dimension: "GRI 200 Series",
    flag: "false",
    subcategory: "GRI 205: Anti-corruption 2016",
    category_no: '8',
    scores: "",
    bgcolor: "rgb(74 134 232 / 62%)",
    color: '#000',
  },
  
  {
    dimension: "GRI 200 Series",
    flag: "false",
    subcategory: "GRI 206: Anti-competitive Behavior 2016",
    category_no: '8',
    bgcolor: "rgb(74 134 232 / 62%)",
    color: '#000',
  },
  {
    dimension: "GRI 200 Series",
    flag: "false",
    subcategory: "GRI 207: Tax 2019",
    category_no: '8',
    scores: "",
    bgcolor: "rgb(74 134 232 / 62%)",
    color: '#000',
  },
  
  {
    dimension: "GRI 300 Series",
    flag: "true",
    subcategory: "GRI 300 Environmental Standards",
    category_no: '9',
    scores: "",
    bgcolor: "rgb(143 210 114 / 87%)",
    color: '#000',
  },
  {
    dimension: "GRI 300 Series",
    flag: "false",
    subcategory: "GRI 301: Materials 2016",
    category_no: '9',
    scores: "",
    bgcolor: "rgb(143 210 114 / 87%)",
    color: '#000',
  },
  {
    dimension: "GRI 300 Series",
    flag: "false",
    subcategory: "GRI 302: Energy 2016",
    category_no: '9',
    scores: "",
    bgcolor: "rgb(143 210 114 / 87%)",
    color: '#000',
  },
  {
    dimension: "GRI 300 Series",
    flag: "false",
    subcategory: "GRI 303: Water and Effluents 2018",
    category_no: '9',
    scores: "",
    bgcolor: "rgb(143 210 114 / 87%)",
    color: '#000',
  },

  {
    dimension: "GRI 300 Series",
    flag: "false",
    subcategory: "GRI 304: Biodiversity 2016",
    category_no: '9',
    scores: "",
    bgcolor: "rgb(143 210 114 / 87%)",
    color: '#000',
  }, 
  {
    dimension: "GRI 300 Series",
    flag: "false",
    subcategory: "GRI 305: Emissions 2016",
    category_no: '9',
    scores: "",
    bgcolor: "rgb(143 210 114 / 87%)",
    color: '#000',
  }, 
  {
    dimension: "GRI 300 Series",
    flag: "false",
    subcategory: "GRI 306: Effluents and Waste 2016",
    category_no: '9',
    scores: "",
    bgcolor: "rgb(143 210 114 / 87%)",
    color: '#000',
  }, 
  {
    dimension: "GRI 300 Series",
    flag: "false",
    subcategory: "GRI 306: Waste 2020",
    category_no: '9',
    scores: "",
    bgcolor: "rgb(143 210 114 / 87%)",
    color: "#000",
  },
  {
    dimension: "GRI 300 Series",
    flag: "false",
    subcategory: "GRI 308: Supplier Environmental Assessment 2016",
    category_no: '9',
    scores: "",
    bgcolor: "rgb(143 210 114 / 87%)",
    color: '#000',
  },
   {
    dimension: "GRI 400 Series",
    flag: "true",
    subcategory: "GRI 400 Social Standards",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: "#000",
  }, 
  {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 401: Employment 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: "#000",
  }, 
  {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 402: Labor/Management Relations 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: "#000",
  }, 
  {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 403: Occupational Health and Safety 2018",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: "#000",
  }, 
   {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 404: Training and Education 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
  {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 405: Diversity and Equal Opportunity 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
  {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 406: Non-discrimination 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  },
   {
    dimension: "GRI 400 Seriese",
    flag: "false",
    subcategory: "GRI 407: Freedom of Association and Collective Bargaining 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  },
   {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 408: Child Labor 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
   {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 409: Forced or Compulsory Labor 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
   {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 410: Security Practices 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
   {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 411: Rights of Indigenous Peoples 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
   {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 413: Local Communities 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
   {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 414: Supplier Social Assessment 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
   {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 415: Public Policy 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
   {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 416: Customer Health and Safety 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
   {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 417: Marketing and Labeling 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
   {
    dimension: "GRI 400 Series",
    flag: "false",
    subcategory: "GRI 418: Customer Privacy 2016",
    category_no: '18',
    scores: "",
    bgcolor: "rgb(132 71 173 / 32%)",
    color: '#000',
  }, 
  
];