import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index'; 
import Modal from '@material-ui/core/Modal';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PortfolioMultiSelect from './PortfolioMultiSelect';
import { DateRangePicker } from "materialui-daterange-picker";
import loader from '../../../assets/images/loader.gif';

import InvestmentsPie from './InvestmentsPie';
import ContributionsPie from './ContributionsPie';
import TemperatureScores from './TemperatureScores';
import CompanyDataTables from './CompanyDataCharts';
import StockIdMultiSelect from './StockIdMultiSelect';
import GUI3Investment from './GUI3Investment';
import GUI3Contribution from './GUI3Contribution';
import GUI3TemperatureScores from './GUI3TemperatureScores';
import AggregationTable from './AggregationTable';
import ScenarioTable from './ScenarioTable';
import moment from 'moment';
import News from '../Signin/News';

import {HOST_URL} from '../../../store/Constant'
let userMail = '';
let portfolioNames = [];
let fileData;
let fileNamesArr = [];
let filenameList = [];
let companyValue = [];
let stockListArr = [];

let portfolioSelected = [];
let selectedCsv = [];
 
class SBTITab extends Component {

  constructor(props){
    super(props);

      this.state = {
        open: false,
        portfolioList:[],
        portfolio:"",
        portfolioDateValue: '01/01/2015 - 02/25/2015',
        pickeropen:false,
        portfolioDateRange: new Date(),
        uploadFile:'',
        userMail:'',
        Upload_hash: '',
        disabledGo:true,
        fileNamesArr: [],
        goclikedSentiments:false,
        goclikedIntencity:false,
        frameWork:'',
        goclikedStock: false,
        gocliked: false,
        uploadStatus: "",
        openEditModel: false,
        modelConfirmOpen: false,
        deleteName:"",
        modelConfirmOpen:"",
        timeFrame:"< 5 years",
        Scope:"S1 + S2",
        Grouping:"Sector",
        companyContribution:[],
        companyValue:"",
        showDropdown: false,
        disabledsbtiGo: true,
        showtable:false,
        Scenario:'1',
        Engagement:'a',
        nameSbtiList:[],
        stockListArr: ['BR0000000012'],
        showGUI3:false,
        disabled3Go:false,
        showgui3: false,
        showGui1: false,
        goClickLoader:false,
        goClickLoaderG3:false,
        disabledEnagment: false,
        showcompanyList: false,
        setStockValue : false,
        stockListSbti:"",
        sbtistockHash:"",
        portfolioSelected: [],
        filenameList:[],
        dropdownList : [],
      }
      
     
      this.togglePicker = this.togglePicker.bind(this);
      this.openPortfolioPicker = this.openPortfolioPicker.bind(this);
      this.setPortfolioDate = this.setPortfolioDate.bind(this);
      this.uploadFile = this.uploadFile.bind(this);
      this.handleUploadFile = this.handleUploadFile.bind(this);
      this.callGraphData = this.callGraphData.bind(this); 
      this.setDisabledFalse = this.setDisabledFalse.bind(this); 
      this.handleFrameWork = this.handleFrameWork.bind(this); 
      this.handlePortfolio = this.handlePortfolio.bind(this); 
      this.handleOpen = this.handleOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handlemodelConfirmOpen = this.handlemodelConfirmOpen.bind(this);
      this.handlemodelConfirmClose = this.handlemodelConfirmClose.bind(this);
      this.handleRemoveOption = this.handleRemoveOption.bind(this);
      this.handleDeleteName = this.handleDeleteName.bind(this);
      this.handleTimeFrame = this.handleTimeFrame.bind(this);
      this.handleScope = this.handleScope.bind(this);
      this.handlecompanyContribution = this.handlecompanyContribution.bind(this);
      this.callCompanyData = this.callCompanyData.bind(this);
      this.handleScenario = this.handleScenario.bind(this);
      this.handleEngagement = this.handleEngagement.bind(this);
      this.handleStockList = this.handleStockList.bind(this);
      this.callGUI3 = this.callGUI3.bind(this);
      this.handleDownloadFile = this.handleDownloadFile.bind(this);
   
  }


  componentDidMount(){
   
    if( sessionStorage.getItem('signInEmail') === "" || sessionStorage.getItem('signInEmail') === null){
      this.setState({
        userEmail: sessionStorage.getItem('signUpEmail')
      });
      userMail = sessionStorage.getItem('signUpEmail')
    }else{
      this.setState({
        userEmail: sessionStorage.getItem('signInEmail')
      });
      userMail = sessionStorage.getItem('signInEmail')
    }

    
    let filenameParam = JSON.stringify({
      "mail_id":userMail
    });

    this.props.sbtiNameList(filenameParam);
    this.props.getFrameWorks();
    
  }
  
  
  componentDidUpdate(prevProps, prevState) {

    let $this = this;


    if(this.props.frameListHash !== prevProps.frameListHash){
      this.setState({
        frameWork: this.props.frameWorkNames[0]
      })
    }
    
    let filenameParam = JSON.stringify({
      "mail_id":userMail,
    });

    if(this.props.codeUpload === 1){
      if(prevProps.Upload_hash !== this.props.Upload_hash){
        this.setState({
          Upload_hash: this.props.Upload_hash,
          uploadStatus: this.props.statusUpload
        });

        setTimeout(function(){
          $this.setState({
            uploadStatus: ""
          });
        },5000)
        this.props.sbtiNameList(filenameParam);

      }
    }else{
      if(prevProps.Upload_hash !== this.props.Upload_hash){
        this.setState({
          Upload_hash: this.props.Upload_hash,
          uploadStatus: this.props.statusUpload
        });

        setTimeout(function(){
          $this.setState({
            uploadStatus: "",

          });
        },5000)
      }
    }


    if(this.props.esgCsvHash !== prevProps.esgCsvHash){
      this.setState({
        filenameList: this.props.filenameList,
      });
      filenameList = this.props.filenameList
      
    }




    // company contribution dropdown array
    if(this.props.sbtiStatusCode ===  1){

      if(this.props.sbtirand_hash !== prevProps.sbtirand_hash){
        if(this.props.sbtiResponse.Temprature_score_data){
        
          companyValue = []
          this.props.sbtiResponse.Temprature_score_data.map((item,index) => {
            companyValue.push(item.sector_name)
          })
          this.setState({
            companyContribution:companyValue,
            showDropdown: true,
          })
       
          this.setState({
            goClickLoader:false,
          })
        }
      }
    }

    if(this.props.esgCsvHashsbti !== prevProps.esgCsvHashsbti){
    
      this.setState({
        portfolioSelected : this.props.nameSbtiList,
        nameSbtiList : this.props.nameSbtiList,
        dropdownList : this.props.nameSbtiList,
      })
      portfolioSelected = this.props.filenameList
    }

    if(this.props.gui3rand_hash !== prevProps.gui3rand_hash){
      this.setState({
        goClickLoaderG3: false,
      })
    }

    

  }

  handleScenario(e){
   
    this.setState({
      Scenario: e.target.value,
      setStockValue : false,
      disabled3Go: false

    })


    if(e.target.value === "1"){
      this.setState({
        disabledEnagment:false,
        showcompanyList: false,
        Engagement: 'a',
        stockListArr: ['BR0000000012'],
        
      })
    }else if(e.target.value === "2"){
       this.setState({
        disabledEnagment:false,
        showcompanyList: false,
        Engagement: "b",
        stockListArr: ['BR0000000012'],
        
      })
     }else if(e.target.value === "3"){
      if(stockListArr.length !== 0){
        
        this.setState({
          disabledEnagment:true,
          showcompanyList: false,
          Engagement: this.state.Engagement,
          disabled3Go: false,
          setStockValue : false,

        })

      }else{
       
        this.setState({
          disabledEnagment:true,
          showcompanyList: false,
          Engagement: this.state.Engagement,
          stockListArr: ['BR0000000012'],
          disabled3Go: false,
          setStockValue : true,
        })
      }
     }else{
     
      stockListArr = [];
    
       this.setState({
        disabledEnagment:true,
        showcompanyList: true,
        Engagement: this.state.Engagement,
        stockListSbti:this.props.stockListSbti,
        sbtistockHash:this.props.sbtistockHash,
        disabled3Go:true,
        
      })
     }
  }

  handleEngagement(e){
    
    if(this.state.Scenario === "4"){

      if(stockListArr.length !== 0){
        this.setState({
           disabled3Go: false,
        })
      }else{
        this.setState({
           disabled3Go: true,
        })
      }
    }else if(this.state.Scenario === "3"){
      this.setState({
        stockListArr: ['BR0000000012'],
        disabled3Go: false,
      })

    }else{
      this.setState({
         disabled3Go: false,
      })
    }
    this.setState({
      Engagement: e.target.value,
      //disabled3Go: false,
    })
  }


  
  handlePortfolio(list){
    fileNamesArr =[];
    let newlist = this.state.nameSbtiList 
    if(list.length){

     
     
      for(let i=0;i < list.length;i++){
        fileNamesArr.push(list[i].name);
      }
      
      const namesToDeleteSet = new Set(fileNamesArr);

      if(fileNamesArr.length > 0 ){
        selectedCsv = fileNamesArr
        
        newlist = newlist.filter((name) => {
          return !namesToDeleteSet.has(name);
        });
       
      }
      this.setState({
        fileNamesArr:fileNamesArr,
        disabledGo:false,
        dropdownList: newlist
      })

    }else{
      this.setState({
        disabledGo:true,
        dropdownList: newlist
      })
    }
  }

  
  

  handleStockList(list){
   
    stockListArr =[];
    if(list.length){
      for(let i=0;i < list.length;i++){
        stockListArr.push(list[i].name);
      }
      this.setState({
        stockListArr:stockListArr,
        disabled3Go:false
      })
    }else{
      this.setState({
        disabled3Go:true
      })
    }
  }

  




  togglePicker(){
    
    this.setState({
      pickeropen: !this.state.pickeropen
    })
  }

  openPortfolioPicker(){
    
    this.setState({
      pickeropen: !this.state.pickeropen
    })
  }

  setPortfolioDate (range) {
    
    this.setState({
      portfolioDateRange:range,
      disabledGo:false,
    })
  
    let endDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.endDate);
    let startDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.startDate);
    
    
    let FinalDate = moment(startDateValue).format("MM/DD/YYYY")+'-'+moment(endDateValue).format("MM/DD/YYYY");

    this.setState({
      portfolioDateValue : FinalDate,
      pickeropen: false,
    })


  }
   
  uploadFile(event) {
    let file = event.target.files[0];
   
    fileData = file;
  

    this.setState({
      uploadFile: file
    });

    const data = new FormData();
    data.append('file', file);
    data.append('mail_id', userMail);

    
    this.props.uploadSbtiFile(data);

  }

  handleUploadFile(){
  
    let uploadFileParam = JSON.stringify({
      "file": fileData,
      "mail_id":userMail
    });
  
    this.props.uploadFile(uploadFileParam);
  }

  
  callGraphData() {
   
    if(fileNamesArr.length > 0){
      this.setState({
        disabledGo:true,
        goclikedSentiments:true,
        goclikedIntencity:true,
        gocliked: true,
        goclikedStock:true,
        selectStocks:"",
        showGui1: true,
        goClickLoader: true,
      });
      let portfolioString = "";
    
      for(let i=0; i< fileNamesArr.length; i++){
       
        if(fileNamesArr.length > 1){
          portfolioString = portfolioString + ',' + this.state.fileNamesArr[i] 
        }else{
          portfolioString = this.state.fileNamesArr[i]
        }
      
        
      }
   
      portfolioString = portfolioString.replace(/^,/, '');

      
      let sbtiParams = JSON.stringify({
        "file_name": portfolioString,
        "sector":this.state.Grouping,
        "time_frame":this.state.timeFrame,
        "scope":this.state.Scope,
        "mail_id":this.state.userEmail,


      });

     
      this.props.sbtiDataCharts(sbtiParams);

    }else{
      this.setState({
        selectStocks: "Please choose one or more files."
      });
    }

    


  }

  callGUI3(){
  
    if(fileNamesArr.length > 0){
       this.setState({
        goclikedSentiments:true,
        goclikedIntencity:true,
        gocliked: true,
        goclikedStock:true,
        selectStocks:"",
        showgui3:true,
        disabled3Go: true,
        goClickLoaderG3:true,
       });
      let portfolioString = "";
    
      for(let i=0; i< fileNamesArr.length; i++){
       
        if(fileNamesArr.length > 1){
          portfolioString = portfolioString + ',' + this.state.fileNamesArr[i] 
        }else{
          portfolioString = this.state.fileNamesArr[i]
        }
      
        
      }
   
      portfolioString = portfolioString.replace(/^,/, '');

      
      
      let gui3Params = JSON.stringify({
        "file_name": portfolioString,
        "frame_name": this.state.frameWork,
        "sector":this.state.Grouping,
        "time_frame":this.state.timeFrame,
        "scope":this.state.Scope,
        "mail_id":this.state.userEmail,
        "scenario_type":this.state.Scenario,
        "engagement_type":this.state.Engagement,
        "companies": this.state.stockListArr


      });
     
      this.props.getDataGUI3(gui3Params);


     
      if(this.state.setStockValue  === true){
        if(this.state.Scenario === '3' || this.state.Scenario === '2' || this.state.Scenario === '1'){
          stockListArr = this.state.stockListArr;
          const index = stockListArr.indexOf('BR0000000012');
          if (index > -1) {
            stockListArr.splice(index, 1);
          }
          
          this.setState({
            stockListArr: stockListArr
          })
        }else{
          this.setState({
            stockListArr: this.state.stockListArr,
          })
        }
      }

      

  }else{
      this.setState({
        selectStocks: "Please choose one or more files."
      });
    }
}



  setDisabledFalse(){
   
    this.setState({
      disabledGo:false
    })
  }
  

  handleFrameWork (event){
    this.setState({
      frameWork: event.target.value,
      disabledGo:false
    })
  };



  handleOpen(){
    this.setState({
      openEditModel: true,
    })
  };

  handleClose() {
    this.setState({
      openEditModel: false,
    })
  };

  handlemodelConfirmOpen() {
    this.setState({
      modelConfirmOpen:true,
    })
  };

  handlemodelConfirmClose() {
    this.setState({
      modelConfirmOpen:false,
    })
  };


  handleRemoveOption(e){
    this.setState({
      deleteName:e.target.getAttribute("value")
    })
    this.handlemodelConfirmOpen();
    e.stopPropagation();
    
  }

  handleTimeFrame(e){
    this.setState({
      timeFrame: e.target.value,
      disabledGo:false
    })
  }

  handleScope(e){
    this.setState({
      Scope: e.target.value,
      disabledGo:false

    })
  }

  handleGrouping(e){
    this.setState({
      Grouping: e.target.value,
      disabledGo:false
    })
  }

 

  handleDeleteName(){
    document.getElementById("csv_upload").value = "";
    portfolioSelected = this.state.nameSbtiList
    portfolioSelected = portfolioSelected.filter(item => item !== this.state.deleteName);
    let newlist = portfolioSelected 
    const namesToDeleteSet = new Set(selectedCsv);
    if(newlist.length > 0 ){
     
      newlist = newlist.filter((name) => {
        return !namesToDeleteSet.has(name);
      });
     
    }
    
    this.setState({
      portfolioSelected: portfolioSelected,
      nameSbtiList:portfolioSelected,
      dropdownList:newlist,
    });

    let deleteParams = JSON.stringify({
      "mail_id":userMail,
      "file_name" : this.state.deleteName
    });

   
    this.props.deletesbtiGCsv(deleteParams)
    
    this.handlemodelConfirmClose();
  } 

 

  handlecompanyContribution(e) {
    this.setState({
      companyValue: e.target.value,
      disabledsbtiGo:false,
    })
  }

  callCompanyData(){
    if(fileNamesArr.length > 0){
      this.setState({
       showtable:true,
       showGUI3: true,
       disabledsbtiGo:true,
      });
      let portfolioString = "";
    
      for(let i=0; i< fileNamesArr.length; i++){
       
        if(fileNamesArr.length > 1){
          portfolioString = portfolioString + ',' + this.state.fileNamesArr[i] 
        }else{
          portfolioString = this.state.fileNamesArr[i]
        }
      
        
      }
   
      portfolioString = portfolioString.replace(/^,/, '');

     
      
      let sbtiParams = JSON.stringify({
        "file_name": portfolioString,
        "frame_name": this.state.frameWork,
        "sector":this.state.Grouping,
        "time_frame":this.state.timeFrame,
        "scope":this.state.Scope,
        "mail_id":this.state.userEmail,
        "sector_category": this.state.companyValue


      });
     
      let stocksIdParams = JSON.stringify({
        "file_name": portfolioString,
        "mail_id":this.state.userEmail,
      });

      this.props.getStocksId(stocksIdParams)

      this.props.sbtiDataTables(sbtiParams);

    }else{
      this.setState({
        selectStocks: "Please choose one or more files."
      });
    }
  }

  handleDownloadFile(){
    var currentTimeInSeconds=Math.floor(Date.now()/1000);
   
    window.open( HOST_URL+"download/sample/Net_zero_portfolio.csv"+"?x="+currentTimeInSeconds, "_blank");
  }
 


  render() {
   
    return (
      <div className="esg_company_head"><div className="portFolio_container ESG_tab">
        <div className="container_wrapper">
          <h3 className="instruction_text">
            Upload a new portfolio, or select a previously uploaded portfolio.
            <div className="info_area">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              <div className="instruction_tooltip">
                <span>The csv portfolio file should contain the column headers (ISIN , Weight , Engagment_Target)</span>
                <div className="arrow-down"></div>
              </div>
            </div>
          </h3>
          <p className="sample_file" > Sample portfolio <a target="_blank" onClick={this.handleDownloadFile} > Net_zero_portfolio.csv </a></p>
          <div className="left_intro">
            <div>

              <div className="upload_button">
                
                <div className="upload_csv">
                  <input onChange={e => this.uploadFile(e)} type="file" className="custom-file-input" id="csv_upload" accept=".csv" />
                </div>
                <p className={"upload_status " + (this.props.codeUpload === 1 ? 'success_upload' : 'error_upload')}> 
                  {this.state.uploadStatus} 
                </p>
              </div>
              
              <div className="uploadedcsv_list">
                <div className="dropdown_csv">
                  <PortfolioMultiSelect fileNameList = {this.state.nameSbtiList} onSelectPortfolio={this.handlePortfolio} sbtiFlag="true" />
                  
                </div>
                <div className="main_modal_container">
                  {this.state.nameSbtiList ?  <div className="edit_dropdown" onClick={this.handleOpen}>
                    <span>Delete or download portfolio </span><i className="fa fa-pencil"></i>
                  </div> : ""}

                  <Modal
                    open={this.state.openEditModel}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="listingModal"
                  >
                    <div className="listing_container">
                      <div className="container_modal">
                        <h2>Portfolio listing</h2>
                        <ul>
                          {this.state.dropdownList ? this.state.dropdownList.map((item,index) => 
                            <li>
                              <div>
                                <span>{item}</span>
                                <span className="icon_div">
                                  <i className="fa fa-times delete_portfolio" value={item} onClick={(e) => this.handleRemoveOption(e)} ></i>
                               
                                 <a href={'https://analytics.emalpha.com:5000/download/file/'+userMail+'/'+item+'/SBTi_folder'} target="_blank"><i className="fa fa-download download_portfolio" ></i></a>
                                </span>
                              </div>
                            </li>
                          ) : ""}
                        </ul>
                      </div>
                    </div>
                  </Modal>
                </div>


                <div className="modal_main">
                  <div>
                    <div>
                    
                      <Modal
                        open={this.state.modelConfirmOpen}
                        //onClose={this.handlemodelConfirmClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                      >
                        <div className="deleteModal">
                          <h2 id="simple-modal-title">Are you sure you want to delete {this.state.deleteName} dictionary from the list ?</h2>
                          <i className="fa fa-times close_delete" onClick={this.handlemodelConfirmClose}></i>
                          <div className="button_section">
                            <button className="yes_bttn" onClick={this.handleDeleteName} >YES</button>
                            <button className="no_bttn" onClick={this.handlemodelConfirmClose}>NO</button>
                          </div>
                         
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>



              </div>
              
            
            </div>
          </div>
           <div className="right_info sbtisubtab">

            <div className="timeframe_select">
              <FormControl>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Select Time Frame
                </InputLabel>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={this.state.timeFrame}
                  defaultValue={this.state.timeFrame}
                  onChange={this.handleTimeFrame}
                >
                  <MenuItem  key="< 5 years" value="< 5 years"> &#60; 5 years</MenuItem>
                  <MenuItem  key="5 - 15 years" value="5 - 15 years"> 5 - 15 years</MenuItem>
                  <MenuItem  key="> 15 years" value="> 15 years"> &#62; 15 years</MenuItem>

              

                 
                </Select>
                
              </FormControl>
            </div>

             <div className="grouping_selectBox">
              <FormControl>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Select Grouping
                </InputLabel>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={this.state.Grouping}
                  defaultValue={this.state.Grouping}
                  onChange={this.handleGrouping}
                >
                  <MenuItem  key="Sector" value="Sector"> Sector </MenuItem>


                 
                </Select>
                
              </FormControl>
            </div>
          </div>

          <div className="select_section">
            <div>
              <FormControl>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Select Scope
                </InputLabel>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={this.state.Scope}
                  defaultValue={this.state.Scope}
                  onChange={this.handleScope}
                >
                  <MenuItem  key="S1 + S2" value="S1 + S2"> Scope 1 + Scope 2 </MenuItem>
                  <MenuItem  key="S3" value="S3"> Scope 3 </MenuItem>
                  <MenuItem  key="S1 + S2 + S3" value="S1 + S2 + S3"> Scope 1 + Scope 2 + Scope 3 </MenuItem>


                 
                </Select>
                
              </FormControl>
            </div>

          </div>
          <div className="go_button">
              <a href="javascript:void(0)" onClick={this.callGraphData} className={(this.state.disabledGo ? 'disabledGo' : '')} >Go</a>
            </div>
            <p className="slectStockError">{this.state.selectStocks}</p>

         
        </div>
        {this.state.showGui1 ? <div className="portfolio_graph">
          {this.props.sbtiStatusCode === 0 ? <div className="esg_errorMsg">Some Thing went wrong !! Please try after some time.</div>
          :
          <div>
            <div>
              <InvestmentsPie dataArray={this.props.sbtiResponse.Investment_data} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} />
               {this.state.goClickLoader ?  <div className="loader_gif">
                      <img src={loader} /> </div>: ""}
            </div>
            <div>
              <ContributionsPie dataArray={this.props.sbtiResponse.Contribution_data} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} />
              {this.state.goClickLoader ?  <div className="loader_gif">
                      <img src={loader} /> </div>: ""}
            </div>
            <div>
              <TemperatureScores dataArray={this.props.sbtiResponse.Temprature_score_data} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} />
              {this.state.goClickLoader ?  <div className="loader_gif">
                      <img src={loader} /> </div>: ""}
            </div>
            {this.state.showDropdown ? 
              <div className="sbtiDropdown">
                <div> 
                    <h3>Please select the sector value and hit "Go" to display company contributions data. </h3>
                    <div className="value_dropdown">
                      <FormControl>
                      
                        <Select
                          labelId="demo-simple-select-placeholder-label-label"
                          id="demo-simple-select-placeholder-label"
                          value={this.state.companyValue}
                          defaultValue={this.state.companyValue}
                          onChange={this.handlecompanyContribution}
                        >
                          {this.state.companyContribution.length ? this.state.companyContribution.map((list,index) => 

                            <MenuItem  key={index} value={list}> {list} </MenuItem>

                          ):""}


                         
                        </Select>
                        
                      </FormControl>
                    </div>
                    <div className="go_button">
                      <a href="javascript:void(0)" onClick={this.callCompanyData} className={(this.state.disabledsbtiGo ? 'disabledGo' : '')} >Go</a>
                    </div>

                 
                </div>
              </div>  : ""}

              {this.state.showtable ? <div className="companyCharts">
                <CompanyDataTables  sbtiTResponse = {this.props.sbtiTResponse} sbtiTStatusCode = {this.props.sbtiTStatusCode}sbtiTrand_hash = {this.props.sbtiTrand_hash} />

              </div> : "" }
            </div> }
        </div> : ""}

        {this.state.showGUI3 ?<div className="gui-3">
          <div>
            <h4>Engagement Scenario Analysis</h4>
            <div className="select_input">
              <FormControl>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Scenario Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={this.state.Scenario}
                  defaultValue={this.state.Scenario}
                  onChange={this.handleScenario}
                >
                  <MenuItem  key="1" value="1"> 1: Set 2 degree target </MenuItem>
                  <MenuItem  key="2" value="2"> 2: Set well below 2 degree targets </MenuItem>
                  <MenuItem  key="3" value="3"> 3: Engage top 10 contributors </MenuItem>
                  <MenuItem  key="4" value="4"> 4: Engage predefined companies </MenuItem>


                 
                </Select>
                
              </FormControl>
            </div>

            {this.state.disabledEnagment ? <div className="select_input">
              <FormControl>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Engagement Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={this.state.Engagement}
                  defaultValue={this.state.Engagement}
                  onChange={this.handleEngagement}
                >
                  <MenuItem  key="a" value="a"> Set 2 degree targets </MenuItem>
                  <MenuItem  key="b" value="b"> Set well below 2 degree targets </MenuItem>


                 
                </Select>
                
              </FormControl>
            </div> :"" }


           
            {this.state.showcompanyList  ?<div className={`companymultiselect ${!this.state.showcompanyList === true ? "disabled_compantList" : "" }`}>
              <StockIdMultiSelect stockListSbti={this.state.stockListSbti} sbtistockHash={this.state.sbtistockHash} onSelectPortfolio={this.handleStockList}  />
            </div>  : ""}

            <div className="go_button">
              <a href="javascript:void(0)" onClick={this.callGUI3} className={(this.state.disabled3Go ? 'disabledGo' : '')} >Go</a>
            </div>

           


          </div>
        </div> : ""}


        {this.state.showgui3 ? <div className="gui3Charts">
          <div>
            <GUI3Investment dataArray={this.props.Investment_data} sbtiStatusCode={this.props.gui3StatusCode} sbtirand_hash={this.props.gui3rand_hash} />
             {this.state.goClickLoaderG3 ?  <div className="loader_gif">
                    <img src={loader} /> </div>: ""}
          </div>

          <div>
            <GUI3Contribution dataArray={this.props.Contribution_data} sbtiStatusCode={this.props.gui3StatusCode} sbtirand_hash={this.props.gui3rand_hash} />
            {this.state.goClickLoaderG3 ?  <div className="loader_gif">
                    <img src={loader} /> </div>: ""}
          </div>

          <div>
            <GUI3TemperatureScores dataArray={this.props.Temprature_score_data} sbtiStatusCode={this.props.gui3StatusCode} sbtirand_hash={this.props.gui3rand_hash} />
            {this.state.goClickLoaderG3 ?  <div className="loader_gif">
                    <img src={loader} /> </div>: ""}
          </div>

          <AggregationTable  sbtiTResponse = {this.props.aggregation_data} sbtiTStatusCode = {this.props.gui3StatusCode}sbtiTrand_hash = {this.props.gui3rand_hash} />

          <ScenarioTable  sbtiTResponse = {this.props.scenario_aggregated_data} sbtiTStatusCode = {this.props.gui3StatusCode}sbtiTrand_hash = {this.props.gui3rand_hash} />




        </div> : ""}



      </div>
      <News />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    statusUpload: state.esgReducers.statusUpload,
    codeUpload: state.esgReducers.codeUpload,
    Upload_hash: state.esgReducers.Upload_hash,
    filenameList: state.esgReducers.nameList,
    filestatusCode: state.esgReducers.statusCode,
    frameWorkNames: state.esgReducers.frameWorkNames,
    frameListHash: state.esgReducers.frameListHash,
    esgCsvHash: state.esgReducers.esgCsvHash,
    csvFilePath: state.esgReducers.csvFilePath,
    sbtiResponse: state.esgReducers.sbtiResponse,
    sbtiStatusCode: state.esgReducers.sbtiStatusCode,
    sbtirand_hash: state.esgReducers.sbtirand_hash,
    sbtiTResponse: state.esgReducers.sbtiTResponse,
    sbtiTStatusCode: state.esgReducers.sbtiTStatusCode,
    sbtiTrand_hash: state.esgReducers.sbtiTrand_hash,
    nameSbtiList: state.esgReducers.nameSbtiList,
    statusCodesbti: state.esgReducers.statusCodesbti,
    esgCsvHashsbti: state.esgReducers.esgCsvHashsbti,
    csvFilePathsbti: state.esgReducers.csvFilePathsbti,
    stockListSbti: state.esgReducers.stockListSbti,
    sbtiSC: state.esgReducers.sbtiSC,
    sbtistockHash: state.esgReducers.sbtistockHash,

    Contribution_data: state.esgReducers.Contribution_data,
    Investment_data: state.esgReducers.Investment_data,
    Temprature_score_data: state.esgReducers.Temprature_score_data,
    aggregation_data: state.esgReducers.aggregation_data,
    scenario_aggregated_data: state.esgReducers.scenario_aggregated_data,
    gui3StatusCode: state.esgReducers.gui3StatusCode,
    gui3rand_hash: state.esgReducers.gui3rand_hash,

    
  };
}

const mapDispatchToProps = dispatch => {
  return {
    uploadSbtiFile : (uploadFileParam) => dispatch(dataActions.uploadSbtiFile(uploadFileParam)),
    sbtiNameList : (filenameParam) => dispatch(dataActions.sbtiNameList(filenameParam)),
    getFrameWorks : () => dispatch(dataActions.getFrameWorks()),
    esgDataAllFiles : (portfolioParams) => dispatch(dataActions.esgDataAllFiles(portfolioParams)),
    deletesbtiGCsv : (deleteParams) => dispatch(dataActions.deletesbtiGCsv(deleteParams)),
    sbtiDataCharts : (sbtiParams) => dispatch(dataActions.sbtiDataCharts(sbtiParams)),
    sbtiDataTables : (sbtiParams) => dispatch(dataActions.sbtiDataTables(sbtiParams)),
    getStocksId : (stocksIdParams) => dispatch(dataActions.getStocksId(stocksIdParams)),
    getDataGUI3 : (gui3Params) => dispatch(dataActions.getDataGUI3(gui3Params)),
    
      
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(SBTITab);
