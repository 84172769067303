import axios from 'axios';
import * as actionTypes from './actionTypes';
import {HOST_URL} from '../Constant';

let AuthToken = sessionStorage.getItem('authToken');


export const insightList = (data) => {
  return {
    type: actionTypes.INSIGHTLIST,
    insightData: data
  };
};
export const insightFiledata = (data) => {
  return {
    type: actionTypes.INSIGHTFILEDATA,
    insightDataHTML: data
  };
};
export const categoryListName = (data) => {
  return {
    type: actionTypes.CATEGORYLISTNAMES,
    categoryListName: data
  };
};
 

export const getInsightsList = () => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.get(HOST_URL+'read/map/file'  , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      
      dispatch(insightList(response.data))
    })
    .catch(error => {
      console.log('error getInsightsList');
    })
  };
};

export const getCategoryList = () => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.get(HOST_URL+'get/category/folder/name'  , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      
      dispatch(categoryListName(response.data))
    })
    .catch(error => {
      console.log('error getCategoryList');
    })
  };
};

export const getInsightFileData = (insightsParams) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.post(HOST_URL+'read/doc/file/content'  , insightsParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
     
      dispatch(insightFiledata(response.data))
    })
    .catch(error => {
      console.log('error getInsightFileData');
    })
  };
};

export const getFilterInsightsList = (insightsParams) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.post(HOST_URL+'get/data/from/map/file'  , insightsParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
     
       dispatch(insightList(response.data))
    })
    .catch(error => {
      console.log('error getInsightFileData');
    })
  };
};
