import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

let fullDatarow = [];
let sentimentsValues = [];
let colorShades = ['#003f5c' , '#ffa600' , '#58508d' , '#bc5090' , '#ff6361'];
let fileNames= [];



class DailyPortfolioSentiment extends Component {
	constructor(props){
    super(props);

    this.state = {
    	noData: true,
    	goClicked: false,
    	sentimentsHashNo: '0a96bbd5feb2451e884e1c2e6d28d7c3',
    	dataStatus: 'No portfolio selected'
    }
  	
 
	}

	componentDidUpdate(prevProps, prevState) {
		
		if(this.props.portfolioSenticode == 1){
			if(this.props.sentimentsValues !== prevProps.sentimentsValues){
				this.props.onGoClick(false);
				
				
				this.setState({
					noData:false,
					csvError:false,
					dataStatus: 'No portfolio selected'
				});
			}
			
		}
		else{
			
			if(this.props.sentimentsHashNo !== prevProps.sentimentsHashNo){
				
				
				this.props.onGoClick(false)
				this.setState({
					sentimentsHashNo:this.props.sentimentsHashNo,
					noData:true,
					csvError:false,
					dataStatus: 'No data found'
				});
			}
		}

		
	}

	render() {
	

	fullDatarow = [];
	sentimentsValues = [];
	fileNames= [];

	
	
	if(this.props.sentimentsValues){


		this.props.sentimentsValues.map((row , index )=> {
		
			let x,y;
			let items_row = [];
			sentimentsValues = [];
			row.data.map((item , index) => {
				
					sentimentsValues.push({
					  x: new Date(item.date[2],item.date[1],item.date[0]),
					  y: parseFloat(item.Sentiment),
					});
					items_row =  sentimentsValues
			}) 

		
			fileNames.push({
				name: row.fileName.split('.')[0],
			})
			fullDatarow.push({
				type: "line",
				showInLegend: true ,
				name: "",
				markerType: "none",
				axisYIndex: 1,
				color: colorShades[index],
				toolTipContent:"{y} "+ row.fileName.split('.')[0],
				dataPoints: items_row

			})
		});

	}
	

	const options = {
		theme: "light2", // "light1", "dark1", "dark2"
		title:{
	      text: ""  
	     },
		axisX:{
		   title:"Date",
		   valueFormatString: "DD-MMM-YY",
		   labelAngle: -50
		},
		
		axisY:[{
			title: "Sentiments",
			labelAngle: -50
			// interval: 5
		}],
		
		data: fullDatarow
	}
	

	return (
		<div>
			{this.state.csvError ? <div class="header_error">Headers are not correct in above CSV file.</div> : "" }
			{!this.state.noData ? <div>
				
				<div className="chart_wrapper"><CanvasJSChart options = {options}
				
				/>
				<div className="whiteStrip"></div></div>
				<div className="line_indicator">
					<ul>
						{fileNames.map((fileNames,index) => {
							return <li key={index} >
								<span className="first_shape"></span>
								<span>{fileNames.name}</span>
							</li>
						}) }
					</ul>
				</div>
			</div> : <div className="noData">{this.state.dataStatus}</div> }
		</div>
		);
	}
}
export default DailyPortfolioSentiment;   