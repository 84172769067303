export default [
 {
    title: 'Emerging Markets and Importance of Managing the Corporate Governance Risk',
    summary: 'To avoid unpleasant surprise, we need to apply a Corporate Governance filter first and then only trade. Another somewhat similar way is to look at the stocks through ESG (Environmental, Social and Governance) framework and then decide what you can invest in and what you should avoid. In EMs especially, that is as important as tracking news flow, understanding investor sentiment or analysing financial statements. In the markets to succeed, the first learning needs to be…how to minimize mistakes and is why ‘Corporate Governance’ helps.',
    category: 'ESG & Sustainability',
    date: '8/22/2019',
 },
 {
    title: 'India’s Central Bank Suffers Another High Profile Exit and The Importance of Strong and Independent Institutions',
   summary: 'Reserve Bank of India (RBI) is one of the oldest monetary institutions in the World and it is much respected as well. Usually, RBI Deputy Governors tend to be away from media glare, but Dr Viral Acharya was different. He was vocal and his statements were often controversial which media could not always ignore. Dr. Acharya, who quit as Reserve Bank of India deputy governor on 24th June, is joining the long list of high profile exits caused by difference of opinion between institutions and ruling dispensation in last few years. These controversies are totally unnecessary because these events reflect very poorly on India’s governance standards and how much respect the government shows to a dissenting opinion.',
   category: 'ESG & Sustainability',
   date: '6/26/2019',
 },
];  
