export default [
  {
     title: 'Does Economic Revival from Coronavirus Pandemic Depend on Recklessness in Human Behavior? ',
     summary: 'There is evidence that the economic recovery from an unforeseen event is dependent on how quickly people will get back to normal. This EMAlpha insight takes a look at how the ‘speed of revival’ from the effects of Coronavirus pandemic depends on how ‘risk-taking’ people are. Less ‘risk averse’ people are, faster the recovery would be. The difference between UK and Continental Europe are recovering at a different pace mainly because of difference in threat perception while the on-ground statistics are alomost same on the impact of Coronavirus pandemic.',
     category: 'Developed Markets',
     date: '13-Aug-20',
  },
{
    title: 'Oil Prices and S&P 500: Which One is in Drivers Seat?',
   summary: 'There is a huge similarity in Oil Price Movement and S&P 500 as the dip, recovery and volatility are virtually the same for both. While the drivers are so different for Oil and US markets, there is evidence that while Fed’s actions may not directly help the Oil demand, Fed action made oil markets more stable and helped prices recover. We explore this on the basis of EMAlpha Oil News Sentiment.',
   category: 'Developed Markets',
   date: '17-Jun-20',
 },
 {
    title: 'EURO Gaining Strength over USD: What it Means for Emerging Markets?',
    summary: 'After Germany and France proposed a huge Recovery Fund to provide the EU with more capacity to deal with Coronavirus pandemic shock, Euro has gained significantly against USD. More importantly, it gives enhanced visibility on the future of European Union. EMAlpha has explored how does this link with overall market sentiment?  The "topical sentiment" scores emphasizes the point that gains for EUR/USD and weak dollar have sent the overall sentiment soaring.',
    category: 'Developed Markets',
    date: '8-Jun-20',
 },
{
    title: 'Are Central Banks Encouraging Risk Taking Behavior in Speculators…Oops…Investors',
    summary: 'EMAlpha Sentiment Analysis based on News flow indicates that the signalling mechanism by Central Banks is a very powerful tool to change the risk perception by market participants. When a Central Banker is telling investors that it is OK to indulge in high risk investments, investors become more adventurous. This has been a driving force for markets as picked in sentiment on commodities and country by country sentiment analysis. How much risk an asset class carries is more dependent on perception EMAlpha analysis confirms.',
    category: 'Developed Markets',
    date: '1-May-20',
 },
 {
    title: '‘News Sentiment Impact’ and Its Influence on Market Movement',
    summary: 'For US economy and market indices like S&P500, the signals from fundamentals of economy and what Fed is doing are pulling the financial markets in US in opposite directions. From the market reaction to datapoints like jobless claims, can we deduce that the Fed action is in the price already while fundamentals could get worse and that will matter more for the markets. EMAlpha News flow sentiment on specific topics and from multiple countries is reflecting interesting linkages between Sentiment Analysis and Market movement.',
    category: 'Developed Markets',
    date: '30-Apr-20',
 },
 {
    title: 'News Sentiment on Trump Does Not Matter for Markets, Oh...Really?',
    summary: 'The mishandling of Coronavirus pandemic negatively impacted the EMAlpha News sentiment on US President. When we look at Markets and EMAlpha News sentiment on Donald Trump, there is no sign that market is really bothered about Trump. However, it can be looked at this way: Once the emergency response has been provided by Fed, the economy will still require a careful steering and proactive, consistent response from the administration. So, Fed has saved the markets in near term but the divergence between news sentiment on Trump and market direction is not sustainable.',
    category: 'Developed Markets',
    date: '17-Apr-20',
 },
 {
    title: 'Is Donald Trump Losing Perception Battle on Coronavirus Pandemic and Why Should Markets care?',
    summary: 'In the past, US presidents have mostly benefitted from crisis, politically and in the eyes of media. However, EMAlpha News flow Sentiment on US President, Donald Trump had quickly worsened over last couple of weeks. And it has worsened significantly. Trump’s inconsistent approach and him being in rush to lift the lockdown could have been the key factors in making Trump an easy media target but it is a fact that the sentiment in news on Trump is negative. Why this is important for the markets in short to medium terms? Effective handling of crisis matters for Economic recovery and if the negative news sentiment on Trump sustains, this may play a key role in US presidential elections.',
    category: 'Developed Markets',
    date: '15-Apr-20',
 },
 {
    title: 'Fed Making Fundamentals Irrelevant For Markets - Saving From Disaster or Creating a Bigger One?',
    summary: 'The Coronavirus pandemic has made the US Fed extremely active and this has led to a dichotomy. The market is telling you that it is focusing less on fundamentals and more on Fed. A look at the S&P 500 chart makes it clear the link between Fed Action, Impact of Coronavirus on Fundamentals of US Economy and the direction of Markets. There will always be questions but at least for the time being, the markets are telling you that what Fed is doing, is working.',
    category: 'Developed Markets',
    date: '9-Apr-20',
 },
 {
    title: 'Dichotomy Between News and Reaction: Coronavirus Pandemic Getting worse, But Markets Getting Better',
    summary: 'To assess the market impact of Coronavirus pandemic, EMAlpha tracks several important indicators including, a) Coronavirus Country-by-Country Sentiment, b) Global Coronavirus Sentiment, c) News Topic Sentiment on terms such as “Donald Trump”, “Dow Jones Index”, “Fed Interest Rate”, “Nasdaq Composite”, “Chinese Economy” and “US Economy”, d) Crude Oil related News Sentiment, and, e) Coronavirus Sentiment Heat Map. These EMAlpha Sentiment trackers offer interesting insights on the current state of this pandemic and how News coverage is reacting to it.',
    category: 'Developed Markets',
    date: '31-Mar-20',
 },
 {
    title: 'Coronavirus News Sentiment: Voltaility and Direction of Indian Markets',
    summary: 'If we map the EMAlpha Aggregate Market Sentiment for India and not just Coronavirus News Sentiment for the country, with the local market indices, there is a clear message that the market volatility may continue in the near term.  The smart recovery on Friday (20th March) followed by an even deeper fall on Monday (23rd March) gels very well with the EMAlpha India Corona News Sentiment. EMAlpha Coronavirus News Sentiment also tells that US markets may continue to remain in deep turmoil.',
    category: 'Developed Markets',
    date: '24-Mar-20',
 },
 {
    title: 'Coronavirus News Sentiment Deteriorating and Peculiarities of Country News Sentiment',
    summary: 'After the already disastrous impact on global health scenario and the severe hit to world economy because of Coronavrius pandemic, the bigger worry now is that the EMAlpha Aggregate Coronavirus sentiment (the sum total of news flow across all countries which we track) continues to plunge. Also, the latest edition of EMAlpha Country by country "Corona Sentiment" based on news flow continues to show further deterioration in many countries including USA. However, things have started to look up in China and India is seeing a further fall in Corona Sentiment and thats bearish.',
    category: 'Developed Markets',
    date: '18-Mar-20',
 },
 {
    title: 'Human Psychology, High Profile Covid-19 Cases and the Impact on Sentiment',
    summary: 'While huge fluctuations in EMAlpha Coronavirus sentiment continue for different countries, this news flow sentiment is driven by what kind of news items are getting more coverage. For example, the EMAlpha Coronavirus news flow sentiment for Canada has deteriorated sharply and this is because of a high-profile case of infection (Prime Minister’s wife). In USA, the huge volatility noticed in the markets is linked with rapid deterioration on one side and the policy announcements on the other. Similarly, there are interesting trends from other countries as well.',
    category: 'Developed Markets',
    date: '15-Mar-20',
 },
 {
    title: 'News Sentiment, Market Imapct and Unchecked Spread of Coronavirus Pandemic',
    summary: 'On March 9th 2020, VIX Index, the widely monitored gauge of market volatility, shot up to levels not seen since the Great Financial Crisis more than ten years ago. At EMAlpha, we monitor daily news sentiments in a range of emerging and developed markets and from this, it has become clear that Coronavirus related news sentiment could continue to be a driver of market sentiment for a while. We collect Coronavirus related targeted news flow in a number of languages as for many emerging market countries, most of the news flow is in local language. There are some interesting observations on the market impact of the news and underlying sentiment country by country.',
    category: 'Developed Markets',
    date: '10-Mar-20',
 },
 {
    title: 'Fed Rate Cut: Words Speak Louder than Actions and the Importance of Signalling Mechanism ',
    summary: 'The conventional wisdom says that it is the actions which matter more than words. However, the last day of July 2019 was different from that perspective. It was a big event as the Federal Reserve cut interest rates for the first time in more than a decade. However, the market reaction to the rate cut was still unexpected as if the rate cut didn’t happen at all. Fed’s first rate cut since it slashed rates to near zero in 2008 disappointed the markets because Fed Chairman didn’t make any attempt whatsoever to signal the beginning of an aggressive rate-cutting campaign. A Fed Rate Cut which is first in more than ten years and the market reaction which followed was clearly the case when Fed’s words spoke louder than its actions instead of Fed’s actions speaking louder than its words.',
    category: 'Developed Markets',
    date: '2-Aug-19',
 },
 {
    title: 'Fed Rate Cut Will Help, But Policy Makers in Emerging Markets Need to Do More',
    summary: 'It may appear from the immediate market reaction that a rate cut by Fed will help the EMs. But, this is an illusion. Investor sentiment is impacted as a result of several factors and that is a key thing to focus on in the longer term because fundamental factors will continue to be more important for EMs and that is something the news analysis based sentiment gauge as of now is not really able to capture completely.',
    category: 'Developed Markets',
    date: '12-Jul-19',
 },
];  
