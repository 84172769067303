export default [
 {
    title: 'Human Behavior and Emerging Markets: The Case of Cricket World Cup and Indians Not Buying Televisions',
    summary: 'The thing about demographic dividend is that everyone will expect that more people with higher purchasing power will continue to drive consumption up. And it comes as a surprise when the companies have started to talk about slowdown. But that is the new reality in India and investor sentiment is seriously affected. The poor sales of televisions in the midst of a Cricket World Cup is an interesting example. The way retail sales of physical stores got impacted because of online purchases, is Television staring at the same fate and what it means?',
    category: 'Sentiment Analysis',
    date: '7/10/2019',
 },
 {
    title: 'Investment Management - The Final Frontier for Machines ',
   summary: 'The adoption rate of new technology is dependent on several factors but the important thing is that the new age machines are not just taking over the work which used to be done by humans in the financial markets, they are also opening new avenues and approaches for investment management. EMAlpha’s proprietary model which generates sentiment scores using our dictionary and the trades executed on the basis of those sentiment scores are beating markets and this tells us that there is need for more sophisticated data analytics, more advanced algorithms and use of Artificial Intelligence for generating superior investment returns.',
   category: 'Sentiment Analysis',
   date: '10/6/2019',
 },{
    title: 'Sentiment Analysis and Its Role In Investing: Now and In The Future',
   summary: 'When the time scale is short, the sentiment can matter more than the facts. In stock markets when the reaction time needs to be quick and the trade decision has to be made immediately after the event, the fund manager lacks the luxury to do it at leisure. The sentiment, i.e. the first impression, can matter more in this case and the price impact can be seen very quickly after a good or bad event. Since even a small change in sentiment can quickly become big, Sentiment analysis and discovery of news which can move markets is useful for market participants.',
   category: 'Sentiment Analysis',
   date: '10/12/2019',
 },{
    title: "Recession is trending, Could 'People's Worries' Bring Bad News For the Economy?",
   summary: 'Trends are so powerful that often they become self-fulfilling prophecies. When we look at what people are talking about, what they are feeling worried about and what their concerns are, it gives us some idea about what future may look like. The chart showing the frequency of google search for "recession" from 2004 onward tells us that it has hit the 2008-2009 levels recently. The number of people searching for the word ‘recession’ remains at a higher level than observed in the past ten years and this is a significant change because such spikes could actually precede the real event. ',
   category: 'Sentiment Analysis',
   date: '10/25/2019',
 },{
    title: "Coronavirus News Sentiment: How much is NOISE in the SIGNAL?",
   summary: 'Though Coronavirus is a global health emergency and there is no proven cure yet in sight, the media coverage is still disproportionate considering the tangible impact estimated as of now. In the age of rapid information dissemination and huge role played by social media, this skew is even more prominent. Naturally, financial markets also react more to these events. Whether the impact of Coronavirus is NOISE or SIGNAL, the reaction of financial markets proves ‘News may be the same, but the impact could be vastly different because of the varied nature of Sentiment’. EMAlpha tracks this news flow and the underlying sentiment.',
   date: '2/9/2020',
 },{
    title: "Coronavirus Pandemic and How Sentiment is Impacting the Emerging Markets?",
   summary: 'The impact on the global markets from Coronavirus pandemic also seems to have a local component as Asian markets close to China have suffered more than others. Geographically distant markets have remained shielded. When we look at how EMAlpha Sentiment Score has evolved over the last month for some of these emerging countries and the performance of their equity markets, the maximum market impact has been in smaller neighboring countries, like Thailand. ',
   date: '2/9/2020',
 },{
    title: "Coronavirus and Markets: Sentiment Bigger Driver than Fundamentals in Near-Term",
   summary: 'As the news sentiment has evolved leading to the market gyrations because of Coronavirus pandemic, EMAlpha is tracking the overall market sentiment on a daily basis. While the way forward does not look good, sentiment will clearly be a bigger driver for markets than fundamentals in the near term. In this insight, we also take a look at some of the important questions such as how are hedge fund strategies doing against this backdrop and how has the EMAlpha long/short equity strategy done?',
   date: '2/27/2020',
 },{
    title: "Coronavirus Impact on Markets: Is Local News Sentiment Getting More Important?",
   summary: 'In the initial response to Coronavirus pandemic, most financial markets globally reacted in a predictable negative fashion. But this is slowly changing now and the role of local news flow and underlying sentiment is getting more prominent. For example, after India reported two fresh cases of Coronavirus in the afternoon on 2nd March, Indian markets reacted sharply and closed lower. It is not looking very good on EMAlpha country-by-country sentiment analysis and there could be more downside ahead.',
   date: '3/2/2020',
 },{
    title: "News Sentiment Watch and How Portfolio Managers Can Use It for Systematic Trading?",
   summary: 'EMAlpha Coronavirus Sentiment reflects that the markets have reacted strongly to pandemic related news and country-by-country scores are even more interesting. We have used EMAlpha proprietary sentiment measure to first find the raw sentiment for each country for news related to Coronavirus and the raw scores were then Z-scored for standardization. As the daily sentiment changes for countries, it can be a useful input into systematic trading model for portfolio managers and it can also be valuable in risk management.  ',
   date: '3/4/2020',
 },{
    title: "Human Irrationality, Threat Perception and Coronavirus Pandemic",
   summary: "As reflected in the EMAlpha News Sentiment and reaction of Global Markets to Coronavirus threat, it is easy to see why sentiment is often much more powerful than facts alone. Over the years, many philosophers, psychologists, authors and scientists have spoken about this phenomenon where the impact of news flow of certain events immediately triggers a 'survival reaction' or 'fight or flight' response. In many of these areas, one of the most elegant explanations and lucid examples backed by years of research and experiments with large sample size have come from Daniel Kahneman. This insight explores some of these snippets.",
   date: '3/4/2020',
 },{
    title: "News Sentiment, Market Imapct and Unchecked Spread of Coronavirus Pandemic",
   summary: "On March 9th 2020, VIX Index, the widely monitored gauge of market volatility, shot up to levels not seen since the Great Financial Crisis more than ten years ago. At EMAlpha, we monitor daily news sentiments in a range of emerging and developed markets and from this, it has become clear that Coronavirus related news sentiment could continue to be a driver of market sentiment for a while. We collect Coronavirus related targeted news flow in a number of languages as for many emerging market countries, most of the news flow is in local language. There are some interesting observations on the market impact of the news and underlying sentiment country by country.",
   date: '3/4/2020',
 },{
    title: "Human Psychology, High Profile Covid-19 Cases and the Impact on Sentiment",
   summary: "While huge fluctuations in EMAlpha Coronavirus sentiment continue for different countries, this news flow sentiment is driven by what kind of news items are getting more coverage. For example, the EMAlpha Coronavirus news flow sentiment for Canada has deteriorated sharply and this is because of a high-profile case of infection (Prime Minister’s wife). In USA, the huge volatility noticed in the markets is linked with rapid deterioration on one side and the policy announcements on the other. Similarly, there are interesting trends from other countries as well.",
   date: '3/4/2020',
 },{
    title: "Coronavirus News Sentiment Deteriorating and Peculiarities of Country News Sentiment",
   summary: 'After the already disastrous impact on global health scenario and the severe hit to world economy because of Coronavrius pandemic, the bigger worry now is that the EMAlpha Aggregate Coronavirus sentiment (the sum total of news flow across all countries which we track) continues to plunge. Also, the latest edition of EMAlpha Country by country "Corona Sentiment" based on news flow continues to show further deterioration in many countries including USA. However, things have started to look up in China and India is seeing a further fall in Corona Sentiment and that bearish.',
   date: '3/18/2020',
 },{
    title: "Coronavirus Pandemic, News Sentiment and Where the Markets Are Going?",
   summary: "The EMAlpha Emerging Markets model portfolio performance, has delivered significantly better returns versus the market index recently. And it is easy to see the reason. In the recent Coronavirus pandemic driven reaction of Global Financial Markets, Sentiment is a dominant driver of investor behaviour. How does EMAlpha do Sentiment Anlsysis on the news flow? EMAlpha machines are collecting Coronavirus related targeted news flow in a variety of languages. For a number of emerging market countries, most of the news flow is in local language and, hence, we focus on news collection in both the local language and in English. We then use natural language processing tools to extract the sentiment for each country. The details and takeaways are discussed in this insight. ",
   date: '3/23/2020',
 },{
    title: "Coronavirus News Sentiment: Voltaility and Direction of Indian Markets",
   summary: "If we map the EMAlpha Aggregate Market Sentiment for India and not just Coronavirus News Sentiment for the country, with the local market indices, there is a clear message that the market volatility may continue in the near term.  The smart recovery on Friday (20th March) followed by an even deeper fall on Monday (23rd March) gels very well with the EMAlpha India Corona News Sentiment. EMAlpha Coronavirus News Sentiment also tells that US markets may continue to remain in deep turmoil.",
   date: '3/24/2020',
 },{
    title: "For Global Economy and Emerging Markets, How Much Better News Sentiment on US Helps",
   summary: "There have been interesting takeaways from the EMAlpha sentiment analysis for news flow around some very important and immensely popular key words. These news topics which matter for US Markets and hence, are important for other global markets can also capture the market direction effectively. EMAlpha machines have picked up some improvement in sentiment for US economy and that gels well with the improvement in sentiment for Dow Jones Index and NASDAQ composite both.",
   date: '3/25/2020',
 },{
    title: "Coronavrius News Sentiment vs. Aggregate Market Sentiment and the Importance of Base Rate",
   summary: "EMAlpha’s Aggregate Market Sentiment has a base rate which is above '0'. This makes sense because the usual market sentiment has a positive bias and hence, the change in market sentiment should be measured as compared to this base rate. EMAlpha Sentiment Analysis tells us that the absolute values don’t matter that much and only how far is the point in either direction from the base rate, matters. The same things apply to languages, books, movies and almost everything where we can do Sentiment Analysis. This has important implications for how to read the signals and what to deduce from Sentiment Analysis. ",
   date: '4/1/2020',
 },{
    title: "Why Local News based Sentiment Analysis Matters and Do Machines Can See the Difference?",
   summary: "When machines pick news on same subject in multiple languages for the same country, the result can be significantly different. EMAlpha has noted such divergence for many countries including China, Japan and Turkey and when news flow is different, sentiment analysis will not be the same. For Emerging Markets, EMAlpha experience with news collection and Sentiment Analysis for different countries in different languages confirms that English news and translated (from local language to English) are qualitatively and quantitatively different.",
   date: '4/8/2020',
 },{
    title: "Impact of Coronavirus: Is 'Timing the Markets' Possible Using Sentiment Analysis?",
   summary: "Is it possible to position your portfolio for market volatility in a timely manner? We look at this question using EMAlpha Sentiment Signal as a guide. We consider two major emerging equity markets: Brazil and India and use EMAlpha Sentiment based Signals, constructed using Machine Translation and Sentiment Analysis on news flow in major languages: a) Portuguese and English for Brazil, b) Hindi and English for India, to trade the broad equity indices in these two countries. The result, in terms of the cumulative return in excess of the equity benchmark shows that EMAlpha Sentiment Signal for Brazil and India seem to have navigated the recent unprecedented market volatility quite well. ",
   date: '4/13/2020',
 },{
    title: "Is Donald Trump Losing 'Perception Battle' on Coronavirus Pandemic and Why Should Markets care?",
   summary: "In the past, US presidents have mostly benefitted from crisis, politically and in the eyes of media. However, EMAlpha News flow Sentiment on US President, Donald Trump had quickly worsened over last couple of weeks. And it has worsened significantly. Trump’s inconsistent approach and him being in rush to lift the lockdown could have been the key factors in making Trump an easy media target but it is a fact that the sentiment in news on Trump is negative. Why this is important for the markets in short to medium terms? Effective handling of crisis matters for Economic recovery and if the negative news sentiment on Trump sustains, this may play a key role in US presidential elections.",
   date: '4/15/2020',
 },{
    title: "News Sentiment on Trump Does Not Matter for Markets, Oh...Really?",
   summary: "The mishandling of Coronavirus pandemic negatively impacted the EMAlpha News sentiment on US President. When we look at Markets and EMAlpha News sentiment on Donald Trump, there is no sign that market is really bothered about Trump. However, it can be looked at this way: Once the emergency response has been provided by Fed, the economy will still require a careful steering and proactive, consistent response from the administration. So, Fed has saved the markets in near term but the divergence between news sentiment on Trump and market direction is not sustainable.",
   date: '4/17/2020',
 },{
    title: "Crude Oil and Coronavirus Pandemic: Oil Futures in Negative and How Machines are Reading it??",
   summary: "Monday, 20th April 2020 was a historic day for Crude Oil Industry as the May US oil futures contract in went into negative territory for the first time in history. The US reserves are full and there is simply no place to put the crude and everyone is avoiding taking delivery of physical crude. But it is not just a storage problem. There is limited visibility on when the demand will recover. This is also visible on EMAlpha Oil News Sentiment tracker as well. When seen in the context of SAUDI Aramco’s stock price movement and recovery in S&P 500, we can conclude that investors are less worried about markets in general and the concern is limited to Oil only. ",
   date: '4/21/2020',
 },{
    title: "Oil Sentiment has Already Hit Rock Bottom, But Will it Plunge even More?",
   summary: "While the drop in Oil prices is putting huge pressure on producers, they can’t easily agree on who needs to cut how much of production. The drop in demand and low crude prices actually made some of them more desperate in their attempts to gain market share. This is also visible on EMAlpha Oil News Sentiment tracker. While the market movements are related to specific reasons, the general sentiment on the demand side is extremely negative. EMAlpha Oil News Sentiment is at the lowest levels after it has hit an all-time low on April 20th. Because of this commodity’s strong linkage with global economy, will this have implications for financial markets worldwide? ",
   date: '4/27/2020',
 },{
    title: "‘News Sentiment Impact’ and Its Influence on Market Movement",
   summary: "For US economy and market indices like S&P500, the signals from fundamentals of economy and what Fed is doing are pulling the financial markets in US in opposite directions. From the market reaction to datapoints like jobless claims, can we deduce that the Fed action is in the price already while fundamentals could get worse and that will matter more for the markets. EMAlpha News flow sentiment on specific topics and from multiple countries is reflecting interesting linkages between Sentiment Analysis and Market movement. ",
   date: '4/30/2020',
 },{
    title: "Facebook-Jio Deal and Stock Price Movement in Reliance Industries Limited Immediately Before that",
   summary: "Before Reliance Industries Ltd. (the largest market cap company in India) announced a deal with Facebook that the Social Media giant will Invest USD 5.6bn in Reliance-Jio Platforms for a 9.99% Stake, the Reliance stock had shown interesting movement. This movement which was distinctly different in the morning and afternoon session a day prior, provides further evidence about impact of sentiment and news flow on price. ",
   date: '5/4/2020',
 },{
    title: "Is Oil Sentiment Telling Us that Demand Recovery has Begun and How Long it Will Take?",
   summary: "One of the most closely tracked sentiments at EMAlpha is on Oil News as the short-term price movement in crude depends on near term outlook, which is influenced heavily by the news flow sentiment. If we go by the prevailing sentiment, the demand is unlikely to pick up anytime soon. However, the EMAlpha Oil News Sentiment is having its ‘break out’ moment and the Oil News sentiment has scaled its new peak. This is an entirely different picture from what we had seen in the month of April. Is this change in oil news sentiment telling us that we are past the worst of Coronavirus crisis? ",
   date: '5/14/2020',
 },{
    title: "Oil Sentiment has Conflicting Signals from IEA and Saudi Aramco Stock Price",
   summary: "EMAlpha Oil News Sentiment is having its ‘break out’ moment and this change is telling us that we are past the worst of Coronavirus crisis and things will get better from now on for Oil. We looked at other possible signals on what may happen next and the most interesting were two data points which are giving us conflicting view. The first is Saudi Aramco Stock price and the second data point is International Energy Agency (IEA) forecast. What do they tell us and how to read it?",
   date: '5/16/2020',
 },{
    title: "Oil News Sentiment Captures the Firmness in Crude Prices, Is the worst over for Oil?",
   summary: "The EMAlpha Oil News Sentiment is volatile but it is clearly showing a significant improvement in last fifteen days. As such the average EMAlpha Oil News Sentiment in May is much more positive than either March or April and in last two weeks, the volatility also has been on the lower side. This implies that the news Sentiment is doing a good job in making the price forecast and on the basis of EMAlpha Oil News sentiment, it is unlikely that Oil is going below 30 dollars.",
   date: '5/29/2020',
 },{
    title: "EURO Gaining Strength over USD: What it Means for Emerging Markets?",
   summary: 'After Germany and France proposed a huge Recovery Fund to provide the EU with more capacity to deal with Coronavirus pandemic shock, Euro has gained significantly against USD. More importantly, it gives enhanced visibility on the future of European Union. EMAlpha has explored how does this link with overall market sentiment?  The "topical sentiment" scores emphasizes the point that gains for EUR/USD and weak dollar have sent the overall sentiment soaring.',
   date: '6/8/2020',
 },{
    title: "Does Geography Change Interpretation and Relevance of Local News Sentiment ",
   summary: "On news flow sentiment analysis, often the question is being asked: In this day and age of global interconnection, does local news matter anymore? But on the basis of automated and unbiased news flow collection of EMAlpha for multiple countries, it is easy to see that English news and Local language news can be quite different. So, when news flow is different, sentiment analysis will not be the same either. We discuss the intricacies and implications here as the Sentiment Analysis outcomes are often different for English and Local News. ",
   date: '6/10/2020',
 },{
    title: "Can the Sentiment in Local News Determine the Course of a Geo-Political Conflict?",
   summary: "It is possible to argue that media only reflects the public opinion. But when it comes to sensitive situations, the role of media becomes even more important as it also has a role in shaping the popular discourse. In conflict between countries, media has to act responsibly and should not do anything which can lead to unnecessary and avoidable conflict. But unfortunately, what EMAlpha machines have picked up is not really unpleasant. ",
   date: '6/18/2020',
 },{
    title: "Does International News Flow Capture Seriousness of Coronavirus Pandemic in Brazil?",
   summary: "From the lack of news coverage in other parts of the world, it is difficult to figure out that Brazil is one of the worst-hit from the Coronavirus pandemic. However when we analyse what EMAlpha has picked up on Brazil in media coverage, most of the news flow is still capturing less relevant though more interesting developments like traditional cures and football matches.",
   date: '6/20/2020',
 },{
    title: "Do the Global Financial Markets Care About COVID-19 Numbers Anymore?",
   summary: "March 2020 was tumultuous for global markets as Coronavirus pandemic led to a huge crash, it required a strong monetary policy response from Central Banks to avert the crisis. But the question now is: Is Coronavirus pandemic still the driving force for the markets? Or the markets have moved on? This is the question we explore here on the basis of what EMAlpha machines have picked up. ",
   date: '6/21/2020',
 },
];  
