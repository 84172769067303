import React, { Component } from 'react';
import './ForgotPassword.css';
import { connect } from 'react-redux';
import * as dataActions from '../../../../store/actions/index';
import Cookies from 'js-cookie';


class ForgotPassword extends Component {

  constructor() {

    super();
    this.state = {
      input: {
        password: '',
        confirm_password: ''
      },
      errors: {
        password: '',
        confirm_password: ''
      },
      dissabledMail: false,
      confirmEmail: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }


  componentDidMount() {
    //console.log(Cookies.get('email'))
    this.setState({
      confirmEmail: Cookies.get('email')
    })
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.hashUpdatepass !== prevProps.hashUpdatepass) {
      if (this.props.statusUpdateCode === 1) {
        this.setState({
          statusUpdatepass: this.props.statusUpdatepass
        })
        window.location.href = "/analytics";
      } else {
        this.setState({
          statusUpdatepass: this.props.statusUpdatepass
        })
      }
    }
  }



  // handleChange(event) {
  //   let input = this.state.input;
  //   input[event.target.name] = event.target.value;
  //   this.setState({
  //     input
  //   });

  // }
  handleChange(event) {
    const name = event.target.name
    const passwordInputValue = event.target.value;
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const minLengthRegExp = /.{10,}/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const passwordLength = passwordInputValue.length;
    const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
    const minLengthPassword = minLengthRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    let errMsg = this.state.errors;
    let inputValue = this.state.input
    if (passwordLength === 0) {
      errMsg[name == 'password' ? "password" : ''] = "Please enter your password.";
    }
    else if (!uppercasePassword) {
      errMsg[name == 'password' ? "password" : ''] = "At least one Uppercase.";
    } else if (!lowercasePassword) {
      errMsg[name == 'password' ? "password" : ''] = "At least one Lowercase.";
    }
    else if (!specialCharPassword) {
      errMsg[name == 'password' ? "password" : ''] = "At least one Special Characters.";
    }
    else if (!minLengthPassword) {
      errMsg[name == 'password' ? "password" : ''] = "At least minumum ten characters.";
    }
    else {
      errMsg = {
        password: '',
        // confirm_password: ''
      }
    }
    inputValue[name] = passwordInputValue
    this.setState({ ...this.state, errors: errMsg, input: inputValue });

  }


  handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      // console.log(this.state);

      // console.log('this.state.input.email',this.state.confirmEmail);
      // console.log('this.state.input.password',this.state.input.password);
      // console.log('this.state.input.confirm_password',this.state.input.confirm_password);
      let mail_id = this.state.confirmEmail;
      let password_id = this.state.input.password;

      let resetPasswordParams = JSON.stringify({
        mail_id: this.state.confirmEmail,
        new_password: this.state.input.password,
        confirm_password: this.state.input.confirm_password,

      });

      this.props.setNewpassword(resetPasswordParams, mail_id, password_id)

      /* let input = {};
       input["name"] = "";
       input["email"] = "";
       input["password"] = "";
       input["confirm_password"] = "";*/

      this.setState({
        //input:input,
        dissabledMail: true,
      });

      //alert('Demo Form is submited');
    }

  }


  validate() {

    let input = this.state.input;
    let errors = this.state.errors;
    let isValid = true;
    /*if (!input["name"]) {
  
      isValid = false;
      errors["name"] = "Please enter your name.";
  
    }
  
    if (!input["email"]) {
      isValid = false;
      errors["email"] = "Please enter your email Address.";
    }
  
    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }*/

    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your password.";
    }

    if (!input["confirm_password"]) {
      isValid = false;
      errors["confirm_password"] = "Please enter your confirm password.";
    }
    // if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
    //   if (input["password"].length < 6 && input["confirm_password"].length) {
    //     isValid = false;
    //     errors["password"] = "Please add at least 6 charachter.";
    //   }
    // }

    if (typeof input["password"] !== "undefined" && typeof input["confirm_password"] !== "undefined") {
      if (input["password"] != input["confirm_password"]) {
        isValid = false;
        errors["confirm_password"] = "Passwords don't match.";
      }
    }

    this.setState({
      errors: errors
    });

    return isValid;

  }



  render() {
    // console.log('1this.props',this.props)
    const {errors, input} = this.state;
    return (
      <div className="login_page forgot_password">
        <div className="right_formarea">
          <div>
            <h1>Enter your new Password</h1>

            <div className="login_form">
              <form onSubmit={this.handleSubmit}>
                {/*<div class="form-group">
                  <label for="name">Name:</label>
                  <input 
                    type="text" 
                    name="name" 
                    value={this.state.input.name}
                    onChange={this.handleChange}
                    class="form-control" 
                    placeholder="Enter name" 
                    id="name" />
                    <div className="text-danger">{this.state.errors.name}</div>
                </div>
                <div class="form-group">
                  <label for="email">Email Address:</label>
                  <input 
                    type="text" 
                    name="email" 
                    value={this.state.input.email}
                    onChange={this.handleChange}
                    class="form-control" 
                    placeholder="Enter email" 
                    id="email" />
                    <div className="text-danger">{this.state.errors.email}</div>
                </div>*/}

                <div class="form-group">
                  <label for="password">Password:</label>
                  <input
                    type="password"
                    name="password"
                    value={input.password}
                    onChange={this.handleChange}
                    class="form-control"
                    placeholder="Enter password"
                    id="password" />
                  {/* <div className="text-danger">{this.state.errors.password}</div> */}
                  <span className="text-danger">{errors.password}</span>
                </div>

                <div class="form-group">
                  <label for="password">Confirm Password:</label>
                  <input
                    type="password"
                    name="confirm_password"
                    value={input.confirm_password}
                    onChange={this.handleChange}
                    class="form-control"
                    placeholder="Enter confirm password"
                    id="confirm_password" />

                  {/* <div className="text-danger">{this.state.errors.confirm_password}</div> */}
                  <span className="text-danger">{errors.confirm_password}</span>
                </div>
                <button type="submit" className={"btn btn-primary signin_bttn " + (this.state.dissabledMail ? 'disabled_button' : "")}>Sign In</button>
                <p className={" forgot_message " + (this.props.statusUpdateCode === 1 ? 'green_text' : 'red_text')}>{this.state.statusUpdatepass}</p>
              </form>
            </div>
          </div>
        </div>

        <div className="left_textarea">
          <div className="leftbar_img">

          </div>
        </div>
      </div>

    );

  }

}

const mapStateToProps = state => {
  return {
    //signupMessage: state.graphReducer.signupMessage,
    statusUpdatepass: state.graphReducer.statusUpdatepass,
    statusUpdateCode: state.graphReducer.statusUpdateCode,
    hashUpdatepass: state.graphReducer.hashUpdatepass,

  };
}

const mapDispatchToProps = dispatch => {
  return {
    setNewpassword: (resetPasswordParams, mail_id, password_id) => dispatch(dataActions.setNewpassword(resetPasswordParams, mail_id, password_id)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);