import { combineReducers } from 'redux';
import graphReducer  from './graphReducer';
import esgReducers  from './esgReducers';
import insightReducer  from './insightReducer';

const rootReducer = combineReducers({
    graphReducer,
    esgReducers,
    insightReducer
});

export default rootReducer;

