import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index'; 
import PortfolioMultiSelect from './PortfolioMultiSelect';
import DailyPortfolioNewsIntensity from './DailyPortfolioNewsIntensity';
import DailyPortfolioSentiment from './DailyPortfolioSentiment';
import EditPortfolioModal from './EditPortfolioModal';
import { DateRangePicker } from "materialui-daterange-picker";
import loader from '../../../assets/images/loader.gif'; 
import Modal from '@material-ui/core/Modal';
import moment from 'moment';
import News from '../Signin/News';
import {HOST_URL} from '../../../store/Constant'
let userMail = '';
let portfolioNames = [];
let fileData;
let fileNamesArr = [];
let portfolioSelected = [];
let selectedCsv = [];
 
class PortfolioMain extends Component {

	constructor(props){
    super(props);

	    this.state = {
	    	open: false,
	    	portfolioList:[],
	    	portfolio:"",
	    	portfolioDateValue: '01/23/2020 - 02/16/2021',
	    	pickeropen:false,
	    	portfolioDateRange: new Date(),
	    	uploadFile:'',
	    	userMail:'',
        Upload_hash: '',
        disabledGo:false,
        fileNamesArr: [],
        goclikedSentiments:false,
        goclikedIntencity:false,
        upload_message: "",
        selectStocks:"",
        openEditModel: false,
        modelConfirmOpen: false,
        deleteName:"",
        portfolioSelected: [],
        filenameList:[],
        dropdownList : [],
        dateError: "",
        dateErrorKey : false,

	    }
	    this.getFileName = this.getFileName.bind(this);
	    this.changePortfolio = this.changePortfolio.bind(this);
	    this.togglePicker = this.togglePicker.bind(this);
	    this.openPortfolioPicker = this.openPortfolioPicker.bind(this);
	    this.setPortfolioDate = this.setPortfolioDate.bind(this);
	    this.uploadFile = this.uploadFile.bind(this);
	    this.handleUploadFile = this.handleUploadFile.bind(this);
      this.callGraphData = this.callGraphData.bind(this);
      this.handlePortfolio = this.handlePortfolio.bind(this);
      this.setDisabledFalse = this.setDisabledFalse.bind(this);
      this.handleGoSentiments = this.handleGoSentiments.bind(this);
      this.handleGoIntensity = this.handleGoIntensity.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleRemoveOption = this.handleRemoveOption.bind(this);
      this.handlemodelConfirmOpen = this.handlemodelConfirmOpen.bind(this);
      this.handlemodelConfirmClose = this.handlemodelConfirmClose.bind(this);
      this.handleDeleteName = this.handleDeleteName.bind(this);
      this.downloadFile = this.downloadFile.bind(this);
   
	}


	componentDidMount(){
	 if( sessionStorage.getItem('signInEmail') === "" || sessionStorage.getItem('signInEmail') === null){
    	this.setState({
	      userEmail: sessionStorage.getItem('signUpEmail')
	    });
	    userMail = sessionStorage.getItem('signUpEmail')
    }else{
    	this.setState({
	      userEmail: sessionStorage.getItem('signInEmail')
	    });
	    userMail = sessionStorage.getItem('signInEmail')
    }

    
    let filenameParam = JSON.stringify({
			"mail_id":userMail
		});

    this.props.csvNameList(filenameParam);
		
	}
	
	
  componentDidUpdate(prevProps, prevState) {
    let $this = this;
 		
    let filenameParam = JSON.stringify({
      "mail_id":userMail,
    });
    
    if(this.props.codeUpload === 1){
     
      if(prevProps.Upload_hash !== this.props.Upload_hash){
      
        this.setState({
          Upload_hash: this.props.Upload_hash,
          upload_message: this.props.statusUpload
        });

        setTimeout(function(){
          $this.setState({
            upload_message: ""
          });
        },5000);

        this.props.csvNameList(filenameParam);

      }
    }else{
      if(prevProps.Upload_hash !== this.props.Upload_hash){
        this.setState({
          Upload_hash: this.props.Upload_hash,
          upload_message: this.props.statusUpload
        });

        setTimeout(function(){
          $this.setState({
            upload_message: ""
          });
        },5000);

      }
    }

    
    if(this.props.csvFilehash !== prevProps.csvFilehash){
     
      this.setState({
        portfolioSelected : this.props.filenameList,
        filenameList : this.props.filenameList,
        dropdownList : this.props.filenameList,
      })
      portfolioSelected = this.props.filenameList
    }
   

 		
  }


	getFileName(event){
		var fileList = document.getElementById("csv_upload");

		
    for (var i = 0; i < fileList.files.length; ++i) {
       
        portfolioNames.push({
        	name: fileList.files.item(i).name
        })
    }
    this.setState({
    	portfolioList: portfolioNames
    })

		
  }

  changePortfolio(event){
		
		this.setState({
    	portfolio: event.target.value
    })
	}


  togglePicker(){
  	
  	this.setState({
  		pickeropen: !this.state.pickeropen
  	})
  }

	openPortfolioPicker(){
	
		this.setState({
  		pickeropen: !this.state.pickeropen
  	})
	}

	setPortfolioDate (range) {
		
		this.setState({
			portfolioDateRange:range,
      disabledGo:false,
		})
		
		let endDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.endDate);
		let startDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.startDate);
		
		
    let FinalDate = moment(startDateValue).format("MM/DD/YYYY")+'-'+moment(endDateValue).format("MM/DD/YYYY");
    let year_date = moment(moment(startDateValue).format("MM/DD/YYYY"),"MM/DD/YYYY").year()
    console.log('year_date',year_date)

    if(year_date < "2019"){
     this.setState({
       dateError: "Please choose start date later than 01/01/2019",
       disabledGo: true,
       dateErrorKey : true,
     })
    }else{
      this.setState({
       dateError: "",
       disabledGo: false,
       dateErrorKey : false,
     })
    }
	
		this.setState({
			portfolioDateValue : FinalDate,
			pickeropen: false,
		})
		

  }
  

  uploadFile(event) {
    let file = event.target.files[0];
   
    fileData = file;
   
    this.setState({
    	uploadFile: file
    });

    const data = new FormData();
    data.append('file', file);
    data.append('mail_id', userMail);

    
    this.props.uploadFile(data);

  }

  handleUploadFile(){
  
  	let uploadFileParam = JSON.stringify({
   		"file": fileData,
			"mail_id":userMail
		});
   
    this.props.uploadFile(uploadFileParam);
  }

	
  callGraphData() {
    
    
    let portfolioString = "";
   
    for(let i=0; i< fileNamesArr.length; i++){
   
      if(fileNamesArr.length > 1){
        portfolioString = portfolioString + ',' + this.state.fileNamesArr[i] 
      }else{
        portfolioString = this.state.fileNamesArr[i]
      }
   
      
    }
 
    portfolioString = portfolioString.replace(/^,/, '');

    if(portfolioString === "" || portfolioString === null){
     
      this.setState({
        selectStocks: "Please select one or more portfolio"
      })

    }else{

      this.setState({
        disabledGo:true,
        goclikedSentiments:true,
        goclikedIntencity:true,
        selectStocks:"",
      });

      let portfolioParams = JSON.stringify({
        "files_name": portfolioString,
        "date_range": this.state.portfolioDateValue,
        "mail_id":userMail

      });
      

      this.props.avagPortfolioSentiments(portfolioParams);
      
    }
   
    


  }

  handlePortfolio(list){
    fileNamesArr =[];
    let newlist = this.state.filenameList 
    
   
    for(let i=0;i < list.length;i++){
      fileNamesArr.push(list[i].name);
    }
    
    const namesToDeleteSet = new Set(fileNamesArr);

    if(fileNamesArr.length > 0 ){
      selectedCsv = fileNamesArr
     
   
      newlist = newlist.filter((name) => {
        return !namesToDeleteSet.has(name);
      });
      
    }else{
      
    }

    if(this.state.dateErrorKey === true){
      this.setState({
        fileNamesArr:fileNamesArr,
        disabledGo:true,
        dropdownList: newlist
      })
    }else{
      this.setState({
        fileNamesArr:fileNamesArr,
        disabledGo:false,
        dropdownList: newlist
      })
    }
    
  }

  setDisabledFalse(){
   
    this.setState({
      disabledGo:false
    })
  }
  handleGoSentiments(goValue){
    this.setState({
      goclikedSentiments:false,
    })
  }
  handleGoIntensity(goValue){
    this.setState({
      goclikedIntencity:false,
    })
  }

  handleOpen(){
   
    this.setState({
      openEditModel: true,
    })
  };

  handleClose() {
    this.setState({
      openEditModel: false,
    })
  };


  handleRemoveOption(e){
   
    this.setState({
      deleteName:e.target.getAttribute("value")
    })
    this.handlemodelConfirmOpen();
    e.stopPropagation();
    
  }


  handlemodelConfirmOpen() {
    
    this.setState({
      modelConfirmOpen:true,
    })
  };

  handlemodelConfirmClose() {
    
    this.setState({
      modelConfirmOpen:false,
    })
  };

  handleDeleteName(){
    document.getElementById("csv_upload").value = "";
    
    portfolioSelected = portfolioSelected.filter(item => item !== this.state.deleteName);
    let newlist = portfolioSelected 
    const namesToDeleteSet = new Set(selectedCsv);
    if(newlist.length > 0 ){
      //selectedCsv = fileNamesArr
      
      newlist = newlist.filter((name) => {
        return !namesToDeleteSet.has(name);
      });
      
    }
    
    this.setState({
      portfolioSelected: portfolioSelected,
      filenameList:portfolioSelected,
      dropdownList:newlist,
    });

    let deleteParams = JSON.stringify({
      "mail_id":userMail,
      "file_name" : this.state.deleteName
    });

   
    this.props.deletePortfolioCsv(deleteParams)
   
    this.handlemodelConfirmClose();
  } 

  downloadFile(e){
    
    let fileName = e.target.getAttribute("value")

   

  }



	render() {
	
		return (
			<div className="emdata_head"><div className="portFolio_container">
				<div className="container_wrapper">
					<h3 className="instruction_text">
  				Upload a new portfolio, or select a previously uploaded portfolio.
						<div className="info_area">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
  					  <div className="instruction_tooltip">
  	  					<span>The csv portfolio file should contain the column headers (ISIN, Weight)</span>
  	  					<div className="arrow-down"></div>
  	  				</div>
            </div>
  				</h3>
          <p className="sample_file"> Sample portfolio <a target="_blank" href={`${HOST_URL}download/sample/Portfolio.csv`}> Portfolio.csv </a></p>
      		<div className="left_intro">
      			<div>

      				<div className="upload_button">
                <div className="upload_csv">
        					<input onChange={e => this.uploadFile(e)} type="file" className="custom-file-input" id="csv_upload" accept=".csv" /> 
        				</div> 
        				<p className={"upload_status " + (this.props.codeUpload === 1 ? 'success_upload' : 'error_upload')}> 
        					{this.state.upload_message} 
        				</p>
              </div>
      				
      				<div className="uploadedcsv_list">
      					<div className="dropdown_csv">
      						<PortfolioMultiSelect fileNameList = {this.state.filenameList} onSelectPortfolio={this.handlePortfolio} />
      						
      					</div>
                
                <div className="main_modal_container">
                 {this.state.filenameList ?  <div className="edit_dropdown" onClick={this.handleOpen}>
                    <span>Delete or download portfolio </span><i className="fa fa-pencil"></i>
                  </div> : ""}
                  <Modal
                    open={this.state.openEditModel}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="listingModal"
                  >
                    <div className="listing_container">
                      <div className="container_modal">
                        <h2>Portfolio listing</h2>
                        <ul>
                          {this.state.dropdownList ? this.state.dropdownList.map((item,index) => 
                            <li>
                              <div>
                                <span>{item}</span>
                                <span className="icon_div">
                                  <i className="fa fa-times delete_portfolio" value={item} onClick={(e) => this.handleRemoveOption(e)}></i>
                                  <a href={'https://analytics.emalpha.com:5000/download/file/'+userMail+'/'+item+'/companyPortfolio'} target="_blank">
                                    <i className="fa fa-download download_portfolio"  value={item}></i>
                                  </a>
                                </span>
                              </div>
                            </li>
                          ) : ""}
                        </ul>
                      </div>
                    </div>
                  </Modal>
                </div>


                <div className="modal_main">
                  <div>
                    <div>
                    
                      <Modal
                        open={this.state.modelConfirmOpen}
                        //onClose={this.handlemodelConfirmClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                      >
                        <div className="deleteModal">
                          <h2 id="simple-modal-title">Are you sure you want to delete {this.state.deleteName} dictionary from the list ?</h2>
                          <i className="fa fa-times close_delete" onClick={this.handlemodelConfirmClose}></i>
                          <div className="button_section">
                            <button className="yes_bttn" onClick={this.handleDeleteName} >YES</button>
                            <button className="no_bttn" onClick={this.handlemodelConfirmClose}>NO</button>
                          </div>
                         
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>





      				</div>
              <div className="daterange_input portfolio_picker">
                <input type="text"  value={this.state.portfolioDateValue} onFocus={this.openPortfolioPicker} onChange={this.setDisabledFalse}/>
                <i className="fa fa-calendar" aria-hidden="true" onClick={this.openPortfolioPicker}></i>
              </div>
              <p className="error_msg">{this.state.dateError}</p>
              <div className="go_button">
                <a href="javascript:void(0)" onClick={this.callGraphData} className={(this.state.disabledGo ? 'disabledGo' : '')} >Go</a>
              </div>
              <p className="slectStockError">{this.state.selectStocks}</p>
      			</div>
      		</div>
      		
          <div className="daterange_picker_custom">
						<DateRangePicker
					      open={this.state.pickeropen}
					      toggle={this.togglePicker}
					      onChange={(range) => this.setPortfolioDate(range)}
                format="MM/DD/YYYY"
					    />
					</div>
	      </div>
        <div className="portfolio_graph">
          <div>
            <div className="graph_container">
              <div>
                <h5>Portfolio Sentiment</h5>
                <DailyPortfolioSentiment sentimentsValues={this.props.sentimentsValues} portfolioSenticode={this.props.portfolioSenticode} onGoClick={this.handleGoSentiments } sentimentsHashNo={this.props.sentimentHash} />
                {this.state.goclikedSentiments ?  <div className="loader_gif">
                  <img src={loader} alt="" />
                </div> : "" }
              </div>
            </div>
           
          </div>
        </div>
			</div>
      <News nonewsdisplay="true" />
      </div>
		)
	}
}

const mapStateToProps = state => {
  return{
    statusUpload: state.graphReducer.statusUpload,
    codeUpload: state.graphReducer.codeUpload,
    Upload_hash: state.graphReducer.Upload_hash,
    filenameList: state.graphReducer.nameList,
    filestatusCode: state.graphReducer.statusCode,
    sentimentsValues: state.graphReducer.sentimentsValues,
    portfolioSenticode: state.graphReducer.portfolioSenticode,
    intensityValues: state.graphReducer.intensityValues,
    portfolioIntencode: state.graphReducer.portfolioIntencode,
    intensitysError: state.graphReducer.intensitysError,
    intensityHash: state.graphReducer.intensityHash,
    sentimentsError:state.graphReducer.sentimentsError,
    sentimentHash:state.graphReducer.sentimentHash,
    csvFilePath:state.graphReducer.csvFilePath,
    csvFilehash:state.graphReducer.csvFilehash,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    uploadFile : (uploadFileParam) => dispatch(dataActions.uploadFile(uploadFileParam)),
    csvNameList : (filenameParam) => dispatch(dataActions.csvNameList(filenameParam)),
    avagPortfolioSentiments : (portfolioParams) => dispatch(dataActions.avagPortfolioSentiments(portfolioParams)),
    avagPortfolioIntensity : (portfolioParams) => dispatch(dataActions.avagPortfolioIntensity(portfolioParams)),
    deletePortfolioCsv : (deleteParams) => dispatch(dataActions.deletePortfolioCsv(deleteParams)),
    
      
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PortfolioMain);
