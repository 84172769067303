import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index'; 
import PortfolioMultiSelectPacta from './PortfolioMultiSelectPacta';
import TRiskAOne from './TRiskAOne';
import TRiskATwo from './TRiskATwo';
import PactaTableData from './PactaTableData';
import Modal from '@material-ui/core/Modal';
import loader from '../../../assets/images/loader.gif';
import News from '../Signin/News';
import {HOST_URL} from '../../../store/Constant'
let userMail = '';
let fileData;
let fileNamesArr =[];
let filenameList = [];

let portfolioSelected = [];
let selectedCsv = [];

class PactaTab extends Component {

	constructor(props){
    super(props);

	    this.state = {
	    	userEmail:"",
        uploadFile:"",
        pactastatus:"",
        fileNamesArr:"",
        pactaResponseData:[],
        disabledGo: true,
        showpactaTable: false,
        openEditModel: false,
        modelConfirmOpen: false,
        deleteName:"",
        modelConfirmOpen:"",
        goClickLoaderpacta: false,

        portfolioSelected: [],
        filenameList:[],
        dropdownList : [],

	    }

	    this.uploadFile = this.uploadFile.bind(this);
      this.handlePortfolio = this.handlePortfolio.bind(this);
      this.callGraphData = this.callGraphData.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handlemodelConfirmOpen = this.handlemodelConfirmOpen.bind(this);
      this.handlemodelConfirmClose = this.handlemodelConfirmClose.bind(this);
      this.handleRemoveOption = this.handleRemoveOption.bind(this);
      this.handleDeleteName = this.handleDeleteName.bind(this);
      this.handleDownloadFile = this.handleDownloadFile.bind(this);
	  
   
	}


	componentDidMount(){
	

		if( sessionStorage.getItem('signInEmail') === "" || sessionStorage.getItem('signInEmail') === null){
      this.setState({
        userEmail: sessionStorage.getItem('signUpEmail')
      });
      userMail = sessionStorage.getItem('signUpEmail')
      }else{
        this.setState({
          userEmail: sessionStorage.getItem('signInEmail')
        });
        userMail = sessionStorage.getItem('signInEmail')
      }

      
      let filenameParam = JSON.stringify({
        "mail_id":userMail
      });
      this.props.pactaNameList(filenameParam);

	}

	componentDidUpdate(prevProps, prevState) {
    let $this = this;
    let filenameParam = JSON.stringify({
      "mail_id":userMail,
    });

    
    if(this.props.pactaUpload_hash !== prevProps.pactaUpload_hash){
     
      this.setState({
        pactastatus: this.props.pactastatus,
      })
      setTimeout(function(){
        $this.setState({
          pactastatus: ""
        });
      },5000)
      this.props.pactaNameList(filenameParam);
    }

    if(this.props.pactacsv_file_hash !== prevProps.pactacsv_file_hash){
   
      this.setState({
        portfolioSelected : this.props.pactaResponseData,
        pactaResponseData : this.props.pactaResponseData,
        dropdownList : this.props.pactaResponseData,
      })
      portfolioSelected = this.props.pactaResponseData
    }

    if(this.props.pactaGrand_hash !== prevProps.pactaGrand_hash){
      this.setState({
        showpactaTable:true,
        goClickLoaderpacta: false,
      })
    }
    
  }

  uploadFile(event) {
    let file = event.target.files[0];
   
    fileData = file;
  

    this.setState({
      uploadFile: file
    });

    const data = new FormData();
    data.append('file', file);
    data.append('mail_id', userMail);

    
    this.props.uploadpactaFile(data);

  }




   handlePortfolio(list){
    fileNamesArr =[];
    let newlist = this.state.pactaResponseData 
    if(list.length){

      
     
      for(let i=0;i < list.length;i++){
        fileNamesArr.push(list[i].name);
      }
   
      const namesToDeleteSet = new Set(fileNamesArr);

      if(fileNamesArr.length > 0 ){
        selectedCsv = fileNamesArr
       
        //newlist = newlist.filter(item => item !== selectedCsv[0]);
        newlist = newlist.filter((name) => {
          return !namesToDeleteSet.has(name);
        });
        
      }
      this.setState({
        fileNamesArr:fileNamesArr,
        disabledGo:false,
        dropdownList: newlist
      })

    }else{
      this.setState({
        disabledGo:true,
        dropdownList: newlist
      })
    }
  }


  handleOpen(){
    //setOpen(true);
    this.setState({
      openEditModel: true,
    })
  };

  handleClose() {
    this.setState({
      openEditModel: false,
    })
  };

  handlemodelConfirmOpen() {
    //setOpen(true);
    this.setState({
      modelConfirmOpen:true,
    })
  };

  handlemodelConfirmClose() {
    //setOpen(false);
    this.setState({
      modelConfirmOpen:false,
    })
  };

  handleRemoveOption(e){
   
    this.setState({
      deleteName:e.target.getAttribute("value")
    })
    this.handlemodelConfirmOpen();
    e.stopPropagation();
    
  }

 

  handleDeleteName(){
    document.getElementById("csv_upload").value = "";
    portfolioSelected = this.state.pactaResponseData

    portfolioSelected = portfolioSelected.filter(item => item !== this.state.deleteName);

    let newlist = portfolioSelected 
    const namesToDeleteSet = new Set(selectedCsv);
    if(newlist.length > 0 ){
      //selectedCsv = fileNamesArr
     
      newlist = newlist.filter((name) => {
        return !namesToDeleteSet.has(name);
      });
      
    }
    
    this.setState({
      portfolioSelected: portfolioSelected,
      pactaResponseData:portfolioSelected,
      dropdownList:newlist,
    });

    let deleteParams = JSON.stringify({
      "mail_id":userMail,
      "file_name" : this.state.deleteName
    });

   
    this.props.deletePactaCsv(deleteParams)
    //this.handleClose();
    this.handlemodelConfirmClose();
  } 




  callGraphData(){
   
    if(fileNamesArr.length > 0){
      this.setState({
        disabledGo:true,
        showpactaTable: true,
        goClickLoaderpacta: true,
      });
      let portfolioString = "";
    
      for(let i=0; i< fileNamesArr.length; i++){
       
        if(fileNamesArr.length > 1){
          portfolioString = portfolioString + ',' + this.state.fileNamesArr[i] 
        }else{
          portfolioString = this.state.fileNamesArr[i]
        }
      
        
      }
   
      portfolioString = portfolioString.replace(/^,/, '');

      let pactaParams = JSON.stringify({
        "file_name": portfolioString,
        "mail_id": this.state.userEmail,
      });
     
      this.props.pactaGraphs(pactaParams)
    }else{
      this.setState({
        selectStocks: "Please choose one or more files."
      });
    }
  }

  handleDownloadFile(){
    var currentTimeInSeconds=Math.floor(Date.now()/1000);
    window.open( HOST_URL+"download/sample/Climate_stress_portfolio.csv"+"?x="+currentTimeInSeconds, "_blank");
  }
 



	render() {
  
		return (
			<div className="esg_company_head"><div className="portFolio_container ESG_tab">
				<div className="container_wrapper">
          <h3 className="instruction_text">
            Upload a new portfolio, or select a previously uploaded portfolio.
            <div className="info_area">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              <div className="instruction_tooltip">
                <span>The csv portfolio file should contain the column headers (ISIN , Weight)</span>
                <div className="arrow-down"></div>
              </div>
            </div>
          </h3>
          <p className="sample_file"> Sample portfolio <a onClick={this.handleDownloadFile} > Climate_stress_portfolio.csv </a></p>
          <div className="left_intro">
            <div>

              <div className="upload_button">
                
                <div className="upload_csv">
                  <input onChange={e => this.uploadFile(e)} type="file" className="custom-file-input" id="csv_upload" accept=".csv" />
                </div>
                <p className={"upload_status " + (this.props.pactastatusCode === 1 ? 'success_upload' : 'error_upload')}> 
                  {this.state.pactastatus}
                </p>
              </div>
              <div className="uploadedcsv_list">
                <div className="dropdown_csv">
                  <PortfolioMultiSelectPacta fileNameList = {this.state.pactaResponseData} onSelectPortfolio={this.handlePortfolio} />

                  <div className="main_modal_container">
                    {this.state.pactaResponseData ?  <div className="edit_dropdown" onClick={this.handleOpen}>
                      <span>Delete or download portfolio </span><i className="fa fa-pencil"></i>
                    </div> : ""}

                    <Modal
                      open={this.state.openEditModel}
                      onClose={this.handleClose}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                      className="listingModal"
                    >
                      <div className="listing_container">
                        <div className="container_modal">
                          <h2>Portfolio listing</h2>
                          <ul>
                            {this.state.dropdownList ? this.state.dropdownList.map((item,index) => 
                              <li>
                                <div>
                                  <span>{item}</span>
                                  <span className="icon_div">
                                    <i className="fa fa-times delete_portfolio" value={item} onClick={(e) => this.handleRemoveOption(e)} ></i>
                                 
                                   <a href={'https://analytics.emalpha.com:5000/download/file/'+userMail+'/'+item+'/pacta'} target="_blank"><i className="fa fa-download download_portfolio" ></i></a>
                                  </span>
                                </div>
                              </li>
                            ) : ""}
                          </ul>
                        </div>
                      </div>
                    </Modal>
                  </div>


                  <div className="modal_main">
                    <div>
                      <div>
                      
                        <Modal
                          open={this.state.modelConfirmOpen}
                          //onClose={this.handlemodelConfirmClose}
                          aria-labelledby="simple-modal-title"
                          aria-describedby="simple-modal-description"
                        >
                          <div className="deleteModal">
                            <h2 id="simple-modal-title">Are you sure you want to delete{this.state.deleteName} dictionary from the list ?</h2>
                            <i className="fa fa-times close_delete" onClick={this.handlemodelConfirmClose}></i>
                            <div className="button_section">
                              <button className="yes_bttn" onClick={this.handleDeleteName} >YES</button>
                              <button className="no_bttn" onClick={this.handlemodelConfirmClose}>NO</button>
                            </div>
                           
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="go_button">
                <a href="javascript:void(0)" onClick={this.callGraphData} className={(this.state.disabledGo ? 'disabledGo' : '')} >Go</a>
              </div>
                  
              {this.state.showpactaTable ? <div className="barcharts_pacta">
                {this.props.pactaGStatusCode === 0 ? <div className="esg_errorMsg">Some Thing went wrong !! Please try after some time.</div>
                  :
                <div>
                  <div className="main_whitebox">
                      <div className="chartOne">
                        <TRiskAOne dataArray={this.props.pactaGResponseData[0]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} titleText="Transition Risks A"/>
                         {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                      </div>

                      <div className="chartTwo">
                        <TRiskATwo dataArray={this.props.pactaGResponseData[1]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} titleText="Transition Risks A" />
                        {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                      </div>

                  </div>

                  <div className="main_whitebox">
                       <div className="chartOne">
                        <TRiskAOne dataArray={this.props.pactaGResponseData[2]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} titleText="Transition Risks B" />
                        {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                      </div>

                      <div className="chartTwo">
                        <TRiskATwo dataArray={this.props.pactaGResponseData[3]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash}  titleText="Transition Risks B" />
                        {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                      </div>

                  </div>

                  <div className="main_whitebox">
                       <div className="chartOne">
                        <TRiskAOne dataArray={this.props.pactaGResponseData[4]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash}  titleText="Transition Risks C" />
                        {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                        </div>

                      <div className="chartTwo">
                        <TRiskATwo dataArray={this.props.pactaGResponseData[5]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash}  titleText="Transition Risks C" />
                        {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                      </div>

                  </div>

                  <div className="main_whitebox">
                       <div className="chartOne">
                        <TRiskAOne dataArray={this.props.pactaGResponseData[6]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} titleText="Physical Risks A"/>
                        {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                      </div>

                      <div className="chartTwo">
                        <TRiskATwo dataArray={this.props.pactaGResponseData[7]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} titleText="Physical Risks A" />
                        {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                      </div>

                  </div>

                  <div className="main_whitebox">
                      <div className="chartOne">
                        <TRiskAOne dataArray={this.props.pactaGResponseData[8]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} titleText="Physical Risks B" />
                        {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                            
                      </div>

                      <div className="chartTwo">
                        <TRiskATwo dataArray={this.props.pactaGResponseData[9]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} titleText="Physical Risks B" />
                        {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                      </div>

                  </div>

                  <div className="main_whitebox">
                      <div className="chartOne">
                        <TRiskAOne dataArray={this.props.pactaGResponseData[10]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} titleText="Physical Risks C" />
                         {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                      </div>


                      <div className="chartTwo">
                        <TRiskATwo dataArray={this.props.pactaGResponseData[11]} sbtiStatusCode={this.props.sbtiStatusCode} sbtirand_hash={this.props.sbtirand_hash} titleText="Physical Risks C" />
                        {this.state.goClickLoaderpacta ?  <div className="loader_gif">
                            <img src={loader} /> </div>: ""}
                      </div>

                  </div>
                </div> }
              </div> : ""}

              {this.state.showpactaTable ? <div className="pactaTable">
                {this.props.pactaGStatusCode === 1 ?
                  <div>
                    <h3>Scenario Definitions</h3>
                    <PactaTableData />
                  </div> : ""}
              </div> : ""}


            </div>
          </div>

				</div>

			</div>
      <News />
      </div>
		)
	}
}
const mapStateToProps = state => {
  return{
    pactastatus: state.esgReducers.pactastatus,
    pactastatusCode: state.esgReducers.pactastatusCode,
    pactaUpload_hash: state.esgReducers.pactaUpload_hash,

    pactaResponseData: state.esgReducers.pactaResponseData,
    pactaStatus: state.esgReducers.pactaStatus,
    pactaStatusCode: state.esgReducers.pactaStatusCode,
    pactacsv_file_hash: state.esgReducers.pactacsv_file_hash,
    pactacsv_file_path: state.esgReducers.pactacsv_file_path,

    pactaGResponseData: state.esgReducers.pactaGResponseData,
    pactaGStatus: state.esgReducers.pactaGStatus,
    pactaGStatusCode: state.esgReducers.pactaGStatusCode,
    pactaGrand_hash: state.esgReducers.pactaGrand_hash,
    

    
  };
}

const mapDispatchToProps = dispatch => {
  return {
    uploadpactaFile : (uploadFileParam) => dispatch(dataActions.uploadpactaFile(uploadFileParam)),
    pactaNameList : (filenameParam) => dispatch(dataActions.pactaNameList(filenameParam)),
    pactaGraphs : (pactaParams) => dispatch(dataActions.pactaGraphs(pactaParams)),
    deletePactaCsv : (pactaParams) => dispatch(dataActions.deletePactaCsv(pactaParams)),
     
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PactaTab);

