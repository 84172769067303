import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index';

class CountryDropdown extends Component {

	constructor(props){
    super(props);

    this.state = {
    	country:'',
    	countryList:[],
    	framework:"",
    }
    this.ChangeCountry = this.ChangeCountry.bind(this);
	    
	} 

	componentDidMount(){
		if(this.props.frameName !== this.state.frameName){
    	

			this.setState({
				frameName: this.props.frameName
			})
	    	let listparams = JSON.stringify({
				"folder_name":this.props.frameName
			});
			this.props.countryNamesList(listparams);
	    }
		
	}

	componentDidUpdate(prevProps, prevState) {
   
	    if(this.props.countryListHash !== prevProps.countryListHash){
			//console.log('in');
			
			this.setState({
		      countryList:this.props.countryListESG,
		      country: this.props.countryListESG[0]
		    });
		    this.props.onInitCountry(this.props.countryListESG[0])
			

	    }
    	
	}

	ChangeCountry(event, newValue){
		//console.log('newValue',event.target.value)
		this.setState({
	      country:event.target.value
	    });
		this.props.onSelectCountry(event.target.value)
	  //setCountry(newValue);
	};

	render() {
		return (
			<div className="country_select">
				<div className="maincountry_dropdown">
					<FormControl>
			        <InputLabel shrink id="demo-simple-selPect-placeholder-label-label">
			          Country:
			        </InputLabel>
			        <Select
			          labelId="demo-simple-select-placeholder-label-label"
			          id="demo-simple-select-placeholder-label"
			          value={this.state.country}
			          onChange={this.ChangeCountry}
			          displayEmpty 
			          
			        >
			        	{this.props.countryListESG ? this.props.countryListESG.map((list,index) => 
			        		<MenuItem key={index} value={list}> {list} </MenuItem>
			        	) : ""}
			          
			         
			        </Select>
			      </FormControl>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
    return{
        countryListESG: state.graphReducer.countryListESG,
        countryListHash: state.graphReducer.countryListHash,
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        countryNamesList : (listparams) => dispatch(dataActions.getESGCountryNames(listparams)),
        
        
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CountryDropdown);