import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index'; 
import Modal from '@material-ui/core/Modal';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PortfolioMultiSelect from './PortfolioMultiSelect';
import { DateRangePicker } from "materialui-daterange-picker";
import loader from '../../../assets/images/loader.gif';
import PortfolioESGraph from './PortfolioESGraph';
import QuantileStats from './QuantileStats';
import ControlledStats from './ControlledStats';
import SectorStats from './SectorStats';
import News from '../Signin/News';
import moment from 'moment'
import {HOST_URL} from '../../../store/Constant'
let userMail = '';
let portfolioNames = [];
let fileData;
let fileNamesArr = [];
let filenameList = [];
let portfolioSelected = [];
let selectedCsv = [];
 
class PortfolioESG extends Component {

	constructor(props){
    super(props);

	    this.state = {
	    	open: false,
	    	portfolioList:[],
	    	portfolio:"",
	    	portfolioDateValue: '01/23/2020 - 02/23/2021',
	    	pickeropen:false,
	    	portfolioDateRange: new Date(),
	    	uploadFile:'',
	    	userMail:'',
        Upload_hash: '',
        disabledGo:true,
        fileNamesArr: [],
        goclikedSentiments:false,
        goclikedIntencity:false,
        frameWork:'',
        goclikedStock: false,
        gocliked: false,
        uploadStatus: "",
        openEditModel: false,
        modelConfirmOpen: false,
        deleteName:"",
        modelConfirmOpen:"",
        portfolioSelected: [],
        filenameList:[],
        dropdownList : [],
        dateError: "",
        dateErrorKey : false,

	    }
	    this.getFileName = this.getFileName.bind(this);
	    this.changePortfolio = this.changePortfolio.bind(this);
	    this.togglePicker = this.togglePicker.bind(this);
	    this.openPortfolioPicker = this.openPortfolioPicker.bind(this);
	    this.setPortfolioDate = this.setPortfolioDate.bind(this);
	    this.uploadFile = this.uploadFile.bind(this);
	    this.handleUploadFile = this.handleUploadFile.bind(this);
      this.callGraphData = this.callGraphData.bind(this);
      this.handlePortfolio = this.handlePortfolio.bind(this);
      this.setDisabledFalse = this.setDisabledFalse.bind(this);
      this.handleGoSentiments = this.handleGoSentiments.bind(this);
      this.handleGoIntensity = this.handleGoIntensity.bind(this);
      this.handleFrameWork = this.handleFrameWork.bind(this);
      this.handleGoStock = this.handleGoStock.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handlemodelConfirmOpen = this.handlemodelConfirmOpen.bind(this);
      this.handlemodelConfirmClose = this.handlemodelConfirmClose.bind(this);
      this.handleRemoveOption = this.handleRemoveOption.bind(this);
      this.handleDeleteName = this.handleDeleteName.bind(this);
      this.handleDownloadFile = this.handleDownloadFile.bind(this);
   
	}


	componentDidMount(){
    //console.log('in portolios esg')
    //document.querySelector('.new_items').innerHTML = "";
    

	 if( sessionStorage.getItem('signInEmail') === "" || sessionStorage.getItem('signInEmail') === null){
    	this.setState({
	      userEmail: sessionStorage.getItem('signUpEmail')
	    });
	    userMail = sessionStorage.getItem('signUpEmail')
    }else{
    	this.setState({
	      userEmail: sessionStorage.getItem('signInEmail')
	    });
	    userMail = sessionStorage.getItem('signInEmail')
    }

    
    let filenameParam = JSON.stringify({
			"mail_id":userMail
		});

    this.props.esgNameList(filenameParam);
    this.props.getFrameWorks();
		
	}
	
	
  componentDidUpdate(prevProps, prevState) {

    let $this = this;


    if(this.props.frameListHash !== prevProps.frameListHash){
      this.setState({
        frameWork: this.props.frameWorkNames[0]
      })
    }
 		
    let filenameParam = JSON.stringify({
      "mail_id":userMail,
    });
    if(this.props.codeUpload === 1){
      if(prevProps.Upload_hash !== this.props.Upload_hash){
        this.setState({
          Upload_hash: this.props.Upload_hash,
          uploadStatus: this.props.statusUpload
        });

        setTimeout(function(){
          $this.setState({
            uploadStatus: ""
          });
        },5000)
        this.props.esgNameList(filenameParam);

      }
    }else{
      if(prevProps.Upload_hash !== this.props.Upload_hash){
        this.setState({
          Upload_hash: this.props.Upload_hash,
          uploadStatus: this.props.statusUpload
        });

        setTimeout(function(){
          $this.setState({
            uploadStatus: ""
          });
        },5000)
      }
    }


    if(this.props.esgCsvHash !== prevProps.esgCsvHash){
      /*this.setState({
        filenameList: this.props.filenameList,
      });
      filenameList = this.props.filenameList*/
      this.setState({
        portfolioSelected : this.props.filenameList,
        filenameList : this.props.filenameList,
        dropdownList : this.props.filenameList,
      })
      portfolioSelected = this.props.filenameList
    }

  }


	getFileName(event){
		var fileList = document.getElementById("csv_upload");

		
    for (var i = 0; i < fileList.files.length; ++i) {
        
        portfolioNames.push({
        	name: fileList.files.item(i).name
        })
    }
    this.setState({
    	portfolioList: portfolioNames
    })

		
  }

  changePortfolio(event){
	
		this.setState({
    	portfolio: event.target.value
    })
	}


  togglePicker(){
  	
  	this.setState({
  		pickeropen: !this.state.pickeropen
  	})
  }

	openPortfolioPicker(){
		
		this.setState({
  		pickeropen: !this.state.pickeropen
  	})
	}

	setPortfolioDate (range) {
		
		this.setState({
			portfolioDateRange:range,
      disabledGo:false,
      dateError: "",
      dateErrorKey : false,
		})
	
		let endDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.endDate);
		let startDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.startDate);
		
		
    let FinalDate = moment(startDateValue).format("MM/DD/YYYY")+'-'+moment(endDateValue).format("MM/DD/YYYY");

    let year_date = moment(moment(startDateValue).format("MM/DD/YYYY"),"MM/DD/YYYY").year()
    console.log('year_date',year_date)

    if(year_date < "2019"){
     this.setState({
       dateError: "Please choose start date later than 01/01/2019",
       disabledGo: true,
       dateErrorKey : true,
     })
    }else{
      this.setState({
       dateError: "",
       disabledGo: false,
       dateErrorKey : false,
     })
    }

		this.setState({
			portfolioDateValue : FinalDate,
			pickeropen: false,
		})


  }
   
  uploadFile(event) {
    let file = event.target.files[0];
   
    fileData = file;
  

    this.setState({
    	uploadFile: file
    });

    const data = new FormData();
    data.append('file', file);
    data.append('mail_id', userMail);

    
    this.props.uploadESGFile(data);

  }

  handleUploadFile(){
  
  	let uploadFileParam = JSON.stringify({
   		"file": fileData,
			"mail_id":userMail
		});
  
    this.props.uploadFile(uploadFileParam);
  }

	
  callGraphData() {
   
    if(fileNamesArr.length > 0){
      this.setState({
        disabledGo:true,
        goclikedSentiments:true,
        goclikedIntencity:true,
        gocliked: true,
        goclikedStock:true,
        selectStocks:"",
      });
      let portfolioString = "";
    
      for(let i=0; i< fileNamesArr.length; i++){
       
        if(fileNamesArr.length > 1){
          portfolioString = portfolioString + ',' + this.state.fileNamesArr[i] 
        }else{
          portfolioString = this.state.fileNamesArr[i]
        }
      
        
      }
   
      portfolioString = portfolioString.replace(/^,/, '');
      
      let portfolioParams = JSON.stringify({
        "files_name": portfolioString,
        "date_range": this.state.portfolioDateValue,
        "mail_id":userMail,
        "frame": this.state.frameWork

      });
     

      this.props.esgDataAllFiles(portfolioParams);
    }else{
      this.setState({
        selectStocks: "Please choose one or more files."
      });
    }

    


  }


 /* handlePortfolio(list){
    fileNamesArr =[];
   
    for(let i=0;i < list.length;i++){
      fileNamesArr.push(list[i].name);
    }
    this.setState({
      fileNamesArr:fileNamesArr,
      disabledGo:false
    })
  }*/

  handlePortfolio(list){
    fileNamesArr =[];
    let newlist = this.state.filenameList 
  
   
    for(let i=0;i < list.length;i++){
      fileNamesArr.push(list[i].name);
    }
    
    const namesToDeleteSet = new Set(fileNamesArr);

    if(fileNamesArr.length > 0 ){
      selectedCsv = fileNamesArr
      
      //newlist = newlist.filter(item => item !== selectedCsv[0]);
      newlist = newlist.filter((name) => {
        return !namesToDeleteSet.has(name);
      });
      
    }else{
      
    }

    if(this.state.dateErrorKey === true){

      this.setState({
        fileNamesArr:fileNamesArr,
        disabledGo:true,
        dropdownList: newlist
      })
    }else{
      this.setState({
        fileNamesArr:fileNamesArr,
        disabledGo:false,
        dropdownList: newlist
      })
    }
  }



  setDisabledFalse(){
   
    this.setState({
      disabledGo:false
    })
  }
  handleGoSentiments(goValue){
    this.setState({
      goclikedSentiments:false,
    })
  }
  handleGoIntensity(goValue){
    this.setState({
      goclikedIntencity:false,
    })
  }

  handleFrameWork (event){
    if(this.state.dateErrorKey === true){
      this.setState({
        frameWork: event.target.value,
        disabledGo:true
      })
    }else{
      this.setState({
        frameWork: event.target.value,
        disabledGo:false
      })
    }
  };

  handleGoStock(goValue){
  
    this.setState({
      goclikedStock:false
    })
  }


  handleOpen(){
    
    this.setState({
      openEditModel: true,
    })
  };

  handleClose() {
    this.setState({
      openEditModel: false,
    })
  };

  handlemodelConfirmOpen() {
   
    this.setState({
      modelConfirmOpen:true,
    })
  };

  handlemodelConfirmClose() {
   
    this.setState({
      modelConfirmOpen:false,
    })
  };


  handleRemoveOption(e){
   
    this.setState({
      deleteName:e.target.getAttribute("value")
    })
    this.handlemodelConfirmOpen();
    e.stopPropagation();
    
  }

  handleDeleteName(){
    document.getElementById("csv_upload").value = "";
    
    portfolioSelected = portfolioSelected.filter(item => item !== this.state.deleteName);
    let newlist = portfolioSelected 
    const namesToDeleteSet = new Set(selectedCsv);
    if(newlist.length > 0 ){
      //selectedCsv = fileNamesArr
      
      newlist = newlist.filter((name) => {
        return !namesToDeleteSet.has(name);
      });
      
    }
    this.setState({
      portfolioSelected: portfolioSelected,
      filenameList:portfolioSelected,
      dropdownList:newlist,
    });

    let deleteParams = JSON.stringify({

      "mail_id":userMail,
      "file_name" : this.state.deleteName
    
    });

   
    this.props.deletePortfolioESGCsv(deleteParams);
    //this.handleClose();
    this.handlemodelConfirmClose();
  }  
 
  
  handleDownloadFile(){
    var currentTimeInSeconds=Math.floor(Date.now()/1000);
    window.open( HOST_URL+"download/sample/ESG_portfolio.csv"+"?x="+currentTimeInSeconds, "_blank");
  } 


	render() {
	
		return (
			<div className="esg_company_head"><div className="portFolio_container ESG_tab">
				<div className="container_wrapper">
					<h3 className="instruction_text">
  					Select a framework and upload a new portfolio, or select a previously uploaded portfolio.
						<div className="info_area">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
  					  <div className="instruction_tooltip">
  	  					<span>The csv portfolio file should contain the column headers (Country, ISIN, Weight)</span>
  	  					<div className="arrow-down"></div>
  	  				</div>
            </div>
  				</h3>
          <p className="sample_file"> Sample portfolio <a onClick={this.handleDownloadFile} > ESG_portfolio.csv </a></p>
      		<div className="left_intro">
      			<div>

      				<div className="upload_button">
                
                <div className="upload_csv">
        					<input onChange={e => this.uploadFile(e)} type="file" className="custom-file-input" id="csv_upload" accept=".csv" />
        				</div>
        				<p className={"upload_status " + (this.props.codeUpload === 1 ? 'success_upload' : 'error_upload')}> 
        					{this.state.uploadStatus} 
        				</p>
              </div>
      				
      				<div className="uploadedcsv_list">
      					<div className="dropdown_csv">
      						<PortfolioMultiSelect fileNameList = {this.state.filenameList} onSelectPortfolio={this.handlePortfolio} />
      						
      					</div>
                <div className="main_modal_container">
                  {this.state.filenameList ?  <div className="edit_dropdown" onClick={this.handleOpen}>
                    <span>Delete or download portfolio </span><i className="fa fa-pencil"></i>
                  </div> : ""}

                  <Modal
                    open={this.state.openEditModel}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="listingModal"
                  >
                    <div className="listing_container">
                      <div className="container_modal">
                        <h2>Portfolio listing</h2>
                        <ul>
                          {this.state.dropdownList ? this.state.dropdownList.map((item,index) => 
                            <li>
                              <div>
                                <span>{item}</span>
                                <span className="icon_div">
                                  <i className="fa fa-times delete_portfolio" value={item} onClick={(e) => this.handleRemoveOption(e)} ></i>
                               
                                  <a href={'https://analytics.emalpha.com:5000/download/file/'+userMail+'/'+item+'/esgPortfolio'} target="_blank"><i className="fa fa-download download_portfolio" ></i></a>
                                </span>
                              </div>
                            </li>
                          ) : ""}
                        </ul>
                      </div>
                    </div>
                  </Modal>
                </div>


                <div className="modal_main">
                  <div>
                    <div>
                    
                      <Modal
                        open={this.state.modelConfirmOpen}
                        //onClose={this.handlemodelConfirmClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                      >
                        <div className="deleteModal">
                          <h2 id="simple-modal-title">Are you sure you want to delete{this.state.deleteName} dictionary from the list ?</h2>
                          <i className="fa fa-times close_delete" onClick={this.handlemodelConfirmClose}></i>
                          <div className="button_section">
                            <button className="yes_bttn" onClick={this.handleDeleteName} >YES</button>
                            <button className="no_bttn" onClick={this.handlemodelConfirmClose}>NO</button>
                          </div>
                         
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>



      				</div>
              <div className="daterange_input portfolio_picker">
                <input type="text"  value={this.state.portfolioDateValue} onFocus={this.openPortfolioPicker} onChange={this.setDisabledFalse}/>
                <i className="fa fa-calendar" aria-hidden="true" onClick={this.openPortfolioPicker}></i>
              </div>
              <p className="error_msg">{this.state.dateError}</p>
              <div className="go_button">
                <a href="javascript:void(0)" onClick={this.callGraphData} className={(this.state.disabledGo ? 'disabledGo' : '')} >Go</a>
              </div>
              <p className="slectStockError">{this.state.selectStocks}</p>

      			</div>
      		</div>
          <div className="right_info">
            <div>
              <FormControl>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Select Framework
                </InputLabel>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={this.state.frameWork}
                  defaultValue={this.state.frameWork}
                  onChange={this.handleFrameWork}
                >

                {this.props.frameWorkNames ? this.props.frameWorkNames.map((list,index) => 

                  <MenuItem  key={index} value={list}> {list} </MenuItem>

                ):""}

                 
                </Select>
                
              </FormControl>
            </div>
          </div>
      		
          <div className="daterange_picker_custom">
						<DateRangePicker
					      open={this.state.pickeropen}
					      toggle={this.togglePicker}
					      onChange={(range) => this.setPortfolioDate(range)}
					    />
					</div>
	      </div>
        <div className="portfolio_graph">
          <div>
            <div className="graph_container">
              <div>
                <h5>Portfolio ESG-framework score</h5>
                <PortfolioESGraph portfolioESGValues={this.props.portfolioESGValues} portfolioESGcode={this.props.portfolioESGcode} portfolioESGhash={this.props.portfolioESGhash} goClicked={this.state.gocliked} portfolioESGStatus={this.props.portfolioESGStatus} onGoClick={this.handleGoStock } portfolioESGerror={this.props.portfolioESGerror}  />

                {this.state.goclikedStock ?  <div className="loader_gif">
                  <img src={loader} alt="" />
                </div> : "" }
              </div>
            </div>

            <div className="graph_container quantile_graph_main">
              <div>
                <h5>Quantile buckets</h5>
                <QuantileStats quantilestatsdata={this.props.quantile_stats_data} portfolioESGcode={this.props.portfolioESGcode} portfolioESGhash={this.props.portfolioESGhash} goClicked={this.state.gocliked} portfolioESGStatus={this.props.portfolioESGStatus} onGoClick={this.handleGoStock } portfolioESGerror={this.props.portfolioESGerror}  />

                {this.state.goclikedStock ?  <div className="loader_gif quantile_graph">
                  <img src={loader} alt="" />
                </div> : "" }
              </div>
            </div>

            <div className="graph_container quantile_graph_main">
              <div>
                <h5>Sector-controlled buckets</h5>
                <ControlledStats sectorControlledBuckets={this.props.sector_controlled_buckets_data} portfolioESGcode={this.props.portfolioESGcode} portfolioESGhash={this.props.portfolioESGhash} goClicked={this.state.gocliked} portfolioESGStatus={this.props.portfolioESGStatus} onGoClick={this.handleGoStock } portfolioESGerror={this.props.portfolioESGerror}  />

                {this.state.goclikedStock ?  <div className="loader_gif quantile_graph">
                  <img src={loader} alt="" />
                </div> : "" }
              </div>
            </div>

            <div className="graph_container quantile_graph_main multiColum">
              <div>
                <h5>Sector scores</h5>
                <SectorStats sectorControlData={this.props.sector_control_data} portfolioESGcode={this.props.portfolioESGcode} portfolioESGhash={this.props.portfolioESGhash} goClicked={this.state.gocliked} portfolioESGStatus={this.props.portfolioESGStatus} onGoClick={this.handleGoStock } portfolioESGerror={this.props.portfolioESGerror}  />

                {this.state.goclikedStock ?  <div className="loader_gif quantile_graph">
                  <img src={loader} alt="" />
                </div> : "" }
              </div>
            </div>


            
          </div>
        </div>
			</div>
      <News nonewsdisplay="true" />
      </div>
		)
	}
}

const mapStateToProps = state => {
  return{
    statusUpload: state.esgReducers.statusUpload,
    codeUpload: state.esgReducers.codeUpload,
    Upload_hash: state.esgReducers.Upload_hash,
    filenameList: state.esgReducers.nameList,
    filestatusCode: state.esgReducers.statusCode,
    frameWorkNames: state.esgReducers.frameWorkNames,
    frameListHash: state.esgReducers.frameListHash,
    portfolioESGValues: state.esgReducers.portfolioESGValues,
    portfolioESGcode: state.esgReducers.portfolioESGcode,
    portfolioESGStatus: state.esgReducers.portfolioESGStatus,
    portfolioESGerror: state.esgReducers.portfolioESGerror,
    portfolioESGhash: state.esgReducers.portfolioESGhash,
    quantile_stats_data: state.esgReducers.quantile_stats_data,
    sector_controlled_buckets_data: state.esgReducers.sector_controlled_buckets_data,
    sector_control_data: state.esgReducers.sector_control_data,
    esgCsvHash: state.esgReducers.esgCsvHash,
    csvFilePath: state.esgReducers.csvFilePath,
    
  };
}

const mapDispatchToProps = dispatch => {
  return {
    uploadESGFile : (uploadFileParam) => dispatch(dataActions.uploadESGFile(uploadFileParam)),
    esgNameList : (filenameParam) => dispatch(dataActions.esgNameList(filenameParam)),
    getFrameWorks : () => dispatch(dataActions.getFrameWorks()),
    esgDataAllFiles : (portfolioParams) => dispatch(dataActions.esgDataAllFiles(portfolioParams)),
    deletePortfolioESGCsv : (deleteParams) => dispatch(dataActions.deletePortfolioESGCsv(deleteParams)),
    
      
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PortfolioESG);
