import React, { Component } from 'react';

import {connect} from 'react-redux';
import * as dataActions from '../../../../store/actions/index';


class SignUp extends Component {

	constructor(props){
	    super(props);

	    this.state = {
	    	notLogin: false,
	    	tabpage: false,
	    	input: {},
    		errors: {},
    		signinStatus:'',
    		signinMessage:'',
    		forgotLink: false,
    		mailSend: false,
    		statusMail:"",
    		dissabledMail:false,
	    }
	    this.handleChange = this.handleChange.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.handleLogin = this.handleLogin.bind(this);
	    this.signUpToggle = this.signUpToggle.bind(this);
	    this.handleForgotLink = this.handleForgotLink.bind(this);
	    this.backToSignin = this.backToSignin.bind(this);
	}

	componentDidMount(){
		// console.log('signupStatus',sessionStorage.getItem('signupStatus'))
		// console.log('signinStatus',sessionStorage.getItem('signinStatus'))
		// alert('in')
		/*if(parseInt(sessionStorage.getItem('signupStatus')) === 1){
			console.log('yes')
			this.setState({
		      tabpage:true
		    });
		    // window.location.href = "/analytics";
		}
		else */if(parseInt(sessionStorage.getItem('signinStatus')) === 1){
			// console.log('yes')
			this.setState({
		      tabpage:true
		    });
		    window.location.href = "/analytics";
		}if(parseInt(sessionStorage.getItem('signinStatus')) === 0){
				this.setState({
		      tabpage:false
		    });
		    // window.location.href = "/signin";
		}
		else{
			this.setState({
		      tabpage:false
		    });
		    // window.location.href = "/signin";
		}


	}
	
  

  componentDidUpdate(prevProps, prevState) {
 //  	console.log('signupStatus1',sessionStorage.getItem('signupStatus'))
	// console.log('signinStatus1',sessionStorage.getItem('signinStatus'))
  	if(this.props.signinStatus === 1){
  		
  		if(this.props.signinStatus !== prevProps.signinStatus){
  			// console.log('this.props',this.props)
  			/*this.setState({
		      tabpage:true
		    });*/
		    // this.props.router.push('/mainTabs')
		   window.location.href = "/analytics";
  		}
  	}else{
  		if(this.props.signinStatus !== prevProps.signinStatus){
  			// console.log('this.props',this.props)
  			/*this.setState({
		      tabpage:false
		    });*/
		    // this.props.router.push('/signin')
		    //window.location.href = "/signin";
  		}
  	}

  	if(this.props.signupStatus === 1){
  		
  		if(this.props.signupStatus !== prevProps.signupStatus){
  			// console.log('this.props',this.props)
  			/*this.setState({
		      tabpage:true
		    });*/
		    // this.props.router.push('/mainTabs')
		    window.location.href = "/analytics";
  		}
  	}else{
  		if(this.props.signupStatus !== prevProps.signupStatus){
  			/*this.setState({
		      tabpage:false
		    });*/
		    // console.log('this.props',this.props)
		    window.location.href = "/signin";
		    // this.props.router.push('/signin')
  		}
  	}


  	if(this.props.hashMail !== prevProps.hashMail){
  		this.setState({
  			statusMail: this.props.statusMail,

  		})
  	}

  	
  }
  
  


	handleChange(event) {
    	let input = this.state.input;
	    input[event.target.name] = event.target.value;
	  
	    this.setState({
	      input:input,
	      dissabledMail: false
	    });
	}

	handleLogin(event) {
    event.preventDefault();
  	// console.log('yes')
  	if(this.state.forgotLink === true){

  		if(this.validateForgotPassword()){
	  	console.log('in else if');
	  		  	this.setState({
	  		  		mailSend: true,
	  		  		dissabledMail:true
	  		  	})
	  	
	  		  	// console.log('this.state.input.email',this.state.input.email);
	  	
	  	      let forgotParams = JSON.stringify({
	  					mail_id: this.state.input.email
	  	
	  				});
	  	
	  				this.props.forgotPassword(forgotParams)
	  	
	  		  }else{
	  	
	  		  }
  	}else{
  		if(this.validateLogin()){
	    	console.log('in login')
	      let input = {};
	      input["email"] = "";
	      input["password"] = "";

	      let signInParams = JSON.stringify({
					mail_id: this.state.input.email,

					password: this.state.input.password,

				});

				this.props.onSignIn(signInParams, this.state.input.email,this.state.input.password);
		  }else{
		  	console.log(' login else')
		  }
  	}

   /* if(this.validateLogin()){
    	console.log('in login')
      let input = {};
      input["email"] = "";
      input["password"] = "";

      let signInParams = JSON.stringify({
				mail_id: this.state.input.email,

				password: this.state.input.password,

			});

			this.props.onSignIn(signInParams, this.state.input.email,this.state.input.password);
	  }else if(this.validateForgotPassword()){
	  	console.log('in else if');
	  	this.setState({
	  		mailSend: true,
	  		dissabledMail:true
	  	})

	  	// console.log('this.state.input.email',this.state.input.email);

      let forgotParams = JSON.stringify({
				mail_id: this.state.input.email

			});

			this.props.forgotPassword(forgotParams)

	  }else{

	  }*/
	}

	validateLogin(){
	    let input = this.state.input;
	    let errors = {};
	    let isValid = true;
	   
	   
	    if (!input["email"]) {
	        isValid = false;
	        errors["email"] = "Please enter your email Address.";
	    }

	    if (typeof input["email"] !== "undefined") {
	          
	        var pattern = new RegExp(/^([\w+]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i);
	        if (!pattern.test(input["email"])) {
	          isValid = false;
	          errors["email"] = "Please enter valid email address.";
	        }
	    }
	  
	    if (!input["password"]) {
	        isValid = false;
	        errors["password"] = "Please enter your password.";
	    }

	    if (typeof input["password"] !== "undefined") {
	        if(input["password"].length < 6){
	            isValid = false;
	            errors["password"] = "Please enter 6 digit password.";
	        }
	    }
	      
	    this.setState({
	        errors: errors
	    });
	  
	    return isValid;
	     
	}


	validateForgotPassword(){
	    let input = this.state.input;
	    let errors = {};
	    let isValid = true;
	   
	   
	    if (!input["email"]) {
	        isValid = false;
	        errors["email"] = "Please enter your email Address.";
	    }

	    if (typeof input["email"] !== "undefined") {
	          
			var pattern = new RegExp(/^([\w+]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i);
	        if (!pattern.test(input["email"])) {
	          isValid = false;
	          errors["email"] = "Please enter valid email address.";
	        }
	    }
	  
	    
	    this.setState({
	        errors: errors
	    });
	  
	    return isValid;
	     
	}

     

  handleSubmit(event) {
	    event.preventDefault();
	  
	    if(this.validate()){
	      
	      let input = {};
	      input["username"] = "";
	      input["email"] = "";
	      input["password"] = "";

	      

	      let signUpParams = JSON.stringify({
       		username: this.state.input.username,

					mail_id: this.state.input.email,

					password: this.state.input.password,

				});


	    	this.props.onSignUp(signUpParams,this.state.input.email);
	    }
	}

	validate(){
	    let input = this.state.input;
	    let errors = {};
	    let isValid = true;
	   
	    if (!input["username"]) {
	        isValid = false;
	        errors["username"] = "Please enter your username.";
	    }	
	    if (typeof input["username"] !== "undefined") {
	        const re = /^\S*$/;
	        if(input["username"].length < 6 || !re.test(input["username"])){
	            isValid = false;
	            errors["username"] = "Please enter valid username.";
	        }
	    }
	  
	    if (!input["email"]) {
	        isValid = false;
	        errors["email"] = "Please enter your email Address.";
	    }

	    if (typeof input["email"] !== "undefined") {
	          
			var pattern = new RegExp(/^([\w+]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i);
	        if (!pattern.test(input["email"])) {
	          isValid = false;
	          errors["email"] = "Please enter valid email address.";
	        }
	    }
	  
	    if (!input["password"]) {
	        isValid = false;
	        errors["password"] = "Please enter your password.";
	    }

	    if (typeof input["password"] !== "undefined") {
	        if(input["password"].length < 6){
	            isValid = false;
	            errors["password"] = "Please add at least 6 charachter.";
	        }
	    }
	      
	    this.setState({
	        errors: errors
	    });
	  
	    return isValid;
	     
	}
	

	signUpToggle(){
		this.setState({ 
			notLogin: !this.state.notLogin,
			input: {},
      		errors: {},
      		
		});
	}


	handleForgotLink(){
		this.setState({
			forgotLink: true,
			input: {},
			errors: {},
		})
	}


	backToSignin(){
		this.setState({
			forgotLink:false
		})
	}

	render() {
		
		return (
			<div>
			
				{!this.state.notLogin ? 
				<div className="login_page">
					<div className="right_formarea">
						<div>
							<h1>Sign In to EMalpha</h1>
						
							<div className="login_form">
								<form onSubmit={this.handleLogin}>
									{!this.state.forgotLink ? <div>
									  <div className="form-group">
									  	<i className="fa fa-envelope-o" aria-hidden="true"></i>
									    <input 
								              type="text" 
								              name="email" 
								              value={this.state.input.email}
								              onChange={this.handleChange}
								              className="form-control" 
								              placeholder="Enter email" 
								              id="email" />
								  
								        <div className="error_msg">{this.state.errors.email}</div>
									  </div>
									  <div className="form-group">
									  	<i className="fa fa-lock" aria-hidden="true"></i>
									    <input 
								              type="password" 
								              name="password" 
								              value={this.state.input.password}
								              onChange={this.handleChange}
								              className="form-control" 
								              placeholder="Enter password" 
								              id="password" />
								  
								        <div className="error_msg">{this.state.errors.password}</div>
									  </div>
									  <a className="forget_pass" onClick={this.handleForgotLink } >Forgot your password ?</a>
									  <div className={"signup_message signinText " + (this.props.signinStatus === 1 ? 'green_text' : 'red_text')}>
											<p>{this.props.signinMessage}</p>
										</div>
									</div> : 
									<div className="forpass_container">
										<div>
											<h3>Please enter the email id , we will send you recovery password over there. </h3>
											<div className="form-group">
										  	<i className="fa fa-envelope-o" aria-hidden="true"></i>
										    <input 
								              type="text" 
								              name="email" 
								              value={this.state.input.email}
								              onChange={this.handleChange}
								              className="form-control" 
								              placeholder="Enter email" 
								              id="email" />
								  
								        	<div className="error_msg">{this.state.errors.email}</div>
											</div>

											<a className="signinLink" href="javascript:void(0)" onClick={this.backToSignin} >Already a member ? Signin now</a>

											{this.state.mailSend ? <p className={" forgot_message " + (this.props.statusCodeMail === 1 ? 'green_text' : 'red_text')}> {this.state.statusMail}</p> : ""}

											<button type="submit" className={"btn btn-primary signin_bttn " + (this.state.dissabledMail ? 'disabled_button': "")}>Send mail</button>

										</div>
									</div> }


								  {!this.state.forgotLink ? <button type="submit" className="btn btn-primary signin_bttn">Sign In</button> : ""}
								</form>
							</div>
						</div>
					



					</div>
					<div className="left_textarea">
						<div className="leftbar_img">
							
						</div>
					</div>
				</div> :
				<div className="not_login"> 
					<div className="left_textarea">
						<div>
							<h1>Welcome back !!</h1>
							<p>To keep connected with us please login with your personal details.</p>
							<a onClick={this.signUpToggle} className="signup_bttn">Sign In</a>
						</div>
					</div>
					<div className="right_formarea">
						<div>
							<h1>Create Account</h1>
							
							<div className="login_form">
								<form onSubmit={this.handleSubmit}>
									<div className="form-group">
									  	<i className="fa fa-user" aria-hidden="true"></i>
									    <input 
					              type="text" 
					              name="username" 
					              value={this.state.input.username}
					              onChange={this.handleChange}
					              className="form-control" 
					              placeholder="Enter username" 
					              id="username" />
					  
					              <div className="error_msg">{this.state.errors.username}</div>
									</div>
									<div className="form-group">
									  	<i className="fa fa-envelope-o" aria-hidden="true"></i>
									    <input 
					              type="text" 
					              name="email" 
					              value={this.state.input.email}
					              onChange={this.handleChange}
					              className="form-control" 
					              placeholder="Enter email" 
					              id="email" />
					  
					            <div className="error_msg">{this.state.errors.email}</div>
									</div>
									<div className="form-group">
									  	<i className="fa fa-lock" aria-hidden="true"></i>
									    <input 
					              type="password" 
					              name="password" 
					              value={this.state.input.password}
					              onChange={this.handleChange}
					              className="form-control" 
					              placeholder="Enter password" 
					              id="password" />
					  
					            <div className="error_msg">{this.state.errors.password}</div>
									</div>
									<div className={"signup_message " + (this.props.signupStatus === 1 ? 'green_text' : 'red_text')}>
										<p>{this.props.signupMessage}</p>
									</div>
									<button type="submit" className="btn btn-primary signin_bttn">Sign Up</button>
								</form>
							</div>
						</div>
					</div>
					
				</div> } 

				
			</div>

			
		)
	}
}

const mapStateToProps = state => {
    return{
        signupMessage: state.graphReducer.signupMessage,
        signupStatus: state.graphReducer.signupStatus,
        signinMessage: state.graphReducer.signinMessage,
        signinStatus: state.graphReducer.signinStatus,
        statusMail: state.graphReducer.statusMail,
        statusCodeMail: state.graphReducer.statusCodeMail,
        hashMail: state.graphReducer.hashMail,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSignUp : (signUpParams,email) => dispatch(dataActions.signUpUser(signUpParams,email)),
        onSignIn : (signInParams,email,password) => dispatch(dataActions.signInUser(signInParams,email,password)),
        forgotPassword : (forgotParams) => dispatch(dataActions.forgotPassword(forgotParams)),
        
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SignUp);