import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(id, name, contribution, percentage,portfolio ,sector ,temperature) {
  return { id, name, contribution, percentage ,portfolio ,sector ,temperature };
}


const rows = [
  /*createData( 159, 6.0, 24, 4.0,1,1,1 ),
  createData( 237, 9.0, 37, 4.3,1,1,1 ),
  createData( 262, 16.0, 24, 6.0,1,1,1 ),
  createData( 305, 3.7, 67, 4.3,1,1,1 ),
  createData( 356, 16.0, 49, 3.9,1,1,1 ),*/
];

 
export default function PactaTableData(props) {

 
  
  const classes = useStyles();
  
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Key assumptions</TableCell>
            <TableCell align="center">Temperature rise</TableCell>
            <TableCell align="center">Year of impact</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
         
            <TableRow key='1'>
              <TableCell align="center">Scenario A</TableCell>
              <TableCell align="center">Sudden disorderly transition (Minsky moment) that follows from rapid global action and policies</TableCell>
              <TableCell align="center">Below 2° C by 2100</TableCell>
              <TableCell align="center">2022</TableCell>
            </TableRow>
            <TableRow key='2'>
              <TableCell align="center">Scenario B</TableCell>
              <TableCell align="center">Long-term orderly transition that is broadly in line with the Paris Agreement</TableCell>
              <TableCell align="center">Well below 2°C by 2100</TableCell>
              <TableCell align="center">2050</TableCell>
            </TableRow> 
            <TableRow key='3'>
              <TableCell align="center">Scenario C</TableCell>
              <TableCell align="center">No transition and a continuation of current policy trends</TableCell>
              <TableCell align="center">Exceeding 4°C by 2100</TableCell>
              <TableCell align="center">2100</TableCell>
            </TableRow> 
            
         
        </TableBody>
      </Table> 
    </TableContainer>
  );
}