export default [
 {
    title: 'Oil Prices and S&P 500: Which One is in Drivers Seat?',
    summary: 'There is a huge similarity in Oil Price Movement and S&P 500 as the dip, recovery and volatility are virtually the same for both. While the drivers are so different for Oil and US markets, there is evidence that while Fed’s actions may not directly help the Oil demand, Fed action made oil markets more stable and helped prices recover. We explore this on the basis of EMAlpha Oil News Sentiment.',
    category: 'Markets and Macro',
    date: '17-Jun-20',
 },
 {
    title: 'Oil Sentiment has Conflicting Signals from IEA and Saudi Aramco Stock Price',
   summary: 'EMAlpha Oil News Sentiment is having its ‘break out’ moment and this change is telling us that we are past the worst of Coronavirus crisis and things will get better from now on for Oil. We looked at other possible signals on what may happen next and the most interesting were two data points which are giving us conflicting view. The first is Saudi Aramco Stock price and the second data point is International Energy Agency (IEA) forecast. What do they tell us and how to read it?',
   category: 'Markets and Macro',
   date: '16-May-20',
 },
 {
    title: 'Are Central Banks Encouraging Risk Taking Behavior in Speculators…Oops…Investors',
    summary: 'EMAlpha Sentiment Analysis based on News flow indicates that the signalling mechanism by Central Banks is a very powerful tool to change the risk perception by market participants. When a Central Banker is telling investors that it is OK to indulge in high risk investments, investors become more adventurous. This has been a driving force for markets as picked in sentiment on commodities and country by country sentiment analysis. How much risk an asset class carries is more dependent on perception EMAlpha analysis confirms.',
    category: 'Markets and Macro',
    date: '1-May-20',
 },
 {
    title: '‘News Sentiment Impact’ and Its Influence on Market Movement',
    summary: 'For US economy and market indices like S&P500, the signals from fundamentals of economy and what Fed is doing are pulling the financial markets in US in opposite directions. From the market reaction to datapoints like jobless claims, can we deduce that the Fed action is in the price already while fundamentals could get worse and that will matter more for the markets. EMAlpha News flow sentiment on specific topics and from multiple countries is reflecting interesting linkages between Sentiment Analysis and Market movement.',
    category: 'Markets and Macro',
    date: '30-Apr-20',
 },
 {
    title: 'Oil Sentiment has Already Hit Rock Bottom, But Will it Plunge even More?',
    summary: 'While the drop in Oil prices is putting huge pressure on producers, they can’t easily agree on who needs to cut how much of production. The drop in demand and low crude prices actually made some of them more desperate in their attempts to gain market share. This is also visible on EMAlpha Oil News Sentiment tracker. While the market movements are related to specific reasons, the general sentiment on the demand side is extremely negative. EMAlpha Oil News Sentiment is at the lowest levels after it has hit an all-time low on April 20th. Because of this commodity’s strong linkage with global economy, will this have implications for financial markets worldwide?',
    category: 'Markets and Macro',
    date: '27-Apr-20',
 },
 {
    title: 'Oil’s Historic Fall: Precipitated by Quickly Worsened Sentiment amid Demand Concerns',
    summary: 'As May WTI Futures traded in Negative, the historic fall in crude oil prices prompted us to investigate this issue further on the lines of how EMAlpha Oil news sentiment fared around that time. After Coronavirus pandemic has become a global issue, the market performance in several countries is strongly linked with local news flow and underlying sentiment on Coronavirus related developments. This applies to Oil too as the EMAlpha Oil News sentiment plot shows how the oil sentiment has fallen to a level lower than what it was in mid-March when the Saudi-Russia talks broke down and oil price and stock market fell.',
    category: 'Markets and Macro',
    date: '22-Apr-20',
 },
 {
    title: 'Market and Coronavirus Pandemic News: Shifting Pendulum Between Optimism and Pessimism',
    summary: 'On EMAlpha Global Coronavirus news sentiment and country-by-country Coronavirus sentiment, things continue to deteriorate. Most countries are struggling to contain infections and the global Coronavirus sentiment has deteriorated sharply. There are interesting takeaways from EMAlpha News Topic Sentiment for Key Words such as Donald Trump and Chinese Economy, Crude Oil News Sentiment and Aggregate India Equity Markets Sentiment.',
    category: 'Markets and Macro',
    date: '20-Apr-20',
 },
 {
    title: 'Fed Making Fundamentals Irrelevant For Markets - Saving From Disaster or Creating a Bigger One?',
    summary: 'The Coronavirus pandemic has made the US Fed extremely active and this has led to a dichotomy. The market is telling you that it is focusing less on fundamentals and more on Fed. A look at the S&P 500 chart makes it clear the link between Fed Action, Impact of Coronavirus on Fundamentals of US Economy and the direction of Markets. There will always be questions but at least for the time being, the markets are telling you that what Fed is doing, is working.',
    category: 'Markets and Macro',
    date: '9-Apr-20',
 },
 {
    title: 'Why Local News based Sentiment Analysis Matters and Do Machines Can See the Difference?',
    summary: 'When machines pick news on same subject in multiple languages for the same country, the result can be significantly different. EMAlpha has noted such divergence for many countries including China, Japan and Turkey and when news flow is different, sentiment analysis will not be the same. For Emerging Markets, EMAlpha experience with news collection and Sentiment Analysis for different countries in different languages confirms that English news and translated (from local language to English) are qualitatively and quantitatively different.',
    category: 'Markets and Macro',
    date: '8-Apr-20',
 },
 {
    title: 'News Sentiment on Coronavrius Pandemic: Darkest Before the Dawn or No Light at End of the Tunnel?',
    summary: 'Over the last few days, the EMAlpha Coronavirus News Sentiment has deteriorated in eleven out of the twelve countries in our main panel (USA, China, Germany, Brazil, Italy, India, Mexico, Malaysia, Norway, Colombia, Australia and Poland). More importantly, out of the eleven countries in which sentiment has turned negative, more than half (total six) see rather sharp decline. This doesn’t inspire much confidence that we are anywhere close to recovery. EMAlpha Sentiment analysis for some key words such as Donald Trump, Fed Rate, Nasdaq Composite, Chinese Economy and the US Economy, also throw interesting conclusions.',
    category: 'Markets and Macro',
    date: '6-Apr-20',
 },
 {
    title: 'For Global Economy and Emerging Markets, How Much Better News Sentiment on US Helps',
    summary: 'There have been interesting takeaways from the EMAlpha sentiment analysis for news flow around some very important and immensely popular key words. These news topics which matter for US Markets and hence, are important for other global markets can also capture the market direction effectively. EMAlpha machines have picked up some improvement in sentiment for US economy and that gels well with the improvement in sentiment for Dow Jones Index and NASDAQ composite both.',
    category: 'Markets and Macro',
    date: '25-Mar-20',
 },
 {
    title: 'Human Irrationality, Threat Perception and Coronavirus Pandemic',
    summary: "As reflected in the EMAlpha News Sentiment and reaction of Global Markets to Coronavirus threat, it is easy to see why sentiment is often much more powerful than facts alone. Over the years, many philosophers, psychologists, authors and scientists have spoken about this phenomenon where the impact of news flow of certain events immediately triggers a 'survival reaction' or 'fight or flight' response. In many of these areas, one of the most elegant explanations and lucid examples backed by years of research and experiments with large sample size have come from Daniel Kahneman. This insight explores some of these snippets.",
    category: 'Markets and Macro',
    date: '4-Mar-20',
 },
 {
    title: 'Coronavirus and Markets: Sentiment Bigger Driver than Fundamentals in Near-Term',
    summary: 'As the news sentiment has evolved leading to the market gyrations because of Coronavirus pandemic, EMAlpha is tracking the overall market sentiment on a daily basis. While the way forward does not look good, sentiment will clearly be a bigger driver for markets than fundamentals in the near term. In this insight, we also take a look at some of the important questions such as how are hedge fund strategies doing against this backdrop and how has the EMAlpha long/short equity strategy done?',
    category: 'Markets and Macro',
    date: '27-Feb-20',
 },
  {
    title: 'Investment Management - The Final Frontier for Machines',
   summary: 'The adoption rate of new technology is dependent on several factors but the important thing is that the new age machines are not just taking over the work which used to be done by humans in the financial markets, they are also opening new avenues and approaches for investment management. EMAlpha’s proprietary model which generates sentiment scores using our dictionary and the trades executed on the basis of those sentiment scores are beating markets and this tells us that there is need for more sophisticated data analytics, more advanced algorithms and use of Artificial Intelligence for generating superior investment returns.',
   category: 'Markets and Macro',
   date: '6-Oct-19',
 },
 {
    title: 'Fed Rate Cut: Words Speak Louder than Actions and the Importance of Signalling Mechanism ',
    summary: 'The conventional wisdom says that it is the actions which matter more than words. However, the last day of July 2019 was different from that perspective. It was a big event as the Federal Reserve cut interest rates for the first time in more than a decade. However, the market reaction to the rate cut was still unexpected as if the rate cut didn’t happen at all. Fed’s first rate cut since it slashed rates to near zero in 2008 disappointed the markets because Fed Chairman didn’t make any attempt whatsoever to signal the beginning of an aggressive rate-cutting campaign. A Fed Rate Cut which is first in more than ten years and the market reaction which followed was clearly the case when Fed’s words spoke louder than its actions instead of Fed’s actions speaking louder than its words.',
    category: 'Markets and Macro',
    date: '2-Aug-19',
 },
 {
    title: 'India’s Central Bank Suffers Another High Profile Exit and The Importance of Strong and Independent Institutions',
    summary: 'Reserve Bank of India (RBI) is one of the oldest monetary institutions in the World and it is much respected as well. Usually, RBI Deputy Governors tend to be away from media glare, but Dr Viral Acharya was different. He was vocal and his statements were often controversial which media could not always ignore. Dr. Acharya, who quit as Reserve Bank of India deputy governor on 24th June, is joining the long list of high profile exits caused by difference of opinion between institutions and ruling dispensation in last few years. These controversies are totally unnecessary because these events reflect very poorly on India’s governance standards and how much respect the government shows to a dissenting opinion.',
    category: 'Markets and Macro',
    date: '26-Jun-19',
 },
 {
    title: 'India and Economic Policy Making: Do Election Results Really Matter?',
    summary: 'In Emerging Markets, political stability and continuity of Government policy is very important for the direction of indices. In this respect, India has been relatively stable over years with democracy taking deep roots and despite changes in political parties which enjoyed power, the Government policy has remained largely consistent and broad contours of economic policies over last three decades have changed for better. Does this mean that elections are irrelevant and unnecessary distraction for markets? This is what we explore in this insight.',
    category: 'Markets and Macro',
    date: '7-Apr-19',
 },
];  
