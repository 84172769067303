import React, { Component } from 'react';
import Sidebar from '../Signin/Sidebar';



class mainTabs extends Component {

	constructor(props){
    super(props);

    this.state = {
    	insightTabs: true,
    	
    }
    /*this.handleChange = this.handleChange.bind(this);*/
	    
	}

	componentDidMount(){
		
		if(parseInt(sessionStorage.getItem('signinStatus')) === 1){
			
			this.setState({
		      tabpage:true
		    });
		    //window.location.href = "/analytics";
		}else if(parseInt(sessionStorage.getItem('signinStatus')) === 0){
			
				this.setState({
		      tabpage:false
		    });
		     window.location.href = "/signin";
		}
		else{
			
			this.setState({
		      tabpage:false
		    });
		     window.location.href = "/signin";
		}

		if(sessionStorage.getItem('accessToken') === "All"){
			this.setState({
				insightTabs: false
			})
		}else{
			this.setState({
				insightTabs: true
			})
		}

	}
	
  

  componentDidUpdate(prevProps, prevState) {
  	
  	
  }
  
  


	
	render() {
		// console.log('this.state.insightTabstttttttttt',this.state.insightTabs)
		return (
			<div className="main_container">
					<div className="main_tabs">
						<div className="signup_main">
							<div className="container page_tabs">
								<Sidebar insightTabs={this.state.insightTabs} />
							</div> 
						</div>
					</div>
			</div>

			
		)
	}
}


export default mainTabs;