export default [
  {
     title: 'Does Economic Revival from Coronavirus Pandemic Depend on Recklessness in Human Behavior? ',
     summary: 'There is evidence that the economic recovery from an unforeseen event is dependent on how quickly people will get back to normal. This EMAlpha insight takes a look at how the ‘speed of revival’ from the effects of Coronavirus pandemic depends on how ‘risk-taking’ people are. Less ‘risk averse’ people are, faster the recovery would be. The difference between UK and Continental Europe are recovering at a different pace mainly because of difference in threat perception while the on-ground statistics are alomost same on the impact of Coronavirus pandemic.',
     category: 'Behavioral Finance',
     date: '13-Aug-20',
  },
  {
    title: 'Do the Global Financial Markets Care About COVID-19 Numbers Anymore?',
     summary: 'March 2020 was tumultuous for global markets as Coronavirus pandemic led to a huge crash, it required a strong monetary policy response from Central Banks to avert the crisis. But the question now is: Is Coronavirus pandemic still the driving force for the markets? Or the markets have moved on? This is the question we explore here on the basis of what EMAlpha machines have picked up.',
     category: 'Behavioral Finance',
     date: '21-Jun-20',
  },
  {
    title: 'Did World Media Underestimate Coronavirus Crisis in Latin America?',
    summary: 'EMAlpha analysis of news and underlying sentiment indicates that news coverage on Coronavirus is partial. This directly impacts sentiment on threat perception and how serious the crisis will appear in different parts of the world. The contrast in EMAlpha News Sentiment between Europe and the United States versus South America speaks a lot about supremacy of underlying news sentiment over actual facts. This shapes several factors including the local government’s response, foreign aids to deal with the crisis and global sympathy for affected countries.',
    category: 'Behavioral Finance',
    date: '14-May-20',
 },
  {
    title: 'Coronavirus Threat and Lockdowns: Which Countries can Afford Them and for How Long?',
   summary: 'An analysis of the country-by-country EMAlpha New Sentiment shows that the Govt’s stance to combat Coronavirus pandemic is dependent on threat sentiment. When we analyse country sentiment scores for our news collection, we find that there are significant differences in the tone and frequency of Coronavirus related news flow. Some of the countries which are still nowhere close to the disastrous impact some others have seen, are still more scared. In reality, a country’s ability to afford a lock down depend on its economy, both size as well as wealth distribution over the population. However, Govt policy on lock downs will be determined more by threat perception than the ability to afford it.',
   category: 'Behavioral Finance',
   date: '10-May-20',
 },
 {
    title: 'Market and Coronavirus Pandemic News: Shifting Pendulum Between Optimism and Pessimism',
    summary: 'On EMAlpha Global Coronavirus news sentiment and country-by-country Coronavirus sentiment, things continue to deteriorate. Most countries are struggling to contain infections and the global Coronavirus sentiment has deteriorated sharply. There are interesting takeaways from EMAlpha News Topic Sentiment for Key Words such as Donald Trump and Chinese Economy, Crude Oil News Sentiment and Aggregate India Equity Markets Sentiment.',
    category: 'Behavioral Finance',
    date: '20-Apr-20',
 },
 {
    title: 'Coronavrius News Sentiment vs. Aggregate Market Sentiment and the Importance of Base Rate',
    summary: 'EMAlpha’s Aggregate Market Sentiment has a base rate which is above 0. This makes sense because the usual market sentiment has a positive bias and hence, the change in market sentiment should be measured as compared to this base rate. EMAlpha Sentiment Analysis tells us that the absolute values don’t matter that much and only how far is the point in either direction from the base rate, matters. The same things apply to languages, books, movies and almost everything where we can do Sentiment Analysis. This has important implications for how to read the signals and what to deduce from Sentiment Analysis.',
    category: 'Behavioral Finance',
    date: '1-Apr-20',
 },
 {
    title: 'Coronavirus Pandemic, News Sentiment and Where the Markets Are Going?',
    summary: 'The EMAlpha Emerging Markets model portfolio performance, has delivered significantly better returns versus the market index recently. And it is easy to see the reason. In the recent Coronavirus pandemic driven reaction of Global Financial Markets, Sentiment is a dominant driver of investor behaviour. How does EMAlpha do Sentiment Anlsysis on the news flow? EMAlpha machines are collecting Coronavirus related targeted news flow in a variety of languages. For a number of emerging market countries, most of the news flow is in local language and, hence, we focus on news collection in both the local language and in English. We then use natural language processing tools to extract the sentiment for each country. The details and takeaways are discussed in this insight.',
    category: 'Behavioral Finance',
    date: '23-Mar-20',
 },
 {
    title: 'Human Psychology, High Profile Covid-19 Cases and the Impact on Sentiment',
    summary: 'While huge fluctuations in EMAlpha Coronavirus sentiment continue for different countries, this news flow sentiment is driven by what kind of news items are getting more coverage. For example, the EMAlpha Coronavirus news flow sentiment for Canada has deteriorated sharply and this is because of a high-profile case of infection (Prime Minister’s wife). In USA, the huge volatility noticed in the markets is linked with rapid deterioration on one side and the policy announcements on the other. Similarly, there are interesting trends from other countries as well.',
    category: 'Behavioral Finance',
    date: '15-Mar-20',
 },
 {
    title: 'Human Irrationality, Threat Perception and Coronavirus Pandemic',
    summary: "As reflected in the EMAlpha News Sentiment and reaction of Global Markets to Coronavirus threat, it is easy to see why sentiment is often much more powerful than facts alone. Over the years, many philosophers, psychologists, authors and scientists have spoken about this phenomenon where the impact of news flow of certain events immediately triggers a 'survival reaction' or 'fight or flight' response. In many of these areas, one of the most elegant explanations and lucid examples backed by years of research and experiments with large sample size have come from Daniel Kahneman. This insight explores some of these snippets.",
    category: 'Behavioral Finance',
    date: '4-Mar-20',
 },
 {
    title: 'Coronavirus Pandemic and How Sentiment is Impacting the Emerging Markets?',
    summary: 'The impact on the global markets from Coronavirus pandemic also seems to have a local component as Asian markets close to China have suffered more than others. Geographically distant markets have remained shielded. When we look at how EMAlpha Sentiment Score has evolved over the last month for some of these emerging countries and the performance of their equity markets, the maximum market impact has been in smaller neighboring countries, like Thailand.',
    category: 'Behavioral Finance',
    date: '9-Feb-20',
 },
 {
    title: 'Coronavirus News Sentiment: How much is NOISE in the SIGNAL?',
    summary: 'Though Coronavirus is a global health emergency and there is no proven cure yet in sight, the media coverage is still disproportionate considering the tangible impact estimated as of now. In the age of rapid information dissemination and huge role played by social media, this skew is even more prominent. Naturally, financial markets also react more to these events. Whether the impact of Coronavirus is NOISE or SIGNAL, the reaction of financial markets proves ‘News may be the same, but the impact could be vastly different because of the varied nature of Sentiment’. EMAlpha tracks this news flow and the underlying sentiment.',
    category: 'Behavioral Finance',
    date: '9-Feb-20',
 },
 {
    title: 'Recession is trending, Could Peoples Worries Bring Bad News For the Economy?',
    summary: 'Trends are so powerful that often they become self-fulfilling prophecies. When we look at what people are talking about, what they are feeling worried about and what their concerns are, it gives us some idea about what future may look like. The chart showing the frequency of google search for "recession" from 2004 onward tells us that it has hit the 2008-2009 levels recently. The number of people searching for the word ‘recession’ remains at a higher level than observed in the past ten years and this is a significant change because such spikes could actually precede the real event.',
    category: 'Behavioral Finance',
    date: '25-Oct-19',
 },
 {
    title: 'Will Huge Cut in Corporate Tax Rate Revive Investor Interest in India?',
    summary: 'The massive corporate tax rate cut in India is a historic step and a massive comforting factor for the industry that the Government is listening to the signals from the ground. However, earnings growth is a challenge for Indian companies and this step in the form of a tax rate cut for companies is an artificial one-time measure. It doesnt do anything to address the core problem of slack in demand. The expectation that the investment cycle will pick up is unrealistic. Having more money is one thing but the corporate will not invest unless they are hopeful on demand.',
    category: 'Behavioral Finance',
    date: '22-Sep-19',
 },
 {
    title: 'Fed Rate Cut: Words Speak Louder than Actions and the Importance of Signalling Mechanism ',
    summary: 'The conventional wisdom says that it is the actions which matter more than words. However, the last day of July 2019 was different from that perspective. It was a big event as the Federal Reserve cut interest rates for the first time in more than a decade. However, the market reaction to the rate cut was still unexpected as if the rate cut didn’t happen at all. Fed’s first rate cut since it slashed rates to near zero in 2008 disappointed the markets because Fed Chairman didn’t make any attempt whatsoever to signal the beginning of an aggressive rate-cutting campaign. A Fed Rate Cut which is first in more than ten years and the market reaction which followed was clearly the case when Fed’s words spoke louder than its actions instead of Fed’s actions speaking louder than its words.',
    category: 'Behavioral Finance',
    date: '2-Aug-19',
 },
 {
    title: 'Emerging Markets: Increasing Domestic Retail Participation and Declinging Role for Foreign Investors',
    summary: 'Historically, Foreign Institutional Investors (FIIs) have been a major driving force in Emerging Markets (EMs). FIIs may not always outperform but they have two significant advantages vs. domestic investors, a) they can look at other comparable markets and this is not just in EM space and other markets globally but they can also look at other asset classes and then decide how attractive equities in a particular EM look like, b) the FIIs track news flow carefully and diligently but they don’t get too exuberant or excessively pessimistic unnecessarily and they will approach an EM with more equanimity.  But, do FIIs remain equally important at all times and this is what the insight takes a look at.',
    category: 'Behavioral Finance',
    date: '22-Jul-19',
 },
 {
    title: 'Human Behavior and Emerging Markets: The Case of Cricket World Cup and Indians Not Buying Televisions',
    summary: 'The thing about demographic dividend is that everyone will expect that more people with higher purchasing power will continue to drive consumption up. And it comes as a surprise when the companies have started to talk about slowdown. But that is the new reality in India and investor sentiment is seriously affected. The poor sales of televisions in the midst of a Cricket World Cup is an interesting example. The way retail sales of physical stores got impacted because of online purchases, is Television staring at the same fate and what it means?',
    category: 'Behavioral Finance',
    date: '10-Jul-19',
 },
 {
    title: 'India Automobiles Sales in Slow Lane and How Robust is the Emerging Market Consumption Story',
    summary: 'The news flow for Indian Auto Industry is turning from bad to worse over previous many months and over last one year and a little more, there have been multiple reports highlighting factors like poor sales numbers and liquidity crunch impacting sales of vehicles. The bigger question is related to consumer demand. One way to look at this is that there may not be any huge structural challenges for discretionary demand in India and challenges related with consumer demand may only be temporary. However, discretionary demand is relatively safer in EMs but we need to keep an eye on the data points.',
    category: 'Behavioral Finance',
    date: '6-Jul-19',
 },
];  
