export default [
 {
    title: 'Sentiment Analysis and Its Role In Investing: Now and In The Future',
    summary: 'When the time scale is short, the sentiment can matter more than the facts. In stock markets when the reaction time needs to be quick and the trade decision has to be made immediately after the event, the fund manager lacks the luxury to do it at leisure. The sentiment, i.e. the first impression, can matter more in this case and the price impact can be seen very quickly after a good or bad event. Since even a small change in sentiment can quickly become big, Sentiment analysis and discovery of news which can move markets is useful for market participants.',
    category: 'Fixed Income',
    date: '12-Oct-19',
 },
 {
    title: 'Investment Management - The Final Frontier for Machines',
   summary: 'The adoption rate of new technology is dependent on several factors but the important thing is that the new age machines are not just taking over the work which used to be done by humans in the financial markets, they are also opening new avenues and approaches for investment management. EMAlpha’s proprietary model which generates sentiment scores using our dictionary and the trades executed on the basis of those sentiment scores are beating markets and this tells us that there is need for more sophisticated data analytics, more advanced algorithms and use of Artificial Intelligence for generating superior investment returns.',
   category: 'Fixed Income',
   date: '6-Oct-19',
 },
 {
    title: 'Fed Rate Cut: Words Speak Louder than Actions and the Importance of Signalling Mechanism ',
    summary: 'The conventional wisdom says that it is the actions which matter more than words. However, the last day of July 2019 was different from that perspective. It was a big event as the Federal Reserve cut interest rates for the first time in more than a decade. However, the market reaction to the rate cut was still unexpected as if the rate cut didn’t happen at all. Fed’s first rate cut since it slashed rates to near zero in 2008 disappointed the markets because Fed Chairman didn’t make any attempt whatsoever to signal the beginning of an aggressive rate-cutting campaign. A Fed Rate Cut which is first in more than ten years and the market reaction which followed was clearly the case when Fed’s words spoke louder than its actions instead of Fed’s actions speaking louder than its words.',
    category: 'Fixed Income',
    date: '2-Aug-19',
 },
{
    title: 'Fed Rate Cut Will Help, But Policy Makers in Emerging Markets Need to Do More',
    summary: 'It may appear from the immediate market reaction that a rate cut by Fed will help the EMs. But, this is an illusion. Investor sentiment is impacted as a result of several factors and that is a key thing to focus on in the longer term because fundamental factors will continue to be more important for EMs and that is something the news analysis based sentiment gauge as of now is not really able to capture completely.',
    category: 'Fixed Income',
    date: '12-Jul-19',
 },
];  
