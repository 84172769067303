import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;
let fullDatarow= [];
let esgScoresData= [];
let stockNames= [];

class StackedChart extends Component {
	constructor() {
		super();
		
		this.state = {
	    	noData: true,
	    	nodataValue: 'No portfolio selected',
	    	Upload_hash: "0a29755431f84363a31c090b87b2d025"
	    	
	    }
	}

	componentDidUpdate(prevProps, prevState) {

		if(this.props.Upload_hash !== prevProps.Upload_hash){
			if(this.props.esgStatusCode === 1){
				//console.log('ininin')
				this.props.onGoClicked(false);
				this.setState({
					noData: false,
					Upload_hash: this.props.Upload_hashESG
				})
			}else{
				//console.log('6666')
				this.props.onGoClicked(false);
				this.setState({
					noData: true,
					nodataValue: 'No data found',
					Upload_hash: this.props.Upload_hashESG
				})
			}
		}


		
	}
	
	render() {
		fullDatarow = [];
		esgScoresData = [];
		stockNames= [];
		//console.log('this.props.esgScoresDataaaaaaaaaaaaa',this.props.esgScoresData)
		if(this.props.final_pct){
			this.props.final_pct.map((row , index )=> {
				esgScoresData.push({
				  label: row.stock_full_name,
				  y: row.pct_rank,
				});
						
			});
			fullDatarow.push({
				type: "column",
				legendText: "{label}",
				toolTipContent: "{label}: <strong>{y}</strong>",
				indexLabel: "{y}",
				dataPoints: esgScoresData

			})
			
		}



		const options = {
			title: {
				fontFamily: "verdana"
			},
			
			axisY: {
				title: "Percentile",
				interval: 25,
				includeZero: true,
				maximum: 110,
			},

			toolTip: {
				//shared: true,
				//reversed: true
			},
			legend: {
				verticalAlign: "bottom",
				horizontalAlign: "center",
				//reversed: true,
				cursor: "pointer",
			},
			data : fullDatarow
			
		}
		return (
		<div>
			{!this.state.noData ? <CanvasJSChart options = {options} /> : <div className="noData">{this.state.nodataValue}</div> }
			
		</div>
		);
	}
}
export default StackedChart;      