import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

let fullDatarow = [];
let portfolioESGValues = [];
let colorShades = ['#6d78ad' , '#51cda0' , '#df7970' , '#4c9ca0' , '#ae7d99'];
let lableName= [];
let uniqueArr = [];
let indeNo = [];


class SectorStats extends Component {
	constructor(props){
	    super(props);

	    this.state = {
	    	noData: true,
	    	goClicked: false,
	    	sentimentsHashNo: '0a96bbd5feb2451e884e1c2e6d00d7c3',
	    	nodataValue: 'No Data Found',
	    	
	    }
  
 
	}

	componentDidUpdate(prevProps, prevState) {
	
		if(this.props.portfolioESGcode == 1){
			if(this.props.sectorControlData !== prevProps.sectorControlData){
				this.props.onGoClick(false);
			
				
				this.setState({
					noData:false,
					csvError:false,
					nodataValue: 'No portfolio selected',
				});
			}
			
		}
		else{
			if(this.props.portfolioESGhash !== prevProps.portfolioESGhash){
				this.props.onGoClick(false)
				this.setState({
					portfolioESGhash:this.props.sentimentsHashNo,
					noData:true,
					csvError:false,
					nodataValue: 'No data found',
				});
				if( this.props.portfolioESGerror === 'Wrong Header'){
					this.setState({
						csvError:true,
						nodataValue: 'No portfolio selected',
					});
				}
			}
		}

		
	}

	render() {
	

	fullDatarow = [];
	portfolioESGValues = [];
	lableName= [];
	
	if(this.props.sectorControlData){
		
		this.props.sectorControlData.map((elemants , index )=> {
		
			let items_row = [];
			portfolioESGValues = [];
			elemants.map((items,index)=> {
				
				items.map((item)=> {
				
					portfolioESGValues = [];
					item.data.map((ele) => {
						portfolioESGValues.push({
						  label: ele.Sector,
						  y: parseFloat(ele.Stats),
						 
						});
						
						lableName.push({
							name: ele.Sector_Bucket
						})
					})
					
				
					uniqueArr = [];
					indeNo= [];
					uniqueArr = lableName.map(item => item.name).filter((value, index, self) => self.indexOf(value) === index)
					
					uniqueArr.map(val => {
						
						let newVal = parseInt(val) -1 ;
						indeNo.push(newVal)
					})
				

					fullDatarow.push({
						type: "column",
						color: colorShades[index],
						toolTipContent:"{y} Returns",
						dataPoints: portfolioESGValues

					})
				
				})

			 })
			
			
		});

	}
	

	const options = {
		theme: "light2", // "light1", "dark1", "dark2"
		title:{
	      text: ""  
	     },
		axisX:{
		   title:"Sector",
		  
		   labelAngle: 0
		},
		
		axisY:[{
			title: "Return %",
			labelAngle: 0,
			
		}],
		data: fullDatarow
		
	} 
	
	

	return (
		<div>
			{this.state.csvError ? <div class="header_error">Headers are not correct in above CSV file.</div> : "" }
			{!this.state.noData ? <div>
				
				<div className="chart_wrapper"><CanvasJSChart options = {options}
					
				/>
				<div className="whiteStrip"></div></div>
				<div className="line_indicator">
					<ul>
						{uniqueArr.map((label,index) => {
							return <li key={index} >
								<span className="first_shape"></span>
								<span>Bucket {label}</span>
							</li>
						}) }
					</ul>
				</div>
			</div> : <div className="noData">{this.state.nodataValue}</div> }
		</div>
		);
	}
}
export default SectorStats;   