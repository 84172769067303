import React , {useEffect} from 'react'; 
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './Sidebar.css'
import RealtimeTab from '../RealtimeTab/RealtimeTab.js';
import CompanySentiments from '../CompanySentiments/CompanySentiments.js';
import ESGTab from '../ESGTab/ESGTab.js';
import ESGWorkspaceTab from '../ESGWorkspaceTab/ESGWorkspaceTab.js';
import InsightsSignin from '../InsightsSignin/InsightsSignin';
import EsgReporting from '../EsgReporting/EsgReporting';
import ClimateData from '../ClimateData/ClimateData';

 

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}




export default function Sidebar(props) {

	let Climate_Data = sessionStorage.getItem('Climate_Data')
	let Company_Data = sessionStorage.getItem('Company_Data')
	let ESG_Data = sessionStorage.getItem('ESG_Data')
	let ESG_Lens = sessionStorage.getItem('ESG_Lens')
	let ESG_Reporting = sessionStorage.getItem('ESG_Reporting')
	let Insights = sessionStorage.getItem('Insights')

	if(Climate_Data == "" || Climate_Data == null){
		console.log('ininnnnnnnnnnnnnnnnn')
	}
	
	
	const [value, setValue] = React.useState(0);
	const [tabOnedisabled, setTabOnedisabled] = React.useState(false);
	const [tabTwodisabled, setTabTwodisabled] = React.useState(false);
	const [tabThreedisabled, setTabThreedisabled] = React.useState(false);
	const [tabFourdisabled, setTabFourdisabled] = React.useState(false);
	const [tabFivedisabled, setTabFivedisabled] = React.useState(false);
	const [tabSixdisabled, setTabSixdisabled] = React.useState(false);
	const [RLtabdisabled, setRLTabdisabled] = React.useState(true);

	const handleChange = (event, newValue) => {
	    setValue(newValue);
	};

	useEffect(() => {
		 
		/*if(sessionStorage.getItem('accessToken') === "All"){
			setValue(0);
			setTabdisabled(false)
		}else{
			setValue(5);
			setTabdisabled(true)
		}*/

		if(Company_Data === "0"){
			setTabOnedisabled(true)
			
				//ESG_Data
				if(ESG_Data === "0"){
					setTabTwodisabled(true)
					//climate tab 
					if(Climate_Data === "0"){
						setTabThreedisabled(true)
						//ESG_Lens
						if(ESG_Lens === "0"){
							setTabFourdisabled(true)
							//ESG_Reporting
							if(ESG_Reporting === "0"){
								setTabFivedisabled(true)
								//Insights
								if(Insights === "0"){
									setValue(5);
									setTabSixdisabled(true)
								}else{
									setValue(5);
									setTabSixdisabled(false)
								}

							}else{
								setValue(4);
								setTabFivedisabled(false)
								
								//Insights
								if(Insights === "0"){
									setValue(4);
									setTabSixdisabled(true)
								}else{
									setValue(4);
									setTabSixdisabled(false)
								}
							}
						}else{
							setValue(3);
							setTabFourdisabled(false)


							//ESG_Reporting
							if(ESG_Reporting === "0"){
								setValue(3);
								setTabFivedisabled(true)
							}else{
								setValue(3);
								setTabFivedisabled(false)
							}

							//Insights
							if(Insights === "0"){
								setValue(3);
								setTabSixdisabled(true)
							}else{
								setValue(3);
								setTabSixdisabled(false)
							}


						}
					}else{
						setValue(2);
						setTabThreedisabled(false)

						//ESG_Lens
						if(ESG_Lens === "0"){
							setValue(2);
							setTabFourdisabled(true)
						}else{
							setValue(2);
							setTabFourdisabled(false)
						}

						//ESG_Reporting
						if(ESG_Reporting === "0"){
							setValue(2);
							setTabFivedisabled(true)
						}else{
							setValue(2);
							setTabFivedisabled(false)
						}

						//Insights
						if(Insights === "0"){
							setValue(2);
							setTabSixdisabled(true)
						}else{
							setValue(2);
							setTabSixdisabled(false)
						}


					}
				}else{
					setValue(1);
					setTabTwodisabled(false)


					//climate tab 
					if(Climate_Data === "0"){
						setValue(1);
						setTabThreedisabled(true)
					}else{
						setValue(1);
						setTabThreedisabled(false)
					}


					//ESG_Lens
					if(ESG_Lens === "0"){
						setValue(1);
						setTabFourdisabled(true)
					}else{
						setValue(1);
						setTabFourdisabled(false)
					}

					//ESG_Reporting
					if(ESG_Reporting === "0"){
						setValue(1);
						setTabFivedisabled(true)
					}else{
						setValue(1);
						setTabFivedisabled(false)
					}

					//Insights
					if(Insights === "0"){
						setValue(1);
						setTabSixdisabled(true)
					}else{
						setValue(1);
						setTabSixdisabled(false)
					}
				}
		
		}else{
			setValue(0);
			setTabOnedisabled(false)

			//ESG_Data
			if(ESG_Data === "0"){
				setValue(0);
				setTabTwodisabled(true)
			}else{
				setValue(0);
				setTabTwodisabled(false)
			}
			//climate tab 
			if(Climate_Data === "0"){
				setValue(0);
				setTabThreedisabled(true)
			}else{
				setValue(0);
				setTabThreedisabled(false)
			}


			//ESG_Lens
			if(ESG_Lens === "0"){
				setValue(0);
				setTabFourdisabled(true)
			}else{
				setValue(0);
				setTabFourdisabled(false)
			}

			//ESG_Reporting
			if(ESG_Reporting === "0"){
				setValue(0);
				setTabFivedisabled(true)
			}else{
				setValue(0);
				setTabFivedisabled(false)
			}

			//Insights
			if(Insights === "0"){
				setValue(0);
				setTabSixdisabled(true)
			}else{
				setValue(0);
				setTabSixdisabled(false)
			}
		}









		
	},[])
	
	
	return (
		<div className="main_sidebar">
			<div className="tabs_main">
				<AppBar position="static">
	        <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">
	          {/*<Tab label="Real Time Data" {...a11yProps(0)} disabled={RLtabdisabled}  />*/}
	          <Tab label="Company Data" {...a11yProps(0)} disabled={tabOnedisabled} />
	          <Tab label="ESG Data" {...a11yProps(1)} disabled={tabTwodisabled} />
	          <Tab label="Climate Data" {...a11yProps(2)} disabled={tabThreedisabled} />
	          <Tab label="ESG Lens" {...a11yProps(3)} disabled={tabFourdisabled} />
	          <Tab label="ESG Reporting" {...a11yProps(4)} disabled={tabFivedisabled}  />
	          <Tab label="Insights" {...a11yProps(5)} disabled={tabSixdisabled} />
	         
	        </Tabs>
      	</AppBar>
		  </div>
	    <div className="content_container">
      	{/*<TabPanel value={value} index={0}>
        	<div id="real_time" className="tab-content current ">
						<div className="main_innertabs">
							<RealtimeTab />
						</div>
					</div>
      	</TabPanel>*/}
	     	<TabPanel value={value} index={0} className="">
	        <div id="g10" className="tab-content">
	        		
						<CompanySentiments />
					</div>
	      </TabPanel>
	      <TabPanel value={value} index={1}>
	        <div id="geo_regions" className="tab-content">
						<ESGTab />
					</div>
	      </TabPanel>
	      	
	      <TabPanel value={value} index={2}>
	        <div id="climate_data" className="tab-content">
						<ClimateData />
					</div>
	      </TabPanel>

	     	<TabPanel value={value} index={3}>
	        <div id="download_data" className="tab-content">
						<ESGWorkspaceTab /> 
					</div>
	      </TabPanel>

	      <TabPanel value={value} index={4}>
	        <div id="help_refrence" className="tab-content">
						<EsgReporting /> 
					</div>
	      </TabPanel>

	      <TabPanel value={value} index={5}>
	        <div id="insightTab" className="tab-content">
						<InsightsSignin /> 
					</div>
	      </TabPanel>
	      	
	    </div>
	  </div>
	)
	
}

