import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

let fullDatarow = [];
let investmentValue = [];

class ContributionsPie extends Component {

	componentDidUpdate(prevProps,prevState){
		if(this.props.sbtirand_hash !== prevProps.sbtirand_hash){
			
		}
	}

	render() {
		fullDatarow = [];
		investmentValue= [];
		if(this.props.dataArray){
		
		
			let items_row = [];
			this.props.dataArray.map((row , index )=> {
				
				investmentValue.push({
				  label: row.sector_name,
				  y: (row.investment_value),
				});

				

			})
			items_row =  investmentValue;

			fullDatarow.push({
				type: "pie",
				showInLegend: true,
				legendText: "{label}",
				toolTipContent: "{label}: <strong>{y}%</strong>",
				indexLabel: "{y} %",
				indexLabelPlacement: "outside",
				dataPoints: items_row

			})
		}
		

		const options = {
			theme: "light",
			animationEnabled: true,
			
			title:{
				text: "Carbon Contribution",
				fontSize: 20,
			},
			data: fullDatarow
			/*data: [{
				type: "pie",
				showInLegend: true,
				legendText: "{label}",
				toolTipContent: "{label}: <strong>{y}%</strong>",
				indexLabel: "{y}%",
				indexLabelPlacement: "inside",
				dataPoints: [
					{ y: 40, label: "Health" },
					{ y: 22, label: "Finance" },
					{ y: 65, label: "Education" },
					{ y: 19, label: "Career" },
					{ y: 5, label: "Family" },
					{ y: 7, label: "Real Estate" }
				]
			}]*/
		}
		return (
		<div>
			<CanvasJSChart options = {options}
				/* onRef={ref => this.chart = ref} */
			/>
			
		</div>
		);
	}
}
export default ContributionsPie;       