import React, { Component } from 'react';
import image1 from '../../../assets/images/lang1.jpg';
import image2 from '../../../assets/images/lang2.jpg';
import image3 from '../../../assets/images/lang3.jpg';
import image4 from '../../../assets/images/lang4.jpg';
import './Products.css';

class Products extends Component {
	render() {
		return (
				<div className="main_product">
					<div className="ema_platform">
						<div className="container">
							<div className="">
								<div>
									<span className="sub_heading">Lorum ipsum</span>
									<h1 className="main_heading">EMAlpha Platform</h1>
									<p className="main_title"> Move with the speed of local experts. Discover market moving information previously hidden in local news and foreign languages. </p>
									<div className="blog_section">
										<div>
											<div className="platform_blog">
												<div>
													<h2>AI aided information discovery across multiple countries.</h2>
													<p>NLP methods remove language barriers to help with timely access to local information for multiple continents and countries. Local experts and AI team up to take down the barriers to emerging markets investing.</p>
												</div>
											</div>

											<div className="platform_blog">
												<div>
													<h2>Real time updates on market moving local developments</h2>
													<p>It can be hours or even days before important local language news makes its way to more mainstream news sources. Beat the wait, access foreign language information quickly to gain an edge and make investment decisions faster. </p>
												</div>
											</div>

											<div className="platform_blog">
												<div>
													<h2>Responsible investing and risk mitigation with ESG solutions</h2>
													<p>ESG is a major concern for many emerging markets investors. EMAlpha's proprietary AI aided ESG solutions were developed by experts with emerging markets in mind. Instead of being an afterthought, ESG is an integral part of this powerful approach to emerging markets investing.</p>
												</div>
											</div>

											<div className="platform_blog">
												<div>
													<h2>NLP aided Analytics & Investment Insights</h2>
													<p>EMAlpha's news and sentiment analytics tools combine with highly regarded local markets expert analysis to help investors track important developments. Deep insights and timely alerts help investment professionals make timely decisions.</p>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="ema_data">
						
						<div className="container">
							<div className="data_container">
								<span className="sub_heading">Lorum ipsum</span>
								<h1 className="main_heading">EMAlpha Data</h1>
								<p className="main_title dark_shade">Discover a world of data hidden by language barriers until now. Let AI and human experts decipher the intricacies.</p>
								<div className="data_blog">
									<div>
										<div className="single_data">
											<div>
												<div className="data_icon">
													<i class="fa fa-language" aria-hidden="true"></i>
												</div>
												<div className="data_text">
													<h2>Find novel alpha opportunities in Emerging Markets.</h2>
													<p>EMAlpha translates local language intelligence, separates noise from signal, and delivers actionable data. The focus is on quality over quantity. Instead of subjecting the user to a flood of mindless data, EMAlpha's solution provides actionable insights on items that matter.</p>
												</div>
											</div>
										</div>

										<div className="single_data">
											<div>
												<div className="data_icon">
													<i class="fa fa-line-chart" aria-hidden="true"></i>
												</div>
												<div className="data_text">
													<h2>Mutiple horizon data for different investment strategies.</h2>
													<p>We understand that different investors have different horizons of interest. A public markets investor and a private markets investor will act on very different times scales and care about different types of information. Accordingly, we have separated emerging markets news analytics and scores into different frequencies.</p>
												</div>
											</div>
										</div>

										<div className="single_data">
											<div>
												<div className="data_icon">
													<i class="fa fa-tachometer" aria-hidden="true"></i>
												</div>
												<div className="data_text">
													<h2>Track relevant market movers.</h2>
													<p>When it comes to emerging markets, the relevance of any given market development can appear confusing to an outside investor. Cultural differences and language barreirs can add to an investor's challenges. AI techniques can help solve some of these problems and help investors navigate these opaque markets.</p>
												</div>
											</div>
										</div>

										<div className="single_data">
											<div>
												<div className="data_icon">
													<i class="fa fa-modx" aria-hidden="true"></i>
												</div>
												<div className="data_text">
													<h2>Enhance risk and return profile with EMAlpha data solutions</h2>
													<p>EMAlpha maintains paper portfolios invested in emerging markets stocks. Out of sample of these model portfolios is available to interested clients. We believe in maintaining a proper out of sample record of our data solutions to help clients gain confidence in our products.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="ema_intelligence">
						<div className="container">
							<div>
								<span className="sub_heading">Lorum ipsum</span>
								<h1 className="main_heading">EMAlpha Local Language Intelligence Solutions</h1>
								<p className="main_title dark_shade">Powerful Custom solutions that fit the needs of a diverse clientele</p>
								
								<div className="main_wrapper">
									<div className="intelligence_sol">
										<div>
											<div className="text_right">
												<i class="fa fa-cogs" aria-hidden="true"></i>
												<h2>Use EMAlpha NLP tools as per your requirements</h2>
												<p>EMAlpha’s language and processing tools can be useful for a diverse set of clients including policy watchers, media and observers of markets and Emerging Market economies. Often in these markets, the authenticity of data is a major challenge and ‘filtered information’ leads to misconceptions about the real situation on ground, analysis of local news with EMAlpha tools is useful and value additive </p>
											</div>
										</div>
									</div>


									<div className="intelligence_sol">
										<div>
											<div className="text_right">
												<i class="fa fa-exclamation-circle" aria-hidden="true"></i>
												<h2>Early Warning System using EMAlpha’s local language solutions</h2>
												<p>You could be a company operating in some specific Emerging Markets or you could be a Private Equity firm running a portfolio of investments or you could also be a Consultant preparing a ‘Market Entry Strategy’ in these markets, there is usefulness of EMAlpha’s local language solutions which can identify red flags long before they become visible to others. An early warning system could help in stopping the irreversible damage to an enterprise.</p>
											</div>
										</div>
									</div>


									<div className="intelligence_sol">
										<div>
											<div className="text_right">
												<i class="fa fa-object-ungroup" aria-hidden="true"></i>

												<h2>Specialized NLP tools for emerging markets</h2>
												<p>EMAlpha leverages its many years of human expertise in emerging markets to develop NLP tools specifically relevant to decision makers and investment professionals looking at many of these countries. Our experts have painstakingly curated "seed" dictionaries for various emerging markets specific themes that are further enhanced by AI methods.</p>
											</div>
										</div>
									</div>

									<div className="intelligence_sol">
										<div>
											<div className="text_right">
												<i class="fa fa-random" aria-hidden="true"></i>
												<h2>Customized Foreign Language Solutions</h2>
												<p>Needs of different investors can be different. Some investors will be more focused on fast moving headline risk, others might be interested in a longer information cycle, yet another type of investor might want to monitor the gradual change in ESG scores. We can custom craft solutions suiting the need of a particular client.</p>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>

				</div>
		)
	}
}

export default Products;