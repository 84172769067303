import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let fullDatarow = [];
let indexSentimentsData = [];
let colorShades = ['#003f5c' , '#ffa600' , '#58508d' , '#bc5090' , '#ff6361'];
let stockNames= [];

class DailyIndexSentiments extends Component {
	constructor(props){
    super(props);

    this.state = {
    	noData: true,
    	
    }
    //this.handleChange = this.handleChange.bind(this);
 
	}
 
	
	componentDidUpdate(prevProps, prevState) {
		/*console.log('prevProps1',prevProps);
		console.log('prevState1',prevState);
		console.log('this.props.indexSentimentsData2222' , this.props.indexSentimentsData)*/
		// console.log('this.props.indexSentimentsData',this.props.indexSentimentsData)
		
		if(this.props.indexSentimentsData.StatusCode === 0){
			 
			if(this.props.indexSentimentsData.charts_hash !== prevProps.indexSentimentsData.charts_hash){
				
				this.setState({
					noData:true
				});
			}
		}else{
			// console.log('33333333 data');
			if(this.props.indexSentimentsData.charts_hash !== prevProps.indexSentimentsData.charts_hash){
				// console.log('4444444444 data');
				this.setState({
					noData:false
				});
			}
		}

		if(this.props.indexSentimentsData.charts_hash !== prevProps.indexSentimentsData.charts_hash){
				this.props.onGoClick(false);
			}
		
	}


	render() {
		
		fullDatarow = [];
		indexSentimentsData = [];
		stockNames= [];
		
		if(this.props.indexSentimentsData.ResponseData){
			this.props.indexSentimentsData.ResponseData.map((row , index )=> {
			
				let x,y;
				let items_row = [];
				row.data.map((item , index) => {
					
						indexSentimentsData.push({
						  x: new Date(item.date[2],item.date[1],item.date[0]),
						  y: parseFloat(item.stockValue),
						});
						items_row =  indexSentimentsData
				}) 
				
				stockNames.push({
					name: row.stockName
				})
				fullDatarow.push({
					type: "line",
					showInLegend: true ,
					name: "",
					markerType: "none",
					axisYIndex: 1,
					color: colorShades[index],
					toolTipContent:"{y}"+ row.stockName ,
					dataPoints: items_row

				})
			});
		}
		
	


	
	const options = {
		theme: "light2", 
		title:{
	      text: ""  
	     },
		axisX:{
		   title:"Date",
		   valueFormatString: "DD-MMM-YY",
		   labelAngle: -50
		},
		
		axisY:[{
			title: "Stocks",
			// interval: 0.2
		}],
		
		data: fullDatarow
	}

	

	return (
		<div>
			{!this.state.noData ? <div>
				<div className="chart_wrapper"><CanvasJSChart options = {options}
					/* onRef={ref => this.chart = ref} */
				/>
				<div className="whiteStrip"></div></div>
				<div className="line_indicator">
					<ul>
						{stockNames.map((stockName,index) => {
							return <li key={index} >
								<span className="first_shape"></span>
								<span>{stockName.name}</span>
							</li>
						}) }
					</ul>
				</div>
			</div> : <div className="noData">No Data Found</div> }


		</div>
		);
	}
}
export default DailyIndexSentiments;   