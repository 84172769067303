import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index'; 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'; 
import FormControl from '@material-ui/core/FormControl'; 
import Select from '@material-ui/core/Select'; 
import loader from '../../../assets/images/loader.gif';  
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import pdfimg from '../../../assets/images/lang2.jpg';
import {HOST_URL} from '../../../store/Constant';
import cat_name_mapping from'../../../assets/Category_name_mapping.json'


let dummyText =  ' <span class="pos Category_2 Category_1 selected_word">Coroutine</span> is just the very fancy term for the thing returned by an async def function . Python knows that it is something like a function that it can start and that it will end at some point , but that it might be paused internally too , whenever there is an await inside of it. </p><p> But all this  <span class="neg Category_1 selected_word">functionality</span> of using asynchronous code with async and await is many times summarized as using “coroutines” . It is comparable to the main key feature of Go , the “Goroutines” . <span class="pos Category_2 selected_word">&nbspaccess to education{.S.}&nbsp</span></p><p> Coroutine is just the very fancy term for the thing returned by an async def function . Python knows that it is something like a function that it can start and that it will end at some point , but that it might be paused internally too , whenever there is an await inside of it. </p><p> But all this  <span class="neg Category_1 selected_word">functionality</span> of using asynchronous code with async and await is many times summarized as using “coroutines” . It is comparable to the main key feature of Go , the “Goroutines” . <span class="pos Category_3  Category_1 selected_word">&nbspaccess to education{.S.}&nbsp</span></p><p>'

let userMail = '';
let dictionaryName = ['dummy.csv','dumy1.csv','dummy2.csv'];
let pagenoArr = [];

 
class ScoreText extends Component {

	constructor(props){
    super(props);

    this.state = {
    	uploadFile:'',
    	dictionaryUpload:'',
    	modelOpen: false,
    	dictionaryName:[],
    	deleteName: '',
      radioValue: 'textarea',
      textareaActive: true,
      textareaText:'',
      resultDictionary: false,
      submitValue: false,
      documentIdSubmitValue:false,
      dictionarySelect : false , 
      showingResults: false,
      scoreHash: '',
      EValue: 0.0,
      SValue: 0.0,
      GValue: 0.0,
      dictionary_file: '',
      inputText:'',
      errorMessageText: "",
      errorMessageFile:"",
      scoresAllValue:[],
      countFlag: 0,
      pagenoArr:[],
      disableNext:false,
      disablePrev: true,
      showImages: false,
      loaderError:"Please wait. The file is being processed.",
      intevalset: false,
      disableSubmit: true,
      textHtml: false,
      showRadios: false,
      pdfAPICheck: false,
      runOnlyText: false,
      selectedCategory: "",
      showSelectedName: false,

    }
    this.handleDictionaryUpload = this.handleDictionaryUpload.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeleteName = this.handleDeleteName.bind(this);
    this.handleRadioState = this.handleRadioState.bind(this);
    this.changeAPIOption = this.changeAPIOption.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.submitFormData = this.submitFormData.bind(this);
    this.changeText = this.changeText.bind(this);
    this.handleBackTo = this.handleBackTo.bind(this);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
    this.showAllCategory = this.showAllCategory.bind(this);
    this.changeDocumentId = this.changeDocumentId.bind(this);

    
	    
	}


	componentDidMount(){
  
		
    if( sessionStorage.getItem('signInEmail') === "" || sessionStorage.getItem('signInEmail') === null){
    	this.setState({
	      userEmail: sessionStorage.getItem('signUpEmail')
	    });
	    userMail = sessionStorage.getItem('signUpEmail')
    }else{
    	this.setState({
	      userEmail: sessionStorage.getItem('signInEmail')
	    });
	    userMail = sessionStorage.getItem('signInEmail')
    }


		let filenameParam = JSON.stringify({
      "mail_id":userMail
    });

    this.props.getDictionaryFilesName(filenameParam)


		
	}
	
 componentDidUpdate(prevProps, prevState) {
    let $this = this;

    let filenameParam = JSON.stringify({
      "mail_id":userMail
    });


    if(this.props.file_hash !== prevProps.file_hash){
      this.setState({
        file_hash: this.props.file_hash,
        dictionaryName : this.props.dictionaryNamesList
      })
      dictionaryName = this.props.dictionaryNamesList
    }
   
    if(this.props.dictionaryCode === 1){
      if(this.props.dictionaryHash !== prevProps.dictionaryHash){
        this.props.getDictionaryFilesName(filenameParam)
      }
    }
    if(this.props.scoreHash !== prevProps.scoreHash){
      if(this.props.scoreStatusCode === 1){
        this.setState({
          scoreHash: this.props.scoreHash,
          scoresAllValue : this.props.ResponseScores.all_scores,
          dictionary_file: this.props.ResponseScores.dictionary_file,
          inputText: this.props.ResponseScores.inputText,
          html_text: this.props.ResponseScores.html_text,
          resultDictionary: true,
          textHtml: this.props.textHtml
        });
        

      }else{
        this.setState({
          errorMessageFile: this.props.scoreStatus,
          showingResults: false,
          disableSubmit: true,
        });
      }
    }
   
    if(this.state.intevalset === true){

      let intervalValue =  setInterval(function(){
      // console.log('inini')
        if($this.state.showingResults) {
          // console.log('in set interval');
          $this.setState({
            loaderError: "File is processed , calculating scores.",
            intevalset: false,
          })
          clearInterval(intervalValue);
        }
      }, 15000);
    }

    
    

  } 

  handleDictionaryUpload (event){
  	
    this.setState({
      dictionaryUpload: event.target.value,
      dictionarySelect: true,
      disableSubmit: true,
    })
  };

  handleRemoveOption(e){
  
    this.setState({
    	deleteName:e.target.getAttribute("value")
    })
    this.handleOpen();
    e.stopPropagation();
    
  }

  handleOpen() {
   
    this.setState({
    	modelOpen:true,
    })
  };

  handleClose() {
   
    this.setState({
    	modelOpen:false,
    })
  };




  handleDeleteName(){
   
    dictionaryName = dictionaryName.filter(item => item !== this.state.deleteName);
    
    this.setState({
      dictionaryName: dictionaryName,
    });

    let deleteParams = JSON.stringify({
      "mail_id":userMail,
      "dictionary_file" : this.state.deleteName
    });

    /*console.log('dictionaryName',dictionaryName);*/
    this.props.deleteDictionary(deleteParams)
    this.handleClose();
  }

  handleRadioState (event) {
   
    this.setState({
      radioValue: event.target.value,
     
    })
    if(event.target.value === 'uploadFile'){
      this.setState({
        textareaActive: false,
        errorMessageText:"",
        showRadios:true,
      })

    }else if(event.target.value === 'textarea'){
      this.setState({
        textareaActive: true,
        errorMessageFile:"",
        showRadios:false,
        pdfAPICheck : false,
      })
    }
  };

  changeAPIOption(event){
    this.setState({
      pdfAPI: event.target.value,
      pdfAPICheck : true,
      disableSubmit: true,
    })
 

    if(event.target.value === "textpdf"){
      this.setState({
        runOnlyImages: false,
        runOnlyText:false,
      })
    }else if(event.target.value === "onlypdf"){
      this.setState({
        runOnlyImages: true,
        runOnlyText:false,
      })
    }else if(event.target.value === "onlytext"){
      this.setState({
        runOnlyText: true,
      })
    }
  }

  uploadFile(event) {
    let file = event.target.files[0];
 
    this.setState({
      uploadFile: file,
      submitValue:true,
      disableSubmit:true,
      
    });

    const data = new FormData();
    data.append('file', file);
    data.append('mail_id', userMail);


  }

  changeDocumentId(event) {
    
    if (event.target.value === "" || event.target.value === null) {


      this.setState({
        documentId: event.target.value,
        documentIdSubmitValue: false,
        disableSubmit: false,
      })
    } else {

      this.setState({
        documentId: event.target.value,
        documentIdSubmitValue: true,
        disableSubmit: true,
      })
    }
  }

  changeText(event){
    
      if(event.target.value === "" || event.target.value === null){
        this.setState({
          textareaText: event.target.value,
          submitValue:false,
          disableSubmit: false,
        })
      }else{
        this.setState({
          textareaText: event.target.value,
          submitValue:true,
          disableSubmit: true,
        })
      }
   
    
    
  }

  handleBackTo() {
    this.setState({
      resultDictionary: false,
      showingResults: false,
      disableSubmit: true,
      textareaText: "",
      submitValue: false,
      errorMessageText: "",
      errorMessageFile: "",
      uploadFile:"",
      showSelectedName: false,
      selectedCategory: ""
    })
  }

  submitFormData(){ 

   if(this.state.radioValue === 'textarea'){
      if(this.state.dictionaryUpload === "" || this.state.dictionaryUpload === null){
        this.setState({
          errorMessageText: 'Please choose a dictionary and then enter text in the text area'
        })
      }else if(this.state.textareaText === "" || this.state.textareaText === null){
        this.setState({
          errorMessageText: 'Please enter some text in the textarea'
        })
      }else{
        this.setState({
         showingResults:true,
         showImages:false,
         errorMessageText:"",
         errorMessageFile:"",
         loaderError:"Please wait. The file is being processed.",
         intevalset: true,
         disableSubmit: false,

        })      
        const data = new FormData();
        data.append('mail_id', userMail);
        data.append('dictionary_file', this.state.dictionaryUpload);
        data.append('text', this.state.textareaText);
        data.append('document_id',this.state.documentId);
	this.props.getScoresText(data);

	this.setState({
         documentId: ' ',
         submitValue: false,
         disableSubmit: false,
        })
      }
      
   }else if (this.state.radioValue === 'uploadFile'){
    if(this.state.dictionaryUpload === "" || this.state.dictionaryUpload === null){
      this.setState({
        errorMessageFile: 'Please choose a dictionary and then upload a file'
      })
    }else if(this.state.uploadFile === "" || this.state.uploadFile === null){
      this.setState({
        errorMessageFile: 'Please upload a file'
      })
    }
    else{
      this.setState({
       showingResults:true,
       showImages:true,
       errorMessageText:"",
       errorMessageFile:"",
       loaderError:"Please wait. The file is being processed.",
       intevalset: true,
       disableSubmit: false,
      })
      const data = new FormData();
      data.append('file', this.state.uploadFile);
      data.append('mail_id', userMail);
      data.append('dictionary_file', this.state.dictionaryUpload);
      if(this.state.runOnlyText === true){
        this.props.getScoresTextOnly(data)
      }
      else{
        if(this.state.runOnlyImages === true){
          this.props.getScoresImages(data)
        }
        else if(this.state.runOnlyImages === false){
          this.props.getScoresFiles(data);

        }
      }


    }
   }

  }

  getCategoryName(fileName,category){
    const regex = /_(SASB|UN_SDG|EU_Taxonomy|TCFD|ESG)\.csv$/;
    const match = fileName.match(regex);
    if (match) {
      return cat_name_mapping[match[1]][category]
    } else {
      console.log( 'Unknown Framework')
      return category
    }
  }

  handleCategoryClick(name){
    console.log('name',name)
    this.setState({
      selectedCategory : name,
      showSelectedName: true
    })

    let finalWord = name.split(" ");
    finalWord = finalWord[0] +"_"+ finalWord[1]
    console.log('finalWord',finalWord);
    /*let elememtVal = document.getElementsByClassName("selected_word");
    elememtVal.style.color = "#000"*/

    let myElements = document.querySelectorAll(".selected_word");

    for (let i = 0; i < myElements.length; i++) {
      myElements[i].style.color = "#000";
    }

    let selectedRed = document.getElementsByClassName(`${finalWord} neg`);
    for (let i = 0; i < selectedRed.length; i++) {
      selectedRed[i].style.color = "#ff0000";
    }
    let selectedGreen = document.getElementsByClassName(`${finalWord} pos`);
    for (let i = 0; i < selectedGreen.length; i++) {
      selectedGreen[i].style.color = "#008000";
    }
  }

  showAllCategory(){

    let myElementNeg = document.getElementsByClassName("selected_word neg");

    for (let i = 0; i < myElementNeg.length; i++) {
      myElementNeg[i].style.color = "#ff0000";
    }


    let myElementPos = document.getElementsByClassName("selected_word pos");

    for (let i = 0; i < myElementPos.length; i++) {
      myElementPos[i].style.color = "#008000";
    }

    this.setState({
      selectedCategory : "All Category"
    })
  }


	
	render() {
		return (
			<div className="esgWorkspace upload_dictionary">
				{!this.state.resultDictionary ? 
        <div className="container_wrapper">
      		
					<div className="right_info">
						<div>
							<FormControl>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Select Framework:
                </InputLabel>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={this.state.dictionaryUpload}
                  defaultValue={this.state.dictionaryUpload}
                  onChange={this.handleDictionaryUpload}
                >
                  {this.state.dictionaryName ? this.state.dictionaryName.map((item,index) => 
                  		<MenuItem value={item.file_name} key={index}> 
		                    {item.file_name} 
		                  </MenuItem>

                  ): ""}

                </Select>
                
              </FormControl>


						</div>
					</div>
            
					<div className="radio_section">
            <div>
              <FormControl component="fieldset">
                
                <RadioGroup aria-label="gender" name="gender1" value={this.state.radioValue} onChange={this.handleRadioState}>
                  <div className="radio_option">
                    <FormControlLabel value="textarea" control={<Radio />} label="Enter text below for framework evaluation:" />
                    <div className="documentIdInput">
                          Document Id: <input onChange={this.changeDocumentId} />
                    </div>
		    <div className={"innerItem teaxtareaSection " + (!this.state.textareaActive ? 'disabledItem' : '')} >
                      <textarea onChange={this.changeText}></textarea>
                      {/*<button onClick={this.submitFormData} className={"save_bttn " + ((this.state.submitValue === true && this.state.dictionarySelect === true) ? "" : "disabled_bttn")}>Submit</button>*/}
                      <button onClick={this.submitFormData} className={"save_bttn " + ((this.state.submitValue === true && this.state.documentIdSubmitValue === true  && this.state.dictionarySelect === true && this.state.disableSubmit === true) ? "" : "disabled_bttn")} >Submit</button>
                      <p>{this.state.errorMessageText}</p>
                    </div>
                  </div>
                  <div className="radio_option">
                    <FormControlLabel value="uploadFile" control={<Radio />} label="Upload pdf files for framework evaluation:" />
                    
                    <div className={"innerItem upload_button " + (this.state.textareaActive ? 'disabledItem' : '')} >
                      {this.state.showRadios ? <div className="choose_api">
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="apichoose"
                            //defaultValue="onlypdf"
                            name="radio-buttons-group"
                            onChange={this.changeAPIOption}
                          >
                            <FormControlLabel value="textpdf" control={<Radio />} label="PDF followed by text" />
                            <FormControlLabel value="onlypdf" control={<Radio />} label="Only PDF" />
                            <FormControlLabel value="onlytext" control={<Radio />} label="Only Text" />
                          </RadioGroup>
                        </FormControl>
                      </div> : ""}

                      <div className="upload_csv">
                        <input onChange={e => this.uploadFile(e)} type="file" className="custom-file-input" id="csv_upload" accept=".pdf " />
                      </div>
                      {/*<button onClick={this.submitFormData} className={"save_bttn " + ((this.state.submitValue === true && this.state.dictionarySelect === true) ? "" : "disabled_bttn")}>Submit</button>*/}
                      <button onClick={this.submitFormData} className={"save_bttn " + ((this.state.submitValue === true && this.state.dictionarySelect === true && this.state.disableSubmit === true && this.state.pdfAPICheck === true ) ? "" : "disabled_bttn")} >Submit</button>
                      <p>{this.state.errorMessageFile}</p>
                    </div>
                  </div>
                 
                </RadioGroup>
              </FormControl>

              {this.state.showingResults ? <div className="showing_message">
                <div>
                  <p>{this.state.loaderError}
                   <img alt="" src={loader} />
                  
                  </p>
                </div>
              </div> : ""}

            </div>
          </div>

					
				</div> : 
        <div className="dictionary_results">
          <div>
            <div className="file_display">
              <a href="javascript:void(0)" className="back_to" onClick={this.handleBackTo}>
               <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
              </a>
              <h3>Framework file selected:</h3>
              <p>{this.state.dictionary_file}</p>
            </div>
            <div className="scores_display">
              <div>
                <h4>ESG scores:</h4>
                {this.state.textHtml ? <ul>
                
                  {this.state.scoresAllValue ? this.state.scoresAllValue.map((cat,index) => 
                    //<li key={index}><span className="Categories_click " onClick={(name) => this.handleCategoryClick(cat.Category_name)}>{cat.Category_name}</span> : {cat.Score_value}</li>
                    <li key={index}><span className="Categories_click " onClick={(name) => this.handleCategoryClick(cat.Category_name)}>{cat.Category_name}</span> </li>
		    //<li key={index}><span className="Categories_click " onClick={(name) => this.handleCategoryClick(this.getCategoryName((this.state.dictionary_file),(cat.Category_name)))}>{this.getCategoryName((this.state.dictionary_file),(cat.Category_name))}</span> </li>
                  ): ""}
                </ul> : 
                <ul>
                
                  {this.state.scoresAllValue ? this.state.scoresAllValue.map((cat,index) => 
                    <li key={index}><span className="">{cat.Category_name}</span> : {cat.Score_value}</li>
                  ): ""}
                </ul> 

                }
              </div>
              {this.state.textHtml ? <div className="all_catogery" onClick={this.showAllCategory}>
                <p>Show all Categories</p>
              </div> : ""}
            </div>
            <div className="note_area note_section">
              <div>
                <h3>Legend:</h3>
                
                <ul>
                  <li>Framework-relevant text are shown in <span className="green_text">green</span> and <span className="red_text">red</span> boxes according to classification.</li>
                  <li>Categories are divided into Environmental, Social, and Governance related themes.</li>
                  
                </ul>

                

                {this.state.showImages ? <div className="annotated_text">
                  <h3>Annotated text:  ( {this.props.ResponseScores.inputText} ) </h3>
                 {!this.state.textHtml ? 
                  
                  <div className="pdf_images show_item" > 
                    {this.props.image_name_list ? this.props.image_name_list.map(list => 
                      <div className="pdf_page_container">
                        <div class="each_img">

                          <img src={`${HOST_URL}${this.props.image_folder_path}/${list}`} />
                        </div>
                      </div>
                   ) : ""}

                 </div> : 
                  <div><div>{this.state.showSelectedName ? <p className="selected_category">{this.state.selectedCategory} is Selected</p> : ""} </div>
                   <div className="htmlDisplay">
                    <div dangerouslySetInnerHTML={{ __html: this.props.ResponseScores.pdftotext }} />
                    {/*<div dangerouslySetInnerHTML={{ __html: dummyText }} />*/}
                   </div></div> }

                  
                </div> : 

                <div className="showtestFormatted">
                  <h3>Annotated text / File:</h3>
                  <div>{this.state.showSelectedName ? <p className="selected_category">{this.state.selectedCategory} is Selected</p> : ""} </div>
                  <div className="textBox" dangerouslySetInnerHTML={{ __html: this.props.ResponseScores.inputText }} />
                </div>

                }


              </div>
            </div>

          </div>
        </div> }
				
			</div>

			
		)
	}
}


const mapStateToProps = state => {
  return{
    dictionaryNamesList: state.esgReducers.dictionaryNames,
    dictionaryCodeStatus: state.esgReducers.dictionaryCodeStatus,
    file_hash: state.esgReducers.file_hash,
    scoreStatus: state.esgReducers.scoreStatus,
    scoreHash: state.esgReducers.scoreHash,
    ResponseScores: state.esgReducers.ResponseScores,
    scoreStatusCode: state.esgReducers.scoreStatusCode,
    image_folder_path: state.esgReducers.image_folder_path,
    image_name_list: state.esgReducers.image_name_list,
    match_text_co_ordinate_data: state.esgReducers.match_text_co_ordinate_data,
    textHtml: state.esgReducers.textHtml,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getDictionaryFilesName : (data) => dispatch(dataActions.getDictionaryFilesName(data)),
    getScoresText : (scoresParams) => dispatch(dataActions.getScoresText(scoresParams)),
    getScoresFiles : (scoresParams) => dispatch(dataActions.getScoresFiles(scoresParams)),
    getScoresImages : (scoresParams) => dispatch(dataActions.getScoresImages(scoresParams)),
    getScoresTextOnly : (scoresParams) => dispatch(dataActions.getScoresTextOnly(scoresParams)),
   
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ScoreText);
