import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';


var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let priceVal = [];
let sentimentsVal = [];
let priceArr = [];
let sentimentArr = [];


 

class FXChart extends Component {
	constructor(props){
	    super(props);

	    this.state = {
	    	csvData: [],
	    	flagValue: 0
	    }
	}


	render() {
		
		priceVal = this.props.pricevalue;
		sentimentsVal = this.props.sentimentvalue;
		
		priceArr = [];
 		sentimentArr = [];

		 priceVal.map(data => {
			
			let date_arr = data.Datetime.split(',');
			priceArr.push({
			  x: new Date(date_arr[0],date_arr[1],date_arr[2],date_arr[3],date_arr[4]),
			  y: parseFloat(data.Open),
			});
		});
		//console.log('priceArr',priceArr);
		
		if(sentimentsVal.ResponseData ){
		
			sentimentsVal.ResponseData.map(data => {
			
				let date_arr = data.Date.split(',');
				let sentisent_scrore = parseFloat(data.Sentiment).toFixed(2);
				sentimentArr.push({
				  x: new Date( date_arr[0],date_arr[1],date_arr[2],date_arr[3],date_arr[4] ),
				  y: parseFloat(sentisent_scrore),
				});
			});
		}
		//console.log('sentimentArr',sentimentArr);
		

		const options = {
			theme: "light2", // "light1", "dark1", "dark2"
			title:{
				text: ""
			},
			axisX:{
			   title:"Date",
			   valueFormatString: "DD MMM HH:mm" ,
			   labelAngle: -50,
			  
			},
			
		
			axisY:[{
				title: "Sentiment",
			}],
			data: [
			{
				type: "line",
				showInLegend: true,
				name: "",
				markerType: "none",
				color: "#06cb70",
				showInLegend: true, 
        legendText: "Sentiment",
				// axisYType: "secondary",
				axisYIndex: 1,
				toolTipContent: "{y} sentiment",
				dataPoints:sentimentArr
				
			}
			
			]
		}

		
		
		return (
		<div>
			<div className="chart_wrapper"><CanvasJSChart options = {options}
			/>
			<div className="whiteStrip"></div></div>
		</div>
		);
	}
}
export default FXChart;   