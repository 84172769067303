export const UNSDGdata = [
  {
    image: "Icons-01.jpg",
    description: "End poverty in all its forms everywhere.",
    scores: "",
  },
  {
    image: "Icons-02.jpg",
    description: "End hunger, achieve food security and improved nutrition and promote sustainable agriculture.",
    scores: "",
  },
  {
    image: "Icons-03.jpg",
    description: "Ensure healthy lives and promote well-being for all at all ages.",
    scores: "",
  },
  {
    image: "Icons-04.jpg",
    description: "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all.",
    scores: "",
  },
  {
    image: "Icons-05.jpg",
    description: "Achieve gender equality and empower all women and girls.",
    scores: "",
  },
  {
    image: "Icons-06.jpg",
    description: "Ensure availability and sustainable management of water and sanitation for all.",
    scores: "",
  },
  
  {
    image: "Icons-07.jpg",
    description: "Ensure access to affordable, reliable, sustainable and modern energy for all.",
    scores: "",
  },
  {
    image: "Icons-08.jpg",
    description: "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all.",
    scores: "",
  },
  {
    image: "Icons-09.jpg",
    description: "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation.",
    scores: "",
  },
  {
    image: "Icons-10.jpg",
    description: "Reduce inequality within and among countries.",
    scores: "",
  },
  {
    image: "Icons-11.jpg",
    description: "Make cities and human settlements inclusive, safe, resilient and sustainable.",
    scores: "",
  },
  {
    image: "Icons-12.jpg",
    description: "Ensure sustainable consumption and production patterns.",
    scores: "",
  },
  {
    image: "Icons-13.jpg",
    description: "Take urgent action to combat climate change and its impacts*.",
    scores: "",
  },

  {
    image: "Icons-14.jpg",
    description: "Librarians assist researchers studying dolphins and whales in Florida to identify pollutants.",
    scores: "",
  }, 
  {
    image: "Icons-15.jpg",
    description: "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss.",
    scores: "",
  }, 
  {
    image: "Icons-16.jpg",
    description: "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels.",
    scores: "",
  }, 
  {
    image: "Icons-17.jpg",
    description: "Strengthen the means of implementation and revitalize the global partnership for sustainable development.",
    scores: "",
  },
  
];