import React, { Component } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import * as dataActions from '../../../store/actions/index';
import {connect} from 'react-redux';

class MultiSelectDropdown extends Component {

	constructor(props){
    super(props);

    this.state = {
    	options: [],
    	selectedStocks:[],
    	countryName:'',
    }
    this.onSelectStock = this.onSelectStock.bind(this);
    this.onRemoveStock = this.onRemoveStock.bind(this);
	    
	}

	componentDidMount(){
		// console.log('multi in')
		
		/*let stockParams = JSON.stringify({
     	"country": this.state.countryName
		});

		console.log('this.props.countryName',this.props.countryName)
		this.props.getStockList(stockParams);*/
	}

	componentDidUpdate(prevProps, prevState) {
		// console.log('multi update')
	
		let stockArray = [];
		

		if(this.props.stockNames !== prevProps.stockNames){
			
			this.props.stockNames.map((name,index) => {
				let newname = name.split('“');
				let finalStock = newname.pop().split('”')[0]
			
				stockArray.push ({
					name: finalStock,
					id: index
				})
			})
			
			this.setState({
				options:stockArray
			});
		}
		if(this.props.countryName !== prevProps.countryName){
			
			this.setState({
				countryName:this.props.countryName,
				selectedStocks:[],
			});
			let stockParams = JSON.stringify({
     			"country": this.props.countryName
			});
			this.props.getStockList(stockParams);
		}

		// console.log('this.props.countryName111',this.props.countryName)
		
		
	}

	onSelectStock(selectedList, selectedItem){
	
		this.setState({
			selectedStocks:selectedList
		});
		this.props.onSelectStock(selectedList);
	}

	onRemoveStock(selectedList, removedItem) {
	 
		this.setState({
			selectedStocks:selectedList
		})
		this.props.onSelectStock(selectedList);
	}

	render() {
	
		return (
			<div className="multiselect_stocks">
				<Multiselect
					selectionLimit="5" options={this.state.options} onSelect={this.onSelectStock} onRemove={this.onRemoveStock} displayValue="name"  selectedValues={this.state.selectedStocks} // Options to display in the dropdown
				/>
			</div>
		)
	}
}

const mapStateToProps = state => {
    return{
        stockNames: state.graphReducer.stockList,
       
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getStockList : (stockParams) => dispatch(dataActions.getStockList(stockParams)),
        
        
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MultiSelectDropdown);