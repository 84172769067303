import React, { Component } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import * as dataActions from '../../../store/actions/index';
import {connect} from 'react-redux';

class PortfolioMultiSelectPacta extends Component {

	constructor(props){
    super(props);

    this.state = {
    	options: [
    	],
    	selectedStocks:[],
    	countryName:'india',
    }
    this.onSelectStock = this.onSelectStock.bind(this);
    this.onRemoveStock = this.onRemoveStock.bind(this);
    
	    
	}

	componentDidMount(){
	
	}

	componentDidUpdate(prevProps, prevState) {
		
		let fileNameList = [];
		console.log('fileNameList',this.props.fileNameList)
		if(this.props.fileNameList){
			if(this.props.fileNameList !== prevProps.fileNameList){
			
				this.props.fileNameList.map((name,index) => {
					/*let newname = name.split('“');
					let finalStock = newname.pop().split('”')[0]*/
					

					fileNameList.push ({
						//name: html_val,
						name: name,
						id: index
					})
				})
				
			
				this.setState({
					options:fileNameList
				});
			}
		}
		
		
	}

	onSelectStock(selectedList, selectedItem,e){
		this.setState({
			selectedStocks:selectedList
		});
		this.props.onSelectPortfolio(selectedList);
	}

	onRemoveStock(selectedList, removedItem) {
	 
		this.setState({
			selectedStocks:selectedList
		})
		this.props.onSelectPortfolio(selectedList);
	}

	

	render() {
	
		return (
			<div className="multiselect_stocks">
				<Multiselect
					selectionLimit="1" options={this.state.options} onSelect={this.onSelectStock} onRemove={this.onRemoveStock} displayValue="name"  selectedValues={this.state.selectedStocks} // Options to display in the dropdown
				/>
			</div>
		)
	}
}



export default PortfolioMultiSelectPacta;