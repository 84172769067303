import React , {Component} from 'react';
import {connect} from 'react-redux'; 
import MenuItem from '@material-ui/core/MenuItem'; 
import Select from '@material-ui/core/Select';
import RealtimeChart from './RealtimeChart.js';
import FXChart from './FXChart.js';
import Crypto from './Crypto.js';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import News from '../Signin/News';
import * as dataActions from '../../../store/actions/index';

let lastval ;
 


class RealtimeTab extends Component{

	constructor(props){
	    super(props);

	    this.state = {
	    	commodities:'',
	    	fxValue:'',
	    	cryptoValue:'',
	    	value:0,
	    	csvdata:0,
	    	pricevalue:0,
	    	sentimentvalue:0,
	    	equality:'india',
	    	activeTab: 'commodities',
	    	folder_name: 'commodities',
	    	listrand_hash:"",

	    }
	    this.onChangeHandle = this.onChangeHandle.bind(this);
	    this.onChangeFX = this.onChangeFX.bind(this);
	    this.onChangeCrypto = this.onChangeCrypto.bind(this);
	}
	  
	onChangeHandle(event) {

	    this.setState({
	    	commodities:event.target.value
	    },() => {
		    //this.props.onfetchPriceData(this.state.commodities,this.state.activeTab);
			this.props.onfetchSentimentsData(this.state.commodities,this.state.activeTab);
			this.props.onfetchNewsData(this.state.commodities,this.state.activeTab);
  		});
	};

	onChangeFX (event) {
	    this.setState({
	    	fxValue:event.target.value
	    },() => {
		    this.props.onfetchSentimentsData(this.state.fxValue,this.state.activeTab);
			this.props.onfetchNewsData(this.state.fxValue,this.state.activeTab);
  		});
	};

	onChangeCrypto (event) {
	    this.setState({
	    	cryptoValue:event.target.value
	    },() => {
		    this.props.onfetchSentimentsData(this.state.cryptoValue,this.state.activeTab);
			this.props.onfetchNewsData(this.state.cryptoValue,this.state.activeTab);
  		});
	};

	activeTab(firstTab) {
		// console.log('firstTab',firstTab)

		
		if(firstTab === 0){
			this.setState({
		    	activeTab: 'commodities',
		    	folder_name: 'commodities',
		    	commodities: this.props.listName[0]
		    },() => {
			    //this.props.onfetchPriceData(this.state.commodities,this.state.activeTab);
				/*this.props.onfetchSentimentsData(this.state.commodities,this.state.activeTab);
				this.props.onfetchNewsData(this.state.commodities,this.state.activeTab);*/

				let folder_name = JSON.stringify({
		     		"folder_name": this.state.folder_name,
				});

				this.props.getFolderList(folder_name)
	  		});
		}else if(firstTab === 1){
			this.setState({
		    	activeTab: 'fx',
		    	folder_name: 'fx',
		    	fxValue: this.props.listName[0]
		    },() => {
			    /*this.props.onfetchSentimentsData(this.state.fxValue,this.state.activeTab);
					this.props.onfetchNewsData(this.state.fxValue,this.state.activeTab);*/
					let folder_name = JSON.stringify({
			     		"folder_name": this.state.folder_name,
					});

				this.props.getFolderList(folder_name)
	  		});
		}else if(firstTab === 2){
			this.setState({
		    	activeTab: 'crypto',
		    	folder_name: 'crypto',
		    	cryptoValue: this.props.listName[0]
		    },() => {
			  /*this.props.onfetchSentimentsData(this.state.cryptoValue,this.state.activeTab);
				this.props.onfetchNewsData(this.state.cryptoValue,this.state.activeTab);*/
				let folder_name = JSON.stringify({
		     		"folder_name": this.state.folder_name,
				});

				this.props.getFolderList(folder_name)
	  		});
		}
	}  

	componentDidMount() {
		let $this = this;
		//$this.props.onfetchPriceData($this.state.commodities,$this.state.activeTab);
		/*$this.props.onfetchSentimentsData($this.state.commodities,$this.state.activeTab);
		$this.props.onfetchNewsData($this.state.commodities,$this.state.activeTab);*/


		let folder_name = JSON.stringify({
     		"folder_name": this.state.folder_name,
		});

		this.props.getFolderList(folder_name)


		setInterval(function(){ 
			//$this.props.onfetchPriceData($this.state.commodities,$this.state.activeTab);
			$this.props.onfetchSentimentsData($this.state.commodities,$this.state.activeTab);
			$this.props.onfetchNewsData($this.state.commodities,$this.state.activeTab);

		}, 3600000);
		
	}	


	componentDidUpdate(prevProps, prevState){
	
		if(this.props.listrand_hash !== prevProps.listrand_hash){
			if( this.props.listName){
				
				this.setState({
					commodities: this.props.listName[0],
					fxValue: this.props.listName[0],
					cryptoValue: this.props.listName[0],
					listrand_hash: this.props.listrand_hash
				})
				
				this.props.onfetchSentimentsData(this.props.listName[0],this.state.activeTab);
				this.props.onfetchNewsData(this.props.listName[0],this.state.activeTab);
			}
		}
	}
	
	render() {
		lastval = this.props.dataSentiments[this.props.dataSentiments.length - 1] ? this.props.dataSentiments[this.props.dataSentiments.length - 1].Sentiment : "";
		lastval = parseFloat(lastval).toFixed(2);

		 
		
		return (
			<div>
				<div className="leftTabs_main Realtime_sectionmain">
					<span className="sub_heading">Hourly sentiments</span>
					<h1>Real Time Data</h1>
					{/*<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>*/}
					<Tabs onSelect={(firstTab, lastTab) => this.activeTab(firstTab)} >
					    <TabList>
					      		<Tab label="ESG">ESG</Tab>
					          	<Tab label="G10">G10</Tab>
					          	<Tab label="EM">EM</Tab>
					    </TabList>
					 
					    <TabPanel>
					      	<div id="Commodities" className="tab-content current">
				        		<div className="select_type">
				        			<Select
							          labelId="demo-simple-select-label"
							          id="demo-simple-select"
							          value={this.state.commodities}
							          onChange={this.onChangeHandle}
							        >
							         {/* <MenuItem value='oil'>oil</MenuItem>
							          <MenuItem value="soybean">soybean</MenuItem>
							          <MenuItem value="silver">silver</MenuItem>
							          <MenuItem value="platinum">platinum</MenuItem>
							          <MenuItem value="palladium">palladium</MenuItem>
							          <MenuItem value="iron">iron</MenuItem>
							          <MenuItem value="gold">gold</MenuItem>
							          <MenuItem value="corn">corn</MenuItem>
							          <MenuItem value="copper">copper</MenuItem>
							          <MenuItem value="aluminium">aluminium</MenuItem>*/}

							          {this.props.listName ? this.props.listName.map((list,index) => 
							          	<MenuItem key={index} value={list}> {list} </MenuItem>
							          ) : ""}
							        </Select>
				        		</div>
								<h2>{this.state.commodities}</h2>
								{/*<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>*/}

								<h4 className="current_sentisemnts">
									Current Sentiments:-
									<span> {lastval} </span>
									<i className="fa fa-location-arrow arrow_signal" aria-hidden="true"></i>
								</h4>
								<div className="graph_main">
									<div>
										<RealtimeChart valueName={this.state.commodities} pricevalue={this.props.dataPrice} sentimentvalue={this.props.dataSentiments} /> 
									</div>
								</div>
								
							</div>
					    </TabPanel>
					   
					    <TabPanel>
					      	<div id="fx" className="tab-content">
						      	<div className="select_type">
				        			<Select
							          labelId="demo-simple-select-label"
							          id="demo-simple-select"
							          value={this.state.fxValue}
							          onChange={this.onChangeFX}
							        >
							         {this.props.listName ? this.props.listName.map((list,index) => 
							          	<MenuItem key={index} value={list}> {list} </MenuItem>
							          ) : ""}
							        </Select>
				        		</div>
								<h2>{this.state.fxValue}</h2>
								<h4 className="current_sentisemnts red_zone">
									Current Sentiments:-
									<span> {lastval} </span>
									<i className="fa fa-location-arrow arrow_signal" aria-hidden="true"></i>
								</h4>
								<div className="graph_main">
										<div>
											<FXChart  valueName={this.state.commodities} pricevalue={this.props.dataPrice} sentimentvalue={this.props.dataSentiments} />
										</div>
								</div>
							</div>
					    </TabPanel>
					    <TabPanel>
					      	<div id="Crypto" className="tab-content">
						      	<div className="select_type">
				        			<Select
							          labelId="demo-simple-select-label"
							          id="demo-simple-select"
							          value={this.state.cryptoValue}
							          onChange={this.onChangeCrypto}
							        >
							         {this.props.listName ? this.props.listName.map((list,index) => 
							          	<MenuItem key={index} value={list}> {list} </MenuItem>
							          ) : ""}
							        </Select>
				        		</div>
								<h2>{this.state.cryptoValue}</h2>
								<h4 className="current_sentisemnts red_zone">
									Current Sentiments:-
									<span> {lastval} </span>
									<i className="fa fa-location-arrow arrow_signal" aria-hidden="true"></i>
								</h4>
								<div className="graph_main">
									<div>
										<Crypto valueName={this.state.commodities} pricevalue={this.props.dataPrice} sentimentvalue={this.props.dataSentiments} />
									</div>
								</div>
							</div>
					    </TabPanel>
					</Tabs>
				</div>
				<News />
		    </div>
		)
	}
	
}

const mapStateToProps = state => {
    return{
        dataPrice: state.graphReducer.dataPrice,
        dataSentiments: state.graphReducer.dataSentiments,
        listName: state.graphReducer.listName,
        listCode: state.graphReducer.listCode,
        listStatus: state.graphReducer.listStatus,
        listrand_hash: state.graphReducer.listrand_hash,
       
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onfetchPriceData : (typevalue, tabvalue) => dispatch(dataActions.fetchPrice(typevalue,tabvalue)),
        onfetchSentimentsData : (typevalue , tabvalue) => dispatch(dataActions.fetchSentiments(typevalue,tabvalue)),
        onfetchNewsData : (typevalue , tabvalue) => dispatch(dataActions.fetchNews(typevalue,tabvalue)),
        getFolderList : (folderName) => dispatch(dataActions.getFolderList(folderName)),
        
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RealtimeTab);