import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let stockNames = [];
let fullDatarow = [];
let investmentValue = [];
let stockNameList=[];


class SASBOne extends Component {
	constructor(props){
	    super(props);

	    this.state = {
	    	noData: true,
	    	sbtirand_hash: "yyd0fb3d4e4143008ed4a9f2006b9e6d"
	    }
    
 
	}

	componentDidUpdate(prevProps,prevState){
		
		if(this.props.sbtirand_hash !== this.state.sbtirand_hash){
			
			this.setState({
				sbtirand_hash: this.props.sbtirand_hash
			})
			if(this.props.sbtiStatusCode === 0 || this.props.tcfdStatus === "No data found"){
				this.setState({
					noData: true,
				})
			}else{
				this.setState({
					noData: false,
				})
			}
		}
	}

	render() {
		fullDatarow = [];
		investmentValue= [];
		stockNames= [];
		if(this.props.dataArray){
			
			let items_row = [];
			this.props.dataArray.map((row , index )=> {
				stockNames =[]
				investmentValue = []
				row.map((list,index) => {
					investmentValue.push({
					  label: list.category,
					  y: (list.score),
					});
					
					stockNames.push({
						name: list.category,
					})
				})
				

				items_row =  investmentValue;

				fullDatarow.push({
					type: "column",
					
					legendText: "{label}",
					toolTipContent: "{label}: <strong>{y}</strong>",
					indexLabel: "{y}",
					dataPoints: items_row

				})
			})

			stockNameList = []
			
			if(this.props.stockListName){

				this.props.stockListName.map((name,index) => {
					stockNameList.push(name)
				}) 
			}
		}
		

		const options = {
			theme: "light",
			animationEnabled: true,
			
			title:{
				text: ""
			},
			data: fullDatarow
			
		}
		return (
		<div>
			{!this.state.noData ? <div className="chart_wrapper">
			<CanvasJSChart options = {options} />
			{stockNameList.length ? <div className="line_indicator">
				<ul>
					{stockNameList.map((stockName,index) => {
						return <li key={index} >
							<span className="first_shape"></span>
							<span>{stockName}</span>
						</li>
					}) }
				</ul>
			</div> : ""}

			</div> : <div className="noData">No Data Found</div> }
		</div>
		);
	}
}
export default SASBOne;       