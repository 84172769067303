export const FETCH_PRICE = 'FETCH_PRICE';
export const FETCH_SENTIMENTS = 'FETCH_SENTIMENTS';
export const FETCH_NEWS = 'FETCH_NEWS';
export const SIGNUP = 'SIGNUP';
export const SIGNIN = 'SIGNIN';
export const COUNTRYLIST = 'COUNTRYLIST';
export const INDEXINTENSITYDATA = 'INDEXINTENSITYDATA';
export const INDEXSENTIMEANTSDATA = 'INDEXSENTIMEANTSDATA';
export const STOCKINTENSITYDATA = 'STOCKINTENSITYDATA';
export const STOCKSENTIMENTSDATA = 'STOCKSENTIMENTSDATA';
export const STOCKLISTNAMES = 'STOCKLISTNAMES';
export const RECENTSTOCKS = 'RECENTSTOCKS';
export const FILEUPLOADES = 'FILEUPLOADES';
export const FILENAMELIST = 'FILENAMELIST';
export const PORTFOLIOSENTIMENTS = 'PORTFOLIOSENTIMENTS';
export const PORTFOLIOINTENSITY = 'PORTFOLIOINTENSITY';
export const PORTFOLIOERROR = 'PORTFOLIOERROR';
export const INTENSITYERROR = 'INTENSITYERROR';
export const FRAMENAMES = 'FRAMENAMES';
export const ESGSCORES = 'ESGSCORES';
export const ESGSCORESERROR = 'ESGSCORESERROR';
export const ESGUPLOADES = 'ESGUPLOADES';
export const ESGNAMELIST = 'ESGNAMELIST';
export const PORTFOLIOESG = 'PORTFOLIOESG';
export const PORTFOLIOESGERROR = 'PORTFOLIOESGERROR';
export const DICTIONARYUPLOADES = 'DICTIONARYUPLOADES';
export const DICTIONARYNAMELIST = 'DICTIONARYNAMELIST';
export const SCORESDATA = 'SCORESDATA';
export const MULTIFILES = 'MULTIFILES';
export const DOWNLOADFILES = 'DOWNLOADFILES';
export const INSIGHTLIST = 'INSIGHTLIST';
export const INSIGHTFILEDATA = 'INSIGHTFILEDATA';
export const CATEGORYLISTNAMES = 'CATEGORYLISTNAMES';
export const FOLDERLISTDATA = 'FOLDERLISTDATA';
export const COUNTRYESGLIST = 'COUNTRYESGLIST';
export const ESGSTOCKLISTNAMES = 'ESGSTOCKLISTNAMES';
export const FORGETMAIL = 'FORGETMAIL';
export const UPDATEPASSWORD = 'UPDATEPASSWORD';
export const SBTIDATA = 'SBTIDATA';
export const SBTITABLEDATA = 'SBTITABLEDATA';
export const ESGSBTINAMELIST = 'ESGSBTINAMELIST';
export const COMPANYSTOCKLIST = 'COMPANYSTOCKLIST';
export const GUI3DATA = 'GUI3DATA';
export const PACTAUPLOADES = 'PACTAUPLOADES';
export const PACTALIST = 'PACTALIST';
export const PACTADATA = 'PACTADATA';
export const TCFDDATA = 'TCFDDATA';
export const SASBDATA = 'SASBDATA';
export const SCORESIMAGES = 'SCORESIMAGES';
export const NEWSESGDATA = 'NEWSESGDATA';
export const GOGITCALL = 'GOGITCALL';
export const ESGREPOSRTING = 'ESGREPOSRTING';
export const ESGREPOSRTINGLIST = 'ESGREPOSRTINGLIST';
export const ESGREPOSRTINGCOLOUMN = 'ESGREPOSRTINGCOLOUMN';
export const ESGREPOSRTINGSAVE = 'ESGREPOSRTINGSAVE';
export const ESGPERCENTILERANK = 'ESGPERCENTILERANK';