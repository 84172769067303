export default [
 {
    title: "Impact of Coronavirus: Is 'Timing the Markets' Possible Using Sentiment Analysis?",
    summary: 'Is it possible to position your portfolio for market volatility in a timely manner? We look at this question using EMAlpha Sentiment Signal as a guide. We consider two major emerging equity markets: Brazil and India and use EMAlpha Sentiment based Signals, constructed using Machine Translation and Sentiment Analysis on news flow in major languages: a) Portuguese and English for Brazil, b) Hindi and English for India, to trade the broad equity indices in these two countries. The result, in terms of the cumulative return in excess of the equity benchmark shows that EMAlpha Sentiment Signal for Brazil and India seem to have navigated the recent unprecedented market volatility quite well. ',
    category: 'Big Data and AI',
    date: '4/13/2020',
 },
 {
    title: 'Why Local News based Sentiment Analysis Matters and Do Machines Can See the Difference?',
   summary: 'When machines pick news on same subject in multiple languages for the same country, the result can be significantly different. EMAlpha has noted such divergence for many countries including China, Japan and Turkey and when news flow is different, sentiment analysis will not be the same. For Emerging Markets, EMAlpha experience with news collection and Sentiment Analysis for different countries in different languages confirms that English news and translated (from local language to English) are qualitatively and quantitatively different.',
   category: 'Big Data and AI',
   date: '4/8/2020',
 },
 {
    title: 'Coronavrius News Sentiment vs. Aggregate Market Sentiment and the Importance of Base Rate ',
    summary: "EMAlpha’s Aggregate Market Sentiment has a base rate which is above '0'. This makes sense because the usual market sentiment has a positive bias and hence, the change in market sentiment should be measured as compared to this base rate. EMAlpha Sentiment Analysis tells us that the absolute values don’t matter that much and only how far is the point in either direction from the base rate, matters. The same things apply to languages, books, movies and almost everything where we can do Sentiment Analysis. This has important implications for how to read the signals and what to deduce from Sentiment Analysis. ",
    category: 'Big Data and AI',
    date: '4/1/2020',
 },
{
    title: 'News Sentiment Watch and How Portfolio Managers Can Use It for Systematic Trading?',
    summary: 'EMAlpha Coronavirus Sentiment reflects that the markets have reacted strongly to pandemic related news and country-by-country scores are even more interesting. We have used EMAlpha proprietary sentiment measure to first find the raw sentiment for each country for news related to Coronavirus and the raw scores were then Z-scored for standardization. As the daily sentiment changes for countries, it can be a useful input into systematic trading model for portfolio managers and it can also be valuable in risk management.  ',
    category: 'Big Data and AI',
    date: '3/4/2020',
 },{
    title: 'Coronavirus Pandemic and How Sentiment is Impacting the Emerging Markets?',
    summary: 'The impact on the global markets from Coronavirus pandemic also seems to have a local component as Asian markets close to China have suffered more than others. Geographically distant markets have remained shielded. When we look at how EMAlpha Sentiment Score has evolved over the last month for some of these emerging countries and the performance of their equity markets, the maximum market impact has been in smaller neighboring countries, like Thailand. ',
    category: 'Big Data and AI',
    date: '2/9/2020',
 },{
    title: 'Coronavirus News Sentiment: How much is NOISE in the SIGNAL?',
    summary: 'Though Coronavirus is a global health emergency and there is no proven cure yet in sight, the media coverage is still disproportionate considering the tangible impact estimated as of now. In the age of rapid information dissemination and huge role played by social media, this skew is even more prominent. Naturally, financial markets also react more to these events. Whether the impact of Coronavirus is NOISE or SIGNAL, the reaction of financial markets proves ‘News may be the same, but the impact could be vastly different because of the varied nature of Sentiment’. EMAlpha tracks this news flow and the underlying sentiment.',
    category: 'Big Data and AI',
    date: '2/9/2020',
 },{
    title: "Recession is trending, Could 'People's Worries' Bring Bad News For the Economy?",
    summary: 'Trends are so powerful that often they become self-fulfilling prophecies. When we look at what people are talking about, what they are feeling worried about and what their concerns are, it gives us some idea about what future may look like. The chart showing the frequency of google search for "recession" from 2004 onward tells us that it has hit the 2008-2009 levels recently. The number of people searching for the word ‘recession’ remains at a higher level than observed in the past ten years and this is a significant change because such spikes could actually precede the real event. ',
    category: 'Big Data and AI',
    date: '10/25/2019',
 },{
    title: "Human Emotions and How Systematic Investing Can Mitigate the Impact on Your Portfolio?",
    summary: 'Often it is difficult to control emotions in the markets and extraordinary events become even more testing. It takes great discipline to not follow emotions in investment and this is what the insight talks about. Even if one has a quantitative, machine learning based model, it is easy to yield to emotions and start tweaking with model parameters. Many quant managers yield to this temptation and tweak the parameters at the worst possible moment. Discipline does not come easy and it takes experience and practice, but it pays off.',
    category: 'Big Data and AI',
    date: '10/14/2019',
 },{
    title: "Sentiment Analysis and Its Role In Investing: Now and In The Future",
    summary: 'When the time scale is short, the sentiment can matter more than the facts. In stock markets when the reaction time needs to be quick and the trade decision has to be made immediately after the event, the fund manager lacks the luxury to do it at leisure. The sentiment, i.e. the first impression, can matter more in this case and the price impact can be seen very quickly after a good or bad event. Since even a small change in sentiment can quickly become big, Sentiment analysis and discovery of news which can move markets is useful for market participants.',
    category: 'Big Data and AI',
    date: '10/12/2019',
 },{
    title: "Investment Management - The Final Frontier for Machines",
    summary: 'The adoption rate of new technology is dependent on several factors but the important thing is that the new age machines are not just taking over the work which used to be done by humans in the financial markets, they are also opening new avenues and approaches for investment management. EMAlpha’s proprietary model which generates sentiment scores using our dictionary and the trades executed on the basis of those sentiment scores are beating markets and this tells us that there is need for more sophisticated data analytics, more advanced algorithms and use of Artificial Intelligence for generating superior investment returns.',
    category: 'Big Data and AI',
    date: '10/6/2019',
 },{
    title: "'Fundamentals' and 'Quant' - The Virtues of Discipline and Why Systematic Trading?",
    summary: 'The debate on which method of stock trading is superior between ‘fundamental approach’ and ‘quantitative approach’ is age old and never ending. Then, what is the solution? The best possible approach we believe is an approach which is systematic. The idea is simple: unless you follow an approach diligently and don’t let your emotions affect your investment decisions, it is difficult to evaluate it. You have rules and you don’t tweak them unless you think that some of the premises are no longer valid. Otherwise, you let the system do the ‘decision making’ for you.',
    category: 'Big Data and AI',
    date: '8/28/2019',
 },{
    title: "'Fundamentals' and 'Quant' - The Virtues of Discipline and Why Systematic Trading?",
    summary: 'The debate on which method of stock trading is superior between ‘fundamental approach’ and ‘quantitative approach’ is age old and never ending. Then, what is the solution? The best possible approach we believe is an approach which is systematic. The idea is simple: unless you follow an approach diligently and don’t let your emotions affect your investment decisions, it is difficult to evaluate it. You have rules and you don’t tweak them unless you think that some of the premises are no longer valid. Otherwise, you let the system do the ‘decision making’ for you.',
    category: 'Big Data and AI',
    date: '8/28/2019',
 },
];  
