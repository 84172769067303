import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index';

class CountryDropdown extends Component {

	constructor(props){
    super(props);

    this.state = {
    	country:'',
    	countryList:[],
    }
    this.ChangeCountry = this.ChangeCountry.bind(this);
	    
	} 

	componentDidMount(){
		this.props.countryNamesList();
	}

	componentDidUpdate(prevProps, prevState) {
    /*console.log('codeee111114',this.props.countryList);
    console.log('prevProps',prevProps);
    console.log('prevState',prevState);*/
    if(this.props.countryList !== prevProps.countryList){
		//console.log('in');
		this.setState({
	      countryList:this.props.countryList,
	      country:this.props.countryList[0]
	    });
	    this.props.onInitCountry(this.props.countryList[0])
    		
    }
  
	}

	ChangeCountry(event, newValue){
		//console.log('newValue',event.target.value)
		this.setState({
	      country:event.target.value
	    });
		this.props.onSelectCountry(event.target.value)
		
	  //setCountry(newValue);
	};

	render() {
		return (
			<div className="country_select">
				<div className="maincountry_dropdown">
					<FormControl>
			        <InputLabel shrink id="demo-simple-selPect-placeholder-label-label">
			          Country:
			        </InputLabel>
			        <Select
			          labelId="demo-simple-select-placeholder-label-label"
			          id="demo-simple-select-placeholder-label"
			          value={this.state.country}
			          onChange={this.ChangeCountry}
			          displayEmpty 
			          
			        >
			        	{this.props.countryList ? this.props.countryList.map((list,index) => 
			        		<MenuItem key={index} value={list}> {list} </MenuItem>
			        	) : ""}
			          
			         
			        </Select>
			      </FormControl>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
    return{
        countryList: state.graphReducer.countryListArray,
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        countryNamesList : () => dispatch(dataActions.getCountryNames()),
        
        
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CountryDropdown);