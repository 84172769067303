import * as action_Types from '../actions/actionTypes'; 

const initialState = {
  frameWorkNames: [],
  esgData: [],
  esgStatusCode:"",
  esgstockList:[],
  esgStatus:"",
  statusUpload:'',
  codeUpload:'',
  Upload_hash:'',
  nameList:[],
  statusCode:'',
  portfolioESGValues: [],
  portfolioESGcode: '',
  portfolioESGStatus: '',
  portfolioESGerror: '',
  portfolioESGhash: '',
  dictionaryStatus: '',
  dictionaryCode: '',
  dictionaryHash: '',
  dictionaryNames: [],
  dictionaryCodeStatus: '',
  file_hash:'',
  scoreStatus:'',
  scoreHash: '',
  ResponseScores: [],
  multiStatus: "",
  Score_files_hash: "",
  multiStatusCode: "",
  returnMain : "",
  user_id: '',
  scoreStatusCode:'',
  file_path: '',
  download_file_name: "",
  frameListHash: "",
  esgstockUpload_hash: "",
  quantile_stats_data: "",
  sector_controlled_buckets_data: "",
  sector_control_data: "",
  esgCsvHash: "",
  csvFilePath: "",
  sbtiResponse: [],
  sbtiStatusCode: "",
  sbtirand_hash: "",
  sbtiTResponse: [],
  sbtiTStatusCode: "",
  sbtiTrand_hash: "",
  nameSbtiList:[],
  statusCodesbti:"",
  esgCsvHashsbti:"",
  csvFilePathsbti:"",
  stockListSbti:[],
  sbtiSC:"",
  sbtistockHash:"",
  Contribution_data:[],
  Investment_data:[],
  Temprature_score_data:[],
  aggregation_data:[],
  scenario_aggregated_data:[],
  gui3StatusCode:"",
  gui3rand_hash:"",
  pactastatus:"",
  pactastatusCode:"",
  pactaUpload_hash:"",
  pactaResponseData:[],
  pactaStatus:"",
  pactaStatusCode:"",
  pactacsv_file_hash:"",
  pactacsv_file_path:"",
  pactaGResponseData:[],
  pactaGStatus:"",
  pactaGStatusCode:"",
  pactaGrand_hash:"",
  tcfdResponseData:[],
  tcfdStatus:"",
  tcfdStatusCode:"",
  tcfdrand_hash:"",
  sasbResponseData:[],
  sasbStatus:"",
  sasbStatusCode:"",
  sasbrand_hash:"",
  match_text_co_ordinate_data: "",
  stockListNameTcfd: [],
  image_folder_path: "",
  image_name_list:[],
  textHtml: "",
  scoreStatusI:"",
  scoreHashI:"",
  ResponseDataI:[],
  scoreStatusCodeI:"",
  image_folder_pathI:"",
  image_name_listI:"",
  match_text_co_ordinate_dataI:"",
  textHtmlI:"",
  ResponseDataNews: [],
  newsStatus: "",
  newsStatusCode: "",
  newsrand_hash: "",
  changeText:"",
  ResponseReporting:[],
  reportingStatus:"",
  reportingStatusCode:"",
  reportingrand_hash:"",
  ResponseReportinglist: [],
  reportingStatuslist: "",
  reportingStatusCodelist: "",
  reportingrand_hashlist: "",
  
  ResponseReportingCell: [],
  reportingStatusCell: "",
  reportingStatusCodeCell: "",
  reportingrand_hashCell: "",
  complete_file_path: "",
  saveStatus: "",
  saveStatusCode: "",
  saveUpload_hash: "",
  column_valueData: "",
  complete_file_pathData: "",
  uploaded_file: "",
  
  pencentResponseData:[],
  percentStatus: "",
  percentStatusCode: "",
  percentUpload_hash: "",
}


const esgReducers = (state = initialState, action) => {
   
  switch(action.type) {
    case action_Types.FRAMENAMES : 
      return{
        ...state,
        frameWorkNames: action.frameList,
        frameListHash: action.frameListHash
      };
     case action_Types.ESGSCORES : 
      return{
        ...state,
        esgData: action.dataList,
        esgStatusCode: action.esgCode,
        esgStatus: action.esgStatus,
      };
    case action_Types.ESGSCORESERROR :
      return{
        ...state,
        esgStatus: action.esgStatus,
        esgStatusCode: action.errorCode,
        esgstockList: action.stockList,
        esgstockUpload_hash: action.Upload_hash,

      };
    case action_Types.ESGUPLOADES :
      return {
        ...state,
        statusUpload: action.status,
        codeUpload: action.statusCode,
        Upload_hash: action.Upload_hash
      }
    case action_Types.PACTAUPLOADES :

      return {
        ...state,
        pactastatus: action.pactastatus,
        pactastatusCode: action.pactastatusCode,
        pactaUpload_hash: action.pactaUpload_hash
      }
    case action_Types.ESGNAMELIST :
      return {
        ...state,
        nameList: action.fileNames,
        statusCode: action.filelistCode,
        esgCsvHash: action.esgCsvHash,
        csvFilePath: action.csvFilePath,
      }
    case action_Types.ESGSBTINAMELIST :
      return {
        ...state,
        nameSbtiList: action.nameSbtiList,
        statusCodesbti: action.statusCodesbti,
        esgCsvHashsbti: action.esgCsvHashsbti,
        csvFilePathsbti: action.csvFilePathsbti,
      }
    case action_Types.PORTFOLIOESG :
      return {
        ...state,
        portfolioESGValues: action.dataList,
        portfolioESGcode: action.dataCode,
        portfolioESGStatus: action.dataStatus,
        quantile_stats_data: action.quantile_stats_data,
        sector_controlled_buckets_data: action.sector_controlled_buckets_data,
        sector_control_data: action.sector_control_data,
      }
    case action_Types.PORTFOLIOESGERROR :
   
      return {
        ...state,
        portfolioESGerror: action.dataStatus,
        portfolioESGcode: action.dataCode,
        portfolioESGhash: action.dataHash,
      }
    case action_Types.DICTIONARYUPLOADES :
      return {
        ...state,
        dictionaryStatus: action.status,
        dictionaryCode: action.statusCode,
        dictionaryHash: action.Upload_hash,
      }
    case action_Types.DICTIONARYNAMELIST :
      return {
        ...state,
        dictionaryNames: action.dictionaryNames,
        dictionaryCodeStatus: action.dictionaryCode,
        file_hash:action.file_hash,
        file_path:action.file_path,
      }
    case action_Types.SCORESDATA :
      return {
        ...state,
        scoreStatus: action.scoreStatus,
        scoreHash: action.scoreHash,
        ResponseScores:action.ResponseData,
        scoreStatusCode:action.scoreStatusCode,
        image_folder_path:action.image_folder_path,
        image_name_list:action.image_name_list,
        match_text_co_ordinate_data: action.match_text_co_ordinate_data,
        textHtml: action.textHtml,
      } 
      case action_Types.SCORESIMAGES :
      return {
        ...state,
        scoreStatusI: action.scoreStatusI,
        scoreHashI: action.scoreHashI,
        ResponseDataI:action.ResponseDataI,
        scoreStatusCodeI:action.scoreStatusCodeI,
        image_folder_pathI:action.image_folder_pathI,
        image_name_listI:action.image_name_listI,
        match_text_co_ordinate_dataI: action.match_text_co_ordinate_dataI,
        textHtmlI: action.textHtmlI,
      }
    case action_Types.MULTIFILES :
      return {
        ...state,
        multiStatus: action.multiStatus,
        Score_files_hash: action.Score_files_hash,
        multiStatusCode:action.multiStatusCode,
        user_id:action.user_id,
        download_file_name:action.download_file_name,
      }

    case action_Types.SBTIDATA :
      return {
        ...state,
        sbtiResponse: action.sbtiResponse,
        sbtiStatusCode: action.sbtiStatusCode,
        sbtirand_hash:action.sbtirand_hash,
      }
    case action_Types.SBTITABLEDATA :
      return {
        ...state,
        sbtiTResponse: action.sbtiTResponse,
        sbtiTStatusCode: action.sbtiTStatusCode,
        sbtiTrand_hash:action.sbtiTrand_hash,
      }
    case action_Types.COMPANYSTOCKLIST :
      return {
        ...state,
        stockListSbti: action.stockListSbti,
        sbtiSC: action.sbtiSC,
        sbtistockHash:action.sbtistockHash,
      }
    case action_Types.GUI3DATA :
      return {
        ...state,
        Contribution_data: action.Contribution_data,
        Investment_data: action.Investment_data,
        Temprature_score_data: action.Temprature_score_data,
        aggregation_data: action.aggregation_data,
        scenario_aggregated_data: action.scenario_aggregated_data,
        gui3StatusCode: action.StatusCode,
        gui3rand_hash: action.gui3rand_hash,
      }
    case action_Types.PACTALIST :
      return {
        ...state,
        pactaResponseData: action.pactaResponseData,
        pactaStatus: action.pactaStatus,
        pactaStatusCode: action.pactaStatusCode,
        pactacsv_file_hash: action.pactacsv_file_hash,
        pactacsv_file_path: action.pactacsv_file_path,
      }
    case action_Types.PACTADATA :
      return {
        ...state,
        pactaGResponseData: action.pactaGResponseData,
        pactaGStatus: action.pactaGStatus,
        pactaGStatusCode: action.pactaGStatusCode,
        pactaGrand_hash: action.pactaGrand_hash,
      
      }
    case action_Types.TCFDDATA :
      return {
        ...state,
        tcfdResponseData: action.tcfdResponseData,
        tcfdStatus: action.tcfdStatus,
        tcfdStatusCode: action.tcfdStatusCode,
        tcfdrand_hash: action.tcfdrand_hash,
        stockListNameTcfd: action.stockListNameTcfd
      }
    case action_Types.SASBDATA :
      return {
        ...state,
        sasbResponseData: action.sasbResponseData,
        sasbStatus: action.sasbStatus,
        sasbStatusCode: action.sasbStatusCode,
        sasbrand_hash: action.sasbrand_hash,
      }
    case action_Types.ESGREPOSRTING :
      return {
        ...state,
        ResponseReporting: action.ResponseReporting,
        reportingStatus: action.reportingStatus,
        reportingStatusCode: action.reportingStatusCode,
        reportingrand_hash: action.reportingrand_hash,
      }
    case action_Types.ESGREPOSRTINGLIST :
      return {
        ...state,
        ResponseReportinglist: action.ResponseReportinglist,
        reportingStatuslist: action.reportingStatuslist,
        reportingStatusCodelist: action.reportingStatusCodelist,
        reportingrand_hashlist: action.reportingrand_hashlist,
      }
    case action_Types.ESGREPOSRTINGCOLOUMN :
      return {
        ...state,
        ResponseReportingCell: action.ResponseReportingCell,
        reportingStatusCell: action.reportingStatusCell,
        reportingStatusCodeCell: action.reportingStatusCodeCell,
        reportingrand_hashCell: action.reportingrand_hashCell,
        column_valueData: action.column_valueData,
        complete_file_pathData: action.complete_file_pathData,
        uploaded_file: action.uploaded_file,
      }
    case action_Types.NEWSESGDATA :
      return {
        ...state,
        ResponseDataNews: action.ResponseDataNews,
        newsStatus: action.newsStatus,
        newsStatusCode: action.newsStatusCode,
        newsrand_hash: action.newsrand_hash,
      }
    case action_Types.ESGREPOSRTINGSAVE :
      return {
        ...state,
        complete_file_path: action.complete_file_path,
        saveStatus: action.saveStatus,
        saveStatusCode: action.saveStatusCode,
        saveUpload_hash: action.saveUpload_hash,
      }
    case action_Types.ESGPERCENTILERANK :
      return {
        ...state,
        pencentResponseData: action.pencentResponseData,
        percentStatus: action.percentStatus,
        percentStatusCode: action.percentStatusCode,
        percentUpload_hash: action.percentUpload_hash,
      
      }
    case action_Types.GOGITCALL :
      return {
        ...state,
        changeText: action.changeText,
      }
      case action_Types.DOWNLOADFILES :
      return {
        ...state,
        returnMain: action.returnMain,
      }
    default:
        return state
  }
    
}


export default esgReducers;