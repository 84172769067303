export default [
  {
     title: 'Does Economic Revival from Coronavirus Pandemic Depend on Recklessness in Human Behavior? ',
     summary: 'There is evidence that the economic recovery from an unforeseen event is dependent on how quickly people will get back to normal. This EMAlpha insight takes a look at how the ‘speed of revival’ from the effects of Coronavirus pandemic depends on how ‘risk-taking’ people are. Less ‘risk averse’ people are, faster the recovery would be. The difference between UK and Continental Europe are recovering at a different pace mainly because of difference in threat perception while the on-ground statistics are alomost same on the impact of Coronavirus pandemic.',
     category: 'Emerging Markets',
     date: '13-Aug-20',
  },

  {
    title: 'Does International News Flow Capture Seriousness of Coronavirus Pandemic in Brazil?',
   summary: 'From the lack of news coverage in other parts of the world, it is difficult to figure out that Brazil is one of the worst-hit from the Coronavirus pandemic. However when we analyse what EMAlpha has picked up on Brazil in media coverage, most of the news flow is still capturing less relevant though more interesting developments like traditional cures and football matches.',
   category: 'Emerging Markets',
   date: '20-Jun-20',
 },
 {
    title: 'Can the Sentiment in Local News Determine the Course of a Geo-Political Conflict?',
    summary: 'It is possible to argue that media only reflects the public opinion. But when it comes to sensitive situations, the role of media becomes even more important as it also has a role in shaping the popular discourse. In conflict between countries, media has to act responsibly and should not do anything which can lead to unnecessary and avoidable conflict. But unfortunately, what EMAlpha machines have picked up is not really unpleasant.',
    category: 'Emerging Markets',
    date: '18-Jun-20',
 },
 {
    title: 'Does Geography Change Interpretation and Relevance of Local News Sentiment',
    summary: 'On news flow sentiment analysis, often the question is being asked: In this day and age of global interconnection, does local news matter anymore? But on the basis of automated and unbiased news flow collection of EMAlpha for multiple countries, it is easy to see that English news and Local language news can be quite different. So, when news flow is different, sentiment analysis will not be the same either. We discuss the intricacies and implications here as the Sentiment Analysis outcomes are often different for English and Local News.',
    category: 'Emerging Markets',
    date: '10-Jun-20',
 },
 {
    title: 'EURO Gaining Strength over USD: What it Means for Emerging Markets?',
    summary: 'After Germany and France proposed a huge Recovery Fund to provide the EU with more capacity to deal with Coronavirus pandemic shock, Euro has gained significantly against USD. More importantly, it gives enhanced visibility on the future of European Union. EMAlpha has explored how does this link with overall market sentiment?  The "topical sentiment" scores emphasizes the point that gains for EUR/USD and weak dollar have sent the overall sentiment soaring.',
    category: 'Emerging Markets',
    date: '8-Jun-20',
 },
 {
    title: 'Did World Media Underestimate Coronavirus Crisis in Latin America?',
    summary: 'EMAlpha analysis of news and underlying sentiment indicates that news coverage on Coronavirus is partial. This directly impacts sentiment on threat perception and how serious the crisis will appear in different parts of the world. The contrast in EMAlpha News Sentiment between Europe and the United States versus South America speaks a lot about supremacy of underlying news sentiment over actual facts. This shapes several factors including the local government’s response, foreign aids to deal with the crisis and global sympathy for affected countries.',
    category: 'Emerging Markets',
    date: '14-May-20',
 },
 {
    title: 'Coronavirus Threat and Lockdowns: Which Countries can Afford Them and for How Long?',
   summary: 'An analysis of the country-by-country EMAlpha New Sentiment shows that the Govt’s stance to combat Coronavirus pandemic is dependent on threat sentiment. When we analyse country sentiment scores for our news collection, we find that there are significant differences in the tone and frequency of Coronavirus related news flow. Some of the countries which are still nowhere close to the disastrous impact some others have seen, are still more scared. In reality, a country’s ability to afford a lock down depend on its economy, both size as well as wealth distribution over the population. However, Govt policy on lock downs will be determined more by threat perception than the ability to afford it.',
   category: 'Emerging Markets',
   date: '10-May-20',
 },
 {
    title: 'Facebook-Jio Deal and Stock Price Movement in Reliance Industries Limited Immediately Before that',
    summary: 'Before Reliance Industries Ltd. (the largest market cap company in India) announced a deal with Facebook that the Social Media giant will Invest USD 5.6bn in Reliance-Jio Platforms for a 9.99% Stake, the Reliance stock had shown interesting movement. This movement which was distinctly different in the morning and afternoon session a day prior, provides further evidence about impact of sentiment and news flow on price.',
    category: 'Emerging Markets',
    date: '4-May-20',
 },
 {
    title: 'Are Central Banks Encouraging Risk Taking Behavior in Speculators…Oops…Investors',
    summary: 'EMAlpha Sentiment Analysis based on News flow indicates that the signalling mechanism by Central Banks is a very powerful tool to change the risk perception by market participants. When a Central Banker is telling investors that it is OK to indulge in high risk investments, investors become more adventurous. This has been a driving force for markets as picked in sentiment on commodities and country by country sentiment analysis. How much risk an asset class carries is more dependent on perception EMAlpha analysis confirms.',
    category: 'Emerging Markets',
    date: '1-May-20',
 },
 {
    title: 'Market and Coronavirus Pandemic News: Shifting Pendulum Between Optimism and Pessimism',
    summary: 'On EMAlpha Global Coronavirus news sentiment and country-by-country Coronavirus sentiment, things continue to deteriorate. Most countries are struggling to contain infections and the global Coronavirus sentiment has deteriorated sharply. There are interesting takeaways from EMAlpha News Topic Sentiment for Key Words such as Donald Trump and Chinese Economy, Crude Oil News Sentiment and Aggregate India Equity Markets Sentiment.',
    category: 'Emerging Markets',
    date: '20-Apr-20',
 },
 {
    title: 'Impact of Coronavirus: Is Timing the Markets Possible Using Sentiment Analysis?',
    summary: 'Is it possible to position your portfolio for market volatility in a timely manner? We look at this question using EMAlpha Sentiment Signal as a guide. We consider two major emerging equity markets: Brazil and India and use EMAlpha Sentiment based Signals, constructed using Machine Translation and Sentiment Analysis on news flow in major languages: a) Portuguese and English for Brazil, b) Hindi and English for India, to trade the broad equity indices in these two countries. The result, in terms of the cumulative return in excess of the equity benchmark shows that EMAlpha Sentiment Signal for Brazil and India seem to have navigated the recent unprecedented market volatility quite well.',
    category: 'Emerging Markets',
    date: '13-Apr-20',
 },
 {
    title: 'News Sentiment on Coronavrius Pandemic: Darkest Before the Dawn or No Light at End of the Tunnel?',
    summary: 'Over the last few days, the EMAlpha Coronavirus News Sentiment has deteriorated in eleven out of the twelve countries in our main panel (USA, China, Germany, Brazil, Italy, India, Mexico, Malaysia, Norway, Colombia, Australia and Poland). More importantly, out of the eleven countries in which sentiment has turned negative, more than half (total six) see rather sharp decline. This doesn’t inspire much confidence that we are anywhere close to recovery. EMAlpha Sentiment analysis for some key words such as Donald Trump, Fed Rate, Nasdaq Composite, Chinese Economy and the US Economy, also throw interesting conclusions.',
    category: 'Emerging Markets',
    date: '6-Apr-20',
 },
 {
    title: 'Dichotomy Between News and Reaction: Coronavirus Pandemic Getting worse, But Markets Getting Better',
    summary: 'To assess the market impact of Coronavirus pandemic, EMAlpha tracks several important indicators including, a) Coronavirus Country-by-Country Sentiment, b) Global Coronavirus Sentiment, c) News Topic Sentiment on terms such as “Donald Trump”, “Dow Jones Index”, “Fed Interest Rate”, “Nasdaq Composite”, “Chinese Economy” and “US Economy”, d) Crude Oil related News Sentiment, and, e) Coronavirus Sentiment Heat Map. These EMAlpha Sentiment trackers offer interesting insights on the current state of this pandemic and how News coverage is reacting to it.',
    category: 'Emerging Markets',
    date: '31-Mar-20',
 },
 {
    title: 'For Global Economy and Emerging Markets, How Much Better News Sentiment on US Helps',
    summary: 'There have been interesting takeaways from the EMAlpha sentiment analysis for news flow around some very important and immensely popular key words. These news topics which matter for US Markets and hence, are important for other global markets can also capture the market direction effectively. EMAlpha machines have picked up some improvement in sentiment for US economy and that gels well with the improvement in sentiment for Dow Jones Index and NASDAQ composite both.',
    category: 'Emerging Markets',
    date: '25-Mar-20',
 },
 {
    title: 'Coronavirus News Sentiment: Voltaility and Direction of Indian Markets',
    summary: 'If we map the EMAlpha Aggregate Market Sentiment for India and not just Coronavirus News Sentiment for the country, with the local market indices, there is a clear message that the market volatility may continue in the near term.  The smart recovery on Friday (20th March) followed by an even deeper fall on Monday (23rd March) gels very well with the EMAlpha India Corona News Sentiment. EMAlpha Coronavirus News Sentiment also tells that US markets may continue to remain in deep turmoil.',
    category: 'Emerging Markets',
    date: '24-Mar-20',
 },
 {
    title: 'Coronavirus Pandemic, News Sentiment and Where the Markets Are Going?',
    summary: 'The EMAlpha Emerging Markets model portfolio performance, has delivered significantly better returns versus the market index recently. And it is easy to see the reason. In the recent Coronavirus pandemic driven reaction of Global Financial Markets, Sentiment is a dominant driver of investor behaviour. How does EMAlpha do Sentiment Anlsysis on the news flow? EMAlpha machines are collecting Coronavirus related targeted news flow in a variety of languages. For a number of emerging market countries, most of the news flow is in local language and, hence, we focus on news collection in both the local language and in English. We then use natural language processing tools to extract the sentiment for each country. The details and takeaways are discussed in this insight.',
    category: 'Emerging Markets',
    date: '23-Mar-20',
 },
 {
    title: 'Coronavirus News Sentiment Deteriorating and Peculiarities of Country News Sentiment',
    summary: 'After the already disastrous impact on global health scenario and the severe hit to world economy because of Coronavrius pandemic, the bigger worry now is that the EMAlpha Aggregate Coronavirus sentiment (the sum total of news flow across all countries which we track) continues to plunge. Also, the latest edition of EMAlpha Country by country "Corona Sentiment" based on news flow continues to show further deterioration in many countries including USA. However, things have started to look up in China and India is seeing a further fall in Corona Sentiment and thats bearish.',
    category: 'Emerging Markets',
    date: '18-Mar-20',
 },
 {
    title: 'Human Psychology, High Profile Covid-19 Cases and the Impact on Sentiment',
    summary: 'While huge fluctuations in EMAlpha Coronavirus sentiment continue for different countries, this news flow sentiment is driven by what kind of news items are getting more coverage. For example, the EMAlpha Coronavirus news flow sentiment for Canada has deteriorated sharply and this is because of a high-profile case of infection (Prime Minister’s wife). In USA, the huge volatility noticed in the markets is linked with rapid deterioration on one side and the policy announcements on the other. Similarly, there are interesting trends from other countries as well.',
    category: 'Emerging Markets',
    date: '15-Mar-20',
 },
 {
    title: 'News Sentiment, Market Imapct and Unchecked Spread of Coronavirus Pandemic',
    summary: 'On March 9th 2020, VIX Index, the widely monitored gauge of market volatility, shot up to levels not seen since the Great Financial Crisis more than ten years ago. At EMAlpha, we monitor daily news sentiments in a range of emerging and developed markets and from this, it has become clear that Coronavirus related news sentiment could continue to be a driver of market sentiment for a while. We collect Coronavirus related targeted news flow in a number of languages as for many emerging market countries, most of the news flow is in local language. There are some interesting observations on the market impact of the news and underlying sentiment country by country.',
    category: 'Emerging Markets',
    date: '10-Mar-20',
 },
 {
    title: 'Coronavirus Impact on Markets: Is Local News Sentiment Getting More Important?',
    summary: 'In the initial response to Coronavirus pandemic, most financial markets globally reacted in a predictable negative fashion. But this is slowly changing now and the role of local news flow and underlying sentiment is getting more prominent. For example, after India reported two fresh cases of Coronavirus in the afternoon on 2nd March, Indian markets reacted sharply and closed lower. It is not looking very good on EMAlpha country-by-country sentiment analysis and there could be more downside ahead.',
    category: 'Emerging Markets',
    date: '2-Mar-20',
 },
 {
    title: 'Human Emotions and How Systematic Investing Can Mitigate the Impact on Your Portfolio?',
    summary: 'Often it is difficult to control emotions in the markets and extraordinary events become even more testing. It takes great discipline to not follow emotions in investment and this is what the insight talks about. Even if one has a quantitative, machine learning based model, it is easy to yield to emotions and start tweaking with model parameters. Many quant managers yield to this temptation and tweak the parameters at the worst possible moment. Discipline does not come easy and it takes experience and practice, but it pays off.',
    category: 'Emerging Markets',
    date: '14-Oct-19',
 },
 {
    title: 'Will Huge Cut in Corporate Tax Rate Revive Investor Interest in India?',
    summary: 'The massive corporate tax rate cut in India is a historic step and a massive comforting factor for the industry that the Government is listening to the signals from the ground. However, earnings growth is a challenge for Indian companies and this step in the form of a tax rate cut for companies is an artificial one-time measure. It doesnt do anything to address the core problem of slack in demand. The expectation that the investment cycle will pick up is unrealistic. Having more money is one thing but the corporate will not invest unless they are hopeful on demand.',
    category: 'Emerging Markets',
    date: '22-Sep-19',
 },
 {
    title: 'Emerging Markets and Importance of Managing the Corporate Governance Risk',
    summary: 'To avoid unpleasant surprise, we need to apply a Corporate Governance filter first and then only trade. Another somewhat similar way is to look at the stocks through ESG (Environmental, Social and Governance) framework and then decide what you can invest in and what you should avoid. In EMs especially, that is as important as tracking news flow, understanding investor sentiment or analysing financial statements. In the markets to succeed, the first learning needs to be…how to minimize mistakes and is why ‘Corporate Governance’ helps.',
    category: 'Emerging Markets',
    date: '22-Aug-19',
 },
 {
    title: 'Emerging Markets: Increasing Domestic Retail Participation and Declinging Role for Foreign Investors',
    summary: 'Historically, Foreign Institutional Investors (FIIs) have been a major driving force in Emerging Markets (EMs). FIIs may not always outperform but they have two significant advantages vs. domestic investors, a) they can look at other comparable markets and this is not just in EM space and other markets globally but they can also look at other asset classes and then decide how attractive equities in a particular EM look like, b) the FIIs track news flow carefully and diligently but they don’t get too exuberant or excessively pessimistic unnecessarily and they will approach an EM with more equanimity.  But, do FIIs remain equally important at all times and this is what the insight takes a look at.',
    category: 'Emerging Markets',
    date: '22-Jul-19',
 },
 {
    title: 'Fed Rate Cut Will Help, But Policy Makers in Emerging Markets Need to Do More',
    summary: 'It may appear from the immediate market reaction that a rate cut by Fed will help the EMs. But, this is an illusion. Investor sentiment is impacted as a result of several factors and that is a key thing to focus on in the longer term because fundamental factors will continue to be more important for EMs and that is something the news analysis based sentiment gauge as of now is not really able to capture completely.',
    category: 'Emerging Markets',
    date: '12-Jul-19',
 },
 {
    title: 'Human Behavior and Emerging Markets: The Case of Cricket World Cup and Indians Not Buying Televisions',
    summary: 'The thing about demographic dividend is that everyone will expect that more people with higher purchasing power will continue to drive consumption up. And it comes as a surprise when the companies have started to talk about slowdown. But that is the new reality in India and investor sentiment is seriously affected. The poor sales of televisions in the midst of a Cricket World Cup is an interesting example. The way retail sales of physical stores got impacted because of online purchases, is Television staring at the same fate and what it means?',
    category: 'Emerging Markets',
    date: '10-Jul-19',
 },
 {
    title: 'India Automobiles Sales in Slow Lane and How Robust is the Emerging Market Consumption Story',
    summary: 'The news flow for Indian Auto Industry is turning from bad to worse over previous many months and over last one year and a little more, there have been multiple reports highlighting factors like poor sales numbers and liquidity crunch impacting sales of vehicles. The bigger question is related to consumer demand. One way to look at this is that there may not be any huge structural challenges for discretionary demand in India and challenges related with consumer demand may only be temporary. However, discretionary demand is relatively safer in EMs but we need to keep an eye on the data points.',
    category: 'Emerging Markets',
    date: '6-Jul-19',
 },
 {
    title: 'India’s Central Bank Suffers Another High Profile Exit and The Importance of Strong and Independent Institutions',
    summary: 'Reserve Bank of India (RBI) is one of the oldest monetary institutions in the World and it is much respected as well. Usually, RBI Deputy Governors tend to be away from media glare, but Dr Viral Acharya was different. He was vocal and his statements were often controversial which media could not always ignore. Dr. Acharya, who quit as Reserve Bank of India deputy governor on 24th June, is joining the long list of high profile exits caused by difference of opinion between institutions and ruling dispensation in last few years. These controversies are totally unnecessary because these events reflect very poorly on India’s governance standards and how much respect the government shows to a dissenting opinion.',
    category: 'Emerging Markets',
    date: '26-Jun-19',
 },
 {
    title: 'India and Economic Policy Making: Do Election Results Really Matter?',
    summary: 'In Emerging Markets, political stability and continuity of Government policy is very important for the direction of indices. In this respect, India has been relatively stable over years with democracy taking deep roots and despite changes in political parties which enjoyed power, the Government policy has remained largely consistent and broad contours of economic policies over last three decades have changed for better. Does this mean that elections are irrelevant and unnecessary distraction for markets? This is what we explore in this insight.',
    category: 'Emerging Markets',
    date: '7-Apr-19',
 },
];  
