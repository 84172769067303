import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index'; 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'; 
import FormControl from '@material-ui/core/FormControl'; 
import Select from '@material-ui/core/Select';
import CountryDropdown from './CountryDropdown';
import MultiSelectEsg from './MultiSelectEsg';
import { DateRangePicker } from "materialui-daterange-picker"; 
import loader from '../../../assets/images/loader.gif'; 
import moment from 'moment';
import ESGScores from './ESGScores';
import TCFDCharts from './TCFDCharts';
import SASBOne from './SASBOne';
import News from '../Signin/News';
import StackedChart from './StackedChart';
let userMail = '';

 
class CompanyESG extends Component {

	constructor(props){
    super(props);

    this.state = {
    	open: false,
    	pickeropen:false,
    	dateRangeValue:'01/23/2020 - 02/23/2021',
    	portfolioDateValue:'01/23/2020 - 02/23/2021',
    	dateRange: new Date(),
    	portfolioDateRange: new Date(),
    	stockList:[],
    	countryName :'',
    	indexIntensityData: [],
    	userEmail:'',
    	gocliked: false,
    	goclikedStock:false,
    	disabledGo:false,
    	noRecentStock: true,
    	frameWork:'',
    	selectStocks:"",
    	goclikedtcfd: false,
    	goclikedsasb: false,
    	showFrameworkCharts: false,
    	tcdfChart: false,
    	sasbChart: false,
    	chartFrameName:"",
    	gohitValue: false,
    	dataDescription: "News flow for selected companies",
    	loaderImg: false,
    	tcfdResponseData: null,
    	stockListNameTcfd: null,
    	dateError: "",
      dateErrorKey : false,
      goclikedstacked : false,
    	 
    }
    this.toggle = this.toggle.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
    this.openPortfolioPicker = this.openPortfolioPicker.bind(this);
    this.setDate = this.setDate.bind(this);
    //this.setPortfolioDate = this.setPortfolioDate.bind(this);
    this.callGraphData = this.callGraphData.bind(this);
    this.handleStock = this.handleStock.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleGo = this.handleGo.bind(this);
    this.handleGoStock = this.handleGoStock.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleRecentStocks = this.handleRecentStocks.bind(this);
    this.handleFrameWork = this.handleFrameWork.bind(this);
    this.onInitCountry = this.onInitCountry.bind(this);
    this.handleGoStacked = this.handleGoStacked.bind(this);
    this.handleLoader = this.handleLoader.bind(this);
    //this.handleMessage = this.handleMessage.bind(this);
	    
	}


	componentDidMount(){
		
    if( sessionStorage.getItem('signInEmail') === "" || sessionStorage.getItem('signInEmail') === null){
    	this.setState({
	      userEmail: sessionStorage.getItem('signUpEmail')
	    });
	    userMail = sessionStorage.getItem('signUpEmail')
    }else{
    	this.setState({
	      userEmail: sessionStorage.getItem('signInEmail')
	    });
	    userMail = sessionStorage.getItem('signInEmail')
    }


		this.props.getFrameWorks();
		
	}
	
	
  componentDidUpdate(prevProps, prevState) {
  	if(this.props.frameListHash !== prevProps.frameListHash){
  		this.setState({
  			frameWork: this.props.frameWorkNames[0]
  		})
  	}

  	if(this.props.tcfdrand_hash !== prevProps.tcfdrand_hash){
  		this.setState({
  			goclikedtcfd: false,
  			goclikedsasb: false,
  		})
  		if(this.props.tcfdStatusCode === 1){
  			
  			this.setState({
  				tcfdResponseData : this.props.tcfdResponseData,
  				stockListNameTcfd: this.props.stockListNameTcfd,
  				tcfdStatus: this.props.tcfdStatus,
					tcfdStatusCode: this.props.tcfdStatusCode,
					tcfdrand_hash: this.props.tcfdrand_hash,

  			})
  		}else{
  			this.setState({
  				tcfdStatus: this.props.tcfdStatus,
					tcfdStatusCode: this.props.tcfdStatusCode,
					tcfdrand_hash: this.props.tcfdrand_hash,
  			})
  		}
  	}

  	if(this.props.sasbrand_hash !== prevProps.sasbrand_hash){
  		this.setState({
  			goclikedsasb: false,
  		})
  	}


  }

  toggle(){
  	this.setState({
      open:!this.state.open
    });
  };

	openDatePicker(){
		this.setState({
      open:!this.state.open
    });
  }
  
  togglePicker(){
  	
  	this.setState({
      pickeropen:!this.state.pickeropen
    });
  }

	openPortfolioPicker(){
		this.setState({
	      pickeropen:!this.state.pickeropen
	    });
	}

	setDate(range){
		
		this.setState({
      dateRange:range,
      disabledGo:false,
      selectStocks:"",
    });
		
		
		let endDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.endDate);
		let startDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.startDate);
	
		
		let FinalDate = moment(startDateValue).format("MM/DD/YYYY")+'-'+moment(endDateValue).format("MM/DD/YYYY");

		let year_date = moment(moment(startDateValue).format("MM/DD/YYYY"),"MM/DD/YYYY").year()
    

    if(year_date < "2019"){
     this.setState({
       dateError: "Please choose start date later than 01/01/2019",
       disabledGo: true,
       dateErrorKey : true,
     })
    }else{
      this.setState({
       dateError: "",
       disabledGo: false,
       dateErrorKey : false,
     })
    }
	
		this.setState({
			dateRangeValue:FinalDate,
		    open:false,
		});

  }
  
  

  callGraphData() {

  	if(this.state.stockList.length > 0){
  		this.setState({
	  		gocliked:true,
	  		goclikedStock:true,
	  		disabledGo:true,
	  		noRecentStock: true,
	  		selectStocks: "",
	  		goclikedtcfd: true,
	  		goclikedsasb: true,
	  		showFrameworkCharts: true,
	  		chartFrameName:this.state.frameWork,
	  		gohitValue: true,
	  		dataDescription: "We are fetching the news please wait for few seconds. ",
	  		loaderImg: true,
	  		goclikedstacked:true,
	  	})

	  	let stockString = [];
	  	for(let i=0; i<this.state.stockList.length; i++){
	  	
	  		stockString.push(this.state.stockList[i].name);
	  		
	  	}

	  	let newsParams = JSON.stringify({
				"stocks": stockString,
				"date_range": this.state.dateRangeValue,
				"frame":this.state.frameWork


			});

			this.props.newsESGapi(newsParams);
			//this.props.GoHitCall();
	  

	  	let graphsParams = JSON.stringify({
	     	"country": this.state.countryName,
				"stocks": stockString,
				"date_range": this.state.dateRangeValue,
				"mail_id":userMail,
				"frame":this.state.frameWork


			});

			let pencentileParams = JSON.stringify({
	     	"country": this.state.countryName,
				"stocks": stockString,
				"date_range": this.state.dateRangeValue,
				"frame":this.state.frameWork


			});
			
			this.props.getESGScores(graphsParams);
			this.props.getPercentileRank(pencentileParams)

			let tcfdParams = JSON.stringify({
				"country": this.state.countryName,
				"stocks": stockString,
				"frame_name":this.state.frameWork
			})

			this.setState({
				tcdfChart: true,
				sasbChart : false,
			})

			if(this.state.frameWork === 'TCFD'){
				this.setState({
					tcdfChart: true,
					sasbChart : false,
				})
				
			}else if(this.state.frameWork === 'SASB'){
				this.setState({
					tcdfChart: false,
					sasbChart : true,
				})
			
			}else{
				this.setState({
					tcdfChart: true,
					sasbChart : false,
				})
			
			}

			this.props.tcfdData(tcfdParams)


  	}else{
  		this.setState({
  			selectStocks: "Please choose one or more stocks."
  		})
  	}
  	
  }


  changeHandler() {
  	console.log('change')
  }


  

  handleStock(list){
  	if(this.state.dateErrorKey === true){
	  	this.setState({
	  		stockList:list,
	  		disabledGo:true,
	  		selectStocks:"",
	  	})
	  }else{
	  	this.setState({
	  		stockList:list,
	  		disabledGo:false,
	  		selectStocks:"",
	  	})
	  }
  }

  handleCountry(countryname){
  	
  	this.setState({
  		countryName:countryname,
  		disabledGo:false,
  		tcfdResponseData: null,
  		stockListNameTcfd: null,
  		tcfdStatus: "",
			tcfdStatusCode: "",
			tcfdrand_hash: "",
			selectStocks:"",
  	})

  	if(this.state.dateErrorKey === true){
  		this.setState({
  			disabledGo:true
  		})
  	}else{
  		this.setState({
  			disabledGo:false
  		})
  	}
  }


  handleGo(goValue){
  	
  	this.setState({
  		gocliked:false
  	})
  } 
  handleGoStock(goValue){
  	
  	this.setState({
  		goclikedStock:false
  	})
  }

   handleGoStacked(goValue){
  	this.setState({
  		goclikedstacked:false
  	})
  }

  handleRecentStocks(data){
  
  	if(data !== this.state.noRecentStock){
  		this.setState({
	  		noRecentStock: false,
	  	})
  	}
  	
  }
  handleFrameWork (event){
  if(this.state.dateErrorKey === true){
    this.setState({
    	frameWork: event.target.value,
    	disabledGo:true,
    	tcfdResponseData: null,
    	selectStocks:"",
    })
   }else{
   	this.setState({
    	frameWork: event.target.value,
    	disabledGo:false,
    	tcfdResponseData: null,
    	selectStocks:"",
    })
   }

  };

  onInitCountry(name){
  	
  	this.setState({
  		countryName: name,
  	})
  }

  handleLoader(value){
		/*console.log('yes')
		console.log(value)*/
		this.setState({
			gohitValue: false
		})
  }



	
	render() {
		
		return (
			<div className="esg_company_head"><div className="companyTabmain ESG_tab">
				<div className="container_wrapper">
      		<div className="left_intro">
      			<CountryDropdown onSelectCountry={this.handleCountry}  onInitCountry={this.onInitCountry} frameName = {this.state.frameWork} />
				
						<div className="stock_select">
							<span>Select Stock:</span>
							<MultiSelectEsg onSelectStock={this.handleStock} countryName={this.state.countryName} frameName = {this.state.frameWork} />
						</div>
						<div className="daterange_input">
							<input type="text"  value={this.state.dateRangeValue} onFocus={this.openDatePicker} onChange={this.changeHandler} />

							<i className="fa fa-calendar" aria-hidden="true" onClick={this.openDatePicker}></i>
						</div>
						<p className="error_msg">{this.state.dateError}</p>
						<div className="go_button">
							<a href="javascript:void(0)" onClick={this.callGraphData} className={(this.state.disabledGo ? 'disabledGo' : '')} >Go</a>
						</div>
						<p className="slectStockError">{this.state.selectStocks}</p>
					</div>
					<div className="right_info">
						<div>
							<FormControl>
				        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
				          Select Framework:
				        </InputLabel>
				        <Select
				          labelId="demo-simple-select-placeholder-label-label"
				          id="demo-simple-select-placeholder-label"
				          value={this.state.frameWork}
				          defaultValue={this.state.frameWork}
				          onChange={this.handleFrameWork}
				        >

				        {this.props.frameWorkNames ? this.props.frameWorkNames.map((list,index) => 
				        	 <MenuItem  key={index} value={list}> {list} </MenuItem>
				        ):""}

				         
				        </Select>
				        
				      </FormControl>
						</div>
					</div>

			

					<div className="daterange_picker_custom">
						<DateRangePicker
					      open={this.state.open}
					      toggle={this.toggle}
					      //onChange={(range) => setDateRange(range)}
					      onChange={(range) => this.setDate(range)}
					      format="MM/DD/YYYY"
					      /*minDate= {"01/01/2016"}
					      maxDate= {"01/01/2026"}*/
					    />
					</div>
				</div>
				<div className="graph_section esgCompanyCharts">
					<div>
						<div className="esg_graphs">
							<div className="barchart_area">
								<h5>ESG Score (Percentile Rank)</h5>
								<StackedChart Upload_hash={this.props.percentUpload_hash} final_pct={this.props.pencentResponseData} esgStatusCode={this.props.percentStatusCode} onGoClicked={this.handleGoStacked } />
								{this.state.goclikedstacked ?  <div className="loader_gif">
									<img src={loader} />
								</div> : "" }
							</div>


							<div className="esg_linechart">
								<h5>Daily ESG Score (Independent coverage) </h5>
								<ESGScores esgstockUpload_hash={this.props.esgstockUpload_hash} esgScoresData={this.props.esgData} esgStatusCode={this.props.esgStatusCode} esgstockList={this.props.esgstockList} goClicked={this.state.gocliked} esgStatus={this.props.esgStatus} onGoClick={this.handleGoStock }   />
								{this.state.goclikedStock ?  <div className="loader_gif">
									<img src={loader} />
								</div> : "" }
							</div>

							{this.state.showFrameworkCharts ? 
							<div>
								{this.state.tcdfChart ?<div className="tcfd_charts">
									 {/* <h5>{this.state.chartFrameName} charts</h5>*/}
									 <h5>ESG Score (Self-disclosure based)</h5>
									  <TCFDCharts dataArray={this.state.tcfdResponseData} sbtiStatusCode={this.state.tcfdStatusCode} sbtirand_hash={this.state.tcfdrand_hash} stockListName={this.state.stockListNameTcfd} tcfdStatus={this.state.tcfdStatus} />
									  {this.state.goclikedtcfd ?  <div className="loader_gif">
										<img alt="" src={loader} /> </div>: ""}
								</div> : "" }

								{this.state.sasbChart ?<div className="sasb_charts">
									<h5>{this.state.chartFrameName} charts</h5>
									<div className="bunisness_charts">
									  <h5>Business model & Innovations charts</h5>
									  <SASBOne dataArray={this.state.tcfdStatusCode === 0 ? this.state.tcfdResponseData : this.state.tcfdResponseData ? this.state.tcfdResponseData.Business : "" } sbtiStatusCode={this.state.tcfdStatusCode} sbtirand_hash={this.state.tcfdrand_hash} stockListName={this.state.stockListNameTcfd}  tcfdStatus={this.state.tcfdStatus} />
									  {this.state.goclikedsasb ?  <div className="loader_gif">
										<img alt="" src={loader} /> </div>: ""}
									</div> 

									<div>
									  <h5>Environment</h5>
									  <SASBOne dataArray={this.state.tcfdStatusCode === 0 ? this.state.tcfdResponseData : this.state.tcfdResponseData ? this.state.tcfdResponseData.Environment : ""} sbtiStatusCode={this.state.tcfdStatusCode} sbtirand_hash={this.state.tcfdrand_hash} stockListName={this.state.stockListNameTcfd} tcfdStatus={this.state.tcfdStatus} />
									  {this.state.goclikedsasb ?  <div className="loader_gif">
										<img alt="" src={loader} /> </div>: ""}
									</div> 

									<div>
									  <h5>Human Capital</h5>
									  <SASBOne dataArray={this.state.tcfdStatusCode === 0 ? this.state.tcfdResponseData : this.state.tcfdResponseData ? this.state.tcfdResponseData.Human : ""} sbtiStatusCode={this.state.tcfdStatusCode} sbtirand_hash={this.state.tcfdrand_hash} stockListName={this.state.stockListNameTcfd} tcfdStatus={this.state.tcfdStatus} />
									  {this.state.goclikedsasb ?  <div className="loader_gif">
										<img alt="" src={loader} /> </div>: ""}
									</div> 

									<div>
									  <h5>Leadership & Governance</h5>
									  <SASBOne dataArray={this.state.tcfdStatusCode === 0 ? this.state.tcfdResponseData : this.state.tcfdResponseData ? this.state.tcfdResponseData.Leadership : ""} sbtiStatusCode={this.state.tcfdStatusCode} sbtirand_hash={this.state.tcfdrand_hash} stockListName={this.state.stockListNameTcfd} tcfdStatus={this.state.tcfdStatus} />
									  {this.state.goclikedsasb ?  <div className="loader_gif">
										<img alt="" src={loader} /> </div>: ""}
									</div> 

									<div>
									  <h5>Social Capital</h5>
									  <SASBOne dataArray={this.state.tcfdStatusCode === 0 ? this.state.tcfdResponseData : this.state.tcfdResponseData ? this.state.tcfdResponseData.Social : "" } sbtiStatusCode={this.state.tcfdStatusCode} sbtirand_hash={this.state.tcfdrand_hash} stockListName={this.state.stockListNameTcfd} tcfdStatus={this.state.tcfdStatus} />
									  {this.state.goclikedsasb ?  <div className="loader_gif">
										<img alt="" src={loader} /> </div>: ""}
									</div> 


								</div>: "" }
							</div> : ""}

						</div>
						
					</div>
				</div>
			</div>
		{/*	<News loaderImg={this.state.loaderImg} gohitValue={this.state.gohitValue}  dataDescription ={this.state.dataDescription} nonewsdisplay="false" dataNews=""/>*/}
			<div className="new_container">
				<News loaderImg={this.state.loaderImg} gohitValue={this.state.gohitValue}  dataDescription ={this.state.dataDescription} nonewsdisplay="false" dataNews="" ondataget={this.handleLoader}/>
				{this.state.gohitValue ? <div className="loader_gif">
					<img src={loader} />
				</div>  : "" }
			</div>

			</div>
			
			
		)
	}
}

const mapStateToProps = state => {
  return{
    frameWorkNames: state.esgReducers.frameWorkNames,
    frameListHash: state.esgReducers.frameListHash,
    esgData: state.esgReducers.esgData,
    esgStatusCode: state.esgReducers.esgStatusCode,
    esgstockList: state.esgReducers.esgstockList,
    esgStatus: state.esgReducers.esgStatus,
    esgstockUpload_hash: state.esgReducers.esgstockUpload_hash,

    tcfdResponseData: state.esgReducers.tcfdResponseData,
    tcfdStatus: state.esgReducers.tcfdStatus,
    tcfdStatusCode: state.esgReducers.tcfdStatusCode,
    tcfdrand_hash: state.esgReducers.tcfdrand_hash,
    stockListNameTcfd: state.esgReducers.stockListNameTcfd,

    sasbResponseData: state.esgReducers.sasbResponseData,
    sasbStatus: state.esgReducers.sasbStatus,
    sasbStatusCode: state.esgReducers.sasbStatusCode,
    sasbrand_hash: state.esgReducers.sasbrand_hash,

    pencentResponseData: state.esgReducers.pencentResponseData,
    percentStatus: state.esgReducers.percentStatus,
    percentStatusCode: state.esgReducers.percentStatusCode,
    percentUpload_hash: state.esgReducers.percentUpload_hash,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getFrameWorks : () => dispatch(dataActions.getFrameWorks()),
    getESGScores : (graphsParams) => dispatch(dataActions.getESGScores(graphsParams)),
    getPercentileRank : (graphsParams) => dispatch(dataActions.getPercentileRank(graphsParams)),
    tcfdData : (tcfdParams) => dispatch(dataActions.tcfdData(tcfdParams)),
    sasbData : (tcfdParams) => dispatch(dataActions.sasbData(tcfdParams)),
    newsESGapi : (newsParams) => dispatch(dataActions.newsESGapi(newsParams)),
    //GoHitCall: () => dispatch(dataActions.newsESGapi.GoHitCall())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(CompanyESG);