import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

let fullDatarow = [];
let esgScoresData = [];
let colorShades = ['#003f5c' , '#ffa600' , '#c0504e' , '#9bbb58' , '#a091b0fa'];
let stockNames= [];


class ESGScores extends Component {
	constructor(props){
    super(props);

    this.state = {
    	noData: true,
    	whenNoData: true,
    	nodataValue: 'No stocks selected'
    }
    
 
	}

	
	componentDidUpdate(prevProps, prevState) {
		
		if(this.props.esgStatusCode === 0){

			if(this.props.esgstockUpload_hash !== prevProps.esgstockUpload_hash){
				this.props.onGoClick(false);
				this.setState({
					noData:true,
					whenNoData:!this.state.whenNoData,
					nodataValue: 'No data found'
				});
			}
			if(this.props.esgstockList !== prevProps.esgstockList){
				this.props.onGoClick(false);
			}


			
		}else{
			if(this.props.esgScoresData !== prevProps.esgScoresData){
				this.props.onGoClick(false);
				this.setState({
					noData:false,
					nodataValue: 'No stocks selected',
				});
			}
		}
		
	}

	


	render() {
	
		fullDatarow = [];
		esgScoresData = [];
		stockNames= [];
		//console.log('this.props.esgScoresDataaaaaaaaaaaaa',this.props.esgScoresData)
		if(this.props.esgScoresData){
			this.props.esgScoresData.map((row , index )=> {
				
				let items_row = [];
				esgScoresData = [];
				row.data.map((item , index) => {
						
						esgScoresData.push({
						  x: new Date(item.date[2],item.date[1],item.date[0]),
						  y: parseFloat(item.stockValue),
						});
						items_row =  esgScoresData
				}) 
				
				stockNames.push({
					name: row.stockName
				})
				fullDatarow.push({
					type: "line",
					showInLegend: true ,
					name: "",
					markerType: "none",
					axisYIndex: 1,
					color: colorShades[index],
					toolTipContent:"{y}"+ row.stockName ,
					dataPoints: items_row

				})
			});
		}
		
	

	
	const options = {
		theme: "light2", // "light1", "dark1", "dark2"
		title:{
	      text: ""  
	     },
		axisX:{
		   title:"Date",
		   valueFormatString: "DD-MMM-YY",
		   labelAngle: -50
		},
		
		axisY:[{
			title: "Stocks",
			// interval: 0.2
		}],
		
		data: fullDatarow
	}


	
	
	

	return (
		<div>
			{!this.state.noData ? <div>
				<div className="chart_wrapper"><CanvasJSChart options = {options}
				
				/>
				<div className="whiteStrip"></div></div>
				<div className="line_indicator">
					<ul>
						{stockNames.map((stockName,index) => {
							return <li key={index} >
								<span className="first_shape"></span>
								<span>{stockName.name}</span>
							</li>
						}) }
					</ul>
				</div>
			</div> : <div className="noData">{this.state.nodataValue}</div> }


		</div>
	);
}
}
export default ESGScores;   