import * as action_Types from '../actions/actionTypes'; 

const initialState = {
   insightDataList: [],
   insightDataHTML:[],
   categoryListName: [],
   
}


const insightReducer = (state = initialState, action) => {
   
  switch(action.type) {
    case action_Types.INSIGHTLIST :
     
      return{
        ...state,
        insightDataList: action.insightData
      };
    case action_Types.INSIGHTFILEDATA :

      return{
        ...state,
        insightDataHTML: action.insightDataHTML
      };
    case action_Types.CATEGORYLISTNAMES :
      
      return{
        ...state,
        categoryListName: action.categoryListName
      };
    
    default:
        return state
  }
    
}


export default insightReducer;