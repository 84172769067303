import React, { Component } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import * as dataActions from '../../../store/actions/index';
import {connect} from 'react-redux';

class StockIdMultiSelect extends Component {

	constructor(props){
    super(props);

    this.state = {
    	options: [
    	],
    	selectedStocks:[],
    	countryName:'india',
    }
    this.onSelectStock = this.onSelectStock.bind(this);
    this.onRemoveStock = this.onRemoveStock.bind(this);
    
	    
	}

	componentDidMount(){
		
		let stockListSbti = [];
		
		if(this.props.stockListSbti){
			/*if(this.props.stockListSbti !== prevProps.stockListSbti){*/
			
				this.props.stockListSbti.map((name,index) => {
					

					stockListSbti.push ({
						
						name: name,
						id: index
					})
				})
				
			
				this.setState({
					options:stockListSbti
				});
			/*}*/
		}
	}

	componentDidUpdate(prevProps, prevState) {
		
		let stockListSbti = [];
		//console.log('this.props.stockListSbti',this.props.stockListSbti)
		if(this.props.stockListSbti){
			if(this.props.stockListSbti !== prevProps.stockListSbti){
			
				this.props.stockListSbti.map((name,index) => {
					

					stockListSbti.push ({
						
						name: name,
						id: index
					})
				})
				
			
				this.setState({
					options:stockListSbti
				});
			}
		}
		
		
	}

	onSelectStock(selectedList, selectedItem,e){
		this.setState({
			selectedStocks:selectedList
		});
		this.props.onSelectPortfolio(selectedList);
	}

	onRemoveStock(selectedList, removedItem) {
	 
		this.setState({
			selectedStocks:selectedList
		})
		this.props.onSelectPortfolio(selectedList);
	}

	

	render() {
	
		return (
			<div className="multiselect_stocks">
				<Multiselect
					selectionLimit="10" options={this.state.options} onSelect={this.onSelectStock} onRemove={this.onRemoveStock} displayValue="name"  selectedValues={this.state.selectedStocks} // Options to display in the dropdown
				/>
			</div>
		)
	}
}



export default StockIdMultiSelect;