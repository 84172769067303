import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

let fullDatarow = [];
let portfolioESGValues = [];
let fileNames= [];


class QuantileStats extends Component {
	constructor(props){
    super(props);

    this.state = {
    	noData: true,
    	goClicked: false,
    	sentimentsHashNo: '0a96bbd5feb1111e884e1c2e6d28d7c3',
    	nodataValue: 'No Data Found',
    	
    }
  	//this.handleChange = this.handleChange.bind(this);
 
	}

	componentDidUpdate(prevProps, prevState) {
	
		if(this.props.portfolioESGcode === 1){
			if(this.props.quantilestatsdata !== prevProps.quantilestatsdata){
				this.props.onGoClick(false);
				//console.log('plzzzzzzzzzz 1st');
				
				this.setState({
					noData:false,
					csvError:false,
					nodataValue: 'No portfolio selected',
				});
			}
			
		}
		else{
			if(this.props.portfolioESGhash !== prevProps.portfolioESGhash){
				this.props.onGoClick(false)
				this.setState({
					portfolioESGhash:this.props.sentimentsHashNo,
					noData:true,
					csvError:false,
					nodataValue: 'No data found',
				});
				if( this.props.portfolioESGerror === 'Wrong Header'){
					this.setState({
						csvError:true,
						nodataValue: 'No portfolio selected',
					});
				}
			}
		}

		
	}

	render() {
	

	fullDatarow = [];
	portfolioESGValues = [];
	fileNames= [];
	
	if(this.props.quantilestatsdata){
		// console.log('quantilestatsdata 11111111',this.props.quantilestatsdata[0])

		
		this.props.quantilestatsdata.map((row , index )=> {
			
			//console.log('color',colorShades[index])
			
			let items_row = [];
			portfolioESGValues = [];
			row.data.map((item,index)=> {
				portfolioESGValues.push({
				  label: item.Bucket,
				  y: parseFloat(item.Return),
				});

			 })
			items_row =  portfolioESGValues
			

			

			/*fileNames.push({
				name: row.fileName.split('.')[0],
			})*/
			
			fullDatarow.push({
				type: "column",
				toolTipContent:"{y} Returns",
				dataPoints: items_row

			})
		});

	}
	

	const options = {
		theme: "light2", // "light1", "dark1", "dark2"
		title:{
	      text: ""  
	     },
		axisX:{
		   title:"Bucket",
		   // valueFormatString: "DD-MMM-YY",
		   labelAngle: 0
		},
		
		axisY:[{
			title: "Return %",
			labelAngle: 0,
			// interval: 2
		}],
		data: fullDatarow
		// data: [
		// 	{
		// 		// Change type to "doughnut", "line", "splineArea", etc.
		// 		type: "column",
		// 		dataPoints: [
		// 			{ label: 1,  y: 10  },
		// 			{ label: 2, y: 15  },
		// 			{ label: 3, y: 25  },
		// 			{ label: 4,  y: 30  },
		// 			{ label: 5,  y: 28  }
		// 		]
		// 	}
		// 	]
		
	} 
	

	return (
		<div>
			{this.state.csvError ? <div class="header_error">Headers are not correct in above CSV file.</div> : "" }
			{!this.state.noData ? <div>
				
				<div className="chart_wrapper"><CanvasJSChart options = {options}
					/* onRef={ref => this.chart = ref} */
				/>
				<div className="whiteStrip"></div></div>
				{/*<div className="line_indicator">
					<ul>
						{fileNames.map((fileNames,index) => {
							return <li key={index} >
								<span className="first_shape"></span>
								<span>{fileNames.name}</span>
							</li>
						}) }
					</ul>
				</div>*/}
			</div> : <div className="noData">{this.state.nodataValue}</div> }
		</div>
		);
	}
}
export default QuantileStats;   