import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

let fullDatarow = [];
let stockSentimentsData = [];
let colorShades = ['#003f5c' , '#ffa600' , '#58508d' , '#bc5090' , '#ff6361'];
let stockNames= [];


class DailyStockSentiments extends Component {
	constructor(props){
    super(props);

	    this.state = {
	    	noData: true,
	    	whenNoData: true,
	    	dataStatus: "No stocks selected"
	    }
	    
 
	}
 
	
	componentDidUpdate(prevProps, prevState) {
		
		if(this.props.stockSentimentsData.StatusCode === 0){
			
			if(this.props.stockSentimentsData.charts_hash !== prevProps.stockSentimentsData.charts_hash){
				
				this.props.onGoClick(false);
				if(this.props.stockSentimentsData.Status === "Stocks should be in list"){
					this.setState({
						noData:true,
						whenNoData:!this.state.whenNoData,
						dataStatus: "No stocks selected"
					});
				}else{
					this.setState({
						noData:true,
						whenNoData:!this.state.whenNoData,
						dataStatus: "No data found"
					});
				}
				
			}
			if(this.props.stockSentimentsData.Stock_list !== prevProps.stockSentimentsData.Stock_list){
				this.props.onGoClick(false);
			}


			
		}else{
			 
			if(this.props.stockSentimentsData.charts_hash !== prevProps.stockSentimentsData.charts_hash){
				 // console.log('4 data1');
				this.props.onGoClick(false);
				this.setState({
					noData:false,
					dataStatus: "No stocks selected"
				});
			}
		}
		
	}


	render() {
		
		fullDatarow = [];
		stockSentimentsData = [];
		stockNames= [];
		
		if(this.props.stockSentimentsData.ResponseData){
			this.props.stockSentimentsData.ResponseData.map((row , index )=> {
				
				let items_row = [];
				stockSentimentsData = [];
				row.data.map((item , index) => {
					

						stockSentimentsData.push({
						  x: new Date(item.date[2],item.date[1],item.date[0]),
						  y: parseFloat(item.stockValue),
						});
						items_row =  stockSentimentsData
				}) 
		
				stockNames.push({
					name: row.stockName
				})
				fullDatarow.push({
					type: "line",
					showInLegend: true ,
					name: "",
					markerType: "none",
					axisYIndex: 1,
					color: colorShades[index],
					toolTipContent:"{y}"+ row.stockName ,
					dataPoints: items_row

				})
			});
		}
		
	

	

	const options = {
		theme: "light2",
		title:{
	      text: ""  
	     },
		axisX:{
		   title:"Date",
		   valueFormatString: "DD-MMM-YY",
		   labelAngle: -50
		},
		
		axisY:[{
			title: "Stocks",
			// interval: 1
		}],
		
		data: fullDatarow
	}


	

	return (
		<div>
			{!this.state.noData ? <div>
				<div className="chart_wrapper"><CanvasJSChart options = {options}
					/* onRef={ref => this.chart = ref} */
				/>
				<div className="whiteStrip"></div></div>
				<div className="line_indicator">
					<ul>
						{stockNames.map((stockName,index) => {
							return <li key={index} >
								<span className="first_shape"></span>
								<span>{stockName.name}</span>
							</li>
						}) }
					</ul>
				</div>
			</div> : <div className="noData">{this.state.dataStatus}</div> }


		</div>
	);
}
}
export default DailyStockSentiments;   