import axios from 'axios';
import * as actionTypes from './actionTypes';
import {HOST_URL} from '../Constant'
let AuthToken = sessionStorage.getItem('authToken');



export const setFrameWorkNames = (data) => {
  return{
    type: actionTypes.FRAMENAMES,
    frameList: data.ResponseData,
    frameListHash: data.Upload_hash,
    
  }
}

export const getESGScoresData = (data) => {

  return{
    type: actionTypes.ESGSCORES,
    dataList: data.ResponseData,
    esgCode: data.StatusCode,
    esgStatus: data.Status,
    esgStatus: data.Status,
    
  }
}
export const getESGScoresError = (data) => {
  return{
    type: actionTypes.ESGSCORESERROR,
    esgStatus: data.Status,
    errorCode: data.StatusCode,
    stockList : data.Stock_list,
    Upload_hash : data.Upload_hash,
  }
}

export const esgUploaded = (data) => {
  return{
    type: actionTypes.ESGUPLOADES,
    status: data.Status,
    statusCode: data.StatusCode,
    Upload_hash : data.Upload_hash
  }
}
export const sbtiUploaded = (data) => {
  return{
    type: actionTypes.ESGUPLOADES,
    status: data.Status,
    statusCode: data.StatusCode,
    Upload_hash : data.Upload_hash
  }
}
export const pactaUploaded = (data) => {
  return{
    type: actionTypes.PACTAUPLOADES,
    pactastatus: data.Status,
    pactastatusCode: data.StatusCode,
    pactaUpload_hash : data.Upload_hash
  }
}
 
export const esgNameListItems = (data) => {
  return{
    type: actionTypes.ESGNAMELIST,
    fileNames: data.ResponseData,
    filelistCode: data.StatusCode,
    esgCsvHash: data.Upload_hash,
    csvFilePath: data.csv_file_path,
  }
}
export const sbtiListItems = (data) => {
  return{
    type: actionTypes.ESGSBTINAMELIST,
    nameSbtiList: data.ResponseData,
    statusCodesbti: data.StatusCode,
    esgCsvHashsbti: data.csv_file_hash,
    csvFilePathsbti: data.csv_file_path,
  }
}


export const esgPortfolioScroes = (data) => {
  return{
    type: actionTypes.PORTFOLIOESG,
    dataList: data.ResponseData,
    dataCode: data.StatusCode,
    dataStatus: data.Status,
    quantile_stats_data: data.quantile_stats_data,
    sector_controlled_buckets_data: data.sector_controlled_buckets_data,
    sector_control_data: data.sector_control_data,
    
  }
}
export const esgPortfolioError = (data) => {
  return{
    type: actionTypes.PORTFOLIOESGERROR,
    dataStatus: data.Status,
    dataCode: data.StatusCode,
    dataHash: data.csv_hash,
    
  }
}

// esg workspace 
export const dictionaryUploades = (data) => {
  return{
    type: actionTypes.DICTIONARYUPLOADES,
    status: data.Status,
    statusCode: data.StatusCode,
    Upload_hash : data.Upload_hash
  }
}


export const dictionaryListItems = (data) => {
  return{
    type: actionTypes.DICTIONARYNAMELIST,
    dictionaryNames: data.ResponseData,
    dictionaryCode: data.StatusCode,
    file_hash: data.file_hash,
    file_path: data.dictionary_file_path,
  }
}

export const scoresFiles = (data) => {
  return{
    type: actionTypes.SCORESDATA,
    scoreStatus: data.Status,
    scoreHash : data.Score_hash,
    ResponseData: data.ResponseData,
    scoreStatusCode: data.StatusCode,
    image_folder_path: data.image_folder_path,
    image_name_list: data.image_name_list,
    match_text_co_ordinate_data: data.match_text_co_ordinate_data,
    textHtml: data.textHtml,
  }
}

export const scoresImages = (data) => {
  return{
    type: actionTypes.SCORESIMAGES,
    scoreStatusI: data.Status,
    scoreHashI : data.Score_hash,
    ResponseDataI: data.ResponseData,
    scoreStatusCodeI: data.StatusCode,
    image_folder_pathI: data.image_folder_path,
    image_name_listI: data.image_name_list,
    match_text_co_ordinate_dataI: data.match_text_co_ordinate_data,
    textHtmlI: data.textHtml,
  }
}

export const multipleFiles = (data) => {
  return{
    type: actionTypes.MULTIFILES,
    multiStatus: data.Status,
    Score_files_hash : data.Score_files_hash,
    multiStatusCode: data.StatusCode,
    user_id: data.user_id,
    download_file_name: data.download_file_name,

  }
}
export const downloadSuccess = (data) => {
  return{
    type: actionTypes.DOWNLOADFILES,
    returnMain : true
  }
}


export const sbtiData = (data) => {
  return{
    type: actionTypes.SBTIDATA,
    sbtiResponse: data.ResponseData,
    sbtiStatusCode: data.StatusCode,
    sbtirand_hash: data.rand_hash,

  }
}

export const sbtiTableData = (data) => {
  
  return{
    type: actionTypes.SBTITABLEDATA,
    sbtiTResponse: data.ResponseData,
    sbtiTStatusCode: data.StatusCode,
    sbtiTrand_hash: data.rand_hash,

  }
}

export const compantStockList = (data) => {
  // console.log('dataaaaaa',data)
  return{
    type: actionTypes.COMPANYSTOCKLIST,
    stockListSbti: data.StockName_list,
    sbtiSC: data.StatusCode,
    sbtistockHash: data.Upload_hash,

  }
}

export const gui3Data = (data) => {
  return{
    type: actionTypes.GUI3DATA,
    Contribution_data: data.ResponseData.Contribution_data,
    Investment_data: data.ResponseData.Investment_data,
    Temprature_score_data: data.ResponseData.Temprature_score_data,
    aggregation_data: data.ResponseData.aggregation_data,
    scenario_aggregated_data: data.ResponseData.scenario_aggregated_data,
    gui3StatusCode: data.StatusCode,
    gui3rand_hash: data.rand_hash
  }
}

export const pactaFolderList = (data) => {
  return{
    type: actionTypes.PACTALIST,
    pactaResponseData:data.ResponseData,
    pactaStatus:data.Status,
    pactaStatusCode:data.StatusCode,
    pactacsv_file_hash:data.csv_file_hash,
    pactacsv_file_path:data.csv_file_path,
  }
}


export const pactaChartsData = (data) => {
  return{
    type: actionTypes.PACTADATA,
    pactaGResponseData:data.ResponseData,
    pactaGStatus:data.Status,
    pactaGStatusCode:data.StatusCode,
    pactaGrand_hash:data.rand_hash,
  }
}

export const tcfdChartaData = (data) => {
  return{
    type: actionTypes.TCFDDATA,
    tcfdResponseData:data.ResponseData,
    tcfdStatus:data.Status,
    tcfdStatusCode:data.StatusCode,
    tcfdrand_hash:data.rand_hash,
    stockListNameTcfd: data.stocksNameList
  }
}
export const sasbChartaData = (data) => {
  return{
    type: actionTypes.SASBDATA,
    sasbResponseData:data.ResponseData,
    sasbStatus:data.Status,
    sasbStatusCode:data.StatusCode,
    sasbrand_hash:data.rand_hash,
  }
}

export const newsESGDATA = (data) => {
  return{
    type: actionTypes.NEWSESGDATA,
    ResponseDataNews:data.ResponseData,
    newsStatus:data.Status,
    newsStatusCode:data.StatusCode,
    newsrand_hash:data.charts_hash,
  }
}

export const ESGReportingData = (data) => {
  return{
    type: actionTypes.ESGREPOSRTING,
    ResponseReporting:data.ResponseData,
    reportingStatus:data.Status,
    reportingStatusCode:data.StatusCode,
    reportingrand_hash:data.Score_hash,
  }
}

export const ESGColumnData = (data) => {
  return{
    type: actionTypes.ESGREPOSRTINGCOLOUMN,
    ResponseReportingCell:data.ResponseData,
    reportingStatusCell:data.Status,
    reportingStatusCodeCell:data.StatusCode,
    reportingrand_hashCell:data.rand_hash,
    column_valueData:data.column_value,
    complete_file_pathData:data.complete_file_path,
    uploaded_file:data.uploaded_file,
  }
}


export const ESGReportingDataList = (data) => {
  return{
    type: actionTypes.ESGREPOSRTINGLIST,
    ResponseReportinglist:data.ResponseData,
    reportingStatuslist:data.Status,
    reportingStatusCodelist:data.StatusCode,
    reportingrand_hashlist:data.rand_hash,
  }
}

export const ESGReportingSaved = (data) => {
  return{
    type: actionTypes.ESGREPOSRTINGSAVE,
    complete_file_path:data.complete_file_path,
    saveStatus:data.Status,
    saveStatusCode:data.StatusCode,
    saveUpload_hash:data.Upload_hash,
  }
}

export const getPencentileScores = (data) => {
  return{
    type: actionTypes.ESGPERCENTILERANK,
    pencentResponseData: data.final_pct,
    percentStatus:data.Status,
    percentStatusCode:data.StatusCode,
    percentUpload_hash:data.Upload_hash,
  }
}



export const getFrameWorks = () => {
  return dispatch => {
    axios.get(HOST_URL+'esg/folder/name' , {
        headers: {
          'token' : AuthToken,
        }
      })
      .then(response => {
      	//console.log('response frameee',response);
          dispatch(setFrameWorkNames(response.data));
      })
      .catch(error => {
         console.log('frameee price');
      })
  };
};


export const getESGScores = (graphsParams) => {
  return dispatch => {
    
    axios.post(HOST_URL+'esg/scores', graphsParams , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      if(response.data.Status == 'Success'){
          dispatch(getESGScoresData(response.data))
      }else{
          dispatch(getESGScoresError(response.data))
      }
       
    })
    .catch(error => {
       console.log('error getESGScores');
    })
  };
};


// get pencentile scores bar chart
export const getPercentileRank = (graphsParams) => {
  return dispatch => {
    
    axios.post(HOST_URL+'pct_rank/graph', graphsParams , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      console.log(response.data)
      dispatch(getPencentileScores(response.data))
      
       
    })
    .catch(error => {
       console.log('error getPercentileRank');
    })
  };
};



export const uploadESGFile = (uploadFileParam) => {
  return dispatch => {
    axios.put(HOST_URL+'upload/esg/file' , uploadFileParam , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      dispatch(esgUploaded(response.data))
          
    })
    .catch(error => {
      console.log('error recent stocksss');
    })
  };
};


export const uploadSbtiFile = (uploadFileParam) => {
  return dispatch => {
    axios.put(HOST_URL+'upload/sbti/file' , uploadFileParam , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      dispatch(sbtiUploaded(response.data))
          })
    .catch(error => {
      console.log('error recent stocksss');
    })
  };
};

export const uploadpactaFile = (uploadFileParam) => {
  return dispatch => {
    axios.put(HOST_URL+'upload/pacta/file' , uploadFileParam , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      dispatch(pactaUploaded(response.data))
          
    })
    .catch(error => {
      console.log('error recent stocksss');
    })
  };
};

export const esgNameList = (filenameParam) => {
  return dispatch => {
    axios.post(HOST_URL+'esg/file/name' , filenameParam , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      dispatch(esgNameListItems(response.data))
          
    })
    .catch(error => {
      console.log('error recent stocksss');
    })
  };
};

export const sbtiNameList = (filenameParam) => {
  return dispatch => {
    axios.post(HOST_URL+'sbti/file/name' , filenameParam , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      dispatch(sbtiListItems(response.data))
          
    })
    .catch(error => {
      console.log('error recent stocksss');
    })
  };
};

export const esgDataAllFiles = (portfolioParams) => {
  return dispatch => {
    axios.post(HOST_URL+'addition/esg/scores' , portfolioParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      if(response.data.StatusCode === 1){
        dispatch(esgPortfolioScroes(response.data))
      }else{
        //console.log('this is in esg no data response');
        dispatch(esgPortfolioError(response.data))
      }
      
          
    })
    .catch(error => {
      console.log('error in esg data response');
    })
  };
};



// esg workspace

export const UploadDictionaryFile = (data) => {
  return dispatch => {
    
    axios.put(HOST_URL+'upload/dictionary/file' , data , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      dispatch(dictionaryUploades(response.data))
          
    })
    .catch(error => {
      console.log('error UploadDictionaryFile');
    })
  };
};

export const getDictionaryFilesName = (filenameParam) => {
  return dispatch => {
   
    axios.post(HOST_URL+'dictionary/file/name' , filenameParam , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      dispatch(dictionaryListItems(response.data))
      })
    .catch(error => {
      console.log('error getDictionaryFilesName');
    })
  };
};
export const deleteDictionary = (deleteParams) => {
  return dispatch => {
    axios.delete(HOST_URL+'delete/dictionary/file' , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      },
      data: deleteParams
    })
    .then(response => {
      console.log('res delete', response.data);
      
          
    })
    .catch(error => {
      console.log('error deleteDictionary');
    })
  };
};

export const getScoresText = (scoresParams) => {
  return dispatch => {
    axios.post(HOST_URL+'process_text/text' , scoresParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('res scores text', response.data);
      dispatch(scoresFiles(response.data));
          
    })
    .catch(error => {
      console.log('error getScoresText');
    })
  };
};

export const getScoresFiles = (scoresParams) => {
  return dispatch => {
    axios.post(HOST_URL+'process_text/file' , scoresParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      console.log('res scores filessssss', response.data);
      dispatch(scoresFiles(response.data));
          
    })
    .catch(error => {
      console.log('error getScoresText');
    })
  };
};

export const getScoresImages = (scoresParams) => {
  return dispatch => {
    axios.post(HOST_URL+'process_image/file' , scoresParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      console.log('res scores filessssss', response.data);
      dispatch(scoresFiles(response.data));
          
    })
    .catch(error => {
      console.log('error getScoresText');
    })
  };
};
export const getScoresTextOnly = (scoresParams) => {
  return dispatch => {
    axios.post(HOST_URL+'process_only_text/file' , scoresParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      console.log('res scores filessssss', response.data);
      dispatch(scoresFiles(response.data));
          
    })
    .catch(error => {
      console.log('error getScoresText');
    })
  };
};

export const multiFileSubmit = (fileParam) => {
  return dispatch => {
    axios.post(HOST_URL+'process_multiple_file' , fileParam , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      // console.log('res multiFileSubmit', response.data);
      dispatch(multipleFiles(response.data));
          
    })
    .catch(error => {
      console.log('error multiFileSubmit');
    })
  };
};

export const deleteDownloadFile = (fileParam) => {
  return dispatch => {
    axios.delete(HOST_URL+'delete/download/csv/file', {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      },
      data: fileParam
    })
    .then(response => {
      //console.log('res deleteDownloadFile');
      //dispatch(multipleFiles(response.data));
          
    })
    .catch(error => {
      console.log('error deleteDownloadFile');
    })
  };
}; 


export const deletePortfolioESGCsv = (deleteParams) => {
  return dispatch => {
    axios.delete(HOST_URL+'delete/esg/file' , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      },
      data: deleteParams
      
    })
    .then(response => {
      console.log('response',response.data)
      //dispatch(getUpdatePassword(response.data))
 
    })
    .catch(error => {
      console.log('error deletePortfolioCsv');
    })
  }; 
};

export const deletesbtiGCsv = (deleteParams) => {
  return dispatch => {
    axios.delete(HOST_URL+'delete/sbti/file' , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      },
      data: deleteParams
    })
    .then(response => {
      console.log('response',response.data)
      //dispatch(getUpdatePassword(response.data))
 
    })
    .catch(error => {
      console.log('error deletePortfolioCsv');
    })
  };
};

export const deletePactaCsv = (deleteParams) => {
  return dispatch => {
    axios.delete(HOST_URL+'delete/pacta/file' , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      },
      data: deleteParams
    })
    .then(response => {
      console.log('response',response.data)
      //dispatch(getUpdatePassword(response.data))
 
    })
    .catch(error => {
      console.log('error deletePortfolioCsv');
    })
  };
};

export const sbtiDataCharts = (sbtiParams) => {
  return dispatch => {
    axios.post(HOST_URL+'sbti/gui1/api' , sbtiParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
    /*  console.log('sbtiParams',response.data)*/
      dispatch(sbtiData(response.data))
 
    })
    .catch(error => {
      console.log('error sbtiParams');
    })
  };
};

export const sbtiDataTables = (sbtiParams) => {
  return dispatch => {
    axios.post(HOST_URL+'sbti/gui2/api' , sbtiParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      // console.log('sbtiParams',response.data)
      dispatch(sbtiTableData(response.data))
 
    })
    .catch(error => {
      console.log('error sbtiParams');
    })
  };
};

export const getStocksId = (stocksIdParams) => {
  return dispatch => {
    axios.post(HOST_URL+'sbti/companies/list' , stocksIdParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      // console.log('stocksIdParamsssssss',response.data)
      dispatch(compantStockList(response.data))
 
    })
    .catch(error => {
      console.log('error sbtiParams');
    })
  };
};
export const getDataGUI3 = (gui3Params) => {
  return dispatch => {
    axios.post(HOST_URL+'sbti/gui3/api' , gui3Params , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      // console.log('gui3Paramssssssss',response.data)
      dispatch(gui3Data(response.data))
 
    })
    .catch(error => {
      console.log('error sbtiParams');
    })
  };
};
export const pactaNameList = (filenameParam) => {
  return dispatch => {
    axios.post(HOST_URL+'pacta/file/name' , filenameParam , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
       //console.log('filenameParam',response.data)
      dispatch(pactaFolderList(response.data))
 
    })
    .catch(error => {
      console.log('error sbtiParams');
    })
  };
};
export const pactaGraphs = (pactaParams) => {
  return dispatch => {
    axios.post(HOST_URL+'pacta/api' , pactaParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
       
      dispatch(pactaChartsData(response.data))
 
    })
    .catch(error => {
      console.log('error sbtiParams');
    })
  };
};



export const tcfdData = (tcfdParams) => {
  return dispatch => {
    axios.post(HOST_URL+'get/esg/sus/data' , tcfdParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
       //console.log('responseeeee',response.data)
      dispatch(tcfdChartaData(response.data))
 
    })
    .catch(error => {
      console.log('error sbtiParams');
    })
  };
};
export const sasbData = (tcfdParams) => {
  return dispatch => {
    axios.post(HOST_URL+'get/sasb/data' , tcfdParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
       // console.log('responseeeee 1111111',response.data)
      dispatch(sasbChartaData(response.data))
 
    })
    .catch(error => {
      console.log('error sbtiParams');
    })
  };
};

// news esg
export const newsESGapi = (newsParams) => {
  return dispatch => {
    axios.post(HOST_URL+'news/feed/company_text' , newsParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
     // console.log('responseeeee 1111111',response.data)
      dispatch(newsESGDATA(response.data))
 
    })
    .catch(error => {
      console.log('error sbtiParams');
    })
  };
};

export const newsEMdatapi = (newsParams) => {
  return dispatch => {
    axios.post(HOST_URL+'news/feed/em_feed', newsParams, {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('newsEMdatapi',response.data)
      dispatch(newsESGDATA(response.data))
 
    })
    .catch(error => {
      console.log('error deletePortfolioCsv');
    })
  };
};

// get results esg resporting
export const esgReportingScores = (esgReporting) => {
  return dispatch => {
    axios.post(HOST_URL+'api/helpful', esgReporting, {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('newsEMdatapi',response.data)
      dispatch(ESGReportingData(response.data))
 
    })
    .catch(error => {
      console.log('error esgReporting');
    })
  };
};

// get results second party
export const esgSecondReportingScores = (esgReporting) => {
  return dispatch => {
    axios.post(HOST_URL+'api/second_party_opinion', esgReporting, {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('newsEMdatapi',response.data)
      dispatch(ESGReportingData(response.data))
 
    })
    .catch(error => {
      console.log('error esgReporting');
    })
  };
};

// get coloum list esg reporting
export const getColoumData = (getColoumnParam) => {
  return dispatch => {
    axios.post(HOST_URL+'reporting/files/data', getColoumnParam, {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('getColoumData',response.data)
      dispatch(ESGColumnData(response.data))
 
    })
    .catch(error => {
      console.log('error esgReporting');
    })
  };
};

// get coloum list esg reporting
export const getColoumDataSecond = () => {
  return dispatch => {
    axios.get(HOST_URL+'second/opinion/data', {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      console.log('getColoumData',response.data)
      dispatch(ESGColumnData(response.data))
 
    })
    .catch(error => {
      console.log('error esgReporting');
    })
  };
};


// ESG reporting get list for select
export const getFileList = () => {
  return dispatch => {
    axios.get(HOST_URL+'esg/reporting/files', {
      headers: {
        'token' : AuthToken,
        
      }
    })
    .then(response => {
      //console.log('getFilesParam',response.data)
      dispatch(ESGReportingDataList(response.data))
 
    })
    .catch(error => {
      console.log('error esgReporting');
    })
  };
};


// ESG reporting save
export const esgReportingSave = (esgReportingSave) => {
  return dispatch => {
    axios.post(HOST_URL+'saveapi/helpful', esgReportingSave ,{
      headers: {
        'token' : AuthToken,
         'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      console.log('esgReportingSave',response.data)
      dispatch(ESGReportingSaved(response.data))
 
    })
    .catch(error => {
      console.log('error esgReporting');
    })
  };
};



// ESG reporting reset
export const resetHandleApi = (esgreset) => {
  return dispatch => {
    axios.post(HOST_URL+'reset/api/helpful', esgreset ,{
      headers: {
        'token' : AuthToken,
         'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      console.log('resetHandle',response.data)
      //dispatch(ESGReportingSaved(response.data))
 
    })
    .catch(error => {
      console.log('error esgReporting');
    })
  };
};

// ESG reporting upload
export const uploadNewFramework = (data) => {
  return dispatch => {
    axios.post(HOST_URL+'upload/api/helpful', data ,{
      headers: {
        'token' : AuthToken,
         'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('uploadNewFramework',response.data)
      dispatch(ESGColumnData(response.data))
 
    })
    .catch(error => {
      console.log('error esgReporting');
    })
  };
};




