import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

let fullDatarow = [];
let investmentValue = [];


class InvestmentsPie extends Component {

	componentDidUpdate(prevProps,prevState){
		if(this.props.sbtirand_hash !== prevProps.sbtirand_hash){
			
		}
	}

	render() {
		fullDatarow = [];
		investmentValue= [];
		if(this.props.dataArray){
		// console.log('quantilestatsdata 11111111',this.props.quantilestatsdata[0])

		
			let items_row = [];
			this.props.dataArray.map((row , index )=> {
			
				investmentValue.push({
				  label: row.sector_name,
				  y: (row.investment_value),
				});


			})
			items_row =  investmentValue;

			fullDatarow.push({
				type: "pie",
				showInLegend: true,
				legendText: "{label}",
				toolTipContent: "{label}: <strong>{y}%</strong>",
				indexLabel: "{y}%",
				indexLabelPlacement: "outside",
				dataPoints: items_row

			})
		}
		

		const options = {
			theme: "light",
			animationEnabled: true,
			
			title:{
				text: "Investments",
				fontSize: 20,
			},
			data: fullDatarow
			
		}
		return (
		<div>
			<CanvasJSChart options = {options}
				/* onRef={ref => this.chart = ref} */
			/>
			
		</div>
		);
	}
}
export default InvestmentsPie;       