import React, { Component } from 'react';
import Header from '../CommonSection/Header/Header'; 
import {connect} from 'react-redux';
import * as dataActions from '../../store/actions/index';
import axios from 'axios';
import {HOST_URL} from '../../store/Constant';



class Layout extends Component {

	constructor() {

    super();
    this.state = {
      
    };
    /*this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);*/

  }

 



async componentDidMount() {
	//console.log('this.props Layout',window.location.href)
	let pageUrl = window.location.href;
	pageUrl = pageUrl.substring(pageUrl.lastIndexOf("/") + 1, pageUrl.length);
	let tokenParam = JSON.stringify({
   		"mail_id":sessionStorage.getItem('signInEmail'),
   		"password":sessionStorage.getItem('signInPassword'),
	});

	if(parseInt(sessionStorage.getItem('signinStatus')) === 1){
		//console.log('yes signin')
		axios.post(HOST_URL+'refresh/token', tokenParam,{
      headers: {
          
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
        // console.log('responsee8888',response)
        
        sessionStorage.setItem('authToken', response.data.token);
     
    })
    .catch(error => {
       console.log('error signin');
    })
	 

	try {
	    setInterval(async () => {
	    	//console.log('in interval')
	      axios.post(HOST_URL+'refresh/token', tokenParam,{
		      headers: {
		          
		          'Content-Type': 'application/json',
		          'dataType' : 'json',
		      }
		    })
		    .then(response => {
		         //console.log('token expired',response)
		        sessionStorage.clear();
		        window.location.reload(false);
		        sessionStorage.setItem('authToken', response.data.token);
		     
		    })
		    .catch(error => {
		       console.log('error signin');
		    })

	    }, 17700000);
	  } catch(e) {
	    console.log(e);
	  }
	}
	else{
		console.log('not signin')

	}

}
 


	render() {
		
		return (
			<div>
				<Header />
				<main>
          {this.props.children}
        </main>
        {/*<Footer /> */}
			</div>
		)
	}
}


const mapStateToProps = state => {
    return{
        //signupMessage: state.graphReducer.signupMessage,
        
    };
}

const mapDispatchToProps = dispatch => {
    return {
        refreshToken : (tokenParam) => dispatch(dataActions.refreshToken(tokenParam)),

        
    }
}




export default connect(mapStateToProps,mapDispatchToProps)(Layout);