export default [
 {
    title: 'Oil Prices and S&P 500: Which One is in Drivers Seat?',
    summary: 'There is a huge similarity in Oil Price Movement and S&P 500 as the dip, recovery and volatility are virtually the same for both. While the drivers are so different for Oil and US markets, there is evidence that while Fed’s actions may not directly help the Oil demand, Fed action made oil markets more stable and helped prices recover. We explore this on the basis of EMAlpha Oil News Sentiment.',
    category: 'Commodities',
    date: '17-Jun-20',
 },
 {
     title: 'Oil News Sentiment Captures the Firmness in Crude Prices, Is the worst over for Oil?',
     summary: 'The EMAlpha Oil News Sentiment is volatile but it is clearly showing a significant improvement in last fifteen days. As such the average EMAlpha Oil News Sentiment in May is much more positive than either March or April and in last two weeks, the volatility also has been on the lower side. This implies that the news Sentiment is doing a good job in making the price forecast and on the basis of EMAlpha Oil News sentiment, it is unlikely that Oil is going below 30 dollars.',
     category: 'Commodities',
     date: '29-May-20',
  },

  {
    title: 'Oil Sentiment has Conflicting Signals from IEA and Saudi Aramco Stock Price',
   summary: 'EMAlpha Oil News Sentiment is having its ‘break out’ moment and this change is telling us that we are past the worst of Coronavirus crisis and things will get better from now on for Oil. We looked at other possible signals on what may happen next and the most interesting were two data points which are giving us conflicting view. The first is Saudi Aramco Stock price and the second data point is International Energy Agency (IEA) forecast. What do they tell us and how to read it?',
   category: 'Commodities',
   date: '16-May-20',
 },
 {
    title: 'Is Oil Sentiment Telling Us that Demand Recovery has Begun and How Long it Will Take?',
    summary: 'One of the most closely tracked sentiments at EMAlpha is on Oil News as the short-term price movement in crude depends on near term outlook, which is influenced heavily by the news flow sentiment. If we go by the prevailing sentiment, the demand is unlikely to pick up anytime soon. However, the EMAlpha Oil News Sentiment is having its ‘break out’ moment and the Oil News sentiment has scaled its new peak. This is an entirely different picture from what we had seen in the month of April. Is this change in oil news sentiment telling us that we are past the worst of Coronavirus crisis?',
    category: 'Commodities',
    date: '14-May-20',
 },
 {
    title: 'Oil Sentiment has Already Hit Rock Bottom, But Will it Plunge even More?',
    summary: 'While the drop in Oil prices is putting huge pressure on producers, they can’t easily agree on who needs to cut how much of production. The drop in demand and low crude prices actually made some of them more desperate in their attempts to gain market share. This is also visible on EMAlpha Oil News Sentiment tracker. While the market movements are related to specific reasons, the general sentiment on the demand side is extremely negative. EMAlpha Oil News Sentiment is at the lowest levels after it has hit an all-time low on April 20th. Because of this commodity’s strong linkage with global economy, will this have implications for financial markets worldwide?',
    category: 'Commodities',
    date: '27-Apr-20',
 },
 {
    title: 'Oil’s Historic Fall: Precipitated by Quickly Worsened Sentiment amid Demand Concerns',
    summary: 'As May WTI Futures traded in Negative, the historic fall in crude oil prices prompted us to investigate this issue further on the lines of how EMAlpha Oil news sentiment fared around that time. After Coronavirus pandemic has become a global issue, the market performance in several countries is strongly linked with local news flow and underlying sentiment on Coronavirus related developments. This applies to Oil too as the EMAlpha Oil News sentiment plot shows how the oil sentiment has fallen to a level lower than what it was in mid-March when the Saudi-Russia talks broke down and oil price and stock market fell.',
    category: 'Commodities',
    date: '22-Apr-20',
 },
{
    title: 'Crude Oil and Coronavirus Pandemic: Oil Futures in Negative and How Machines are Reading it?',
    summary: 'Monday, 20th April 2020 was a historic day for Crude Oil Industry as the May US oil futures contract in went into negative territory for the first time in history. The US reserves are full and there is simply no place to put the crude and everyone is avoiding taking delivery of physical crude. But it is not just a storage problem. There is limited visibility on when the demand will recover. This is also visible on EMAlpha Oil News Sentiment tracker as well. When seen in the context of SAUDI Aramco’s stock price movement and recovery in S&P 500, we can conclude that investors are less worried about markets in general and the concern is limited to Oil only.',
    category: 'Commodities',
    date: '21-Apr-20',
 },
   {
    title: 'The Listing of Saudi Aramco: Messages Beyond Emerging Markets',
    summary: 'As EMAlpha has noticed in several Emerging Markets, the Sentiment Analysis of News flow is a bigger game changer in countries where the information flow is irregular or the ones which are not understood that well by global investors. In this context, there are important takeaways from what the Initial Public Offering of Saudi Aramco means for the region and for the local and Emerging Markets in general. As we see in this insight, Market inefficiencies and investment opportunities will remain till the time financial markets exist and hence, an efficient and timely analysis of news and prevailing market sentiment could be the difference between gains and losses.',
    category: 'Commodities',
    date: '9-Feb-20',
 },
];  
