import React from 'react';
import './App.css';
import {Route} from 'react-router-dom';
import Layout from './Components/layout/Layout.js';
import Signin from './Components/layout/Signin/Signin';
import ForgotPassword from './Components/layout/Signin/ForgotPassword/ForgotPassword';
import Home from './Components/layout/Home/Home';
import Products from './Components/layout/Products/Products';
import Insights from './Components/layout/Insights/Insights';
import innerInsights from './Components/layout/Insights/innerInsights/innerInsights';
import mainTabs from './Components/layout/mainTabs/mainTabs';

function App() {
  return (
    <div className="App">
	    <Layout>
			<Route path="/" exact component={Home} />
			<Route path="/signin" component={Signin} />
			<Route path="/forgot_password" component={ForgotPassword} />
			<Route path="/products" component={Products} />
			<Route path="/insights" exact component={Insights} />
			<Route path="/insights/" component={innerInsights} />
			<Route path="/analytics" component={mainTabs} />
			
	    </Layout>
    </div>
  );
}

export default App;
