import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(id, name, contribution, percentage,portfolio ,sector ,temperature) {
  return { id, name, contribution, percentage ,portfolio ,sector ,temperature };
}


const rows = [
  /*createData( 159, 6.0, 24, 4.0,1,1,1 ),
  createData( 237, 9.0, 37, 4.3,1,1,1 ),
  createData( 262, 16.0, 24, 6.0,1,1,1 ),
  createData( 305, 3.7, 67, 4.3,1,1,1 ),
  createData( 356, 16.0, 49, 3.9,1,1,1 ),*/
];

export default function ScenarioTable(props) {

  
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
     {props.sbtiTResponse.length ? <Table className={classes.table} aria-label="simple table">
       {/* <TableHead>
          <TableRow>
            <TableCell align="center">{props.sbtiTResponse[0].data_info[0]}</TableCell>
            <TableCell align="center">{props.sbtiTResponse[0].data_info[1]}</TableCell>
            <TableCell align="center">{props.sbtiTResponse[0].data_info[2]}</TableCell>
            
          </TableRow>
        </TableHead>*/}
        <TableBody>
         
            <TableRow key='1'>
              <TableCell align="center">{props.sbtiTResponse[1].row1[0]}</TableCell>
              <TableCell align="center">{props.sbtiTResponse[1].row1[1]}</TableCell>
              <TableCell align="center">{props.sbtiTResponse[1].row1[2]}</TableCell>
            </TableRow>
             <TableRow key='1'>
              <TableCell align="center">{props.sbtiTResponse[2].row2[0]}</TableCell>
              <TableCell align="center">{props.sbtiTResponse[2].row2[1]}</TableCell>
              <TableCell align="center">{props.sbtiTResponse[2].row2[2]}</TableCell>
            </TableRow>
         
        </TableBody>
      </Table> : ""}
    </TableContainer>
  );
}