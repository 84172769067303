import axios from 'axios';
import * as actionTypes from './actionTypes';
import {HOST_URL} from '../Constant';
let AuthToken = sessionStorage.getItem('authToken');


export const setPrice = (data) => {
  return {
    type: actionTypes.FETCH_PRICE,
    data: data
  };
};
export const setSentiments = (data) => {
  return {
    type: actionTypes.FETCH_SENTIMENTS,
    data: data
  };
};
export const setNews = (data) => {
  return {
    type: actionTypes.FETCH_NEWS,
    data: data
  };
};

export const signUp = (Status, StatusCode) => {
  return {
    type: actionTypes.SIGNUP,
    data: Status,
    status: StatusCode
  };
}

export const signIn = (Status, StatusCode) => {
  return {
    type: actionTypes.SIGNIN,
    data: Status,
    status: StatusCode
  };
}
export const countryList = (countryArray) => {
  return {
    type: actionTypes.COUNTRYLIST,
    data: countryArray
  };
}
export const countryListESG = (countryArray) => {
  return {
    type: actionTypes.COUNTRYESGLIST,
    data: countryArray.Country_name_list,
    countryListHash : countryArray.Upload_hash
  };
}
export const indexIntencityData = (data) => {
  return {
    type: actionTypes.INDEXINTENSITYDATA,
    data: data
  }
}
export const indexSentimentsData = (data) => {
  return {
    type: actionTypes.INDEXSENTIMEANTSDATA,
    data: data
  }
}
export const stockIntensityData = (data) => {
  return {
    type: actionTypes.STOCKINTENSITYDATA,
    data: data
  }
}
export const stockSentimentsData = (data) => {
  return {
    type: actionTypes.STOCKSENTIMENTSDATA,
    data: data
  }
}
export const handleStockList = (data) => {
  return {
    type: actionTypes.STOCKLISTNAMES,
    data: data
  }
}
export const handleESGStockList = (data) => {
  return {
    type: actionTypes.ESGSTOCKLISTNAMES,
    StockList_esg_data: data.StockName_list,
    statusStock: data.Status,
    statuscodeStock: data.StatusCode,
    Upload_hashStocks: data.Upload_hash,
  }
}
export const recentStocks = (data) => {
  return {
    type: actionTypes.RECENTSTOCKS,
    data: data
  }
}

export const fileUploaded = (data) => {
  return{
    type: actionTypes.FILEUPLOADES,
    status: data.Status,
    statusCode: data.StatusCode,
    Upload_hash : data.Upload_hash,
  }
}

export const fileNameList = (data) => {
  return{
    type: actionTypes.FILENAMELIST,
    fileNames: data.ResponseData,
    filelistCode: data.StatusCode,
    csv_file_path: data.csv_file_path,
    csvFilehash: data.csv_file_hash
  }
}
export const portfolioSentiments = (data) => {
  return{
    type: actionTypes.PORTFOLIOSENTIMENTS,
    dataList: data.ResponseData,
    dataCode: data.StatusCode,
    
  }
}
export const portfolioError = (data) => {
  return{
    type: actionTypes.PORTFOLIOERROR,
    dataStatus: data.Status,
    dataCode: data.StatusCode,
    dataHash: data.charts_hash,
    
  }
}
export const portfolioIntensity = (data) => {
  return{
    type: actionTypes.PORTFOLIOINTENSITY,
    dataList: data.ResponseData,
    dataCode: data.StatusCode
  }
}
export const IntensityError = (data) => {
  return{
    type: actionTypes.INTENSITYERROR,
    dataStatus: data.Status,
    dataCode: data.StatusCode,
    dataHash: data.csv_hash,
    
  }
}

export const getItemList = (data) => {
  return{
    type: actionTypes.FOLDERLISTDATA,
    listName: data.ResponseData,
    listCode: data.StatusCode,
    listStatus: data.Status,
    rand_hash: data.rand_hash,
    
  }
}

export const getForgetmail = (data) => {
  return{
    type: actionTypes.FORGETMAIL,
    statusMail:data.Status,
    statusCodeMail:data.StatusCode,
    hashMail:data.rand_hash ,
    
  }
}

export const getUpdatePassword = (data) => {
  return{
    type: actionTypes.UPDATEPASSWORD,
    statusUpdatepass:data.Status,
    statusUpdateCode:data.StatusCode,
    hashUpdatepass:data.rand_hash ,
    
  }
}



export const fetchPrice = (typevalue , tabvalue) => {
  return dispatch => {
    axios.get(HOST_URL+tabvalue+'/'+typevalue+'/files/price' , { 
        headers: {
          'token' : AuthToken,
        }
      })
      .then(response => {
        // console.log('response',response);
          dispatch(setPrice(response.data));
      })
      .catch(error => {
         console.log('error price');
      })
  };
};

export const fetchSentiments = (typevalue , tabvalue) => {
  return dispatch => {
    axios.get(HOST_URL+tabvalue+'/'+typevalue+'/files/sentiment_scores' ,{ 
        headers: {
          'token' : AuthToken,
        }
      })
      .then(response => {
        // console.log('response',response);
          dispatch(setSentiments(response.data));
      })
      .catch(error => {
         console.log('error sentiments');
      })
  };
};

export const fetchNews = (typevalue , tabvalue) => {
  return dispatch => {
    axios.get(HOST_URL+tabvalue+'/'+typevalue+'/files/news_summary',{
      headers: {
          'token' : AuthToken,
        }
      })
      .then(response => {
        // console.log('response11111111',response);
          dispatch(setNews(response.data));
      })
      .catch(error => {
         console.log('error news');
      })
  };
};

export const signUpUser = (signUpParams,email) => {
  return dispatch => {
    axios.post(HOST_URL+'signup', signUpParams,{
      headers: {
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      /*console.log('response signup',response.data.StatusCode);
      console.log('response signup',response.data.Status);
      console.log('signUpParams111111',signUpParams);
      console.log('signUpParams222222',signUpParams.mail_id);*/
      if(response.data.StatusCode == 1){
        dispatch(signUp(response.data.Status, response.data.StatusCode));
        sessionStorage.clear();
        sessionStorage.setItem('signupStatus', response.data.StatusCode);
        sessionStorage.setItem('signUpEmail', email);
      }else{
        dispatch(signUp(response.data.Status, response.data.StatusCode));
      }
    })
    .catch(error => {
       console.log('error signup');
    })
  };
};


export const signInUser = (signInParams,email,password) => {
  return dispatch => {
    axios.post(HOST_URL+'signin', signInParams,{
      headers: {
          
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      /*console.log('response signin',response.data.StatusCode);
      console.log('response signin',response.data.Status);  
      console.log('signUpParams111111',signInParams);
      console.log('signUpParams111111',signInParams.mail_id);*/
        //dispatch(signIn(response.data.Status, response.data.StatusCode));
      //if(response.data.StatusCode === 1){
      //console.log('response1111000',response.data.access_data)
      if(response.data.StatusCode == 1){
        dispatch(signIn(response.data.Status, response.data.StatusCode));
        sessionStorage.clear();
        sessionStorage.setItem('signinStatus', response.data.StatusCode);
        sessionStorage.setItem('signInEmail', email);
        sessionStorage.setItem('signInPassword', password);
        sessionStorage.setItem('authToken', response.data.token);
        sessionStorage.setItem('Climate_Data', response.data.Climate_Data);
        sessionStorage.setItem('Company_Data', response.data.Company_Data);
        sessionStorage.setItem('ESG_Data', response.data.ESG_Data);
        sessionStorage.setItem('ESG_Lens', response.data.ESG_Lens);
        sessionStorage.setItem('ESG_Reporting', response.data.ESG_Reporting);
        sessionStorage.setItem('Insights', response.data.Insights);
        //sessionStorage.setItem('accessToken', response.data.access);

      }else{
        dispatch(signIn(response.data.Status, response.data.StatusCode));
      }
    })
    .catch(error => {
       console.log('error signin');
    })
  };
};



export const refreshToken = (tokenParam) => {
  return dispatch => {
    axios.post(HOST_URL+'refresh/token', tokenParam,{
      headers: {
          
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
        // console.log('responsee8888',response)
        sessionStorage.clear();
        sessionStorage.setItem('authToken', response.data.token);
    })
    .catch(error => {
       console.log('error signin');
    })
  };
};



export const getCountryNames = () => {
    return dispatch => {
      axios.get(HOST_URL+'country/filter',{
          headers: {
              'token' : AuthToken,
              'Content-Type': 'application/json',
              'dataType' : 'json',
          }
      })
      .then(response => {
         // console.log('response country ',response.data);
          dispatch(countryList(response.data))
      })
      .catch(error => {
         //console.log('error country names');
      })
    };
};

export const getESGCountryNames = (listparams) => {
    return dispatch => {
      axios.get(HOST_URL+'esg/country/filter' ,{
          headers: {
              'token' : AuthToken,
              'Content-Type': 'application/json',
              'dataType' : 'json',
          }
      })
      .then(response => {
        // console.log('response country ',response.data);
        dispatch(countryListESG(response.data))
      })
      .catch(error => {
         console.log('error country names');
      })
    };
};

export const indexIntensityCall = (graphsParams) => {
  return dispatch => {
    //console.log('graphsParamsssssssssssssssssssssss',graphsParams)
    axios.post(HOST_URL+'index/intensity/scores', graphsParams , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      if(response.data.Status == 'Success'){
          //console.log('response data', response.data);
          dispatch(indexIntencityData(response.data))
      }else{
          //console.log('response data not success', response.data);
          dispatch(indexIntencityData(response.data))
      }
    })
    .catch(error => {
      console.log('error stockkkkkkk names');
    })
  };
};
export const indexSentimentsCall = (graphsParams) => {
    return dispatch => {
      //console.log('graphsParams77777777777777777',graphsParams)
      axios.post(HOST_URL+'index/sentiment/scores', graphsParams , {
          headers: {
              'token' : AuthToken,
              'Content-Type': 'application/json',
              'dataType' : 'json',
          }
      })
      .then(response => {
          if(response.data.Status == 'Success'){
              //console.log('response 2 data', response.data);
              dispatch(indexSentimentsData(response.data))
          }else{
              //console.log('response 2 not success', response.data);
              dispatch(indexSentimentsData(response.data))
          }
      })
      .catch(error => {
         console.log('error stockkkkkkk 2 names');
      })
    };
};
export const stockIntensityCall = (graphsParams) => {
  return dispatch => {
    //console.log('graphsParams11111',graphsParams)
    axios.post(HOST_URL+'stock/intensity/scores', graphsParams , {
        headers: {
            'token' : AuthToken,
            'Content-Type': 'application/json',
            'dataType' : 'json',
        }
    })
    .then(response => {
        if(response.data.Status == 'Success'){
            //console.log('response 3 data', response.data);
            dispatch(stockIntensityData(response.data))
        }else{
            //console.log('response 3 not success', response.data.Status);
            dispatch(stockIntensityData(response.data))
        }
       
    })
    .catch(error => {
       console.log('error stockkkkkkk 3 names');
    })
  };
};
export const stockSentimentsCall = (graphsParams) => {
  return dispatch => {
    //console.log('graphsParams11111',graphsParams)
    axios.post(HOST_URL+'stock/sentiment/scores', graphsParams , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('response 4 data', response.data);
      if(response.data.Status == 'Success'){
          //console.log('response 4 data', response.data);
          dispatch(stockSentimentsData(response.data))
      }else{
          //console.log('response 4 not success', response.data);
          dispatch(stockSentimentsData(response.data))
      }
    })
    .catch(error => {
      console.log('error stockkkkkkk 4 names');
    })
  };
};


// stock list 
export const getStockList = (stockParams) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.post(HOST_URL+'stockslist' ,stockParams , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('response stockListttttttttttt', response.data);
      dispatch(handleStockList(response.data.StockName_list))
          
    })
    .catch(error => {
      console.log('error stocksss');
    })
  };
};
// stock list ESG
export const getESGStockList = (stockParams) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.post(HOST_URL+'esg/stockslist' ,stockParams , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      // console.log('response stockListttttttttttt ESG', response.data);
      dispatch(handleESGStockList(response.data))
          
    })
    .catch(error => {
      console.log('error stocksss');
    })
  };
};

// recent stock list 
export const recentStockCall = (recentStockParams) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.post(HOST_URL+'recent/search/stocks' ,recentStockParams , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('response stockListttttttttttt', response.data);
      dispatch(recentStocks(response.data))
          
    })
    .catch(error => {
      console.log('error recent stocksss');
    })
  };
};

export const uploadFile = (uploadFileParam) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.put(HOST_URL+'upload/csv/file' , uploadFileParam , {
      headers: {
          'token' : AuthToken,
          'Content-Type': 'application/json',
          'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('res', response.data);
      dispatch(fileUploaded(response.data))
          
    })
    .catch(error => {
      console.log('error recent stocksss');
    })
  };
};

export const csvNameList = (filenameParam) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.post(HOST_URL+'csv/file/name' , filenameParam , {
      headers: {
          'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('res', response.data);
      dispatch(fileNameList(response.data))
          
    })
    .catch(error => {
      console.log('error recent stocksss');
    })
  };
};

export const avagPortfolioSentiments = (portfolioParams) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.post(HOST_URL+'addition/sentiment' , portfolioParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      //console.log('res avagPortfolioSentiments', response.data);
      if(response.data.StatusCode === 1){
        //console.log('sad');
        dispatch(portfolioSentiments(response.data))
      }else{
        //console.log('5435');
        dispatch(portfolioError(response.data))
      }
      
          
    })
    .catch(error => {
      console.log('error avagPortfolioSentiments');
    })
  };
};

export const avagPortfolioIntensity = (portfolioParams) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.post(HOST_URL+'addition/intensity' , portfolioParams , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      console.log('res avagPortfolioIntensity', response.data);
      if(response.data.StatusCode === 1){
        //console.log('sad44');
        dispatch(portfolioIntensity(response.data))
      }else{
        //console.log('qewqepp');
        dispatch(IntensityError(response.data))
      }
          
    })
    .catch(error => {
      console.log('error avagPortfolioIntensity');
    })
  };
};

export const getFolderList = (folderName) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.post(HOST_URL+'get/folder/name' , folderName , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'Accept' : 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      // console.log('res getFolderList', response.data);
      dispatch(getItemList(response.data))
          
    })
    .catch(error => {
      console.log('error getFolderList');
    })
  };
};


// forgot password 

export const forgotPassword = (forgotParams) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.post(HOST_URL+'validate/mail' , forgotParams , {
      headers: {
      /*  'token' : AuthToken,*/
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      console.log('res forgotParams', response.data);
      dispatch(getForgetmail(response.data))
          
    })
    .catch(error => {
      console.log('error getFolderList');
    })
  };
};


// set new password 


export const setNewpassword = (resetPasswordParams ,mail_id ,password_id ) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.post(HOST_URL+'update/password' , resetPasswordParams , {
      headers: {
        /*'token' : AuthToken,*/
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      console.log('ressssss forgotParamsssssss', response.data);

      /*sessionStorage.clear();
      sessionStorage.setItem('signinStatus', response.data.StatusCode);
      dispatch(getUpdatePassword(response.data))*/

      sessionStorage.clear();
      sessionStorage.setItem('signinStatus', response.data.StatusCode);
      sessionStorage.setItem('signInEmail', mail_id);
      sessionStorage.setItem('signInPassword', password_id);
      sessionStorage.setItem('authToken', response.data.token);
      sessionStorage.setItem('accessToken', response.data.access);
      dispatch(getUpdatePassword(response.data))


          
    })
    .catch(error => {
      console.log('error forgotParams');
    })
  };
};




export const deletePortfolioCsv = (deleteParams) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.delete(HOST_URL+'delete/csv/file' , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      },
      data: deleteParams
    })
    .then(response => {
      console.log('response',response.data)
      //dispatch(getUpdatePassword(response.data))

    })
    .catch(error => { 
      console.log('error deletePortfolioCsv');
    })
  };
};

export const downloadAllFiles = (userMail, fileName , folderName) => {
  return dispatch => {
    //console.log('graphsParamsttttttttttttttttttttt',stockParams)
    axios.get(HOST_URL+'download/file/'+userMail+"/"+fileName+"/"+folderName , {
      headers: {
        'token' : AuthToken,
        'Content-Type': 'application/json',
        'dataType' : 'json',
      }
    })
    .then(response => {
      console.log('downloadParams',response.data)
      //dispatch(getUpdatePassword(response.data))
 
    })
    .catch(error => {
      console.log('error deletePortfolioCsv');
    })
  };
};

