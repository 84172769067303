export default [
  {
    title: 'Does Economic Revival from Coronavirus Pandemic Depend on Recklessness in Human Behavior',
    summary: 'There is evidence that the economic recovery from an unforeseen event is dependent on how quickly people will get back to normal. This EMAlpha insight takes a look at how the ‘speed of revival’ from the effects of Coronavirus pandemic depends on how ‘risk-taking’ people are. Less ‘risk averse’ people are, faster the recovery would be. The difference between UK and Continental Europe are recovering at a different pace mainly because of difference in threat perception while the on-ground statistics are alomost same on the impact of Coronavirus pandemic. ',
    category: 'Behavioral Finance, COVID-19, Emerging Markets, Developed Markets',
    date: '8/13/2020',
  },
  {
    title: 'Do the Global Financial Markets Care About COVID-19 Numbers Anymore?',
    summary: 'March 2020 was tumultuous for global markets as Coronavirus pandemic led to a huge crash, it required a strong monetary policy response from Central Banks to avert the crisis. But the question now is: Is Coronavirus pandemic still the driving force for the markets? Or the markets have moved on? This is the question we explore here on the basis of what EMAlpha machines have picked up. ',
    category: 'Behavioral Finance, COVID-19, Sentiment Analysis',
    date: '6/21/2020',
  },
  {
    title: 'Does International News Flow Capture Seriousness of Coronavirus Pandemic in Brazil?',
    summary: 'From the lack of news coverage in other parts of the world, it is difficult to figure out that Brazil is one of the worst-hit from the Coronavirus pandemic. However when we analyse what EMAlpha has picked up on Brazil in media coverage, most of the news flow is still capturing less relevant though more interesting developments like traditional cures and football matches.',
    category: 'Emerging Markets, News flow and Market Movement, Sentiment Analysis',
    date: '6/20/2020',
  },
  {
    title: 'Can the Sentiment in Local News Determine the Course of a Geo-Political Conflict?',
    summary: 'It is possible to argue that media only reflects the public opinion. But when it comes to sensitive situations, the role of media becomes even more important as it also has a role in shaping the popular discourse. In conflict between countries, media has to act responsibly and should not do anything which can lead to unnecessary and avoidable conflict. But unfortunately, what EMAlpha machines have picked up is not really unpleasant. ',
    category: 'Emerging Markets, News flow and Market Movement, Sentiment Analysis',
    date: '6/18/2020',
  },
  {
    title: 'Can the Sentiment in Local News Determine the Course of a Geo-Political Conflict?',
    summary: 'It is possible to argue that media only reflects the public opinion. But when it comes to sensitive situations, the role of media becomes even more important as it also has a role in shaping the popular discourse. In conflict between countries, media has to act responsibly and should not do anything which can lead to unnecessary and avoidable conflict. But unfortunately, what EMAlpha machines have picked up is not really unpleasant. ',
    category: 'Commodities, Developed Markets, Markets and Macro',
    date: '6/17/2020',
  },
  {
    title: 'Does Geography Change Interpretation and Relevance of Local News Sentiment ',
    summary: 'On news flow sentiment analysis, often the question is being asked: In this day and age of global interconnection, does local news matter anymore? But on the basis of automated and unbiased news flow collection of EMAlpha for multiple countries, it is easy to see that English news and Local language news can be quite different. So, when news flow is different, sentiment analysis will not be the same either. We discuss the intricacies and implications here as the Sentiment Analysis outcomes are often different for English and Local News. ',
    category: 'Sentiment Analysis, News flow and Market Movement, Emerging Markets',
    date: '6/10/2020',
  },
  {
    title: 'EURO Gaining Strength over USD: What it Means for Emerging Markets?',
    summary: 'After Germany and France proposed a huge Recovery Fund to provide the EU with more capacity to deal with Coronavirus pandemic shock, Euro has gained significantly against USD. More importantly, it gives enhanced visibility on the future of European Union. EMAlpha has explored how does this link with overall market sentiment?  The "topical sentiment" scores emphasizes the point that gains for EUR/USD and weak dollar have sent the overall sentiment soaring.',
    category: 'Developed Markets, Emerging Markets, Equities, Sentiment Analysis',
    date: '6/8/2020',
  },
  {
    title: 'Oil News Sentiment Captures the Firmness in Crude Prices, Is the worst over for Oil?',
    summary: 'The EMAlpha Oil News Sentiment is volatile but it is clearly showing a significant improvement in last fifteen days. As such the average EMAlpha Oil News Sentiment in May is much more positive than either March or April and in last two weeks, the volatility also has been on the lower side. This implies that the news Sentiment is doing a good job in making the price forecast and on the basis of EMAlpha Oil News sentiment, it is unlikely that Oil is going below 30 dollars.',
    category: 'Commodities, Sentiment Analysis, News flow and Market Movement',
    date: '5/29/2020',
  },
  {
    title: 'Oil Sentiment has Conflicting Signals from IEA and Saudi Aramco Stock Price',
    summary: 'EMAlpha Oil News Sentiment is having its ‘break out’ moment and this change is telling us that we are past the worst of Coronavirus crisis and things will get better from now on for Oil. We looked at other possible signals on what may happen next and the most interesting were two data points which are giving us conflicting view. The first is Saudi Aramco Stock price and the second data point is International Energy Agency (IEA) forecast. What do they tell us and how to read it?',
    category: 'Commodities, News flow and Market Movement, Markets and Macro, Sentiment Analysis',
    date: '5/16/2020',
  },
  {
    title: 'Did World Media Underestimate Coronavirus Crisis in Latin America?',
    summary: 'EMAlpha analysis of news and underlying sentiment indicates that news coverage on Coronavirus is partial. This directly impacts sentiment on threat perception and how serious the crisis will appear in different parts of the world. The contrast in EMAlpha News Sentiment between Europe and the United States versus South America speaks a lot about supremacy of underlying news sentiment over actual facts. This shapes several factors including the local government’s response, foreign aids to deal with the crisis and global sympathy for affected countries.',
    category: 'Emerging Markets, News flow and Market Movement, Behavioral Finance',
    date: '5/14/2020',
  },
  {
    title: 'Is Oil Sentiment Telling Us that Demand Recovery has Begun and How Long it Will Take?',
    summary: 'One of the most closely tracked sentiments at EMAlpha is on Oil News as the short-term price movement in crude depends on near term outlook, which is influenced heavily by the news flow sentiment. If we go by the prevailing sentiment, the demand is unlikely to pick up anytime soon. However, the EMAlpha Oil News Sentiment is having its ‘break out’ moment and the Oil News sentiment has scaled its new peak. This is an entirely different picture from what we had seen in the month of April. Is this change in oil news sentiment telling us that we are past the worst of Coronavirus crisis? ',
    category: 'Commodities, Sentiment Analysis, News flow and Market Movement',
    date: '5/14/2020',
  },
  {
    title: 'Coronavirus Threat and Lockdowns: Which Countries can Afford Them and for How Long?',
    summary: 'An analysis of the country-by-country EMAlpha New Sentiment shows that the Govt’s stance to combat Coronavirus pandemic is dependent on threat sentiment. When we analyse country sentiment scores for our news collection, we find that there are significant differences in the tone and frequency of Coronavirus related news flow. Some of the countries which are still nowhere close to the disastrous impact some others have seen, are still more scared. In reality, a country’s ability to afford a lock down depend on its economy, both size as well as wealth distribution over the population. However, Govt policy on lock downs will be determined more by threat perception than the ability to afford it. ',
    category: 'COVID-19, Behavioral Finance, Emerging Markets, News flow and Market Movement',
    date: '5/10/2020',
  },
  {
    title: 'Facebook-Jio Deal and Stock Price Movement in Reliance Industries Limited Immediately Before that',
    summary: 'Before Reliance Industries Ltd. (the largest market cap company in India) announced a deal with Facebook that the Social Media giant will Invest USD 5.6bn in Reliance-Jio Platforms for a 9.99% Stake, the Reliance stock had shown interesting movement. This movement which was distinctly different in the morning and afternoon session a day prior, provides further evidence about impact of sentiment and news flow on price. ',
    category: 'News flow and Market Movement, Emerging Markets, Sentiment Analysis',
    date: '5/4/2020',
  },
  {
    title: 'Are Central Banks Encouraging Risk Taking Behavior in Speculators…Oops…Investors',
    summary: 'EMAlpha Sentiment Analysis based on News flow indicates that the signalling mechanism by Central Banks is a very powerful tool to change the risk perception by market participants. When a Central Banker is telling investors that it is OK to indulge in high risk investments, investors become more adventurous. This has been a driving force for markets as picked in sentiment on commodities and country by country sentiment analysis. How much risk an asset class carries is more dependent on perception EMAlpha analysis confirms. ',
    category: 'Markets and Macro, Developed Markets, Emerging Markets, News flow and Market Movement',
    date: '5/1/2020',
  },
  {
    title: '‘News Sentiment Impact’ and Its Influence on Market Movement',
    summary: 'For US economy and market indices like S&P500, the signals from fundamentals of economy and what Fed is doing are pulling the financial markets in US in opposite directions. From the market reaction to datapoints like jobless claims, can we deduce that the Fed action is in the price already while fundamentals could get worse and that will matter more for the markets. EMAlpha News flow sentiment on specific topics and from multiple countries is reflecting interesting linkages between Sentiment Analysis and Market movement. ',
    category: 'Sentiment Analysis, Developed Markets, News flow and Market Movement, Markets and Macro',
    date: '4/30/2020',
  },
  {
    title: 'Oil Sentiment has Already Hit Rock Bottom, But Will it Plunge even More?',
    summary: 'While the drop in Oil prices is putting huge pressure on producers, they can’t easily agree on who needs to cut how much of production. The drop in demand and low crude prices actually made some of them more desperate in their attempts to gain market share. This is also visible on EMAlpha Oil News Sentiment tracker. While the market movements are related to specific reasons, the general sentiment on the demand side is extremely negative. EMAlpha Oil News Sentiment is at the lowest levels after it has hit an all-time low on April 20th. Because of this commodity’s strong linkage with global economy, will this have implications for financial markets worldwide? ',
    category: 'Commodities, Sentiment Analysis, News flow and Market Movement, Markets and Macro',
    date: '4/27/2020',
  },
  {
    title: 'Oil’s Historic Fall: Precipitated by Quickly Worsened Sentiment amid Demand Concerns',
    summary: 'As May WTI Futures traded in Negative, the historic fall in crude oil prices prompted us to investigate this issue further on the lines of how EMAlpha Oil news sentiment fared around that time. After Coronavirus pandemic has become a global issue, the market performance in several countries is strongly linked with local news flow and underlying sentiment on Coronavirus related developments. This applies to Oil too as the EMAlpha Oil News sentiment plot shows how the oil sentiment has fallen to a level lower than what it was in mid-March when the Saudi-Russia talks broke down and oil price and stock market fell.',
    category: 'COVID-19, Commodities, News flow and Market Movement, Markets and Macro',
    date: '4/22/2020',
  },
  {
    title: 'Crude Oil and Coronavirus Pandemic: Oil Futures in Negative and How Machines are Reading it?',
    summary: 'Monday, 20th April 2020 was a historic day for Crude Oil Industry as the May US oil futures contract in went into negative territory for the first time in history. The US reserves are full and there is simply no place to put the crude and everyone is avoiding taking delivery of physical crude. But it is not just a storage problem. There is limited visibility on when the demand will recover. This is also visible on EMAlpha Oil News Sentiment tracker as well. When seen in the context of SAUDI Aramco’s stock price movement and recovery in S&P 500, we can conclude that investors are less worried about markets in general and the concern is limited to Oil only. ',
    category: 'Sentiment Analysis, COVID-19, Commodities, News flow and Market Movement',
    date: '4/21/2020',
  },
  {
    title: 'Market and Coronavirus Pandemic News: Shifting Pendulum Between Optimism and Pessimism',
    summary: 'On EMAlpha Global Coronavirus news sentiment and country-by-country Coronavirus sentiment, things continue to deteriorate. Most countries are struggling to contain infections and the global Coronavirus sentiment has deteriorated sharply. There are interesting takeaways from EMAlpha News Topic Sentiment for Key Words such as Donald Trump and Chinese Economy, Crude Oil News Sentiment and Aggregate India Equity Markets Sentiment. ',
    category: 'News flow and Market Movement, Markets and Macro, Behavioral Finance, Emerging Markets',
    date: '4/20/2020',
  },
  {
    title: 'News Sentiment on Trump Does Not Matter for Markets, Oh...Really?',
    summary: 'The mishandling of Coronavirus pandemic negatively impacted the EMAlpha News sentiment on US President. When we look at Markets and EMAlpha News sentiment on Donald Trump, there is no sign that market is really bothered about Trump. However, it can be looked at this way: Once the emergency response has been provided by Fed, the economy will still require a careful steering and proactive, consistent response from the administration. So, Fed has saved the markets in near term but the divergence between news sentiment on Trump and market direction is not sustainable',
    category: 'Developed Markets, News flow and Market Movement, Sentiment Analysis',
    date: '4/17/2020',
  },
  {
    title: 'Is Donald Trump Losing Perception Battle on Coronavirus Pandemic and Why Should Markets care?',
    summary: 'In the past, US presidents have mostly benefitted from crisis, politically and in the eyes of media. However, EMAlpha News flow Sentiment on US President, Donald Trump had quickly worsened over last couple of weeks. And it has worsened significantly. Trump’s inconsistent approach and him being in rush to lift the lockdown could have been the key factors in making Trump an easy media target but it is a fact that the sentiment in news on Trump is negative. Why this is important for the markets in short to medium terms? Effective handling of crisis matters for Economic recovery and if the negative news sentiment on Trump sustains, this may play a key role in US presidential elections.',
    category: 'Developed Markets, News flow and Market Movement, COVID-19, Sentiment Analysis',
    date: '4/15/2020',
  },
  {
    title: 'Impact of Coronavirus: Is Timing the Markets Possible Using Sentiment Analysis?',
    summary: 'Is it possible to position your portfolio for market volatility in a timely manner? We look at this question using EMAlpha Sentiment Signal as a guide. We consider two major emerging equity markets: Brazil and India and use EMAlpha Sentiment based Signals, constructed using Machine Translation and Sentiment Analysis on news flow in major languages: a) Portuguese and English for Brazil, b) Hindi and English for India, to trade the broad equity indices in these two countries. The result, in terms of the cumulative return in excess of the equity benchmark shows that EMAlpha Sentiment Signal for Brazil and India seem to have navigated the recent unprecedented market volatility quite well. ',
    category: 'COVID-19, Sentiment Analysis, Emerging Markets, Big Data and AI',
    date: '4/13/2020',
  },
  {
    title: 'Fed Making Fundamentals Irrelevant For Markets - Saving From Disaster or Creating a Bigger One?',
    summary: 'The Coronavirus pandemic has made the US Fed extremely active and this has led to a dichotomy. The market is telling you that it is focusing less on fundamentals and more on Fed. A look at the S&P 500 chart makes it clear the link between Fed Action, Impact of Coronavirus on Fundamentals of US Economy and the direction of Markets. There will always be questions but at least for the time being, the markets are telling you that what Fed is doing, is working.',
    category: 'Markets and Macro, Developed Markets, News flow and Market Movement',
    date: '4/9/2020',
  },
  {
    title: 'Why Local News based Sentiment Analysis Matters and Do Machines Can See the Difference?',
    summary: 'When machines pick news on same subject in multiple languages for the same country, the result can be significantly different. EMAlpha has noted such divergence for many countries including China, Japan and Turkey and when news flow is different, sentiment analysis will not be the same. For Emerging Markets, EMAlpha experience with news collection and Sentiment Analysis for different countries in different languages confirms that English news and translated (from local language to English) are qualitatively and quantitatively different.',
    category: 'Big Data and AI, News flow and Market Movement, Sentiment Analysis, Markets and Macro',
    date: '4/8/2020',
  },
   {
    title: 'News Sentiment on Coronavrius Pandemic: Darkest Before the Dawn or No Light at End of the Tunnel?',
    summary: 'Over the last few days, the EMAlpha Coronavirus News Sentiment has deteriorated in eleven out of the twelve countries in our main panel (USA, China, Germany, Brazil, Italy, India, Mexico, Malaysia, Norway, Colombia, Australia and Poland). More importantly, out of the eleven countries in which sentiment has turned negative, more than half (total six) see rather sharp decline. This doesn’t inspire much confidence that we are anywhere close to recovery. EMAlpha Sentiment analysis for some key words such as Donald Trump, Fed Rate, Nasdaq Composite, Chinese Economy and the US Economy, also throw interesting conclusions.',
    category: 'COVID-19, Markets and Macro, News flow and Market Movement, Emerging Markets',
    date: '4/6/2020',
  },
   {
    title: 'Coronavrius News Sentiment vs. Aggregate Market Sentiment and the Importance of Base Rate',
    summary: 'EMAlpha’s Aggregate Market Sentiment has a base rate which is above 0. This makes sense because the usual market sentiment has a positive bias and hence, the change in market sentiment should be measured as compared to this base rate. EMAlpha Sentiment Analysis tells us that the absolute values don’t matter that much and only how far is the point in either direction from the base rate, matters. The same things apply to languages, books, movies and almost everything where we can do Sentiment Analysis. This has important implications for how to read the signals and what to deduce from Sentiment Analysis. ',
    category: 'COVID-19, Sentiment Analysis, Big Data and AI, Behavioral Finance ',
    date: '4/1/2020',
  },
   {
    title: 'Dichotomy Between News and Reaction: Coronavirus Pandemic Getting worse, But Markets Getting Better',
    summary: 'To assess the market impact of Coronavirus pandemic, EMAlpha tracks several important indicators including, a) Coronavirus Country-by-Country Sentiment, b) Global Coronavirus Sentiment, c) News Topic Sentiment on terms such as “Donald Trump”, “Dow Jones Index”, “Fed Interest Rate”, “Nasdaq Composite”, “Chinese Economy” and “US Economy”, d) Crude Oil related News Sentiment, and, e) Coronavirus Sentiment Heat Map. These EMAlpha Sentiment trackers offer interesting insights on the current state of this pandemic and how News coverage is reacting to it.',
    category: 'COVID-19, Emerging Markets, Developed Markets, News flow and Market Movement',
    date: '3/31/2020',
  },
  {
    title: 'For Global Economy and Emerging Markets, How Much Better News Sentiment on US Helps',
    summary: 'There have been interesting takeaways from the EMAlpha sentiment analysis for news flow around some very important and immensely popular key words. These news topics which matter for US Markets and hence, are important for other global markets can also capture the market direction effectively. EMAlpha machines have picked up some improvement in sentiment for US economy and that gels well with the improvement in sentiment for Dow Jones Index and NASDAQ composite both.',
    category: 'Sentiment Analysis, Emerging Markets, Markets and Macro, News flow and Market Movement',
    date: '3/25/2020',
  },
  {
    title: 'Coronavirus News Sentiment: Voltaility and Direction of Indian Markets',
    summary: 'If we map the EMAlpha Aggregate Market Sentiment for India and not just Coronavirus News Sentiment for the country, with the local market indices, there is a clear message that the market volatility may continue in the near term.  The smart recovery on Friday (20th March) followed by an even deeper fall on Monday (23rd March) gels very well with the EMAlpha India Corona News Sentiment. EMAlpha Coronavirus News Sentiment also tells that US markets may continue to remain in deep turmoil.',
    category: 'COVID-19, Sentiment Analysis, Emerging Markets, Developed Markets',
    date: '3/24/2020',
  },
  {
    title: 'Coronavirus Pandemic, News Sentiment and Where the Markets Are Going?',
    summary: 'The EMAlpha Emerging Markets model portfolio performance, has delivered significantly better returns versus the market index recently. And it is easy to see the reason. In the recent Coronavirus pandemic driven reaction of Global Financial Markets, Sentiment is a dominant driver of investor behaviour. How does EMAlpha do Sentiment Anlsysis on the news flow? EMAlpha machines are collecting Coronavirus related targeted news flow in a variety of languages. For a number of emerging market countries, most of the news flow is in local language and, hence, we focus on news collection in both the local language and in English. We then use natural language processing tools to extract the sentiment for each country. The details and takeaways are discussed in this insight. ',
    category: 'Behavioral Analysis, COVID-19, Sentiment Analysis, Developed Markets, Emerging Markets',
    date: '3/23/2020',
  },
  {
    title: 'Coronavirus News Sentiment Deteriorating and Peculiarities of Country News Sentiment',
    summary: 'After the already disastrous impact on global health scenario and the severe hit to world economy because of Coronavrius pandemic, the bigger worry now is that the EMAlpha Aggregate Coronavirus sentiment (the sum total of news flow across all countries which we track) continues to plunge. Also, the latest edition of EMAlpha Country by country "Corona Sentiment" based on news flow continues to show further deterioration in many countries including USA. However, things have started to look up in China and India is seeing a further fall in Corona Sentiment and thats bearish.',
    category: 'COVID-19, Emerging Markets, Sentiment Analysis, Developed Markets',
    date: '3/18/2020',
  },
  {
    title: 'Human Psychology, High Profile Covid-19 Cases and the Impact on Sentiment',
    summary: 'While huge fluctuations in EMAlpha Coronavirus sentiment continue for different countries, this news flow sentiment is driven by what kind of news items are getting more coverage. For example, the EMAlpha Coronavirus news flow sentiment for Canada has deteriorated sharply and this is because of a high-profile case of infection (Prime Minister’s wife). In USA, the huge volatility noticed in the markets is linked with rapid deterioration on one side and the policy announcements on the other. Similarly, there are interesting trends from other countries as well.',
    category: 'Developed Markets, COVID-19, Sentiment Analysis, Behavioral Finance, Emerging Markets',
    date: '3/15/2020',
  },
  {
    title: 'News Sentiment, Market Imapct and Unchecked Spread of Coronavirus Pandemic',
    summary: 'On March 9th 2020, VIX Index, the widely monitored gauge of market volatility, shot up to levels not seen since the Great Financial Crisis more than ten years ago. At EMAlpha, we monitor daily news sentiments in a range of emerging and developed markets and from this, it has become clear that Coronavirus related news sentiment could continue to be a driver of market sentiment for a while. We collect Coronavirus related targeted news flow in a number of languages as for many emerging market countries, most of the news flow is in local language. There are some interesting observations on the market impact of the news and underlying sentiment country by country.',
    category: 'COVID-19, Sentiment Analysis, News flow and Market Movement, Developed Markets, Emerging Markets',
    date: '3/10/2020',
  },
  {
    title: 'Coronavirus Impact on Markets: Is Local News Sentiment Getting More Important?',
    summary: 'In the initial response to Coronavirus pandemic, most financial markets globally reacted in a predictable negative fashion. But this is slowly changing now and the role of local news flow and underlying sentiment is getting more prominent. For example, after India reported two fresh cases of Coronavirus in the afternoon on 2nd March, Indian markets reacted sharply and closed lower. It is not looking very good on EMAlpha country-by-country sentiment analysis and there could be more downside ahead.',
    category: 'Emerging Markets, COVID - 19, News flow and Market Movement, Sentiment Analysis',
    date: '3/2/2020',
  },
  {
    title: 'Coronavirus and Markets: Sentiment Bigger Driver than Fundamentals in Near-Term',
    summary: 'As the news sentiment has evolved leading to the market gyrations because of Coronavirus pandemic, EMAlpha is tracking the overall market sentiment on a daily basis. While the way forward does not look good, sentiment will clearly be a bigger driver for markets than fundamentals in the near term. In this insight, we also take a look at some of the important questions such as how are hedge fund strategies doing against this backdrop and how has the EMAlpha long/short equity strategy done?',
    category: 'Sentiment Analysis, COVID - 19, Markets and Macro, News flow and Market Movement',
    date: '2/27/2020',
  },
  {
    title: 'Coronavirus Pandemic and How Sentiment is Impacting the Emerging Markets?',
    summary: 'The impact on the global markets from Coronavirus pandemic also seems to have a local component as Asian markets close to China have suffered more than others. Geographically distant markets have remained shielded. When we look at how EMAlpha Sentiment Score has evolved over the last month for some of these emerging countries and the performance of their equity markets, the maximum market impact has been in smaller neighboring countries, like Thailand. ',
    category: 'COVID - 19, Big Data and AI, Behavioral Finance, Sentiment Analysis',
    date: '2/9/2020',
  },
  {
    title: 'Coronavirus News Sentiment: How much is NOISE in the SIGNAL?',
    summary: 'Though Coronavirus is a global health emergency and there is no proven cure yet in sight, the media coverage is still disproportionate considering the tangible impact estimated as of now. In the age of rapid information dissemination and huge role played by social media, this skew is even more prominent. Naturally, financial markets also react more to these events. Whether the impact of Coronavirus is NOISE or SIGNAL, the reaction of financial markets proves ‘News may be the same, but the impact could be vastly different because of the varied nature of Sentiment’. EMAlpha tracks this news flow and the underlying sentiment.',
    category: 'COVID - 19, Big Data and AI, Sentiment Analysis, Behavioral Finance',
    date: '2/9/2020',
  },
  {
    title: 'The Listing of Saudi Aramco: Messages Beyond Emerging Markets',
    summary: 'As EMAlpha has noticed in several Emerging Markets, the Sentiment Analysis of News flow is a bigger game changer in countries where the information flow is irregular or the ones which are not understood that well by global investors. In this context, there are important takeaways from what the Initial Public Offering of Saudi Aramco means for the region and for the local and Emerging Markets in general. As we see in this insight, Market inefficiencies and investment opportunities will remain till the time financial markets exist and hence, an efficient and timely analysis of news and prevailing market sentiment could be the difference between gains and losses.',
    category: 'Commodities, Emerging Markets, News flow and Market Movement',
    date: '2/9/2020',
  },
  {
    title: 'Recession is trending, Could Peoples Worries Bring Bad News For the Economy?',
    summary: 'Trends are so powerful that often they become self-fulfilling prophecies. When we look at what people are talking about, what they are feeling worried about and what their concerns are, it gives us some idea about what future may look like. The chart showing the frequency of google search for "recession" from 2004 onward tells us that it has hit the 2008-2009 levels recently. The number of people searching for the word ‘recession’ remains at a higher level than observed in the past ten years and this is a significant change because such spikes could actually precede the real event. ',
    category: 'Behavioral Finance, Big Data and AI, Sentiment Analysis',
    date: '10/25/2019',
  },
  {
    title: 'Human Emotions and How Systematic Investing Can Mitigate the Impact on Your Portfolio?',
    summary: 'Often it is difficult to control emotions in the markets and extraordinary events become even more testing. It takes great discipline to not follow emotions in investment and this is what the insight talks about. Even if one has a quantitative, machine learning based model, it is easy to yield to emotions and start tweaking with model parameters. Many quant managers yield to this temptation and tweak the parameters at the worst possible moment. Discipline does not come easy and it takes experience and practice, but it pays off.',
    category: 'Systematic Investing, Emerging Markets, Big Data and AI, News flow and Market Movement',
    date: '10/14/2019',
  },
  {
    title: 'Sentiment Analysis and Its Role In Investing: Now and In The Future',
    summary: 'When the time scale is short, the sentiment can matter more than the facts. In stock markets when the reaction time needs to be quick and the trade decision has to be made immediately after the event, the fund manager lacks the luxury to do it at leisure. The sentiment, i.e. the first impression, can matter more in this case and the price impact can be seen very quickly after a good or bad event. Since even a small change in sentiment can quickly become big, Sentiment analysis and discovery of news which can move markets is useful for market participants.',
    category: 'Sentiment Analysis, Big Data and AI, Equities, Fixed Income',
    date: '10/12/2019',
  },
  {
    title: 'Investment Management - The Final Frontier for Machines ',
    summary: 'The adoption rate of new technology is dependent on several factors but the important thing is that the new age machines are not just taking over the work which used to be done by humans in the financial markets, they are also opening new avenues and approaches for investment management. EMAlpha’s proprietary model which generates sentiment scores using our dictionary and the trades executed on the basis of those sentiment scores are beating markets and this tells us that there is need for more sophisticated data analytics, more advanced algorithms and use of Artificial Intelligence for generating superior investment returns.',
    category: 'Big Data and AI, Sentiment Analysis, Markets and Macro, Equities, Fixed Income',
    date: '10/6/2019',
  },
  {
    title: 'Will Huge Cut in Corporate Tax Rate Revive Investor Interest in India?',
    summary: 'The massive corporate tax rate cut in India is a historic step and a massive comforting factor for the industry that the Government is listening to the signals from the ground. However, earnings growth is a challenge for Indian companies and this step in the form of a tax rate cut for companies is an artificial one-time measure. It doesnt do anything to address the core problem of slack in demand. The expectation that the investment cycle will pick up is unrealistic. Having more money is one thing but the corporate will not invest unless they are hopeful on demand.',
    category: 'Emerging Markets, Equities, Behavioral Finance',
    date: '9/22/2019',
  },
  {
    title: 'Fundamentals and Quant - The Virtues of Discipline and Why Systematic Trading?',
    summary: 'The debate on which method of stock trading is superior between ‘fundamental approach’ and ‘quantitative approach’ is age old and never ending. Then, what is the solution? The best possible approach we believe is an approach which is systematic. The idea is simple: unless you follow an approach diligently and don’t let your emotions affect your investment decisions, it is difficult to evaluate it. You have rules and you don’t tweak them unless you think that some of the premises are no longer valid. Otherwise, you let the system do the ‘decision making’ for you.',
    category: 'Systematic Investing, Big Data and AI, Equities, News flow and Market Movement',
    date: '8/28/2019',
  },
  {
    title: 'Emerging Markets and Importance of Managing the Corporate Governance Risk',
    summary: 'To avoid unpleasant surprise, we need to apply a Corporate Governance filter first and then only trade. Another somewhat similar way is to look at the stocks through ESG (Environmental, Social and Governance) framework and then decide what you can invest in and what you should avoid. In EMs especially, that is as important as tracking news flow, understanding investor sentiment or analysing financial statements. In the markets to succeed, the first learning needs to be…how to minimize mistakes and is why ‘Corporate Governance’ helps.',
    category: 'Emerging Markets, ESG & Sustainability, Equities, News flow and Market Movement',
    date: '8/22/2019',
  },
  {
    title: 'Fed Rate Cut: Words Speak Louder than Actions and the Importance of Signalling Mechanism ',
    summary: 'The conventional wisdom says that it is the actions which matter more than words. However, the last day of July 2019 was different from that perspective. It was a big event as the Federal Reserve cut interest rates for the first time in more than a decade. However, the market reaction to the rate cut was still unexpected as if the rate cut didn’t happen at all. Fed’s first rate cut since it slashed rates to near zero in 2008 disappointed the markets because Fed Chairman didn’t make any attempt whatsoever to signal the beginning of an aggressive rate-cutting campaign. A Fed Rate Cut which is first in more than ten years and the market reaction which followed was clearly the case when Fed’s words spoke louder than its actions instead of Fed’s actions speaking louder than its words.',
    category: 'Developed Markets, Fixed Income, Markets and Macro, Behavioral Finance',
    date: '8/2/2019',
  },
  {
    title: 'Emerging Markets: Increasing Domestic Retail Participation and Declinging Role for Foreign Investors',
    summary: 'Historically, Foreign Institutional Investors (FIIs) have been a major driving force in Emerging Markets (EMs). FIIs may not always outperform but they have two significant advantages vs. domestic investors, a) they can look at other comparable markets and this is not just in EM space and other markets globally but they can also look at other asset classes and then decide how attractive equities in a particular EM look like, b) the FIIs track news flow carefully and diligently but they don’t get too exuberant or excessively pessimistic unnecessarily and they will approach an EM with more equanimity.  But, do FIIs remain equally important at all times and this is what the insight takes a look at.  ',
    category: 'Emerging Markets, Equities, Behavioral Finance',
    date: '7/22/2019',
  },
  {
    title: 'Fed Rate Cut Will Help, But Policy Makers in Emerging Markets Need to Do More',
    summary: 'It may appear from the immediate market reaction that a rate cut by Fed will help the EMs. But, this is an illusion. Investor sentiment is impacted as a result of several factors and that is a key thing to focus on in the longer term because fundamental factors will continue to be more important for EMs and that is something the news analysis based sentiment gauge as of now is not really able to capture completely.',
    category: 'Developed Markets, Emerging Markets, Fixed Income, Equities',
    date: '7/12/2019',
  },
  {
    title: 'Human Behavior and Emerging Markets: The Case of Cricket World Cup and Indians Not Buying Televisions',
    summary: 'The thing about demographic dividend is that everyone will expect that more people with higher purchasing power will continue to drive consumption up. And it comes as a surprise when the companies have started to talk about slowdown. But that is the new reality in India and investor sentiment is seriously affected. The poor sales of televisions in the midst of a Cricket World Cup is an interesting example. The way retail sales of physical stores got impacted because of online purchases, is Television staring at the same fate and what it means?',
    category: 'Emerging Markets, Behavioral Finance, News flow and Market Movement, Sentiment Analysis',
    date: '7/10/2019',
  },
  {
    title: 'India Automobiles Sales in Slow Lane and How Robust is the Emerging Market Consumption Story',
    summary: 'The news flow for Indian Auto Industry is turning from bad to worse over previous many months and over last one year and a little more, there have been multiple reports highlighting factors like poor sales numbers and liquidity crunch impacting sales of vehicles. The bigger question is related to consumer demand. One way to look at this is that there may not be any huge structural challenges for discretionary demand in India and challenges related with consumer demand may only be temporary. However, discretionary demand is relatively safer in EMs but we need to keep an eye on the data points.',
    category: 'Emerging Markets, Behavioral Finance, Equities',
    date: '7/6/2019',
  },
  {
    title: 'India’s Central Bank Suffers Another High Profile Exit and The Importance of Strong and Independent Institutions',
    summary: 'Reserve Bank of India (RBI) is one of the oldest monetary institutions in the World and it is much respected as well. Usually, RBI Deputy Governors tend to be away from media glare, but Dr Viral Acharya was different. He was vocal and his statements were often controversial which media could not always ignore. Dr. Acharya, who quit as Reserve Bank of India deputy governor on 24th June, is joining the long list of high profile exits caused by difference of opinion between institutions and ruling dispensation in last few years. These controversies are totally unnecessary because these events reflect very poorly on India’s governance standards and how much respect the government shows to a dissenting opinion.',
    category: 'Emerging Markets, ESG & Sustainability, Equities, Markets and Macro',
    date: '6/26/2019',
  },
  {
    title: 'India and Economic Policy Making: Do Election Results Really Matter?',
    summary: 'In Emerging Markets, political stability and continuity of Government policy is very important for the direction of indices. In this respect, India has been relatively stable over years with democracy taking deep roots and despite changes in political parties which enjoyed power, the Government policy has remained largely consistent and broad contours of economic policies over last three decades have changed for better. Does this mean that elections are irrelevant and unnecessary distraction for markets? This is what we explore in this insight.',
    category: 'Emerging Markets, Equities, Markets and Macro',
    date: '4/7/2019',
  },
];  
