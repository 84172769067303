import React, { Component } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import * as dataActions from '../../../store/actions/index';
import {connect} from 'react-redux';

class MultiSelectDropdown extends Component {

	constructor(props){
    super(props);

    this.state = {
    	options: [
    		
    	],
    	selectedStocks:[],
    	countryName:'',
    	frameName:"",
    }
    this.onSelectStock = this.onSelectStock.bind(this);
    this.onRemoveStock = this.onRemoveStock.bind(this);
	    
	}

	componentDidMount(){
	
		/*let stockParams = JSON.stringify({
     	"country": this.state.countryName
		});
		this.props.getStockList(stockParams);*/
	}

	componentDidUpdate(prevProps, prevState) {
	
		let stockArray = [];

		if(this.props.Upload_hashStocks !== prevProps.Upload_hashStocks){
			if(this.props.StockList_esg_data){

				this.props.StockList_esg_data.map((name,index) => {
					let newname = name.split('“');
					let finalStock = newname.pop().split('”')[0]
					
					stockArray.push ({
						name: finalStock,
						id: index
					})
				})
				//console.log('stockArrayyyyyyyyyyy',stockArray)
				this.setState({
					options:stockArray
				});
			}
		}

		//console.log('frameName',this.props.frameName)
		if(this.props.countryName !== prevProps.countryName){
			
			this.setState({
				countryName:this.props.countryName,
				selectedStocks:[],
			});
			let stockParams = JSON.stringify({
     			"country": this.props.countryName,
     			"frame": this.props.frameName,
			});
			this.props.getStockList(stockParams);
		}/*else if(this.props.frameName !== this.state.frameName){
			this.setState({
				countryName:this.props.countryName,
				frameName:this.props.frameName,
				
			});
			let stockParams = JSON.stringify({
     			"country": this.props.countryName,
     			"frame": this.props.frameName,
			});
			this.props.getStockList(stockParams);
		}*/
		
		
	}

	onSelectStock(selectedList, selectedItem){
		
		this.setState({
			selectedStocks:selectedList
		});
		this.props.onSelectStock(selectedList);
	}

	onRemoveStock(selectedList, removedItem) {
	 
		this.setState({
			selectedStocks:selectedList
		})
		this.props.onSelectStock(selectedList);
	}

	render() {
	
		return (
			<div className="multiselect_stocks">
				<Multiselect
					selectionLimit="5" options={this.state.options} onSelect={this.onSelectStock} onRemove={this.onRemoveStock} displayValue="name"  selectedValues={this.state.selectedStocks} // Options to display in the dropdown
				/>
			</div>
		)
	}
}

const mapStateToProps = state => {
    return{
        StockList_esg_data: state.graphReducer.StockList_esg_data,
        statusStock: state.graphReducer.statusStock,
        statuscodeStock: state.graphReducer.statuscodeStock,
        Upload_hashStocks: state.graphReducer.Upload_hashStocks,
       
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getStockList : (stockParams) => dispatch(dataActions.getESGStockList(stockParams)),
        
        
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MultiSelectDropdown);