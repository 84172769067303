import React, { Component } from 'react';
import CanvasJSReact from '../../../lib/canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
let stockNames = [];
let fullDatarow = [];
let investmentValue = [];

class GUI3TemperatureScores extends Component {

	componentDidUpdate(prevProps,prevState){
		if(this.props.sbtirand_hash !== prevProps.sbtirand_hash){
			// console.log('dataArray',this.props.dataArray)
			// console.log('sbtiStatusCode',this.props.sbtiStatusCode)
			// console.log('sbtirand_hash',this.props.sbtirand_hash)
		}
	}

	render() {
		fullDatarow = [];
		investmentValue= [];
		stockNames= [];
		if(this.props.dataArray){
		// console.log('quantilestatsdata 11111111',this.props.quantilestatsdata[0])

		
			let items_row = [];
			this.props.dataArray.map((row , index )=> {
				
				
				investmentValue.push({
				  label: row.sector_name,
				  y: (row.investment_value),
				});

				
				stockNames.push({
					name: row.sector_name,
				})
				
				

			})
			items_row =  investmentValue;

			fullDatarow.push({
				type: "column",
				
				legendText: "{label}",
				toolTipContent: "{label}: <strong>{y}&#8451;</strong>",
				indexLabel: "{y}",
				dataPoints: items_row

			})
		}
		

		const options = {
			theme: "light",
			animationEnabled: true,
			
			title:{
				text: "Temperature scores by sector",
				fontSize: 20,
			},
			data: fullDatarow
			
		}
		return (
		<div>
			<CanvasJSChart options = {options} />
			{stockNames.length ? <div className="line_indicator">
				<ul>
					{stockNames.map((stockName,index) => {
						return <li key={index} >
							<span className="first_shape"></span>
							<span>{stockName.name}</span>
						</li>
					}) }
				</ul>
			</div> : ""}
		</div>
		);
	}
}
export default GUI3TemperatureScores;       