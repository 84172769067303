import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import insight from '../../../../assets/images/insight.png';
import './innerInsights.css';
import covid19 from './covid_19.js';
import Developed_Markets from './Developed_Markets.js';
import Emerging_Markets from './Emerging_Markets.js';
import Equities from './Equities.js';
import Commodities from './Commodities.js';
import ESG from './ESG.js';
import Markets_and_Macro from './Markets_and_Macro.js';
import Sentiment_Analysis from './Sentiment_Analysis.js';
import Fixed_Income from './Fixed_Income.js';
import Big_Data_and_AI from './Big_Data_and_AI.js';
import Behavioral_Finance from './Behavioral_Finance.js';
import News_flow from './News_flow.js';
import Systematic_Investing from './Systematic_Investing.js';
let startno = 0;
let endno = 7;
let pageCount = 5;
let data_list = [];
 

class innerInsights extends Component {
	constructor(props){
		super(props);

	    this.state = {
	    	category_name: 'Covid-19',
	    	data_list :[] , 
	    	targetValue: 1,
	    	paginationVal : true , 
	    	activelink:'covid-19',
	    }
	   this.paginationChange = this.paginationChange.bind(this);
	}

	componentDidMount() {
		let category_name = this.props.location.pathname.split('/').pop();
		data_list = [];
		
		if(category_name === 'covid-19'){
			data_list = covid19;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'emerging markets'){
			data_list = Emerging_Markets;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'developed markets'){
			data_list = Developed_Markets;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'Equities'){
			data_list = Equities;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'commodities'){
			data_list = Commodities;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'fixed income'){
			data_list = Fixed_Income;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'esg and sustainability'){
			data_list = ESG;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'markets and micro'){
			data_list = Markets_and_Macro;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'behavioral finance'){
			data_list = Behavioral_Finance;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'sentiment analysis'){
			data_list = Sentiment_Analysis;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'big-data'){
			data_list = Big_Data_and_AI;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'newsflow and impact'){
			data_list = News_flow;
			pageCount = Math.ceil(data_list.length/7);
		}else if(category_name === 'systematic investing'){
			data_list = Systematic_Investing;
			pageCount = Math.ceil(data_list.length/7);
		}


		console.log('data_list',data_list.length);
		if(data_list.length > 7){
			this.setState({
		      category_name : category_name,
		      data_list:data_list.slice(startno,endno),
		      paginationVal: true,
		      activelink:category_name
		    });
		}else{
			this.setState({
		      category_name : category_name,
		      data_list:data_list,
		      paginationVal: false,
		      activelink:category_name
		    });
		}
		
	}


	paginationChange (event) {
		let $this = this;
		console.log(event.target);
		console.log('this.state.targetValue',this.state.targetValue);
		if(event.target.getAttribute('aria-label') === 'Go to next page'){
			console.log('next');
			console.log('targetval',this.state.targetValue);
			startno = 0 + (7 * (this.state.targetValue));
			endno = 7 + (7 * (this.state.targetValue));
			console.log(startno);
			console.log(endno);
			this.setState({
		      targetValue : this.state.targetValue + 1
		    });
		}else if(event.target.getAttribute('aria-label') === 'Go to previous page'){
			console.log('prev');
			startno = 0 + (7 * (this.state.targetValue - 2));
			endno = 7 + (7 * (this.state.targetValue - 2));
			this.setState({
		      targetValue : this.state.targetValue - 1
		    });
		}else{
			var page_no = event.target.getAttribute('aria-label').split(' ').pop();
			console.log(page_no);
			this.setState({
		      targetValue : page_no
		    });
			startno = 0 + (7 * (page_no - 1));
			endno = 7 + (7 * (page_no - 1));
		}
		
		$this.setState({
	      data_list : data_list.slice(startno,endno)
	    });
		
	}



	render() {
		return (
			<div>
				<div className="main_container">
					<div className="main_tabs">
						<div className="inner_insights">
							<div className="inner_banner">
								<div className="container">
									<div>
										<h2>Insights: <span>{this.state.category_name}</span></h2>
									</div>
								</div>
							</div>
							<div className="category_main">
								<div className="container">
									<div className="category_blogs">
										{/*<div className="insight_search">
											<input type="text" placeholder="Search insight"/>
											<i className="fa fa-search"></i>
										</div>*/}
										<div className="blogs_section">
											<div>
												{this.state.data_list ? this.state.data_list.map(data=> {
													return <div className="single_blog">
														<div>
															<div className="blog_img">
																<img src={insight} />
															</div>
															<div className="blog_details">
																<div>
																	<h5>{data.category}</h5>
																	<h2>{data.title}</h2>
																	<p>{ ((data.summary).length > 200) ? 
																	    (((data.summary).substring(0,200)) + '...') : 
																	    data.summary }</p>
																	<p className="dateon">Published on: <span>{data.date}</span></p>
																</div>
															</div>
														</div>
													</div>
												}) : ""
											}
											</div>
										</div>
										{this.state.paginationVal ?
										<div className="insight_pagination">
											<div className="">
									      		<Pagination onChange={this.paginationChange} count={pageCount} variant="outlined" shape="rounded" />
									      	</div>
									    </div> : ""}
									</div>
									<div className="category_listing">
										<div className="subscription_section">
											<div>
												<p>Sign up to receive the latest Insights posts in your inbox.</p>
												<a href="#">Subscribe</a>
											</div>
										</div>
										<div className="name_list">
											<div>
												<h4>Topics</h4>
												<ul className="">
													<li className={this.state.activelink === 'covid-19' ? 'active_link' : ''} data-attr="covid-19"><Link to="/insights/covid-19">Covid-19</Link></li>
													<li className={this.state.activelink === 'emerging markets' ? 'active_link' : ''}  data-attr="emerging markets"><Link to="/insights/emerging markets">Emerging Markets</Link></li>
													<li className={this.state.activelink === 'developed markets' ? 'active_link' : ''}  data-attr="developed markets"><Link to="/insights/developed markets">Developed Markets</Link></li>
													<li className={this.state.activelink === 'Equities' ? 'active_link' : ''}  data-attr="Equities"><Link to="/insights/Equities">Equities</Link></li>
													<li className={this.state.activelink === 'fixed income' ? 'active_link' : ''}  data-attr="fixed income"><Link to="/insights/fixed income">Fixed Income</Link></li>
													<li className={this.state.activelink === 'commodities' ? 'active_link' : ''}  data-attr="commodities"><Link to="/insights/commodities">Commodities</Link></li>
													<li className={this.state.activelink === 'markets and micro' ? 'active_link' : ''}  data-attr="markets and micro"><Link to="/insights/markets and micro">Markets and Macro</Link></li>
													<li className={this.state.activelink === 'behavioral finance' ? 'active_link' : ''}  data-attr="behavioral finance"><Link to="/insights/behavioral finance">Behavioral Finance</Link></li>
													<li className={this.state.activelink === 'newsflow and impact' ? 'active_link' : ''}  data-attr="newsflow and impact"><Link to="/insights/newsflow and impact">News flow and Impact</Link></li>
													<li className={this.state.activelink === 'systematic investing' ? 'active_link' : ''}  data-attr="systematic investing"><Link to="/insights/systematic investing">Systematic Investing</Link></li>
													<li className={this.state.activelink === 'big-data' ? 'active_link' : ''}  data-attr="big-data"><Link to="/insights/big-data">Big Data and AI in Finance</Link></li>
													<li className={this.state.activelink === 'sentiment analysis' ? 'active_link' : ''}  data-attr="sentiment analysis"><Link to="/insights/sentiment analysis">Sentiment Analysis</Link></li>
													<li className={this.state.activelink === 'esg and sustainability' ? 'active_link' : ''}  data-attr="esg and sustainability"><Link to="/insights/esg and sustainability">ESG and Sustainability</Link></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default innerInsights