import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index'; 
import MultiSelectDropdown from './MultiSelectDropdown';
import RecentStocks from './RecentStocks';
import DailyStockSentiments from './DailyStockSentiments';
import DailyIndexSentiments from './DailyIndexSentiments';
import CountryDropdown from './CountryDropdown';
import StockIntensity from './StockIntensity';
import IndexIntensity from './IndexIntensity';
import DailyPortfolioNewsIntensity from './DailyPortfolioNewsIntensity';
import DailyPortfolioSentiment from './DailyPortfolioSentiment';
import { DateRangePicker } from "materialui-daterange-picker";
import loader from '../../../assets/images/loader.gif';
import axios from 'axios';
import moment from 'moment';
import News from '../Signin/News';
 
let userMail = '';
  
class CompanyMain extends Component {

	constructor(props){
    super(props);

    this.state = {
    	open: false,
    	pickeropen:false,
    	dateRangeValue:'01/23/2020 - 02/16/2021',
    	portfolioDateValue:'01/23/2020 - 02/16/2021',
    	dateRange: new Date(),
    	portfolioDateRange: new Date(),
    	stockList:[],
    	countryName :'',
    	indexIntensityData: [],
    	userEmail:'',
    	gocliked: false,
    	goclikedStock:false,
    	goclikedStockIndex:false,
    	disabledGo:false,
    	noRecentStock: true,
    	gohitValue: false,
    	dataDescription: "News flow for selected companies",
    	loaderImg : false,
    	selectedDate: [null, null],
    	dateError: "",
      	dateErrorKey : false,
    
    	 
    }
    this.toggle = this.toggle.bind(this);
    this.openDatePicker = this.openDatePicker.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
    this.openPortfolioPicker = this.openPortfolioPicker.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setPortfolioDate = this.setPortfolioDate.bind(this);
    this.callGraphData = this.callGraphData.bind(this);
    this.handleStock = this.handleStock.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleGo = this.handleGo.bind(this);
    this.handleGoStock = this.handleGoStock.bind(this);
    this.handleGoStockIndex = this.handleGoStockIndex.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleRecentStocks = this.handleRecentStocks.bind(this);
    this.onInitCountry = this.onInitCountry.bind(this);
    this.handleLoader = this.handleLoader.bind(this);
	    
	}


	componentDidMount(){
		
	    if( sessionStorage.getItem('signInEmail') == "" || sessionStorage.getItem('signInEmail') == null){
	    	this.setState({
		      userEmail: sessionStorage.getItem('signUpEmail')
		    });
		    userMail = sessionStorage.getItem('signUpEmail')
	    }else{
	    	this.setState({
		      userEmail: sessionStorage.getItem('signInEmail')
		    });
		    userMail = sessionStorage.getItem('signInEmail')
	    }

	   
		
	}
	
	
  componentDidUpdate(prevProps, prevState) {
  
  }

  toggle(){
  	this.setState({
      open:!this.state.open
    });
  };

	openDatePicker(){
		this.setState({
      open:!this.state.open
    });
  }
  
  togglePicker(){
  	//setPickeropen(!pickeropen);
  	this.setState({
      pickeropen:!this.state.pickeropen
    });
  }

	openPortfolioPicker(){
		this.setState({
      pickeropen:!this.state.pickeropen
    });
	}

	setDate(range){
		//console.log('range',range)
		this.setState({
	      dateRange:range,
	      disabledGo:false,
	    });
		
		let endDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.endDate);
		let startDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.startDate);
	
		
		let FinalDate = moment(startDateValue).format("MM/DD/YYYY")+'-'+moment(endDateValue).format("MM/DD/YYYY");
		let year_date = moment(moment(startDateValue).format("MM/DD/YYYY"),"MM/DD/YYYY").year()
	    console.log('year_date',year_date)

	    if(year_date < "2019"){
	     this.setState({
	       dateError: "Please choose start date later than 01/01/2019",
	       disabledGo: true,
	       dateErrorKey : true,
	     })
	    }else{
	      this.setState({
	       dateError: "",
	       disabledGo: false,
	       dateErrorKey : false,
	     })
	    }

		
		
		this.setState({
			dateRangeValue:FinalDate,
		    open:false,
		});

  }
  
  setPortfolioDate(range){
		
		this.setState({
			portfolioDateRange:range
		})
		
		let endDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.endDate);
		let startDateValue = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(range.startDate);
		
		let FinalDate = startDateValue+'-'+endDateValue;
		
		this.setState({
			portfolioDateValue:FinalDate,
			pickeropen:false,

		})

  }

  callGraphData() {
  	
  	this.setState({
  		gocliked:true,
  		goclikedStock:true,
  		goclikedStockIndex: true,
  		disabledGo:true,
  		noRecentStock: true,
  		gohitValue: true,
  		dataDescription: "We are fetching the news please wait for few seconds. ",
  		loaderImg: true,
  	})

  	let stockString = [];
  	for(let i=0; i<this.state.stockList.length; i++){
  		
  		stockString.push(this.state.stockList[i].name);
  		
  	}
  	

  	let graphsParams = JSON.stringify({
     	"country": this.state.countryName,

			"stocks": stockString,

			"date_range": this.state.dateRangeValue,

			"mail_id":userMail

		});
		let intensityParams = JSON.stringify({
	 		"country": this.state.countryName,
			"date_range": this.state.dateRangeValue,
			"mail_id":userMail,
			"stocks": stockString,

		});

		let recentStockParams = JSON.stringify({
	 		"country": this.state.countryName,
			"mail_id":userMail

		});

		let newsParams = JSON.stringify({
			"stocks": stockString,
			"date_range": this.state.dateRangeValue,
		});

			this.props.newsEMdatapi(newsParams);

		

		
		//this.props.indexIntensityCall(intensityParams);
		this.props.indexSentimentsCall(intensityParams);
		//this.props.stockIntensityCall(graphsParams);
		this.props.stockSentimentsCall(graphsParams);
		//this.props.recentStockCall(recentStockParams);


  }


  changeHandler() {
  	console.log('change')
  }


  

  handleStock(list){
  	if(this.state.dateErrorKey === true){
	  	this.setState({
	  		stockList:list,
	  		disabledGo:true
	  	})
	}else{
		this.setState({
	  		stockList:list,
	  		disabledGo:false
	  	})
	}
  }

  handleCountry(countryname){
  
  	/*this.setState({
  		countryName:countryname,
  		disabledGo:false
  	})*/

  	if(this.state.dateErrorKey === true){
  		this.setState({
  			disabledGo:true,
  			countryName:countryname,
  		})
  	}else{
  		this.setState({
  			disabledGo:false,
  			countryName:countryname,
  		})
  	}

  }
  handleGo(goValue){
  	
  	this.setState({
  		gocliked:false
  	})
  } 
  handleGoStock(goValue){
  	
  	this.setState({
  		goclikedStock:false
  	})
  }

  handleGoStockIndex(goValue){
  	
  	this.setState({
  		goclikedStockIndex:false
  	})
  }

  handleRecentStocks(data){
  	
  	if(data !== this.state.noRecentStock){
  		this.setState({
	  		noRecentStock: false,
	  	})
  	}
  	
  }

  onInitCountry(name){
 
  	this.setState({
  		"countryName": name,
  	})
	
	let stockString = [];
  	for(let i=0; i<this.state.stockList.length; i++){
  		
  		stockString.push(this.state.stockList[i].name);
  		
  	}
	
  	let intensityParams = JSON.stringify({
     	    "country": name,
			"date_range": this.state.dateRangeValue,
			"mail_id":userMail,
			"stocks": stockString,

		});

		let recentStockParams = JSON.stringify({
	 		"country": name,
			"mail_id":userMail

		});

		//this.props.indexIntensityCall(intensityParams);
		this.props.indexSentimentsCall(intensityParams);
		//this.props.recentStockCall(recentStockParams);
  }

  handleLoader(value){
	/*console.log('yes')
	console.log(value)*/
	this.setState({
		gohitValue: false
	})
  }

	
	render() {
		
		return (
			<div className="emdata_head"><div className="companyTabmain">
				<div className="container_wrapper">
      		<div className="left_intro">
      			<CountryDropdown onSelectCountry={this.handleCountry} onInitCountry={this.onInitCountry} />
				
						<div className="stock_select">
							<span>Select Stock</span>
							<MultiSelectDropdown onSelectStock={this.handleStock} countryName={this.state.countryName} />
						</div>
						<div className="daterange_input">
							<input type="text"  value={this.state.dateRangeValue} onFocus={this.openDatePicker} onChange={this.changeHandler} />

							<i className="fa fa-calendar" aria-hidden="true" onClick={this.openDatePicker}></i>
						</div>
						<p className="error_msg">{this.state.dateError}</p>
						<div className="go_button">
							<a href="javascript:void(0)" onClick={this.callGraphData} className={(this.state.disabledGo ? 'disabledGo' : '')} >Go</a>
						</div>
					</div>
					 
					 
					<div className="daterange_picker_custom">
						<DateRangePicker
					      open={this.state.open}
					      toggle={this.toggle}
					      //onChange={(range) => setDateRange(range)}
					      onChange={(range) => this.setDate(range)}
					      format="MM/DD/YYYY"
					    />
					</div>
				</div>
				<div className="graph_section">
					<div>
						<div className="quadrant_section">
							<div>
								<h5>Stock Sentiment</h5>
								<DailyStockSentiments stockSentimentsData={this.props.stockSentimentsScores} goClicked={this.state.gocliked}  onGoClick={this.handleGoStock }  />
								{this.state.goclikedStock ?  <div className="loader_gif">
									<img src={loader} />
								</div> : "" }
							</div>
						</div>
						<div className="quadrant_section">
							<div>
								<h5>Index Sentiment</h5>
								<DailyIndexSentiments indexSentimentsData={this.props.indexSentimentsScores} goClicked={this.state.gocliked}  onGoClick={this.handleGoStockIndex }  />
								{this.state.goclikedStockIndex ?  <div className="loader_gif">
									<img src={loader} />
								</div> : "" }
							</div>
						</div>
						
					</div>
				</div>
			</div>
			<div className="new_container">
				<News loaderImg={this.state.loaderImg} gohitValue={this.state.gohitValue}  dataDescription ={this.state.dataDescription} nonewsdisplay="false" dataNews="" ondataget={this.handleLoader}/>
				{this.state.gohitValue ? <div className="loader_gif">
					<img src={loader} />
				</div>  : "" }
			</div>
			</div>
			
		)
	}
}

const mapStateToProps = state => {
    return{
        indexIntensityScore: state.graphReducer.indexIntensityScores,
        indexSentimentsScores: state.graphReducer.indexSentimentsScores,
        stockIntensityScores: state.graphReducer.stockIntensityScores,
        stockSentimentsScores: state.graphReducer.stockSentimentsScores,
        recentStockData: state.graphReducer.recentStockData,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        indexIntensityCall : (intensityParams) => dispatch(dataActions.indexIntensityCall(intensityParams)),
        indexSentimentsCall : (intensityParams) => dispatch(dataActions.indexSentimentsCall(intensityParams)),
        stockIntensityCall : (graphsParams) => dispatch(dataActions.stockIntensityCall(graphsParams)),
        stockSentimentsCall : (graphsParams) => dispatch(dataActions.stockSentimentsCall(graphsParams)),
        //recentStockCall : (recentStockParams) => dispatch(dataActions.recentStockCall(recentStockParams)),
        newsEMdatapi : (newsParams) => dispatch(dataActions.newsEMdatapi(newsParams)),
        
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CompanyMain);