import * as action_Types from '../actions/actionTypes'; 

const initialState = {
   dataPrice: [],
   dataSentiments: [],
   dataNews: [],
   signupMessage : "",
   signupStatus : "",
   signinMessage : "",
   signinStatus : "",
   countryListArray:[],
   indexIntensityScores : [],
   indexSentimentsScores : [],
   stockIntensityScores : [],
   stockSentimentsScores : [],
   stockList : [],
   recentStockData : [],
   statusUpload : '',
   codeUpload : '',
   nameList: [],
   statusCode: '',
   Upload_hash: '',
   sentimentsValues: [],
   portfolioSenticode: '',
   intensityValues: [],
   portfolioIntencode: '',
   sentimentsError:'',
   sentimentCode:'',
   sentimentHash:'',
   intensitysError: '',
   intensityHash: '',
   listName: [],
   listCode: '',
   listStatus: '',
   listrand_hash: "",
   countryListESG: [],
   countryListHash: "",
   StockList_esg_data: [],
   statusStock: "",
   statuscodeStock: "",
   Upload_hashStocks: "",
   statusMail: "",
   statusCodeMail: "",
   hashMail: "",
   statusUpdatepass: "",
   statusUpdateCode: "",
   hashUpdatepass: "",
   csvFilePath: "",
   csvFilehash:"",
}


const graphReducer = (state = initialState, action) => {
   
  switch(action.type) {
    case action_Types.FETCH_PRICE :
      return{
        ...state,
        dataPrice: action.data
      };
    case action_Types.FETCH_SENTIMENTS :
      return{
        ...state,
        dataSentiments: action.data
      };
    case action_Types.FETCH_NEWS :
      return{
        ...state,
        dataNews: action.data
      };
    case action_Types.SIGNUP :
      return{
        ...state,
        signupMessage: action.data,
        signupStatus: action.status
      };
    case action_Types.SIGNIN :
      return{
        ...state,
        signinMessage: action.data,
        signinStatus: action.status
      };
    case action_Types.COUNTRYLIST : 
      return{
        ...state,
        countryListArray: action.data.Country_name_list,
      }; 
    case action_Types.COUNTRYESGLIST : 
      return{
        ...state,
        countryListESG: action.data,
        countryListHash: action.countryListHash,
      };
    case action_Types.INDEXINTENSITYDATA :
      return {
        ...state,
        indexIntensityScores: action.data
      } 
    case action_Types.INDEXSENTIMEANTSDATA :
      return {
        ...state,
        indexSentimentsScores: action.data
      }
    case action_Types.STOCKINTENSITYDATA :
      return {
        ...state,
        stockIntensityScores: action.data
      }
    case action_Types.STOCKSENTIMENTSDATA :
      return {
        ...state,
        stockSentimentsScores: action.data
      }
    case action_Types.STOCKLISTNAMES :
      return {
        ...state,
        stockList: action.data
      }
    case action_Types.ESGSTOCKLISTNAMES :
      return {
        ...state,
        StockList_esg_data: action.StockList_esg_data,
        statusStock: action.statusStock,
        statuscodeStock: action.statuscodeStock,
        Upload_hashStocks: action.Upload_hashStocks,
      }
    case action_Types.RECENTSTOCKS :
      return {
        ...state,
        recentStockData: action.data
      }
    case action_Types.FILEUPLOADES :
      return {
        ...state,
        statusUpload: action.status,
        codeUpload: action.statusCode,
        Upload_hash: action.Upload_hash,
      }
    case action_Types.FILENAMELIST :
      return {
        ...state,
        nameList: action.fileNames,
        statusCode: action.filelistCode,
        csvFilePath: action.csv_file_path,
        csvFilehash: action.csvFilehash,
      }
    case action_Types.PORTFOLIOSENTIMENTS :
      return {
        ...state,
        sentimentsValues: action.dataList,
        portfolioSenticode: action.dataCode,
      }
    case action_Types.PORTFOLIOINTENSITY :
      return {
        ...state,
        intensityValues: action.dataList,
        portfolioIntencode: action.dataCode,
      } 
    case action_Types.PORTFOLIOERROR :
      return {
        ...state,
        sentimentsError: action.dataStatus,
        portfolioSenticode: action.dataCode,
        sentimentHash: action.dataHash
      }
    case action_Types.INTENSITYERROR :
      return {
        ...state,
        intensitysError: action.dataStatus,
        intensityHash: action.dataHash
      }
    case action_Types.FOLDERLISTDATA :
    
      return {
        ...state,
        listName: action.listName,
        listCode: action.listCode,
        listStatus: action.listStatus,
        listrand_hash: action.rand_hash,
      }
    case action_Types.FORGETMAIL :
      return {
        ...state,
        statusMail: action.statusMail,
        statusCodeMail: action.statusCodeMail,
        hashMail: action.hashMail,
      }
    case action_Types.UPDATEPASSWORD :
      return {
        ...state,
        statusUpdatepass: action.statusUpdatepass,
        statusUpdateCode: action.statusUpdateCode,
        hashUpdatepass: action.hashUpdatepass,
      }
    
    default:
        return state
  }
    
}


export default graphReducer;