export const SASDdata = [
  {
    dimension: "Environment",
    flag: "true",
    subcategory: "GHG Emissions",
    category_no: '6',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  },
  {
    dimension: "Environment",
    flag: "false",
    subcategory: "Air Quality",
    category_no: '6',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  },
  {
    dimension: "Environment",
    flag: "false",
    subcategory: "Energy Management",
    category_no: '6',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  },
  {
    dimension: "Environment",
    flag: "false",
    subcategory: "Water and wastewater Management",
    category_no: '6',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  },
  {
    dimension: "Environment",
    flag: "false",
    subcategory: "Water and hazardour materials Management",
    category_no: '6',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  },
  {
    dimension: "Environment",
    flag: "false",
    subcategory: "Ecological Impact",
    category_no: '6',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  },
  
  {
    dimension: "Social Capital",
    flag: "true",
    subcategory: "Human rights and commuinty relations",
    category_no: '7',
    bgcolor: "#fff",
    color: '#000',
  },
  {
    dimension: "Social Capital",
    flag: "false",
    subcategory: "Customer Privacy",
    category_no: '7',
    scores: "",
    bgcolor: "#fff",
    color: '#000',
  },
  {
    dimension: "Social Capital",
    flag: "false",
    subcategory: "Data Security",
    category_no: '7',
    scores: "",
    bgcolor: "#fff",
    color: '#000',
  },
  {
    dimension: "Social Capital",
    flag: "false",
    subcategory: "Access & Affordability",
    category_no: '7',
    scores: "",
    bgcolor: "#fff",
    color: '#000',
  },
  {
    dimension: "Social Capital",
    flag: "false",
    subcategory: "Product Quality & Safety",
    category_no: '7',
    scores: "",
    bgcolor: "#fff",
    color: '#000',
  },
  {
    dimension: "Social Capital",
    flag: "false",
    subcategory: "Customer welfare",
    category_no: '7',
    scores: "",
    bgcolor: "#fff",
    color: '#000',
  },
  {
    dimension: "Social Capital",
    flag: "false",
    subcategory: "Selling pratices & product Labeling",
    category_no: '7',
    scores: "",
    bgcolor: "#fff",
    color: '#000',
  },

  {
    dimension: "Human Capital",
    flag: "true",
    subcategory: "Labour pratices",
    category_no: '3',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  }, 
  {
    dimension: "Human Capital",
    flag: "false",
    subcategory: "Employee health & safety",
    category_no: '3',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  }, 
  {
    dimension: "Human Capital",
    flag: "false",
    subcategory: "Employee engagement , diversity & inclusion",
    category_no: '3',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  }, 
  {
    dimension: "Business Model & Innovations",
    flag: "true",
    subcategory: "Product design & lifecycle Management",
    category_no: '5',
    scores: "",
    bgcolor: "#fff",
    color: "#000",
  },
   {
    dimension: "Business Model & Innovations",
    flag: "false",
    subcategory: "Business model resilience",
    category_no: '5',
    scores: "",
    bgcolor: "#fff",
    color: "#000",
  }, 
  {
    dimension: "Business Model & Innovations",
    flag: "false",
    subcategory: "Supply Chain Management",
    category_no: '5',
    scores: "",
    bgcolor: "#fff",
    color: "#000",
  }, 
  {
    dimension: "Business Model & Innovations",
    flag: "false",
    subcategory: "Materials sourcing & efficency",
    category_no: '5',
    scores: "",
    bgcolor: "#fff",
    color: "#000",
  }, 
  {
    dimension: "Business Model & Innovations",
    flag: "false",
    subcategory: "Physical Impacts of climate change",
    category_no: '5',
    scores: "",
    bgcolor: "#fff",
    color: "#000",
  }, 
   {
    dimension: "Leadership & governace",
    flag: "true",
    subcategory: "Business Ethics",
    category_no: '5',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  }, 
  {
    dimension: "Leadership & governace",
    flag: "false",
    subcategory: "Competative Behaviour",
    category_no: '5',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  }, 
  {
    dimension: "Leadership & governace",
    flag: "false",
    subcategory: "Management of the legal & regulatory Environment",
    category_no: '5',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  },
   {
    dimension: "Leadership & governace",
    flag: "false",
    subcategory: "Critical incident risk management",
    category_no: '5',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  },
   {
    dimension: "Leadership & governace",
    flag: "false",
    subcategory: "Systematic risk management",
    category_no: '5',
    scores: "",
    bgcolor: "#df2a33",
    color: '#fff',
  }, 
];