import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as dataActions from '../../../store/actions/index'; 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'; 
import FormControl from '@material-ui/core/FormControl'; 
import Select from '@material-ui/core/Select'; 

import Modal from '@material-ui/core/Modal';

let userMail = '';
let dictionaryName = [];
 
class UploadDictionary extends Component {

	constructor(props){
    super(props);

    this.state = {
    	uploadFile:'',
    	dictionaryUpload:'None',
    	modelOpen: false,
    	dictionaryName:[],
    	deleteName: '',
      dictionaryStatus:""
    }
    this.handleDictionaryUpload = this.handleDictionaryUpload.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeleteName = this.handleDeleteName.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleRemoveOption = this.handleRemoveOption.bind(this);
    
	    
	}


	componentDidMount(){
		
    if( sessionStorage.getItem('signInEmail') === "" || sessionStorage.getItem('signInEmail') === null){
    	this.setState({
	      userEmail: sessionStorage.getItem('signUpEmail')
	    });
	    userMail = sessionStorage.getItem('signUpEmail')
    }else{
    	this.setState({
	      userEmail: sessionStorage.getItem('signInEmail')
	    });
	    userMail = sessionStorage.getItem('signInEmail')
    }

    let filenameParam = JSON.stringify({
			"mail_id":userMail
		});

		this.props.getDictionaryFilesName(filenameParam)
		
	}
	
	
  componentDidUpdate(prevProps, prevState) {
    let $this = this;

  	let filenameParam = JSON.stringify({
			"mail_id":userMail
		});


  	if(this.props.file_hash !== prevProps.file_hash){
  		this.setState({
  			file_hash: this.props.file_hash,
  			dictionaryName : this.props.dictionaryNamesList
  		})
  		dictionaryName = this.props.dictionaryNamesList
  	}
   
   	if(this.props.dictionaryCode === 1){
   		if(this.props.dictionaryHash !== prevProps.dictionaryHash){
        
   			this.props.getDictionaryFilesName(filenameParam)
        this.setState({
          dictionaryStatus : this.props.dictionaryStatus
        })
        setTimeout(function(){
          $this.setState({
            dictionaryStatus : ""
          })
        },5000)

   		}
   	}else{
      if(this.props.dictionaryHash !== prevProps.dictionaryHash){
       
        this.setState({
          dictionaryStatus : this.props.dictionaryStatus
        })
        setTimeout(function(){
          $this.setState({
            dictionaryStatus : ""
          })
        },5000)
      }
    }

  }

  uploadFile(event) {

    let file = event.target.files[0];
   
    this.setState({
    	uploadFile: file,
    
    });

    const data = new FormData();
    data.append('file', file);
    data.append('mail_id', userMail);

    
   
    this.props.UploadDictionaryFile(data)

  }

  handleDictionaryUpload (event){
  
    this.setState({
      dictionaryUpload: event.target.value,
    })
  };

  handleRemoveOption(e){
   
    this.setState({
    	deleteName:e.target.getAttribute("value")
    })
    this.handleOpen();
    e.stopPropagation();
    
  }

  handleOpen() {
    this.setState({
    	modelOpen:true,
    })
  };

  handleClose() {
   
    this.setState({
    	modelOpen:false,
    })
  };

  handleDeleteName(){
    document.getElementById("csv_upload").value = "";
  	
  	dictionaryName = dictionaryName.filter(item => item !== this.state.deleteName);
  	
  	this.setState({
  		dictionaryName: dictionaryName,
  	});

  	let deleteParams = JSON.stringify({
			"mail_id":userMail,
			"file_name" : this.state.deleteName
		});


  
  	this.props.deleteDictionary(deleteParams)
  	this.handleClose();
  } 



	
	render() {
		
		return (
			<div className="esgWorkspace upload_dictionary">
				<div className="container_wrapper">
      		<div className="left_intro">
      			<div>
      				<div className="upload_button">
      					{/*<span>Upload new Dictionary</span>*/}
                <div className="upload_csv">
        					<input onChange={e => this.uploadFile(e)} type="file" className="custom-file-input" id="csv_upload" accept=".csv" />
        				</div>
        				{<p className={"upload_status " + (this.props.dictionaryCode === 1 ? 'success_upload' : 'error_upload_file')}> 
        					{this.state.dictionaryStatus} 
        				</p>}
        				
              </div>
      			</div>
      		</div>
      			
					<div className="right_info">
						<div>
							<FormControl>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Current Frameworks:
                </InputLabel>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  value={this.state.dictionaryUpload}
                  defaultValue={this.state.dictionaryUpload}
                  onChange={this.handleDictionaryUpload}
                >
                	<MenuItem value='None'> 
                    None
                  </MenuItem>
                  {this.state.dictionaryName ? this.state.dictionaryName.map((item,index) => 
                		
                		<MenuItem value={item.file_name} key={index} className={`${item.last_file === true ? "froozen_file" : "" } `}> 
	                    {item.file_name}
	                  	{!item.frozen_value === true ? <i className="fa fa-times" value={item.file_name} onClick={(e) => this.handleRemoveOption(e)} ></i> : "" }
                      {!item.frozen_value === true ? <a href={'https://analytics.emalpha.com:5000/download/file/'+userMail+'/'+item.file_name+'/dictionaryFiles'} target="_blank"><i className="fa fa-download" ></i></a> : ""}
	                  </MenuItem>

                  ): ""}

                  
                 
                </Select>
                
              </FormControl>

              <div className="modal_main">
              	<div>
              		<div>
							    
							      <Modal
							        open={this.state.modelOpen}
							        onClose={this.handleClose}
							        aria-labelledby="simple-modal-title"
							        aria-describedby="simple-modal-description"
							      >
							        <div className="deleteModal">
									      <h2 id="simple-modal-title">Are you sure you want to delete {this.state.deleteName} dictionary from the list ?</h2>
									      <div className="button_section">
									      	<button className="yes_bttn" onClick={this.handleDeleteName}>YES</button>
									      	<button className="no_bttn" onClick={this.handleClose}>NO</button>
									      </div>
									     
									    </div>
							      </Modal>
							    </div>
              	</div>
              </div>
						</div>
					</div>

         
					<div className="note_area">
						<div>
							<h3>Notes on framework files:</h3>
							<ul>
								<li>Framework file must have the following columns:</li>
								<li className="subnotes"><b>Word or Phrase:</b> Words or combination of words relevant to ESG.</li>
								<li className="subnotes"><b>Classification:</b> <span className="green_text">Positive</span> or <span className="red_text">Negative</span> based on AI or Analyst view.</li>
								<li className="subnotes"><b>Categories:</b>  Grouping of similar items as per the ESG framework theme. Depending on framework opted for, the options could include Environmental, Social, Governance, and others such as Ethics, GHG Emissions, Data Integrity, etc. </li>
                <li>Sample framework file for guidance is available here: <a target="_blank" rel="noopener" href="https://analytics.emalpha.com:5000/download/sample/Sample_Framework.csv">Sample_Framework.csv</a></li>
								
							</ul> 
						</div>
					</div>

					
				</div>
				
			</div>

			
		)
	}
}


const mapStateToProps = state => {
  return{
    dictionaryStatus: state.esgReducers.dictionaryStatus,
    dictionaryCode: state.esgReducers.dictionaryCode,
    dictionaryHash: state.esgReducers.dictionaryHash,
    dictionaryNamesList: state.esgReducers.dictionaryNames,
    dictionaryCodeStatus: state.esgReducers.dictionaryCodeStatus,
    file_hash: state.esgReducers.file_hash,
    file_path: state.esgReducers.file_path,
    
    
  };
}

const mapDispatchToProps = dispatch => {
  return {
    UploadDictionaryFile : (data) => dispatch(dataActions.UploadDictionaryFile(data)),
    getDictionaryFilesName : (data) => dispatch(dataActions.getDictionaryFilesName(data)),
    deleteDictionary : (deleteParams) => dispatch(dataActions.deleteDictionary(deleteParams)),
   
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(UploadDictionary);
