import React, { Component } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import * as dataActions from '../../../store/actions/index';
import {connect} from 'react-redux';

class PortfolioMultiSelect extends Component {

	constructor(props){
    super(props);

    this.state = {
    	options: [
    	],
    	selectedStocks:[],
    	countryName:'india',
    }
    this.onSelectStock = this.onSelectStock.bind(this);
    this.onRemoveStock = this.onRemoveStock.bind(this);
	    
	}

	componentDidMount(){
		
	}

	componentDidUpdate(prevProps, prevState) {
		
		let fileNameList = [];
		// console.log('fileNameListtt',this.props.fileNameList)
		if(this.props.fileNameList){
			if(this.props.fileNameList !== prevProps.fileNameList){
			
				this.props.fileNameList.map((name,index) => {
					let newname = name.split('“');
					let finalStock = newname.pop().split('”')[0]
				
					fileNameList.push ({
						name: finalStock,
						id: index
					})
				})
				
				this.setState({
					options:fileNameList
				});
			}
		}
		
		
		
	}

	onSelectStock(selectedList, selectedItem){
		
		this.setState({
			selectedStocks:selectedList
		});
		this.props.onSelectPortfolio(selectedList);
	}

	onRemoveStock(selectedList, removedItem) {
	
		this.setState({
			selectedStocks:selectedList
		})
		this.props.onSelectPortfolio(selectedList);
	}

	render() {
	
		return (
			<div className="multiselect_stocks">
				{!this.props.sbtiFlag ? <Multiselect
					selectionLimit="5" options={this.state.options} onSelect={this.onSelectStock} onRemove={this.onRemoveStock} displayValue="name"  selectedValues={this.state.selectedStocks} 
				/> : <Multiselect
					selectionLimit="1" options={this.state.options} onSelect={this.onSelectStock} onRemove={this.onRemoveStock} displayValue="name"  selectedValues={this.state.selectedStocks} 
				/> }
			</div>
		)
	}
}

const mapStateToProps = state => {
    return{
        stockNames: state.graphReducer.stockList,
       
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getStockList : (stockParams) => dispatch(dataActions.getStockList(stockParams)),
       
     
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PortfolioMultiSelect);