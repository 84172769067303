import React, { Component } from 'react';
import overview1 from '../../../assets/images/overview1.png';
import overview2 from '../../../assets/images/overview2.png';


class Overview extends Component {
	render() {
		return (
			<div className="viewMain">
				<span className="sub_heading">Lorum ipsum</span>
				<h1 className="main_heading">Overview</h1>
				<p className="title_text">Lorem Ipsum is simply dummy text of the printing and <br />typesetting industry</p>
				<div className="content_main">
					<div>
						<div className="image_container">
							<img src={overview1} />
						</div>
						<div className="description">
							<p><span>Emerging Markets Focus:</span> AI methods to harvest EM alpha.</p>
							<p><span>Systematic Data Driven Approach:</span> NLP, Sentiment, Entity Resolution etc</p>
						</div>
					</div>
					<div className="swap_img">
						<div className="image_container">
							<img src={overview2} />
						</div>
						<div className="description">
							<p><span>Information Access:</span> Using Machine Translation to access Local Language Intelligence.</p>
							<p><span>ESG: </span>Invest better with AI driven EM ESG Scores.</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
	
export default Overview;		