export default [
  {
     title: 'Does Economic Revival from Coronavirus Pandemic Depend on Recklessness in Human Behavior? ',
     summary: 'There is evidence that the economic recovery from an unforeseen event is dependent on how quickly people will get back to normal. This EMAlpha insight takes a look at how the ‘speed of revival’ from the effects of Coronavirus pandemic depends on how ‘risk-taking’ people are. Less ‘risk averse’ people are, faster the recovery would be. The difference between UK and Continental Europe are recovering at a different pace mainly because of difference in threat perception while the on-ground statistics are alomost same on the impact of Coronavirus pandemic.',
     category: 'COVID-19',
     date: '13-Aug-20',
  },
  {
    title: 'Do the Global Financial Markets Care About COVID-19 Numbers Anymore?',
     summary: 'March 2020 was tumultuous for global markets as Coronavirus pandemic led to a huge crash, it required a strong monetary policy response from Central Banks to avert the crisis. But the question now is: Is Coronavirus pandemic still the driving force for the markets? Or the markets have moved on? This is the question we explore here on the basis of what EMAlpha machines have picked up.',
     category: 'COVID-19',
     date: '21-Jun-20',
  },
  {
    title: 'Coronavirus Threat and Lockdowns: Which Countries can Afford Them and for How Long?',
   summary: 'An analysis of the country-by-country EMAlpha New Sentiment shows that the Govt’s stance to combat Coronavirus pandemic is dependent on threat sentiment. When we analyse country sentiment scores for our news collection, we find that there are significant differences in the tone and frequency of Coronavirus related news flow. Some of the countries which are still nowhere close to the disastrous impact some others have seen, are still more scared. In reality, a country’s ability to afford a lock down depend on its economy, both size as well as wealth distribution over the population. However, Govt policy on lock downs will be determined more by threat perception than the ability to afford it.',
   category: 'COVID-19',
   date: '10-May-20',
 },
 {
    title: 'Oil’s Historic Fall: Precipitated by Quickly Worsened Sentiment amid Demand Concerns',
    summary: 'As May WTI Futures traded in Negative, the historic fall in crude oil prices prompted us to investigate this issue further on the lines of how EMAlpha Oil news sentiment fared around that time. After Coronavirus pandemic has become a global issue, the market performance in several countries is strongly linked with local news flow and underlying sentiment on Coronavirus related developments. This applies to Oil too as the EMAlpha Oil News sentiment plot shows how the oil sentiment has fallen to a level lower than what it was in mid-March when the Saudi-Russia talks broke down and oil price and stock market fell.',
    category: 'COVID-19',
    date: '22-Apr-20',
 },
 {
    title: 'Crude Oil and Coronavirus Pandemic: Oil Futures in Negative and How Machines are Reading it?',
    summary: 'Monday, 20th April 2020 was a historic day for Crude Oil Industry as the May US oil futures contract in went into negative territory for the first time in history. The US reserves are full and there is simply no place to put the crude and everyone is avoiding taking delivery of physical crude. But it is not just a storage problem. There is limited visibility on when the demand will recover. This is also visible on EMAlpha Oil News Sentiment tracker as well. When seen in the context of SAUDI Aramco’s stock price movement and recovery in S&P 500, we can conclude that investors are less worried about markets in general and the concern is limited to Oil only.',
    category: 'COVID-19',
    date: '21-Apr-20',
 },
 {
    title: 'Is Donald Trump Losing Perception Battle on Coronavirus Pandemic and Why Should Markets care?',
    summary: 'In the past, US presidents have mostly benefitted from crisis, politically and in the eyes of media. However, EMAlpha News flow Sentiment on US President, Donald Trump had quickly worsened over last couple of weeks. And it has worsened significantly. Trump’s inconsistent approach and him being in rush to lift the lockdown could have been the key factors in making Trump an easy media target but it is a fact that the sentiment in news on Trump is negative. Why this is important for the markets in short to medium terms? Effective handling of crisis matters for Economic recovery and if the negative news sentiment on Trump sustains, this may play a key role in US presidential elections.',
    category: 'COVID-19',
    date: '15-Apr-20',
 },
 {
    title: 'Impact of Coronavirus: Is Timing the Markets Possible Using Sentiment Analysis?',
    summary: 'Is it possible to position your portfolio for market volatility in a timely manner? We look at this question using EMAlpha Sentiment Signal as a guide. We consider two major emerging equity markets: Brazil and India and use EMAlpha Sentiment based Signals, constructed using Machine Translation and Sentiment Analysis on news flow in major languages: a) Portuguese and English for Brazil, b) Hindi and English for India, to trade the broad equity indices in these two countries. The result, in terms of the cumulative return in excess of the equity benchmark shows that EMAlpha Sentiment Signal for Brazil and India seem to have navigated the recent unprecedented market volatility quite well.',
    category: 'COVID-19',
    date: '13-Apr-20',
 },
 {
    title: 'News Sentiment on Coronavrius Pandemic: Darkest Before the Dawn or No Light at End of the Tunnel?',
    summary: 'Over the last few days, the EMAlpha Coronavirus News Sentiment has deteriorated in eleven out of the twelve countries in our main panel (USA, China, Germany, Brazil, Italy, India, Mexico, Malaysia, Norway, Colombia, Australia and Poland). More importantly, out of the eleven countries in which sentiment has turned negative, more than half (total six) see rather sharp decline. This doesn’t inspire much confidence that we are anywhere close to recovery. EMAlpha Sentiment analysis for some key words such as Donald Trump, Fed Rate, Nasdaq Composite, Chinese Economy and the US Economy, also throw interesting conclusions.',
    category: 'COVID-19',
    date: '6-Apr-20',
 },
 {
    title: 'Coronavrius News Sentiment vs. Aggregate Market Sentiment and the Importance of Base Rate',
    summary: 'EMAlpha’s Aggregate Market Sentiment has a base rate which is above 0. This makes sense because the usual market sentiment has a positive bias and hence, the change in market sentiment should be measured as compared to this base rate. EMAlpha Sentiment Analysis tells us that the absolute values don’t matter that much and only how far is the point in either direction from the base rate, matters. The same things apply to languages, books, movies and almost everything where we can do Sentiment Analysis. This has important implications for how to read the signals and what to deduce from Sentiment Analysis.',
    category: 'COVID-19',
    date: '1-Apr-20',
 },
 {
    title: 'Dichotomy Between News and Reaction: Coronavirus Pandemic Getting worse, But Markets Getting Better',
    summary: 'To assess the market impact of Coronavirus pandemic, EMAlpha tracks several important indicators including, a) Coronavirus Country-by-Country Sentiment, b) Global Coronavirus Sentiment, c) News Topic Sentiment on terms such as “Donald Trump”, “Dow Jones Index”, “Fed Interest Rate”, “Nasdaq Composite”, “Chinese Economy” and “US Economy”, d) Crude Oil related News Sentiment, and, e) Coronavirus Sentiment Heat Map. These EMAlpha Sentiment trackers offer interesting insights on the current state of this pandemic and how News coverage is reacting to it.',
    category: 'COVID-19',
    date: '31-Mar-20',
 },
 {
    title: 'Coronavirus News Sentiment: Voltaility and Direction of Indian Markets',
    summary: 'If we map the EMAlpha Aggregate Market Sentiment for India and not just Coronavirus News Sentiment for the country, with the local market indices, there is a clear message that the market volatility may continue in the near term.  The smart recovery on Friday (20th March) followed by an even deeper fall on Monday (23rd March) gels very well with the EMAlpha India Corona News Sentiment. EMAlpha Coronavirus News Sentiment also tells that US markets may continue to remain in deep turmoil.',
    category: 'COVID-19',
    date: '24-Mar-20',
 },
 {
    title: 'Coronavirus Pandemic, News Sentiment and Where the Markets Are Going?',
    summary: 'The EMAlpha Emerging Markets model portfolio performance, has delivered significantly better returns versus the market index recently. And it is easy to see the reason. In the recent Coronavirus pandemic driven reaction of Global Financial Markets, Sentiment is a dominant driver of investor behaviour. How does EMAlpha do Sentiment Anlsysis on the news flow? EMAlpha machines are collecting Coronavirus related targeted news flow in a variety of languages. For a number of emerging market countries, most of the news flow is in local language and, hence, we focus on news collection in both the local language and in English. We then use natural language processing tools to extract the sentiment for each country. The details and takeaways are discussed in this insight.',
    category: 'COVID-19',
    date: '23-Mar-20',
 },
{
    title: 'Coronavirus News Sentiment Deteriorating and Peculiarities of Country News Sentiment',
    summary: 'After the already disastrous impact on global health scenario and the severe hit to world economy because of Coronavrius pandemic, the bigger worry now is that the EMAlpha Aggregate Coronavirus sentiment (the sum total of news flow across all countries which we track) continues to plunge. Also, the latest edition of EMAlpha Country by country "Corona Sentiment" based on news flow continues to show further deterioration in many countries including USA. However, things have started to look up in China and India is seeing a further fall in Corona Sentiment and thats bearish.',
    category: 'COVID-19',
    date: '18-Mar-20',
 },
 {
    title: 'Human Psychology, High Profile Covid-19 Cases and the Impact on Sentiment',
    summary: 'While huge fluctuations in EMAlpha Coronavirus sentiment continue for different countries, this news flow sentiment is driven by what kind of news items are getting more coverage. For example, the EMAlpha Coronavirus news flow sentiment for Canada has deteriorated sharply and this is because of a high-profile case of infection (Prime Minister’s wife). In USA, the huge volatility noticed in the markets is linked with rapid deterioration on one side and the policy announcements on the other. Similarly, there are interesting trends from other countries as well.',
    category: 'COVID-19',
    date: '15-Mar-20',
 },
 {
    title: 'News Sentiment, Market Imapct and Unchecked Spread of Coronavirus Pandemic',
    summary: 'On March 9th 2020, VIX Index, the widely monitored gauge of market volatility, shot up to levels not seen since the Great Financial Crisis more than ten years ago. At EMAlpha, we monitor daily news sentiments in a range of emerging and developed markets and from this, it has become clear that Coronavirus related news sentiment could continue to be a driver of market sentiment for a while. We collect Coronavirus related targeted news flow in a number of languages as for many emerging market countries, most of the news flow is in local language. There are some interesting observations on the market impact of the news and underlying sentiment country by country.',
    category: 'COVID-19',
    date: '10-Mar-20',
 },
 {
    title: 'News Sentiment Watch and How Portfolio Managers Can Use It for Systematic Trading?',
    summary: 'EMAlpha Coronavirus Sentiment reflects that the markets have reacted strongly to pandemic related news and country-by-country scores are even more interesting. We have used EMAlpha proprietary sentiment measure to first find the raw sentiment for each country for news related to Coronavirus and the raw scores were then Z-scored for standardization. As the daily sentiment changes for countries, it can be a useful input into systematic trading model for portfolio managers and it can also be valuable in risk management.',
    category: 'COVID-19',
    date: '4-Mar-20',
 },
 {
    title: 'Coronavirus Impact on Markets: Is Local News Sentiment Getting More Important?',
    summary: 'In the initial response to Coronavirus pandemic, most financial markets globally reacted in a predictable negative fashion. But this is slowly changing now and the role of local news flow and underlying sentiment is getting more prominent. For example, after India reported two fresh cases of Coronavirus in the afternoon on 2nd March, Indian markets reacted sharply and closed lower. It is not looking very good on EMAlpha country-by-country sentiment analysis and there could be more downside ahead.',
    category: 'COVID-19',
    date: '2-Mar-20',
 },
 {
    title: 'Coronavirus and Markets: Sentiment Bigger Driver than Fundamentals in Near-Term',
    summary: 'As the news sentiment has evolved leading to the market gyrations because of Coronavirus pandemic, EMAlpha is tracking the overall market sentiment on a daily basis. While the way forward does not look good, sentiment will clearly be a bigger driver for markets than fundamentals in the near term. In this insight, we also take a look at some of the important questions such as how are hedge fund strategies doing against this backdrop and how has the EMAlpha long/short equity strategy done?',
    category: 'COVID-19',
    date: '27-Feb-20',
 },
 {
    title: 'Coronavirus Pandemic and How Sentiment is Impacting the Emerging Markets?',
    summary: 'The impact on the global markets from Coronavirus pandemic also seems to have a local component as Asian markets close to China have suffered more than others. Geographically distant markets have remained shielded. When we look at how EMAlpha Sentiment Score has evolved over the last month for some of these emerging countries and the performance of their equity markets, the maximum market impact has been in smaller neighboring countries, like Thailand.',
    category: 'COVID-19',
    date: '9-Feb-20',
 },
 {
    title: 'Coronavirus News Sentiment: How much is NOISE in the SIGNAL?',
    summary: 'Though Coronavirus is a global health emergency and there is no proven cure yet in sight, the media coverage is still disproportionate considering the tangible impact estimated as of now. In the age of rapid information dissemination and huge role played by social media, this skew is even more prominent. Naturally, financial markets also react more to these events. Whether the impact of Coronavirus is NOISE or SIGNAL, the reaction of financial markets proves ‘News may be the same, but the impact could be vastly different because of the varied nature of Sentiment’. EMAlpha tracks this news flow and the underlying sentiment.',
    category: 'COVID-19',
    date: '9-Feb-20',
 },
];  
