import React , {Component} from "react";
import Dropzone from "react-dropzone";

// for pdf files
class FileUpload extends Component {

  constructor(props){
    super(props);

    this.state = {
      acceptedFiles:[],
      
    }
    this.getAcceptedFiles = this.getAcceptedFiles.bind(this);
    
  }


  getAcceptedFiles(acceptedFiles){
    /*console.log('fileslist',acceptedFiles);
    console.log('fileslist',acceptedFiles.length);*/
    this.props.handleUploadedFiles(acceptedFiles);
    this.setState({
      acceptedFiles: acceptedFiles
    })
  }


  render(){
    return (
      <div className="dropUploader">
        <Dropzone   onDrop={this.getAcceptedFiles} >
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()}  accept=".pdf " />
                <p>Drag 'n' drop up to 10 files here (up to 30 MB size per file), or click to select files</p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  }
    
}

export default FileUpload;
