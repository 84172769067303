import React, { Component } from 'react';
import Overview from './Overview'; 
import ReactWordcloud from 'react-wordcloud'; 
import './Home.css'; 
import placeholder1 from '../../../assets/images/placeholder5.png';
import placeholder3 from '../../../assets/images/placeholder3.jpg';
let count = 1;
let myVar = '';

 

class Home extends Component {

	constructor(props){
	    super(props);

	    this.state = {
	    	'options' : {
				  colors: ['#083b66', '#ff7f0e', '#2ca09c', '#d62728', '#000', '#8c564b'],
				  enableTooltip: false,
				  deterministic: false,
				  fontFamily: 'impact',
				  fontSizes: [5, 60],
				  fontStyle: 'normal',
				  fontWeight: 'normal',
				  padding: 5,
				  rotations:1,
				  rotationAngles: [0, 0],
				  scale: 'sqrt',
				  spiral: 'archimedean',
				  transitionDuration: 2000,
				},
			'words': [
				  {
				    text: 'Emerging Markets',
				    value: 25
				  },
				  {
				    text: 'NYSE',
				    value: 21,
				  },
				  {
				    text: '上证综指',
				    value: 36,
				  },
				  {
				    text: 'BSE Sensex',
				    value: 27,
				  },
				  {
				    text: 'सेंसेक्स',
				    value: 10,
				  },
				  {
				    text: '코스피지수',
				    value: 54,
				  },
				  {
				    text: 'Reliance',
				    value: 22,
				  },
				  {
				    text: 'Infosys',
				    value: 47,
				  },
				  {
				    text: 'Índice Bovespa',
				    value: 25,
				  },
				  {
				    text: 'ตลาดหุ้น',
				    value: 19,
				  },
				  {
				    text: 'Metallo',
				    value: 13,
				  },
				  {
				    text: 'stock rally',
				    value: 22,
				  },
				  {
				    text: 'correction',
				    value: 22,
				  },
				  {
				    text: 'लिमिटेड',
				    value: 35,
				  },
				  {
				    text: 'Euro Stoxx',
				    value: 24,
				  },
				  {
				    text: 'S&P500',
				    value: 38,
				  },
				  {
				    text: 'रुपये की गिरावट',
				    value: 30,
				  },
				  {
				    text: 'हिन्दुस्तान यूनिलीवर',
				    value: 22,
				  },
				  {
				    text: 'compañía ya puede ser considerada',
				    value: 14,
				  },
				  {
				    text: 'economía global',
				    value: 29,
				  },
				  {
				    text: '交易代码',
				    value: 21,
				  },
				  {
				    text: '交易',
				    value: 19,
				  },
				  {
				    text: 'compañía',
				    value: 20,
				  },
				  {
				    text: '事业单位',
				    value: 19,
				  },
				  {
				    text: 'companhia',
				    value: 49,
				  },
				  {
				    text: 'नियम',
				    value: 15,
				  },
				  {
				    text: 'Sectors',
				    value: 25,
				  },
				  {
				    text: '上证综指',
				    value: 22,
				  },
				  {
				    text: 'Ratings',
				    value: 20,
				  },
				  {
				    text: 'ऊर्जा',
				    value: 28,
				  },
				  {
				    text: 'Energy',
				    value: 24,
				  },
				  {
				    text: '金',
				    value: 14,
				  },
				  {
				    text: 'Brent',
				    value: 27,
				  },
				  {
				    text: 'Gold',
				    value: 20,
				  },
				  {
				    text: '能源',
				    value: 46,
				  },

				  {
				    text: 'Emerging Markets',
				    value: 25,
				  },
				  {
				    text: 'NYSE',
				    value: 21,
				  },
				  {
				    text: '上证综指',
				    value: 36,
				  },
				  {
				    text: 'BSE Sensex',
				    value: 17,
				  },
				  {
				    text: 'सेंसेक्स',
				    value: 20,
				  },
				  {
				    text: '코스피지수',
				    value: 54,
				  },
				  {
				    text: 'Reliance',
				    value: 20,
				  },
				  {
				    text: 'Infosys',
				    value: 37,
				  },
				  {
				    text: 'Índice Bovespa',
				    value: 25,
				  },
				  {
				    text: 'ตลาดหุ้น',
				    value: 19,
				  },
				  {
				    text: 'Metallo',
				    value: 30,
				  },
				  {
				    text: 'stock rally',
				    value: 32,
				  },
				  {
				    text: 'correction',
				    value: 22,
				  },
				  {
				    text: 'लिमिटेड',
				    value: 35,
				  },
				  {
				    text: 'Euro Stoxx',
				    value: 24,
				  },
				  {
				    text: 'S&P500',
				    value: 38,
				  },
				  {
				    text: 'रुपये की गिरावट',
				    value: 30,
				  },
				  {
				    text: 'हिन्दुस्तान यूनिलीवर',
				    value: 26,
				  },
				  {
				    text: 'compañía ya puede ser considerada',
				    value: 14,
				  },
				  {
				    text: 'economía global',
				    value: 29,
				  },
				  {
				    text: '交易代码',
				    value: 21,
				  },
				  {
				    text: '交易',
				    value: 19,
				  },
				  {
				    text: 'compañía',
				    value: 20,
				  },
				  {
				    text: '事业单位',
				    value: 29,
				  },
				  {
				    text: 'companhia',
				    value: 49,
				  },
				  {
				    text: 'नियम',
				    value: 15,
				  },
				  {
				    text: 'Sectors',
				    value: 20,
				  },
				  {
				    text: '上证综指',
				    value: 22,
				  },
				  {
				    text: 'Ratings',
				    value: 12,
				  },
				  {
				    text: 'ऊर्जा',
				    value: 28,
				  },
				  {
				    text: 'Energy',
				    value: 54,
				  },
				  {
				    text: '金',
				    value: 14,
				  },
				  {
				    text: 'Brent',
				    value: 11,
				  },
				  {
				    text: 'Gold',
				    value: 24,
				  },
				  {
				    text: '能源',
				    value: 16,
				  },
				]
	    }
	}

	componentDidMount() {
		  window.location.href = "/signin";
	    myVar = setInterval(() => {
	      //this.forceUpdate();
	      	count ++;
		    let index = this.state.words.findIndex(word => word.text === 'Emerging Markets');
		    let items = [...this.state.words];
		    let item = {...items[index]};
		    item.value =  item.value + 100;
		    items[index] = item;
		    this.setState({
		    	words:items,
		    	option: this.state.options
		    });
	    }, 3000);
	    
	   
	}
	render() {
		// console.log('count', count)
	    if(count >= 4){
	    	clearInterval(myVar);
	    }
		return (
			<div>
				<div className="main_container">
					<div className="home_page">
						<div className="container">
							<div className="banner_section">
								<div className="wordcloud_main">
								 	<ReactWordcloud options={this.state.options} words={this.state.words} />
								    
							    </div>
							</div>
							
						</div>
					</div>
					<div className="overviewSection">
						
						<div className="container">
							<Overview />
						</div>
					</div>
					<div className="aboutus_section">
						<div className="container">
							<div className="content_left">
								<span className="sub_heading">Who are we ?</span>
								<h1 className="main_heading">About us</h1>
								<p>The EMAlpha team consists of  members with diverse backgrounds and experience covering Emerging Markets, Portfolio Management and Trading, Quantitative Analysis and Machine Leaning, and Regulatory Expertise. The team consists of domain experts in Emerging Markets with significant experience in fundamental and quantitative analysis.</p>
								<p>One of our team members  has over  20 years of experience in complex international legal and regulatory issues.  Other team members have Ph.D.s in Hard Sciences and have spent considerable time applying Machine Learning methods to financial markets and pharmaceutical industry.</p>
								<p>Yet another team member has been a recipient of Thomson Reuters' Starmine Analyst Award for 'Best Earnings Estimates' and 'Best Stock Picker'. We believe that a strong team with such diverse backgrounds helps us craft a multidimensional approach to solve the financial data and investment problems in front of us.</p>
							</div>

							<div className="image_right">
								<img alt="" src={placeholder1} className="place1" />
								<img alt="" src={placeholder3} className="place2" />
								<img alt="" src={placeholder3} className="place3" />
							</div>

							<div className="about_copoints">
								<div>
									<div className="achieved_sec">
										<div>
											<div className="icon_sec">
												<i class="fa fa-commenting" aria-hidden="true"></i>
											</div>
											<div className="details">
												<span>We achieved</span>
												<h4>30-40%</h4>
												<p>Lorum</p>
												<span>in Lorun ipsum</span>
											</div>
										</div>
									</div>

									<div className="achieved_sec">
										<div>
											<div className="icon_sec">
												<i class="fa fa-user" aria-hidden="true"></i>
											</div>
											<div className="details">
												<span>We achieved</span>
												<h4>30-40%</h4>
												<p>Lorum</p>
												<span>in Lorun ipsum</span>
											</div>
										</div>
									</div>

									<div className="achieved_sec">
										<div>
											<div className="icon_sec">
												<i class="fa fa-clock-o" aria-hidden="true"></i>
											</div>
											<div className="details">
												<span>We achieved</span>
												<h4>30-40%</h4>
												<p>Lorum</p>
												<span>in Lorun ipsum</span>
											</div>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Home;