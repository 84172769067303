import React, { Component } from 'react';
import Slider from "react-slick";
import * as dataActions from '../../../store/actions/index';
import {connect} from 'react-redux';
import loader from '../../../assets/images/loader.gif';

const messagesEndRef = React.createRef();



 
class News extends Component {

	constructor(props){
	    super(props);

	    this.state = {
	    	noData: true,
	    	apiresult:false,
	    	dataDescription: "Please select the stocks and hit 'Go' button to fetch news. ",
	    	dataNews: []
	    }
	   
	} 

	componentDidMount() {
		//console.log('in mount')

		//this.props.onfetchNewsData()
		/*this.setState({
			dataDescription: "No data present , Please select the stocks and hit 'Go' button."
		})*/

		this.setState({
			dataNews: [],
			noData: true,
		})
	}
	
	componentDidUpdate(prevProps, prevState) {
		if(this.props.gohitValue === true){
				console.log('dsfdsfdsfdsf')

		}
	

		if(this.props.newsrand_hash !== prevProps.newsrand_hash){
			//console.log('8888888')
			if(this.props.newsStatusCode === 1){
				this.setState({
					dataDescription: "",
					noData: false,
					dataNews: this.props.dataNews,

				})
				this.props.ondataget(true)


			}else{
			
				this.setState({
					dataDescription: "No data found",
					noData: true ,
					apiresult : true,
					dataNews:[],
				})
				this.props.ondataget(true)

				
			}
		}
	}

	
	render() {
		 //console.log('apiresult',this.state.apiresult);
		
		const settings = {
		      dots: false,
		      infinite: true,
		      slidesToShow: 3,
		      slidesToScroll: 1,
		      vertical: true,
		      verticalSwiping: true,
		      autoplay: true,
		      swipeToSlide: true,
		      speed: 1000,
		      pauseOnHover: true,
		      autoplaySpeed: 2000,
		      initialSlide:0,
		      beforeChange: function(currentSlide, nextSlide) {
		        //console.log("before change", currentSlide, nextSlide);
		      },
		      afterChange: function(currentSlide) {
		        //console.log("after change", currentSlide);
		      },
		     /* onInit: () => function(){
		      	console.log('00000')
		      }*/
		};
			//console.log('this.props.nonewsdisplay',this.props.nonewsdisplay)
		return (
			<div className="new_maincontainer">
					<div className="new_blogs">
						<div className="heading_news"><h2>News Flow</h2></div>
						{this.props.nonewsdisplay === "false" ? <div className="inner_news">
						{this.state.dataNews ?
							<div>
								{this.state.dataNews.length > 5 ?
								<Slider {...settings}>
								 {this.state.dataNews.map((news,index) => (
										<div className="new_items" key={index} value={news.index}>
                      <div className="new_head">
                        <i className="fa fa-newspaper-o" aria-hidden="true"></i>
                        <h5></h5>

                      </div>
                      <div className="new_body">
                        <a className="truncate_text" target="_blank" href={news.URL}>{news.URL}</a>
                        <p>{news.Description}</p>
                      </div>
                    </div>
									))}

									
									
							</Slider> : 

							<div className="display_news">
								 {this.state.dataNews.map((news,index) => (
										<div className="new_items" key={index} value={news.index}>
                      <div className="new_head">
                        <i className="fa fa-newspaper-o" aria-hidden="true"></i>
                        <h5></h5>

                      </div>
                      <div className="new_body">
                        <a className="truncate_text" target="_blank" href={news.URL}>{news.URL}</a>
                        <p>{news.Description}</p>
                      </div>
                    </div>
									))}
							</div> }

							</div>	: ""}

						</div> : ""}
						{this.state.noData ? <div className="text_items">
							{!this.state.apiresult ? <h3>{this.props.dataDescription}</h3> : <h3>{this.state.dataDescription}</h3> }
						</div> : ""}
					</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
    return{
        dataNews: state.esgReducers.ResponseDataNews,
        newsStatus: state.esgReducers.newsStatus,
        newsStatusCode: state.esgReducers.newsStatusCode,
        newsrand_hash: state.esgReducers.newsrand_hash,
        changeText: state.esgReducers.changeText,
    };
}

export default connect(mapStateToProps,)(News);